// TODO: CURRENTUS: Test it on env with markers on map
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    HostListener,
    ElementRef,
    ViewEncapsulation,
} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { transform } from 'ol/proj';
import { DomOperationUtilsService } from '../shared/utils/dom-operation-utils.service';
import { UiUtilsService } from '../shared/utils/ui-utils.service';
import { StatusCodeService } from '../shared/services/status-code.service';
import { SignalRService } from '../shared/services/signalr.service';
import { SignalRMessage } from 'app/shared/models/signalr-message';
import { Observable, combineLatest, Subject, Subscription, BehaviorSubject, from, of, forkJoin } from 'rxjs';
import OLMap from 'ol/Map';
import View from 'ol/View';
import Feature, { FeatureLike } from 'ol/Feature';
import { createStyleFunction } from 'ol-esri-style';
import Geolocation from 'ol/Geolocation';
import { Attribution, Control, OverviewMap, ScaleLine, defaults as defaultControls } from 'ol/control';
import Point from 'ol/geom/Point';
import { Layer, Group as LayerGroup, Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Cluster, XYZ, Vector as VectorSource, TileWMS, TileArcGISRest, Source } from 'ol/source';
import Draw from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
import { BasemapNames, GISService, LayerSettings } from 'app/shared/services/gis-service';
import {
    AppQueryParams,
    DialogQueryParams,
    SubscriptionLevel
} from 'app/shared/models/customer';
import {
    BaseMapListItem,
    gisUserSettings,
    mapLayerList,
    featureSelList,
    userSettingLayerDetails,
    ServiceTypes,
} from 'app/shared/models/gis-service-list';
import { Polygon, Geometry, Circle, LineString } from 'ol/geom';
import {
    measureStyle,
    featureSelectionStyle,
    monitorLocationStyle,
    monitorLegendOptions,
    generateCompositeLocationPopupTemplate,
    generateLocationsClusterPopupTemplate,
    generateSingleMonitorPopupTemplate,
    generateLinePointPolygonPopupTemplate,
    monitorLocation,
    highlightLineOrPoint,
    monitorStyleFilter,
    pickSingleMonitorPopupTemplate,
    darkThemeColors,
    lightThemeColors
} from 'app/shared/constant/mapStyle.constant';
import { apply } from 'ol-mapbox-style';
import { ChecklistDatabase, LocationLayerListComponent } from './location-layer-list/location-layer-list.component';
import { locationFeaturePropertyListComponent } from './location-feature-property-list/location-feature-property-list.component';
import { Config } from 'app/shared/services/config';
import { OLMAPPROJECTION, ADS_MONITOR_LAYER, UnitOfMeasureType, RAIN_ENTITY, RAIN_FINAL_ENTITY } from 'app/shared/constant';
import { DragBox, Select, DoubleClickZoom } from 'ol/interaction';
import { platformModifierKeyOnly } from 'ol/events/condition';
import { debounceTime, filter, first, map, skip, startWith, switchMap, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { LocationStepsGPSComponent } from './location-steps-gps/location-steps-gps.component';
import { fromLonLat } from 'ol/proj';
import * as turf from '@turf/turf';
import * as converter from 'xml-js';
import TileState from 'ol/TileState';
import { CustomerDetailLocationService } from 'app/shared/services/customer-detail-location.service';
import { CustomerLocationDetail } from 'app/shared/models/customer-location-detail';
import { CompositeLocComponent } from 'app/shared/components/map/composite-loc/composite-loc.component';
import GeometryType from 'ol/geom/GeometryType';
import flatMap from 'lodash/flatMap';
import BaseLayer from 'ol/layer/Base';
import EsriJSON from 'ol/format/EsriJSON';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { fromCircle } from 'ol/geom/Polygon';
import { Style, Fill, Stroke, RegularShape } from 'ol/style';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { MainComponent } from 'app/main';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { AlarmService } from 'app/shared/services/alarm.service';
import { AuthService } from 'app/shared/services/auth.service';
import { MapService } from 'app/shared/services/map.service';
import { LocationService } from 'app/shared/services/location.service';
import { BlockagePredictionService } from 'app/shared/services/blockage-prediction.service';
import { BatteryStatusService } from 'app/shared/services/battery-status.service';
import { EventService } from 'app/shared/services/event.service'
import { AutoScrubSummaryService } from 'app/shared/services/auto-scrub-summary.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
    WIDGET_PARAM,
} from 'app/shared/models/customer';
import { LocationEntitiesData } from 'app/shared/models/locations-entities-data';
import { CumulativeRainRequest, LocationDetails, LocationDetailsModel } from 'app/shared/models/location-details';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { AlarmsTotal, GetAlarmsArgs } from 'app/shared/models/alarms';
import { AlarmLiteResponseItem } from 'app/shared/models/active-alarm';
import { CollectionHistory, GetCollectHistoryArgs } from 'app/shared/models/collection-history';
import { AnomaliesCount } from 'app/shared/models/AnomaliesCount';
import { BatteryTotalCount } from 'app/shared/models/battery-total-count';
import { WidgetsPermission } from 'app/shared/models/widgets-permission';
import { AutoDetectDetectCountArgs } from 'app/shared/models/auto-scrub-details';
import { BatteryStatusEnum, BatteryStatusReportArgs } from 'app/shared/models/battery-status';
import { AlarmStatusChange } from 'app/shared/models/alarm-status-change';
import { MarkerLocationDetailsComponent } from 'app/shared/components/map/marker-location-details/marker-location-details.component';
import { MapBrowserEvent, Overlay, VectorTile } from 'ol';
import { LocationGisQuickLookComponent } from './location-gis-quick-look/location-gis-quick-look.component';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import Projection from 'ol/proj/Projection';
import { AddEventDialogComponent } from 'app/pages/add-event-dialog/add-event-dialog.component';
import { EventModel, EventRain, EventTypes } from 'app/shared/models/event';
import { UiService } from 'app/shared/services/ui.service';
import { LayerNameOpts } from 'app/shared/models/map-type';
import html2canvas from 'html2canvas';
import { EventRangeFilters, EventWidgetComponent } from 'app/pages/event-widget/event-widget.component';
import { MapRain, MapRainColors, StormYearsColors } from 'app/shared/models/map-view';
import { IN_TO_MM_FACTOR, MM_TO_MM_FACTOR } from 'app/shared/models/units';
import { CumulativeRainInfo } from 'app/pages/cumulative-rain-widget/cumulative-rain-widget.component';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { UserPreferencesDateQuickSpan } from 'app/shared/models/user-settings';
import { DateService } from 'app/shared/services/date.service';
import { CollectionEvent } from 'ol/Collection';
import { AdminBillingMetricsComponent } from 'app/pages/admin/admin-usage/admin-billing-metrics/admin-billing-metrics.component';
import { ComponentType } from '@angular/cdk/portal';
import { AdminCostAllocationComponent } from 'app/pages/admin/admin-usage/admin-cost-allocation/admin-cost-allocation.component';

declare module 'ol/layer/BaseTile' {
    export interface Options {
        name: string;
    }
}
declare module 'ol/layer/Group' {
    export interface Options {
        name: string;
    }
}
declare module 'ol/layer/BaseVector' {
    export interface Options {
        name: string;
    }
}
declare module 'ol/layer/Base' {
    export interface Options {
        name: string;
    }
}

const MONITOR_LAYER_NAME = "Monitor";
const esrijsonFormat = new EsriJSON();

enum TABS {
    WIDGET = 0,
    LAYERS = 1,
    TOOLS = 2,
}

const AUTO_REVIEW_REVIEW_REQUIRED = 'Review Required';
const AUTO_REVIEW_MISSING_DATA = 'Missing Data';



@Component({
    selector: 'app-location-dashboard',
    templateUrl: './home-map-dashboard.component.html',
    styleUrls: ['./home-map-dashboard.component.scss'],
})
export class HomeMapDashboardComponent implements OnInit, OnDestroy {
    @ViewChild('selectionlist') public featSelList: locationFeaturePropertyListComponent;
    @ViewChild('selectMenuTrigger') public selectMenuTrigger: MatButton;
    @ViewChild('measureMenuTrigger') public measureMenuTrigger: MatButton;
    // Once ngIf will proceed, map can be processed once reference to layerList is set, Bug #21292
    private layerList: LocationLayerListComponent;
    private preventUC: boolean;
    @ViewChild('layerlist') set content(content: LocationLayerListComponent) {
        if (content && !this.layerList) {
            this.layerList = content;
            this.initMap();
        }
    }

    @ViewChild('gisQuickLook') public gisQuickLook: LocationGisQuickLookComponent;

    @ViewChild('popup') public popup: ElementRef<HTMLDivElement>;
    @ViewChild(EventWidgetComponent) private eventsWidget: EventWidgetComponent;

    @HostListener('window:beforeprint', ['$event']) public onBeforePrint(event?) {
        // Printing takes a screenshot of the screen to render
        // Since openLayers likes to just cut off instead of giving accurate printing, force an image to print
        this.createPrintMap();
    }

    // Widget options
    public widgetOptions = WIDGET_PARAM;
    public currentWidget: WIDGET_PARAM = this.widgetOptions.ALL;
    public openedWidgetData;

    // Map options
    public olMap: OLMap;
    public overviewMapControl: OverviewMap;
    public olView: View;
    public baseLayers: LayerGroup;
    public SubscriptionLevel = SubscriptionLevel;
    public activeBaseMapId: string = null;
    public baseMapOptions: BaseMapListItem[];

    public geometryOptions = GeometryType;

    public activeMonitor: string = null;
    public assignedRainGauge = new Array<Locations>();
    // public mapViews: MapView[] = [];
    // public mapTypes: MapType[] = [];
    public locations: Array<Locations>;
    public filteredLocations: Locations[];
    public initialMapExtent: [number, number, number, number];
    public customerHasChanged: boolean;
    public AdsMonitorLayerSource: VectorSource;
    public AdsMonitorLayerSourceCL: Cluster;
    public AdsMonitorLayer: VectorLayer;
    // public AdsMonitorLayerSourceOV: VectorSource;
    // public AdsMonitorLayerSourceCLOV: Cluster;
    public layerViewParam = '';
    public monitorLayerUrl: string;
    public customerID: number;
    public locationGroupID = 0;
    public projection = OLMAPPROJECTION;

    public prismLayers;
    public prismLayersGroup;
    public manHoleLayer;
    public manholeLayerSource;
    public pipeLayer;
    public pipeLayerSource;
    public basinLayer;
    public basinLayerSource;
    public prismLayerList: LayerSettings[] = [];
    public prismLayerGroupList: any[] = [];
    public mapLayerLists: any[] = [];
    public monitorSelList: any[] = [];
    public manholeSelList: any[] = [];
    public PipelineSelList: any[] = [];
    public basinSelList: any[] = [];
    public featureSelList: any[] = [];
    public selectionListing: any;
    public arcLayers: any = {};
    public checkedLayersList: Set<number>;
    public alarmStateFinder = {};
    public monitorFeatureNamesSet: Set<string>;
    public monitorFeatureCollection: any[] = [];
    public foundSavedLayerExtents = false;
    public esriTileSource: TileArcGISRest;
    public esriTileLayer: TileLayer;
    public layerNameToEsriLayerId: { [index: string]: number };
    public defaultOpacity = 1;
    /**
     * OL map Measure variables
     */
    public propertyItemList = [];
    public prevPropertyItemList = [];

    public measureLayer: VectorLayer;
    public measureDraw: Draw;
    public measureSketch: Feature;
    public measurementUnit = 'us';

    public dateFormat: string;
    public timeFormat: string;
    /*
     * select Interaction
     */
    public SelectionLayer: VectorLayer;
    public SelectionLayerSource: VectorSource;
    public HighlightLayer: VectorLayer;
    public HighlightLayerSource: VectorSource;
    public enableSelection = false;
    public selectInteraction: Select;
    public selectBoxInteraction: DragBox;
    public selectDrawInteraction: Draw;
    public selectedFeatures;
    public customerLocations = new Array<CustomerLocationDetail>();
    private compositeLocationDialog: MatDialogRef<any>;
    /*
     * Scale Control
     */
    public scaleLinecontrol: ScaleLine;

    /**
     *Base Map Constant
     */

    public gisUserSetting: gisUserSettings;

    /*
     * GeoLocation Constant
     */

    public geolocation = new Geolocation({
        trackingOptions: {
            enableHighAccuracy: true,
        },
        tracking: true,
        projection: this.projection,
    });

    public accuracyFeature: Feature;
    public positionFeature: Feature;
    public subscriptionLevel: number;

    /**
     * Location Search Constant
     */
    //locationoptions : locationSearchOption[];
    // public locationoptions = [];
    public searchBarLocation: Observable<string[]>;
    searchControl: FormControl = new FormControl();
    /**
     * sidebar
     */
    public sidebaropen = true;
    public toggleSidePin = true;
    public sidebaropen2 = false;
    public toggleSidePin2 = false;
    public layersParameterJson: any;
    // public userSettingLayerDetailList: userSettingLayerDetails[];

    public activeLayersTab: number;

    public alarmLoadingState: boolean;
    public batteryLoadingState: boolean;
    public bpLoadingState: boolean;
    public bpLoadingLocations = false;
    public autoReviewLoadingState: boolean;
    public collectionLoadingState: boolean;
    public mapLoadingState: boolean;

    public customerLocationsLoaded = false;
    public updateAlarmTable = false;

    public batteryTotalCount: BatteryTotalCount;
    public autoReviewCount: AnomaliesCount;
    public blockagePredictionCount: number;
    public activeAlarmCount: number;

    public failedCollection: number;
    public upcomingScheduleCollection: string;
    public showMap = true;

    public showAlarmSearch = false;
    public showBlockagePredictionSearch = false;
    public showAutoReviwSearch = false;
    public showBatteryStatusSearch = false;
    public showCollectionSearch = false;
    public showEventSearch = false;
    public showCumulativeRainSearch = false;
    public toggleState = true;
    private subscriptions = new Array<Subscription>();
    public events: EventModel[] = [];
    public last30daysEventsCount: number;
    /*
     * @widgetsPermission varibale is used to map api data.
     */
    public widgetsPermission: WidgetsPermission;
    public widgetsPermissionBp: boolean;
    public widgetsPermissionAutoReview: boolean;
    /* as this is required to place widget tool in side bar*/
    public betaFlagBlockagePermission: boolean;
    public betaFlagAutoReviewPermission: boolean;

    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    public includeInactiveLocations = false;

    public availablePageHint = false;

    public anamoliesRefreshSuccessMsg: string;
    public anamoliesRefreshFailedMsg: string;
    public dismissBtn: string;
    public collectionTileLoadingState: boolean;

    public customerIDold = 0;
    public isLoading: boolean;
    private viewableLocationsIds: number[] = [];
    public isAutoReviewEnabled = false;

    public popupOverlay: Overlay;
    saveSubject: any;
    originalLocationsIds: number[];
    locationEntityData: LocationEntitiesData;
    featureclick: featureSelList;
    locationId: any;

    private isDestroyed = false;

    public reloadBatteryLocations$ = new Subject<boolean>();
    // #23323 associative array - [locationId][locationGroupID]
    private locationGroups: number[][];

    private currentCustomerId = new BehaviorSubject(0);

    // #23600 hold state to enable/disable buttons
    public isAnyLayerSelected = false;
    public currentlyDisplayedLocationIds$ = new BehaviorSubject<number[]>([]);
    public displayMapRains = {
        overrideTooltip: false,
        customLegend: false,
        whiteLocations$: new BehaviorSubject<boolean>(false),
        rains$: new BehaviorSubject<MapRain[]>(null)
    }

    public rainLegend = {
        MapRainColors: MapRainColors,
        unitFactor: 1,
        unitPrecisionString: '1.2-2',
        unitTitle: ''
    }
    public stormYearsLegend = {
        StormYearsColors: StormYearsColors
    }


    private monitorsRefreshedSubject = new Subject<number>();

    public cumulativeRainInfo: CumulativeRainInfo;
    public cumulativeRainCachedInfo: CumulativeRainInfo;
    public cumulativeRainCachedQuickSpan: UserPreferencesDateQuickSpan;

    public attributionText: string;

    public routeQueryParams$: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private locationDashboardService: LocationDashboardService,
        public usersService: UsersService,
        private locationGroupService: LocationGroupService,
        private batteryStatusService: BatteryStatusService,
        private autoScrubSummaryService: AutoScrubSummaryService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private scheduleCollectionService: ScheduleCollectionService,
        private alarmService: AlarmService,
        private blockagePredictionService: BlockagePredictionService,
        private dateutilService: DateutilService,
        private dateService: DateService,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        public domOperationUtilsService: DomOperationUtilsService,
        public translate: TranslateService,
        public signalRService: SignalRService,
        private router: Router,
        private authService: AuthService,
        public gisService: GISService,
        private dialog: MatDialog,
        private mapService: MapService,
        private locationService: LocationService,
        private customerLocationService: CustomerDetailLocationService,
        private _sanitizer: DomSanitizer,
        private mainComponent: MainComponent,
        private eventService: EventService,
        public uiService: UiService,
        private layersDatabase: ChecklistDatabase,
        private customerService: CustomerService,
        private locationCardService: LocationCardService
    ) {
        this.routeQueryParams$ = activatedRoute.queryParams.subscribe((params: AppQueryParams) => {
            switch(params.dialog) {
                case DialogQueryParams.CostAllocation:
                    this.openPopupDialog(AdminCostAllocationComponent);
                    break;
                case DialogQueryParams.Usage:
                    this.openPopupDialog(AdminBillingMetricsComponent);
                    break;
                default:
                    break;
            }
          });
    }

    public openPopupDialog(dialogClass: ComponentType<unknown>) {
        this.dialog.open(dialogClass, {
            panelClass: 'no-padding-nor-overflow-relative-dialog',
            backdropClass: 'cdk-overlay-light-backdrop'
        });
    }

    public ngOnInit() {
        this.domOperationUtilsService.isHomePageWidgetToggled = true;
        this.domOperationUtilsService.homePageSelectedWidget = this.widgetOptions.ALL;
        this.subscriptions.push(
            this.locationService.locationChange.pipe(debounceTime(400)).subscribe((x) => {
                this.onLocationChange(x);
            }),
        );

        this.subscriptions.push(
            combineLatest([
                this.usersService.isAutoReviewAllowed,
                this.usersService.isBlockagePredictionAllowed,
            ]).subscribe(([isAR, isBP]) => {
                this.isAutoReviewEnabled = isAR.value;
                this.widgetsPermissionAutoReview = isAR.value;
                this.betaFlagAutoReviewPermission = isAR.isbeta;

                this.widgetsPermissionBp = isBP.value;
                this.betaFlagBlockagePermission = isBP.isbeta;
            }),
        );

        this.subscriptions.push(
            this.domOperationUtilsService.homeDashboardChangeOverlay.subscribe((v: -1 | 1) => {
                let newTabValue = this.domOperationUtilsService.selectedGisTab.getValue() + v;
                if (newTabValue > 3) {
                    newTabValue = 0;
                } else if (newTabValue < 0) {
                    newTabValue = 2;
                } else {
                    newTabValue = newTabValue;
                }

                this.checkToPopulateDemoMonitorData(newTabValue);
                this.activeLayersTab = newTabValue === 3 ? 2 : newTabValue;
                this.domOperationUtilsService.selectedGisTab.next(newTabValue);
            }),
        );
        this.currentWidget = this.widgetOptions.ALL;
        this.translate.get('LOCATION_DASHBOARD.ANAMOLIES_REFRESH_SUCCESS').subscribe((res: string) => {
            this.anamoliesRefreshSuccessMsg = res;
        });
        this.translate.get('LOCATION_DASHBOARD.ANAMOLIES_REFRESH_FAILED').subscribe((res: string) => {
            this.anamoliesRefreshFailedMsg = res;
        });
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtn = res;
        });
        //  subscribe to customer change
        const queryParamsSubs = this.activatedRoute.queryParamMap
            .subscribe((params: ParamMap) => {
                // get updated customer id
                const currentCustomerID = Number(params.get(customerQueryParam));
                const locationGroupID = Number(params.get(customerLocationGroupQueryParam));
                const includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

                if (!currentCustomerID) return;

                // #23462 check if there is any reason to reload locations
                const refreshLocations =
                    currentCustomerID !== this.customerID
                    || locationGroupID !== this.locationGroupID && locationGroupID !== null && locationGroupID !== undefined
                    || (this.customerID > 0 && includeInactiveLocations !== this.includeInactiveLocations);

                if (this.AdsMonitorLayerSource && refreshLocations) {
                    this.AdsMonitorLayerSource.clear();
                }

                this.customerID = currentCustomerID;

                const getProjectDetailsSubscription = this.customerService.getProjectDetails(this.customerID).subscribe(
                    (res) => {
                        this.subscriptionLevel = res.subscriptionLevel;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                );
                this.subscriptions.push(getProjectDetailsSubscription);

                this.currentCustomerId.next(this.customerID);
                if (currentCustomerID != undefined && currentCustomerID != 0) {
                    localStorage.setItem('CustomerId', currentCustomerID.toString());
                    this.loadGisUserSettings();
                }

                const unitsSub = this.customerService.getCustomerUnits(this.customerID).subscribe(() => {
                    if(this.customerService.customer.unitsType === UnitOfMeasureType.METRIC) {
                        this.rainLegend.unitTitle = 'mm';
                        this.rainLegend.unitFactor = IN_TO_MM_FACTOR;
                        this.rainLegend.unitPrecisionString = '1.1-1';
                    } else {
                        this.rainLegend.unitTitle = 'in';
                        this.rainLegend.unitFactor = MM_TO_MM_FACTOR;
                        this.rainLegend.unitPrecisionString = '1.2-2';
                    }
                },
                () => {},
                () => {
                    unitsSub.unsubscribe();
                });

                if (currentCustomerID !== this.customerIDold && currentCustomerID > 0) {
                    this.gisService.layerToFeaturesRelation.clear();
                    this.checkAndHideWidgetsOnCustomerChange();
                    this.customerIDold = currentCustomerID;
                    this.customerID = currentCustomerID;
                    this.currentCustomerId.next(this.customerID);
                    this.locationGroupID = locationGroupID || 0;
                    this.includeInactiveLocations = includeInactiveLocations;
                    this.customerHasChanged = true;
                    this.onCustomerChange();
                } else if (locationGroupID !== this.locationGroupID && this.customerID > 0) {
                    this.gisService.layerToFeaturesRelation.clear();
                    this.locationGroupID = locationGroupID;
                    this.customerHasChanged = false;
                    this.onCustomerChange();
                } else if (includeInactiveLocations !== this.includeInactiveLocations && this.customerID > 0) {
                    this.gisService.layerToFeaturesRelation.clear();
                    this.includeInactiveLocations = includeInactiveLocations;
                    this.customerHasChanged = false;
                    this.onCustomerChange();
                }

                this.searchControl.setValue('');
                this.searchControl.updateValueAndValidity({ onlySelf: true, emitEvent: true });

                // #23323 fetch location groups and store them in associative array
                this.subscriptions.push(
                    this.locationGroupService.getLocationGroups(this.customerID).subscribe((res) => {
                        if (res.locationGroups) {
                            this.locationGroups = [];
                            for (const g of res.locationGroups) {
                                for (const l of g.locations) {
                                    if (!this.locationGroups[l.locationID]) {
                                        this.locationGroups[l.locationID] = [];
                                    }
                                    this.locationGroups[l.locationID].push(g.locationGroupID);
                                }
                            }
                        }
                    })
                )


                // TODO: On startup This causes second OWS call being made, however it won't work without logic inside
                // #23462 reload locations only if there is a reason for it
                if (refreshLocations) {
                    this.currentlyDisplayedLocationIds$.next([]);
                    this.displayMapRains.overrideTooltip = false;
                    this.displayMapRains.customLegend = false;
                    this.displayMapRains.whiteLocations$.next(false);
                    this.displayMapRains.rains$.next(null);
                    this.updateLocationsList();
                }
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });

        this.updateMonitorLayerOnEdit();
        this.subscriptions.push(queryParamsSubs);
        // await this.loadGisUserSettings();

        //  subscribe to location group change
        const locationGroupSubscription = this.locationGroupService.locationGroupEdited.subscribe(
            (locationGroupEdited: number) => {
                if (locationGroupEdited !== 0 && locationGroupEdited === this.locationGroupID) {
                    this.onCustomerChange();
                }
            },
        );
        this.subscriptions.push(locationGroupSubscription);

        //  subscribe to customer date format change
        const dateFormatSubscription = this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            this.dateFormat = newDateFormat;
            this.hideFilters();
        });
        this.subscriptions.push(dateFormatSubscription);

        //  subscribe to customer time format change
        const timeFormatSubscription = this.dateutilService.timeFormat.subscribe((newTimeFormat) => {
            this.timeFormat = newTimeFormat;
            this.hideFilters();
        });

        this.subscriptions.push(timeFormatSubscription);

        //  subscribe to receive new active alarms and update markers
        const alarmSubscription = this.alarmService.currentActiveAlarms.subscribe((activeAlarms) => {
            if (this.currentWidget !== this.widgetOptions.ALARM) {
                this.updateActiveAlarmingLocations(activeAlarms);
                return;
            }

            this.updateAlarmTable = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);

            this.alarmStatusChange({ isAlarmStatusChanged: true, toggleState: false });
        });
        this.subscriptions.push(alarmSubscription);

        this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.availablePageHint = result;

            if (!result && this.featSelList && this.featSelList.isDemoDataPopulated) {
                this.featSelList.feat_dataSource.data = this.featSelList.prevTreeValues;
                this.propertyItemList = this.prevPropertyItemList;
                this.featSelList.isDemoDataPopulated = false;
            }

            if (result && this.currentWidget === this.widgetOptions.EVENT) {
                this.domOperationUtilsService.scrollToTop('#eventWidget');
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        const alarmAccSub = this.signalRService.alarmAcc.subscribe(async (res) => {
            if (res.cid === this.customerID) {
                this.updateMapAndAlarms();
            }
        });
        this.subscriptions.push(alarmAccSub);

        const alarmSub = this.signalRService.alarmReceived.subscribe((res) => {
            if (res.cid === this.customerID) {
                this.updateMapAndAlarms();
            }
        });
        this.subscriptions.push(alarmSub);

        const returnToNormalSub = this.signalRService.returnToNormal.subscribe((res) => {
            if (res.cid === this.customerID) {
                this.updateMapAndAlarms();
            }
        });
        this.subscriptions.push(returnToNormalSub);

        const collectSub = this.signalRService.collectComplete.subscribe((data: SignalRMessage) => {
            if (data && data.cid === this.customerID && (!data.userId || data.userId === this.authService.userID)) {
                this.getFailedCollectsTotal();
            }
        });

        this.subscriptions.push(collectSub);
    }

    private initMap() {
        /////////// olmap adding

        this.addOlMap();
        this.searchBarLocation = this.searchControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.locationFilter(value)),
        );

        this.updateMapSize();
        this.saveSubject = new Subject();
        this.saveSubject.pipe(debounceTime(3500)).subscribe(() => {
            this.saveGisUserSettings();
        });
    }

    name = 'Angular 4';
    y = 100;
    grabber = false;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.updateMapSize();
    }

    public ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.routeQueryParams$.unsubscribe();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());

        this.isDestroyed = true;
    }

    private getAllLocations() {
        this.customerLocationService.getLocationSummary(this.customerID, this.includeInactiveLocations).subscribe((locationData: any) => {
            this.customerLocations = locationData.listCutomerLocations;
        });
    }

    public onLocationChange(locationId: number) {
        const location = this.customerLocations.find((v) => v.locationId === locationId);
        this.editLocation(location);
        this.zoomToSelectedLID(locationId);
    }

    private updateLocationsList() {
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        const params = {
            cid: this.customerID,
            LocationGroupId: this.locationGroupID,
            IncludeInactiveLocations: activeInactiveHandler,
        };

        this.locationService.getLocationData(params as any).subscribe((response: LocationEntitiesData) => {
            if (!response || !response.l) {
                return;
            }
            this.locationEntityData = response;
            const locationIds = response.l.map((v) => v.lid);

            this.originalLocationsIds = locationIds;
            this.viewableLocationsIds = locationIds;

            if (this.AdsMonitorLayerSource) {
                this.loadMonitorLocations(false);
            }
        });
    }

    public editLocation(location: CustomerLocationDetail) {
        this.isLoading = true;
        this.setupRainGaugeLocations();
        this.uiUtilsService.safeChangeDetection(this.cdr);
        // condition added to to open Triton+ popup if series matches
        if (location && location.locationTypeID === 1) {
            combineLatest(
                this.gisService.gisUserSettingsSubject$,
                this.mapService.getMarkerLocationDetails(location.locationId, this.customerID),
            )
                .pipe(first())
                .subscribe(
                    ([userSettings, result]) => {
                        if (result) {
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                            const locationDetails: LocationDetails = {
                                series: result.series,
                                serialNumber: result.serialNumber,
                                status: result.status,
                                modem: result.modem,
                                description: result.description,
                                manholeAddress: result.manholeAddress,
                                coordinate: { latitude: result.latitude, longitude: result.longitude, elevation: 1 },
                                locationID: result.locationID,
                                locationName: result.locationName,
                                assignedRainGaugeLocationId: result.assignedRainGaugeLocationId,
                                installationHeight: result.height,
                                installationWidth: result.width,
                                installationType: result.installationType,
                                installationId: result.installationID,
                                depthUnit: result.depthUnit,
                                flowUnit: result.flowUnit,
                                locationType: result.components ? 3 : 1,
                                components: result.components,
                                installationShape: result.installationShape,
                                ipaddress: result.connectionString,
                                range: result.range,
                                capacity: result.capacity,
                                width: result.width,
                                installationShapeTypeID: result.installationShapeTypeID,
                                manholedepth: result.manholeDepth,
                                length: result.length,
                                breadth: result.breadth,
                                coefficient: result.coefficient,
                                assignedRainGaugeLocationName: result.assignedRainGaugeLocationName,
                                entries: result.entries,
                                lastCollectedDate: '',
                            };

                            const dialogOptions: MatDialogConfig = {
                                disableClose: true,
                                data: {
                                    isFromCustomerEditor: false,
                                    rainGaugeLocations: this.assignedRainGauge,
                                    customerId: this.customerID,
                                    editMode: true,
                                    locationDetails: locationDetails,
                                    enableSelectOnMap: true
                                },
                                hasBackdrop: false,
                            };

                            if (this.gisService.locationCardPosition) {
                                dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                            } else if (
                                userSettings &&
                                userSettings.locationPositionX &&
                                userSettings.locationPositionY
                            ) {
                                dialogOptions.position = this.locationCardService.getLocationCardPosition(true, userSettings);
                            }

                            if (this.mapService.addEditLocationDialog) {
                                this.mapService.addEditLocationDialog.close();
                            }

                            this.mapService.addEditLocationDialog = this.dialog.open(
                                TritonLocationDialogComponent,
                                dialogOptions,
                            );
                            this.mapService.addEditLocationDialog
                                .afterClosed()
                                .pipe(first())
                                .subscribe((result: boolean) => {
                                    this.mapService.addEditLocationDialog = null;
                                    this.zoomToInital();

                                    if (result) {
                                        this.getAllLocations();
                                        this.monitorLocationUpdate();
                                        this.reloadBatteryLocations$.next(true);
                                    }
                                });
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                    },
                );
            return;
        }
        if (location.locationTypeID === 3) {
            this.customerLocationService.getLocationSummary(this.customerID, this.includeInactiveLocations).subscribe((items: any) => {
                let locations = new Array<CustomerLocationDetail>();
                if (items) {
                    locations = items.listCutomerLocations;
                }
                combineLatest(
                    this.gisService.gisUserSettingsSubject$,
                    this.mapService.getMarkerLocationDetails(location.locationId, this.customerID),
                ).subscribe(
                    ([userSettings, result]) => {
                        if (result) {
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                            const locationDetails: LocationDetails = {
                                locationID: result.locationID,
                                locationName: result.locationName,
                                description: result.description,
                                manholeAddress: result.manholeAddress,
                                coordinate: { latitude: result.latitude, longitude: result.longitude, elevation: 1 },
                                status: result.status,
                                modem: result.modem,
                                series: result.series,
                                serialNumber: result.serialNumber,
                                ipaddress: result.ipaddress,
                                lastCollectedDate: undefined,
                                components: result.components,
                                enableSelectOnMap: true
                            };

                            const dialogOptions: MatDialogConfig = {
                                disableClose: true,
                                data: [locationDetails, locations, this.customerID],
                                hasBackdrop: false
                            };

                            if (this.gisService.locationCardPosition) {
                                dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
                            } else if (
                                userSettings &&
                                userSettings.locationPositionX &&
                                userSettings.locationPositionY
                            ) {
                                dialogOptions.position = this.locationCardService.getLocationCardPosition(false, userSettings);
                            }

                            if (this.compositeLocationDialog) {
                                this.compositeLocationDialog.close();
                            }
                            this.compositeLocationDialog = this.dialog.open(CompositeLocComponent, dialogOptions);

                            this.compositeLocationDialog.afterClosed().subscribe((result) => {
                                if (result && result.success) {
                                    // this.locationIdEdited = location.locationId;
                                    this.getAllLocations();
                                    this.uiUtilsService.safeChangeDetection(this.cdr);
                                }
                            });
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                    },
                );
            });
        } else {
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            this.dialog
                .open(MarkerLocationDetailsComponent, {
                    disableClose: true,
                    data: {
                        openEditDialogFromCustomerEdit: true,
                        CustomerId: this.customerID,
                        locationList: this.customerLocations,
                        assignedRainGauge: this.assignedRainGauge,
                        location: location.locationId,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.success) {
                        // this.locationIdEdited = location.locationId;
                        this.getAllLocations();
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                });
        }

        this.isLoading = false;
    }

    private onMapClick(event) {
        const transformed = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
        const [lon, lat] = transformed;

        this.gisService.coordinatePickerFromMap.next({ lon, lat });
    }

    public addOlMap() {
        const currentCustomer = this.gisUserSetting.customerSettings.find((x) => x.cid === this.customerID);
        if (
            currentCustomer &&
            currentCustomer.lastViewedExtents &&
            Array.isArray(currentCustomer.lastViewedExtents) &&
            currentCustomer.lastViewedExtents.length === 3
        ) {
            const lastViewedExtents = currentCustomer.lastViewedExtents;
            this.olView = new View({
                projection: this.projection,
                zoom: lastViewedExtents[0],
                center: [lastViewedExtents[1], lastViewedExtents[2]],
                minZoom: 3,
                maxZoom: 19,
            });
            this.foundSavedLayerExtents = true;
        } else {
            this.olView = new View({
                projection: this.projection,
                center: [0, 0],
                zoom: 4,
                minZoom: 3,
                maxZoom: 19,
            });
        }

        ////Measure Layer Defining
        this.measureLayer = new VectorLayer({
            name: LayerNameOpts.measurement + 'TEST', // TODO AMP: fix this name?
            source: new VectorSource({
                // crossOrigin: "Anonymous"
            }),
            style: (feature) => {
                return measureStyle(feature, this.measurementUnit);
            },
        });

        ////// Base Map Layers

        this.olMap = new OLMap({
            target: 'olmap',
            controls: defaultControls({
                attribution: true,
                zoom: false,
            }).extend([
                new Control({
                    element: document.querySelector('.map-attribution')
                })
            ]),
            layers: [],
            view: this.olView,
        });

        this.popupOverlay = new Overlay({
            element: this.popup.nativeElement,
            offset: [9, 9],
        });
        this.olMap.addOverlay(this.popupOverlay);

        this.olMap.on('pointermove', (event) => {
            this.handleTooltipResult(true, event);
            this.olMap.forEachFeatureAtPixel(
                event.pixel,
                (feature, layer) => {
                    if (feature instanceof Feature) {
                        this.handleMapHover(feature, event);
                    }
                },
                {
                    layerFilter: (layer) => {
                        return layer.get('type') === new VectorLayer().get('type') ? true : false;
                    },
                    hitTolerance: 6,
                },
            );
        });

        this.olMap.on('moveend', () => {
            const currentZoom = this.olMap.getView().getZoom();
            const currentCenter = this.olMap.getView().getCenter();
            const currentCustomer = this.gisUserSetting.customerSettings.find((x) => x.cid === this.customerID);
            currentCustomer.lastViewedExtents = [currentZoom, currentCenter[0], currentCenter[1]];
            this.saveSubject.next({});
        });

        this.gisService.getCustomerSettings(this.customerID).subscribe((res: any) => {
            let measurementUnit;
            if (res) {
                measurementUnit = res['unitsType'] == 2 ? 'metric' : 'us';
            } else {
                measurementUnit = 'metric';
            }
            this.scaleLinecontrol = new ScaleLine({
                target: 'scaleline',
                units: measurementUnit,
                bar: true,
                steps: 4,
                text: true,
                minWidth: 100,
            });
            this.olMap.addControl(this.scaleLinecontrol);
        });

        this.loadBasemaps();
        this.selectInteraction = new Select({
            layers: [this.SelectionLayer]
        });
        this.olMap.addInteraction(this.selectInteraction);
        this.selectedFeatures = this.selectInteraction.getFeatures();
        this.selectedFeatures.on(['add', 'remove'], () => {
            const selectedFeature = this.selectedFeatures;
            if (selectedFeature.length >= 1) {
                // checking single feature or cluster
                //this.onPopupCallback('show', selectedFeature[0]);
            }
        });
        /*
        var select = new Select();
        this.olMap.addInteraction(select);
        var dragBox = new DragBox({
          condition: platformModifierKeyOnly,
        });

        this.olMap.addInteraction(dragBox);


        var selectedFeatures = select.getFeatures();
        dragBox.on('boxstart', function () {
          selectedFeatures.clear();
        });
        dragBox.on('boxend', function () {
         var extent = dragBox.getGeometry().getExtent();
          this.AdsMonitorLayerSource.forEachFeatureIntersectingExtent(extent, function (feature) {
            selectedFeatures.push(feature);
          });
        });*/

        this.olMap.on('click', (evt) => {
            this.onMapClick(evt);
            const newfeatures = {};
            if (this.enableSelection) {
                this.clearSelection();
                let featureFound = false;
                this.olMap.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
                    if (!layer) return;
                    const layername = layer.get('name');
                    const currentLayersAtrributes = this.getCurrentLayersAtrributes();
                    if (
                        currentLayersAtrributes[layername] &&
                        !(
                            currentLayersAtrributes[layername] &&
                            currentLayersAtrributes[layername].selection === true &&
                            currentLayersAtrributes[layername].checked === true
                        )
                    ) {
                        return;
                    }

                    //if(layer.get("name") == MONITOR_LAYER_NAME){
                    if (newfeatures[layername]) {
                        if (feature.get('features')) {
                            feature.get('features').forEach((feat) => {
                                this.addingToSelection(feat, layername);
                                featureFound = true;
                            });
                        } else {
                            this.addingToSelection(feature as any, layername);
                            featureFound = true;
                        }
                    } else {
                        if (feature.get('features')) {
                            feature.get('features').forEach((feat) => {
                                this.addingToSelection(feat, layername);
                                featureFound = true;
                            });
                        } else if (layername !== 'selectionlayer') {
                            this.addingToSelection(feature as any, layername);
                            featureFound = true;
                        }
                    }
                });
                this.featSelList.feat_loaddata(this.selectionListing);

                if (featureFound) {
                    this.activeLayersTab = TABS.TOOLS;
                    this.domOperationUtilsService.selectedGisTab.next(this.activeLayersTab);
                }
            }
        });
        this.enableSelection = true;
    }

    private async handleMapHover(feature: FeatureLike, event: MapBrowserEvent<UIEvent>) {
        const isDarkTheme = this.statusCodeService.userInfoThemeBS.getValue();
        const type = feature.getGeometry().getType();
        const locTypeId = feature.get('LocationTypeID');
        const monitorFeature = await this.AdsMonitorLayer.getFeatures(event.pixel);
        let monitorLayerFeatures;
        let hide = true;
        let features = feature.get('features');
        if (!features && feature.get('LocationID')) {
            features = [feature];
        }

        if (monitorFeature && monitorFeature.length) {
            monitorLayerFeatures = monitorFeature[0].get('features');
        }

        if ((type === 'Point' || type === 'LineString' || type === 'Polygon') && !features && !monitorFeature.length) {
            const template = generateLinePointPolygonPopupTemplate(feature);
            this.popup.nativeElement.innerHTML = template;
            hide = !template;
        } else if (locTypeId === 3 || (features && features.length === 1 && features[0].get('LocationTypeID') === 3)) {
            const name = feature.get('Name') ? feature.get('Name') : features[0].get('Name');
            const status =
                feature.get('IsActiveLocation') !== undefined
                    ? feature.get('IsActiveLocation')
                    : features[0].get('IsActiveLocation');
            this.popup.nativeElement.innerHTML = generateCompositeLocationPopupTemplate(name, status);
            hide = false;
        } else if (this.displayMapRains.overrideTooltip && monitorLayerFeatures && monitorLayerFeatures.length > 1) {
            this.popup.nativeElement.innerHTML = generateLocationsClusterPopupTemplate(monitorLayerFeatures, isDarkTheme, this.displayMapRains.rains$.getValue() as EventRain[]);
            hide = false;
        } else if (
            this.displayMapRains.overrideTooltip &&
            monitorLayerFeatures &&
            monitorLayerFeatures.length === 1 &&
            monitorLayerFeatures[0].get('LocationTypeID') !== 3
        ) {
            this.popup.nativeElement.innerHTML = pickSingleMonitorPopupTemplate(monitorLayerFeatures[0], isDarkTheme, this.displayMapRains.rains$.getValue() as EventRain[]);
            hide = false;
        } else if (
            this.displayMapRains.overrideTooltip &&
            monitorLayerFeatures &&
            monitorLayerFeatures.length === 1 &&
            monitorLayerFeatures[0].get('LocationTypeID') === 3
        ) {
            const name = monitorLayerFeatures[0].get('Name');
            const status = monitorLayerFeatures[0].get('IsActiveLocation');
            this.popup.nativeElement.innerHTML = generateCompositeLocationPopupTemplate(name, status);
            hide = false;
        } else if (features && features.length === 1) {
            this.popup.nativeElement.innerHTML = pickSingleMonitorPopupTemplate(features[0], isDarkTheme,
                this.displayMapRains.overrideTooltip ? this.displayMapRains.rains$.getValue() as EventRain[] : null);
            hide = false;
        } else if (features && features.length > 1) {
            hide = false;
            this.popup.nativeElement.innerHTML = generateLocationsClusterPopupTemplate(features, isDarkTheme,
                this.displayMapRains.overrideTooltip ? this.displayMapRains.rains$.getValue() as EventRain[] : null);
        }

        this.handleTooltipResult(hide, event);
    }

    private checkToPopulateDemoMonitorData(pageIndex: number) {
        if (!this.featSelList || (pageIndex !== 3 && !this.featSelList.isDemoDataPopulated)) {
            return;
        }

        const mockData = [
            {
                item: MONITOR_LAYER_NAME,
                children: [
                    {
                        item: 'DEMO_MONITOR',
                        layername: MONITOR_LAYER_NAME,
                    },
                ],
            },
        ];

        const demoProps = [
            { name: 'CustomerID', value: 0 },
            { name: 'LocationID', value: 0 },
            { name: 'Name', value: 'DEMO MONITOR' },
            { name: 'LocationTypeID', value: 0 },
            { name: 'LocationDesc', value: null },
            { name: 'Latitude', value: 0 },
            { name: 'Longitude', value: 0 },
            { name: 'Elevation', value: 0 },
            { name: 'SerialNumber', value: null },
            { name: 'Status', value: 0 },
            { name: 'ManholeAddress', value: null },
            { name: 'ConnectionString', value: null },
            { name: 'IsActiveLocation', value: 1 },
            { name: 'LastCollectedDate', value: null },
            { name: 'DataCollectTaskID', value: 0 },
            { name: 'MapLabel', value: null },
            { name: 'MapLabelPosition', value: null },
            { name: 'ExportDataToFtp', value: false },
            { name: 'WasCreatedFromImport', value: true },
            { name: 'AssignedRgID', value: 0 },
            { name: 'RgAssignmentDate', value: null },
            { name: 'LocationIdentity', value: 0 },
            { name: 'ManholeDepth', value: 0 },
            { name: 'ExternalID', value: null },
            { name: 'ExternalSiteType', value: null },
            { name: 'ExternalVendorID', value: null },
            { name: 'QFinalEntityID', value: 3302 },
            { name: 'AlarmId', value: undefined },
            { name: 'AcknowledgedBy', value: 0 },
            { name: 'LocationGroupId', value: 0 },
            { name: 'monitorSeriesModelName', value: null },
            { name: 'monitorModel', value: null },
            { name: 'monitorSeries', value: null },
            { name: 'symbolType', value: null },
            { name: 'name', value: 'DEMO MONITOR' },
            { name: '_layername', value: MONITOR_LAYER_NAME },
        ];

        if (pageIndex === 3) {
            this.featSelList.prevTreeValues = [...this.featSelList.feat_dataSource.data];
            this.prevPropertyItemList = [...this.propertyItemList];
            this.propertyItemList = demoProps;
            this.featSelList.feat_dataSource.data = mockData as any;
            this.featSelList.feat_treeControl.expandAll();
            this.featSelList.isDemoDataPopulated = true;
        } else if (this.featSelList.isDemoDataPopulated) {
            this.featSelList.isDemoDataPopulated = false;
            this.propertyItemList = this.prevPropertyItemList;
            this.featSelList.feat_dataSource.data = this.featSelList.prevTreeValues;
        }
    }

    private handleTooltipResult(hide: boolean, event: MapBrowserEvent<UIEvent>) {
        if (hide) {
            this.popup.nativeElement.innerHTML = '';
            this.popup.nativeElement.hidden = true;
        } else {
            this.popup.nativeElement.hidden = false;
            this.popupOverlay.setPosition(event.coordinate);

            const [eventPixelWidth, eventPixelHeight] = event.pixel;
            const { offsetHeight: overlayHeight, offsetWidth: overlayWidth } = this.popupOverlay.getElement();
            const canvas = window.document.getElementsByTagName('canvas')[1];
            const [mapElementHeight, mapElementWidth] = [canvas.offsetHeight, canvas.offsetWidth];

            const ifOutsideLeft = (mapElementWidth - overlayWidth < eventPixelWidth) && mapElementWidth ;
            const ifOutsideBottom = (mapElementHeight - overlayHeight < eventPixelHeight) && mapElementHeight;
            const verticalOffset = mapElementHeight - overlayHeight - eventPixelHeight;
            const horizontalOffset = mapElementWidth - overlayWidth - eventPixelWidth;

            if (ifOutsideLeft && ifOutsideBottom) {
                this.popupOverlay.setOffset([horizontalOffset, verticalOffset]);
            } else if (ifOutsideLeft) {
                this.popupOverlay.setOffset([horizontalOffset, 9]);
            } else if (ifOutsideBottom) {
                this.popupOverlay.setOffset([9, verticalOffset]);
            } else {
                this.popupOverlay.setOffset([9, 9]);
            }
        }
    }

    private loadBasemaps() {
        const tokenReq$ = this.gisService.getGisToken();
        const basemapsReq$ = this.gisService.getGISBasemap();

        combineLatest([tokenReq$, basemapsReq$]).pipe(first())
            .subscribe(([token, basemaps]) => {
                if (!this.activeBaseMapId) {
                    // Ensure active base map is set; otherwise default to first map
                    this.activeBaseMapId = basemaps[0].baseMapId;
                }

                const baseMapLayers: TileLayer[] = [];
                this.baseMapOptions = basemaps;

                this.baseMapOptions.forEach((map) => {
                    if (map.url.includes('{token}')) {
                        map.url = map.url.replace('{token}', token.access_token);
                    }
                });
                // include default layers
                this.baseMapOptions.filter(v => !v.url.includes('token')).forEach(map => {
                    baseMapLayers.push(
                        new TileLayer({
                            name: map.name,
                            visible: map.baseMapId === this.activeBaseMapId ? true : false,
                            source: new XYZ({
                                url: map.url,
                                crossOrigin: 'Anonymous',
                            }),
                        }),
                    );
                });

                const activeMap = this.baseMapOptions.find(v => v.baseMapId === this.activeBaseMapId);

                if (activeMap && activeMap.url.includes('token')) {
                    this.applyDynamicBasemap(activeMap.url, activeMap.name);
                }

                this.baseLayers = new LayerGroup({
                    name: LayerNameOpts.basemap,
                    layers: baseMapLayers,
                });
                this.olMap.addLayer(this.baseLayers);

                this.setMapAttributionText();
                this.generateOverviewMap();
                this.maplayersLoad();
            });
    }

    private applyDynamicBasemap(url: string, name: string) {
        // since apply will add a layer dynamically, and does not provide any success event handler we need to listen to Add layer event to set its name
        apply(this.olMap, url)
            .then((data: OLMap) => {
                const newlyAdded = data.getLayers().getArray().find(v => v.get('name') === undefined)

                if (newlyAdded) {
                    newlyAdded.set('name', name);
                }

                const mapBox = this.olMap.get('mapbox-style');
                const sources = mapBox.sources;
                const sourceKeys = Object.keys(sources);

                let attribution: string = '';

                for (const key of sourceKeys) {
                    const source = sources[key];
                    const srcAttribution = source.attribution;

                    if (srcAttribution) {
                        attribution += srcAttribution;
                    }
                }

                if (attribution) {
                    this.attributionText = attribution;
                }

            })
            .catch((err) => {
                this.gisService.getGisToken().subscribe((res) => this.applyDynamicBasemap(this.replaceToken(url, res.access_token), name))
            });
    }

    private replaceToken(url: string, newToken: string) {
        const regex = /token=([^&]*)/;
        return url.replace(regex, `token=${newToken}`);
    }

    private setMapAttributionText() {
        if (!this.activeBaseMapId || !this.baseMapOptions || !this.baseMapOptions.length) {
            return;
        }

        const basemap = this.baseMapOptions.find(v => v.baseMapId === this.activeBaseMapId);

        if (!basemap) {
            return;
        }

        switch(basemap.name) {
            case BasemapNames.topographic: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.TOPOGRAPHIC');
                break;
            }
            case BasemapNames.imagery: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.IMAGERY');

                break;
            }
            case BasemapNames.esri: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.ESRI');

                break;
            }
            case BasemapNames.esriImage: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.ESRI_IMAGE');
                break;
            }
            case BasemapNames.osm: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.OSM');

                break;
            }
            case BasemapNames.esriBasemap: {
                this.attributionText = this.translate.instant('HOME.MAP.ATTRIBUTIONS.ESRI_BASEMAP');

                break;
            }
            default: break;
        }
    }

    private checkForLocationViewablePermission(featureId: number) {
        return !!this.viewableLocationsIds.find((v) => v === Number(featureId));
    }

    private checkForActiveInactiveHandler(isActive: boolean): boolean {
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        if (activeInactiveHandler) {
            return true; // display all locations is turned on
        }

        return isActive;
    }

    private checkForLocationGroup(locationgroupIds: number[]): boolean {
        if (this.locationGroupID === 0) {
            return true; // display all location groups is selected
        }

        return locationgroupIds && locationgroupIds.includes(this.locationGroupID);
    }

    private updatePropertyItemList(feature: Feature<Geometry>) {
        const locationIdItem = this.propertyItemList.find((v) => v.name === 'LocationID');

        if (Number(locationIdItem.value) !== Number(feature.get('LocationID'))) {
            return;
        }

        this.featureselected([{ feature: feature }] as any);
    }
    private addToMonitorLegend(legendType: string) {
        if (legendType === undefined || legendType === null) {
            legendType = 'Other';
        }
        const monitorLegendInfo = monitorLegendOptions().find((x) => x.type === legendType);
        const affectedLayer = this.mapLayerLists.find((x) => x.name === LayerNameOpts.monitor);
        if (affectedLayer.legendInfo.find((x) => x.type === legendType)) {
            return;
        }
        affectedLayer.legendInfo.push(monitorLegendInfo);
    }

    tileLoadFn(tile, src) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.addEventListener('loadend', (response) => {
            const responsetaget = response['currentTarget'];
            const data = responsetaget['response'];
            if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
            } else {
                tile.setState(TileState.ERROR);
            }
        });
        xhr.addEventListener('error', () => {
            tile.setState(TileState.ERROR);
        });
        xhr.open('GET', src);
        xhr.setRequestHeader('Authorization', 'Basic SURFWDprM1IqdjJMeHEjRkQ=');
        xhr.send();
    }

    private generateMonitorLayerUrl() {
        return Config.urls.geoserverUrl + `ows/${this.customerID}?IncludeInactiveLocations=${this.includeInactiveLocations}`
    }

    maplayersLoad() {
        const geoWFSurl = this.generateMonitorLayerUrl();
        this.AdsMonitorLayerSource = new VectorSource({
            format: new GeoJSON(),
            url: (extent) => {
                return geoWFSurl;
            },
        });

        this.AdsMonitorLayerSource.setLoader((p0, p1, projection: Projection) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';
            xhr.addEventListener('loadend', (response) => {
                const responsetaget = response['currentTarget'];
                const data = responsetaget['response'];
                if (!data) return;

                const uniqueData = [];
                for (const f of data.features) {
                    const featureId = f.properties.LocationID;
                    const name = f.properties.Name;
                    const isActive = f.properties.IsActiveLocation;
                    const locationGroupIds = this.locationGroups ? this.locationGroups[featureId] : [];
                    f.id = featureId;

                    if (
                        !this.monitorFeatureNamesSet.has(name)
                        && this.checkForActiveInactiveHandler(isActive)
                        && this.checkForLocationGroup(locationGroupIds)
                        && this.checkForLocationViewablePermission(featureId)
                    ) {
                        uniqueData.push(f);
                        this.monitorFeatureNamesSet.add(name);
                    }
                }
                data.features = uniqueData;

                const features = this.AdsMonitorLayerSource.getFormat().readFeatures(data, {
                    featureProjection: projection,
                });

                this.AdsMonitorLayerSource.addFeatures(features as Feature[]);
            });
            // xhr.addEventListener('error', () => {
            // });
            const url = this.generateMonitorLayerUrl();
            xhr.open('GET', url);
            const token = sessionStorage.getItem('adsToken');
            if (token) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
            xhr.send();
        });
        this.monitorFeatureNamesSet = new Set();
        this.AdsMonitorLayerSource.on('addfeature', (event) => {
            const feature = this.AdsMonitorLayerSource.getFeatureById(event.feature.getId());
            const locationID = event.feature.get('LocationID');

            const legendSymbol = feature.get('symbolType');
            this.addToMonitorLegend(legendSymbol);

            this.monitorFeatureNamesSet.add(event.feature.get('Name'));
            this.adjustFeatureAlarmsProperties(feature);

            const format = new GeoJSON();
            const feat1 = format.writeFeatureObject(feature as any);

            // #23298 Search in selected features
            let found = null;
            this.SelectionLayerSource.forEachFeature((f) => {
                if (event.feature.get('LocationID') === f.get('LocationID')) {
                    found = f;
                }
            });
            // #23298 Once found, replace them
            if (found) {
                const layerName = found.get('_layername');
                this.SelectionLayerSource.removeFeature(found);
                this.addingToSelection(event.feature, layerName);
            }

            if ((feat1.geometry as any).coordinates[0] == 0 && (feat1.geometry as any).coordinates[1] == 0) {
                //null island
            } else {
                this.monitorFeatureCollection.push(feat1);
                const filteredFeatures = this.monitorFeatureCollection.filter(x => x.properties.Latitude || x.properties.Longitude);

                this.initialMapExtent = turf.bbox({
                    type: 'FeatureCollection',
                    features: filteredFeatures,
                }) as any;

                this.monitorsRefreshedSubject.next(filteredFeatures.length);
                if (!this.foundSavedLayerExtents) {
                    this.zoomToInital();
                }
            }

            if (this.propertyItemList && this.propertyItemList.length) {
                this.updatePropertyItemList(feature);
            }
        });

        this.AdsMonitorLayerSourceCL = new Cluster({
            distance: 20,
            source: this.AdsMonitorLayerSource,
        });

        this.AdsMonitorLayer = new VectorLayer({
            name: LayerNameOpts.monitor,
            source: this.AdsMonitorLayerSourceCL,
            style: monitorStyleFilter(
                this.currentlyDisplayedLocationIds$,
                this.displayMapRains.rains$,
                this.gisService.gisUserSettingsSubject$,
                this.pickMapMonitorColor.bind(this),
                this.displayMapRains.whiteLocations$,
                this.statusCodeService.userInfoThemeBS
            ),
        });
        // this.AdsMonitorLayerSourceOV = new VectorSource({
        //     format: new GeoJSON(),
        //     url: (extent) => {
        //         return Config.urls.geoserverUrl + '/ows?service=WFS&version=1.0.0&request=GetFeature' +
        //             '&typename=' + ADS_MONITOR_LAYER + '&outputFormat=application/json&srsname=' + this.projection + '&viewparams=customerId:' + this.customerID + this.layerViewParam +
        //             '&bbox=' + ADS_LAYER_BBOX; //extent.join(',');
        //     },
        //     strategy: bboxStrategy,
        // });

        // this.AdsMonitorLayerSourceCLOV = new Cluster({
        //     distance: 20,
        //     source: this.AdsMonitorLayerSource,
        // });

        const userSavedLayerInfo = this.gisService.getSavedLayerSettings(this.customerID, this.gisUserSetting, LayerNameOpts.monitor);
        if (userSavedLayerInfo) {
            this.mapLayerLists.push({
                name: userSavedLayerInfo.name,
                checked: userSavedLayerInfo.isVisible,
                child: [],
                selection: userSavedLayerInfo.isSelectable,
                legendInfo: [],
                opacity: this.defaultOpacity,
            });

            // #40626 Toggle Monitor layer visiblity
            this.AdsMonitorLayer.setVisible(userSavedLayerInfo.isVisible)
        } else {
            this.mapLayerLists.push({
                name: LayerNameOpts.monitor,
                checked: true,
                child: [],
                selection: true,
                legendInfo: [],
                opacity: this.defaultOpacity,
            });
        }
        this.updateAnyLayerSelectedStatus();

        ////Measure Layer Defining
        this.measureLayer = new VectorLayer({
            name: LayerNameOpts.measurement,
            source: new VectorSource(),
            style: (feature) => {
                return measureStyle(feature, this.measurementUnit);
            },
        });

        ///// selection Layer Defining
        this.SelectionLayerSource = new VectorSource();
        this.SelectionLayer = new VectorLayer({
            name: LayerNameOpts.selectionlayer,
            source: this.SelectionLayerSource,
        });

        // highlithed layer defining
        this.HighlightLayerSource = new VectorSource();
        this.HighlightLayer = new VectorLayer({
            name: LayerNameOpts.selectionlayer,
            source: this.HighlightLayerSource,
        });
        /*
         *
         */
        this.positionFeature = new Feature();
        //this.positionFeature.setStyle(positionFeatureStyle);
        this.accuracyFeature = new Feature();
        //this.accuracyFeature.setStyle(accuracyFeatureStyle);
        const loctionLayer = new VectorLayer({
            name: LayerNameOpts.locations,
            source: new VectorSource({
                features: [this.accuracyFeature, this.positionFeature],
            }),
        });

        this.AdsMonitorLayer.setZIndex(2);
        this.SelectionLayer.setZIndex(3);
        this.HighlightLayer.setZIndex(10);
        this.olMap.addLayer(this.AdsMonitorLayer);
        this.olMap.addLayer(this.measureLayer);
        this.olMap.addLayer(this.SelectionLayer);
        this.olMap.addLayer(this.HighlightLayer);

        this.generateOverviewMap();
        this.layerList.loaddata(this.mapLayerLists);
        this.loadMonitorLocations();
        //this.prismLayerLoad();
        //this.layerList.loaddata(this.mapLayerLists);
    }

    private pickMapMonitorColor(feature: Feature): string {
        const { currentWidget: curr, widgetOptions: opts } = this;
        const isDarkTheme = this.statusCodeService.userInfoThemeBS.getValue();

        const properties = feature.getProperties();

        if (properties.Status === LocationStatus.Inactive) {
            return isDarkTheme ? darkThemeColors.grey : lightThemeColors.grey;
        }

        if (curr === opts.ALARM || curr === opts.ALL) {
            return this.getMonitorColorOnAlarm(feature, isDarkTheme);
        } else if (curr === opts.BATTERY) {
            return this.getMonitorColorOnBattery(feature, isDarkTheme);
        } else if (curr === opts.DATA_COMMUNICATION) {
            return this.getMonitorColorOnDataComm(feature, isDarkTheme);
        } else if (curr === opts.BLOCKAGE_PREDICT) {
            return this.getMonitorColorOnBlockage(feature, isDarkTheme);
        } else if (curr === opts.AUTO_REVIEW) {
            return this.getMonitorColorOnAutoReview(feature, isDarkTheme);
        } else {
            return this.getMonitorColorOnAlarm(feature, isDarkTheme);
        }
    }

    private getMonitorColorByStatus(feature: Feature, isDarkTheme: boolean) {
        const properties = feature.getProperties();

        let color = isDarkTheme ? darkThemeColors.green : lightThemeColors.green;

        if (properties.Status === LocationStatus.Inactive || properties.Status === LocationStatus.InactiveRemoved) {
            color = isDarkTheme ? darkThemeColors.grey : lightThemeColors.grey;
        } else if (properties.Status === LocationStatus.Maintenance) {
            color = isDarkTheme ? darkThemeColors.maintenance : lightThemeColors.maintenance;
        }

        return color;
    }

    private getMonitorColorOnAlarm(feature: Feature, isDarkTheme: boolean): string {
        const properties = feature.getProperties();
        if (properties.AlarmId) {
            if (properties.AcknowledgedBy) {
                return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
            } else {
                return isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
            }
        }

        return this.getMonitorColorByStatus(feature, isDarkTheme);
    }

    private getMonitorColorOnBattery(feature: Feature, isDarkTheme: boolean): string {
        const properties = feature.getProperties();

        if (!this.openedWidgetData) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        }

        const fromData = this.openedWidgetData.find(v => v.locationID === properties.LocationID);

        if (!fromData || fromData.status === BatteryStatusEnum.Good) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        }

        if (fromData.status === BatteryStatusEnum.Low) {
            return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
        }

        return isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
    }

    private getMonitorColorOnDataComm(feature: Feature, isDarkTheme: boolean): string {
        const properties = feature.getProperties();

        if (!this.openedWidgetData) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        }

        const locationExists = this.openedWidgetData.find(v => v.locationid === properties.LocationID);

        if (locationExists) {
            return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
        }

        return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
    }

    private getMonitorColorOnBlockage(feature: Feature, isDarkTheme: boolean): string {
        const properties = feature.getProperties();

        if (!this.openedWidgetData) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        }

        const fromData = this.openedWidgetData.find(v => v.locationId === properties.LocationID);

        if (!fromData || fromData.blockStatus === 1) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        } else if (fromData.blockStatus === 2) {
            return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
        } else {
            return isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
        }
    }

    private getMonitorColorOnAutoReview(feature: Feature, isDarkTheme: boolean) {
        const properties = feature.getProperties();

        if (!this.openedWidgetData) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        }

        const fromData = this.openedWidgetData.find(v => v.locationId === properties.LocationID);

        if (!fromData) {
            return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
        }

        if (fromData.status && fromData.status.text === AUTO_REVIEW_REVIEW_REQUIRED) {
            return isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
        } else if (fromData.status && fromData.status.text === AUTO_REVIEW_MISSING_DATA) {
            return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
        }

        return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
    }

    public setWidgetData(data) {
        this.openedWidgetData = data;
        this.monitorLocationUpdate();
    }

    private removeAllLayers() {
        const layers = this.olMap.getLayers().getArray();
        layers.forEach((lyr) => {
            const layerName = lyr.get('name');
            if (
                layerName !== LayerNameOpts.basemap &&
                layerName !== LayerNameOpts.selectionlayer &&
                layerName !== LayerNameOpts.locations &&
                layerName !== LayerNameOpts.monitor &&
                layerName !== LayerNameOpts.measurement
            ) {
                this.olMap.removeLayer(lyr);
            }
        });
    }

    setMapList() {
        let networkfound = false;
        let netcnt = 0;
        this.mapLayerLists.forEach((ent) => {
            if (ent.name == 'Network') {
                if (this.prismLayerList.length > 0) {
                    ent.child = this.prismLayerList;
                    networkfound = true;
                } else {
                    this.mapLayerLists.splice(netcnt, 1);
                }
            }
            netcnt++;
        });
        if (this.prismLayerList.length > 0 && !networkfound) {
            this.mapLayerLists.push({
                name: 'Network',
                checked: true,
                child: this.prismLayerList,
                selection: false,
                legend: false,
            });
        }
        this.updateAnyLayerSelectedStatus();
        this.layerList.loaddata(this.mapLayerLists);
    }

    async prismLayerLoad() {
        this.prismLayerList = [];
        this.prismLayerGroupList = [];
        this.setMapList();
        //this.prismLayersGroup.setLayers([]);
        this.removeAllLayers();
        const services = await this.gisService.getGISServicesList(this.customerID).toPromise();

        this.checkedLayersList = new Set();
        this.layerNameToEsriLayerId = {};

        this.refreshBasemapOnCustomerChange();
        await this.gisService.layerLoad(
            services,
            this.gisUserSetting,
            this.customerID,
            () => !this.isDestroyed,
            {
                checkedLayersList: this.checkedLayersList,
                layerNameToEsriLayerId: this.layerNameToEsriLayerId,
                olMap: this.olMap,
                prismLayerList: this.prismLayerList,
                esriTileSource: this.esriTileSource,
                esriTileLayer: this.esriTileLayer
            },
            {
                defaultOpacity: this.defaultOpacity
            }
        );

        this.setMapList();
    }

    tileloading(tile, src) {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.addEventListener('loadend', (response) => {
            const responsetaget = response['currentTarget'];
            const data = responsetaget['response'];
            if (data !== undefined) {
                tile.getImage().src = URL.createObjectURL(data);
            } else {
                tile.setState(TileState.ERROR);
            }
        });
        xhr.addEventListener('error', () => {
            tile.setState(TileState.ERROR);
        });
        xhr.open('GET', src);
        xhr.setRequestHeader('Authorization', 'Basic SURFWDprM1IqdjJMeHEjRkQ=');
        xhr.send();
    }

    private generateOverviewMap() {
        if (!this.baseMapOptions) {
            return;
        }
        let activeBaseLayer = this.baseMapOptions.find((x) => x.baseMapId === this.activeBaseMapId);
        if (!activeBaseLayer) {
            activeBaseLayer = this.baseMapOptions[0];
        }

        let baseLayer: TileLayer;
        if (!activeBaseLayer.url.includes('token')) {
            baseLayer = new TileLayer({
                name: 'OverviewMap - ' + LayerNameOpts.basemap,
                source: new XYZ({
                    url: activeBaseLayer.url,
                    crossOrigin: 'Anonymous',
                }),
            });
        }

        const overviewMonitorLayer = new VectorLayer({
            name: 'OverviewMap - ' + LayerNameOpts.monitor,
            source: this.AdsMonitorLayerSourceCL,
            style: monitorStyleFilter(
                this.currentlyDisplayedLocationIds$,
                this.displayMapRains.rains$,
                this.gisService.gisUserSettingsSubject$,
                this.pickMapMonitorColor.bind(this),
                this.displayMapRains.whiteLocations$,
                this.statusCodeService.userInfoThemeBS
            ),
        });

        if (this.overviewMapControl) {
            this.olMap.removeControl(this.overviewMapControl);
        }


        const layers: Layer<Source>[] = [overviewMonitorLayer];

        if (baseLayer) {
            layers.push(baseLayer);
        }

        this.overviewMapControl = new OverviewMap({
            layers,
            collapseLabel: '\u2199',
            label: '\u2197',
            collapsible: true
        });
        this.olMap.addControl(this.overviewMapControl);
    }

    // Method will recall monitor symbol style function
    monitorLocationUpdate(shouldClearSelection = true) {
        if (shouldClearSelection) {
            this.clearSelection();
        }
        // const extent = '';
        let otherparam = '';
        if (!this.includeInactiveLocations) {
            otherparam = otherparam + ' and IsActiveLocation = 1';
        }
        if (this.locationGroupID != 0) {
            otherparam = otherparam + ' and LocationGroupId = ' + this.locationGroupID;
        }
        // if (this.activewidget != '') {
        //     if (this.activewidget == '0') {
        //         otherparam = otherparam + ' and AcknowledgedBy = 1';
        //     }
        // }
        this.layerViewParam = ';LocationgroupID:' + otherparam;
        // const param = '&viewparams=customerId:' + this.customerID + this.layerViewParam;
        this.monitorFeatureNamesSet = new Set();
        if (this.customerHasChanged !== true) {
            // location group or widget has changed
            // this.foundSavedLayerExtents = false
            this.monitorFeatureCollection = [];
        }
        this.monitorFeatureNamesSet = new Set();
        this.AdsMonitorLayerSource.refresh();
        // this.AdsMonitorLayerSourceOV.refresh();

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    loadMonitorLocations(shouldClearSelection = true) {
        this.monitorLocationUpdate(shouldClearSelection);

        if (this.customerHasChanged) {
            this.removeAllLayers();
            this.monitorFeatureCollection = [];
            this.customerHasChanged = false;
            const currentCustomer = this.gisUserSetting.customerSettings.find((x) => x.cid === this.customerID);

            if (
                currentCustomer &&
                Array.isArray(currentCustomer.lastViewedExtents) &&
                currentCustomer.lastViewedExtents.length === 3
            ) {
                const lastViewedExtents = currentCustomer.lastViewedExtents;
                this.olMap.getView().setCenter([lastViewedExtents[1], lastViewedExtents[2]]);
                this.olMap.getView().setZoom(lastViewedExtents[0]);
                this.foundSavedLayerExtents = true;
            } else {
                this.foundSavedLayerExtents = false;
            }

            this.gisService.getCustomerSettings(this.customerID).subscribe((res: any) => {
                let measurementUnit;
                if (res) {
                    measurementUnit = res['unitsType'] == 2 ? 'metric' : 'us';
                } else {
                    measurementUnit = 'metric';
                }
                this.scaleLinecontrol.setUnits(measurementUnit);
            });

            this.prismLayerLoad();
        }
        this.gisService.getCustomerSettings(this.customerID).subscribe((res: any) => {
            if (res) {
                this.measurementUnit = res['unitsType'] == 2 ? 'metric' : 'us';
            }
        });
        /*setTimeout(() => {
            this.zoomtoInital();
        },3000);*/
    }

    public async loadGisUserSettings() {
        const userSettings = await this.gisService.gisUserSettingsGet();

        if (userSettings !== null) {
            if (!userSettings.customerSettings || userSettings.customerSettings.length === 0) {
                userSettings.customerSettings = [
                    {
                        cid: this.customerID,
                        layers: [],
                        lastViewedExtents: [],
                        lastViewedLocationGroup: undefined,
                    },
                ];
            } else if (
                userSettings.customerSettings &&
                userSettings.customerSettings.findIndex((x) => x.cid === this.customerID) === -1
            ) {
                userSettings.customerSettings.push({
                    cid: this.customerID,
                    layers: [],
                    lastViewedExtents: [],
                    lastViewedLocationGroup: undefined,
                });
            }
            this.gisUserSetting = userSettings;
            this.sidebaropen = userSettings.sidebarIsOn;
            this.sidebaropen2 = userSettings.sidebarIsOn2;
            this.activeBaseMapId = userSettings.baseMapId;
            // It has to be valid, so ngif will show and there will be access to layers. Bug #21292
            this.activeLayersTab = userSettings.activeLayersTabNo || 0;
            this.domOperationUtilsService.selectedGisTab.next(this.activeLayersTab);
            this.toggleSidePin = userSettings.isSidebarPinOn;
            this.toggleSidePin2 = userSettings.isSidebarPinOn2;

            if (userSettings.layersParameterJson != undefined && userSettings.layersParameterJson != '') {
                this.layersParameterJson = JSON.parse(userSettings.layersParameterJson);
                this.olMap.getView().setZoom(this.layersParameterJson.zoomStatus);
            }
            if (this.customerID) {
                this.mainComponent.customerChangeHandler(this.customerID);
            }
        } else {
            this.gisUserSetting = {
                id: 0,
                userId: 0,
                locationsIsActive: true,
                languageSelection: 'en-us',
                sidebarIsOn: true,
                sidebarIsOn2: true,
                baseMapId: this.baseMapOptions[0].baseMapId,
                isMapPanelOn: true,
                isLayersOn: true,
                isActive: true,
                selectedLocationGroupId: undefined,
                locationPositionX: '',
                locationPositionY: '',
                isLayersSelectable: true,
                activeLayersTabNo: 0,
                isSidebarPinOn: true,
                showLocationLabels: true,
                isSidebarPinOn2: true,
                layersParameterJson: '',
                lastViewedCustomerId: this.customerID,
                customerSettings: [
                    {
                        cid: this.customerID,
                        layers: [],
                        lastViewedExtents: [],
                        lastViewedLocationGroup: undefined,
                    },
                ],
            };
            this.saveSubject.next({});
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);

        // await new Promise<string>(resolve => {
        //     this.gisService.getGisUserSettings().subscribe((res: gisUserSettings) => {
        //         if (res != null) {
        //             if (res.customerSettings === []) {
        //                 res.customerSettings = [{
        //                     cid: this.customerID, layers: [],
        //                     lastViewedExtents: [],
        //                     lastViewedLocationGroup: undefined
        //                 }];
        //             } else if (res.customerSettings.findIndex(x => x.cid === this.customerID) === -1) {
        //                 res.customerSettings.push({
        //                     cid: this.customerID, layers: [],
        //                     lastViewedExtents: [],
        //                     lastViewedLocationGroup: undefined
        //                 });
        //             }
        //             this.gisUserSetting = res;
        //             this.sidebaropen = res.sidebarIsOn;
        //             this.activeBaseMapId = res.baseMapId;
        //             this.activeLayersTab = res.activeLayersTabNo;
        //             this.toggleSidePin = res.isSidebarPinOn;

        //             if (res.layersParameterJson != undefined && res.layersParameterJson != '') {
        //                 this.layersParameterJson = JSON.parse(res.layersParameterJson);
        //             }

        //             //this.includeInactiveLocations = res.locationsIsActive;
        //             //this.customerID = res.customerId;
        //             // this.locationGroupID = res.selectedLocationGroupId;
        //             // if (res.gisUserSettingLayerDetailsList != undefined && res.gisUserSettingLayerDetailsList.length > 0) {
        //             //     let lst = []
        //             //     res.gisUserSettingLayerDetailsList.forEach(layer => {
        //             //         let itmlayer = {
        // Id: layer.id, UserSettingId: layer.userSettingId, LayerName: layer.layerName, Selection: layer.selection, Checked: layer.checked, Legend: layer.legend };
        //             //         lst.push(itmlayer);
        //             //     })
        //             //     this.userSettingLayerDetailList = lst;
        //             // }
        //         }
        //         else { // If no response, create blank user settings object
        //             this.gisUserSetting = {
        //                 id: 0,
        //                 userId: 0,
        //                 locationsIsActive: true,
        //                 languageSelection: 'en-us',
        //                 sidebarIsOn: true,
        //                 baseMapId: this.baseMapOptions[0].baseMapId,
        //                 isMapPanelOn: true,
        //                 isLayersOn: true,
        //                 isActive: true,
        //                 selectedLocationGroupId: undefined,
        //                 locationPositionX: '',
        //                 locationPositionY: '',
        //                 isLayersSelectable: true,
        //                 activeLayersTabNo: 0,
        //                 isSidebarPinOn: true,
        //                 layersParameterJson: '',
        //                 lastViewedCustomerId: this.customerID,
        //                 customerSettings: [
        //                     {
        //                         cid: this.customerID,
        //                         layers: [],
        //                         lastViewedExtents: [],
        //                         lastViewedLocationGroup: undefined
        //                     }
        //                 ]
        //             };
        //             this.saveSubject.next({});
        //         }
        //         resolve();
        //     });

        // });
        // this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public saveGisUserSettings() {
        if (this.gisUserSetting === undefined) {
            return;
        }
        this.gisUserSetting.lastViewedCustomerId = this.customerID;
        this.gisUserSetting.locationsIsActive = this.includeInactiveLocations;

        if (this.layersParameterJson != undefined && this.layersParameterJson != '') {
            this.layersParameterJson['zoomStatus'] = this.olMap.getView().getZoom();
        } else {
            this.layersParameterJson = {
                zoomStatus: this.olMap.getView().getZoom(),
            };
        }

        const affectedCustomer = this.gisUserSetting.customerSettings.find((x) => x.cid === this.customerID);
        if (affectedCustomer) {
            affectedCustomer.lastViewedLocationGroup = this.locationGroupID;
        }

        this.gisService.updateGisUserSettings(this.gisUserSetting).subscribe(() => { });
    }

    /**
     * Method for changing customer
     */
    public onCustomerChange() {
        this.gisService.clearArcGisToken();

        if (this.gisQuickLook) {
            this.gisQuickLook.clearGis();
        }

        this.foundSavedLayerExtents = false;
        const monitorSymbols = this.mapLayerLists.find((x) => x.name === LayerNameOpts.monitor);
        if (monitorSymbols) monitorSymbols.legendInfo = [];

        this.customerLocationsLoaded = false;
        this.clearSelection();
        this.hideFilters();
        this.resetToggleState();
        this.getLocations();
        this.getAllLocations();
        this.getAlarmsTotal();
        this.getBatteryStatusTotal();
        this.getBlockagePredictionTotal();
        this.getAutoDetectTotal();
        this.getFailedCollectsTotal();
        this.getUpcomingCollects();
        this.getEventsTotal({fromEdit: false});
        // if (this.gisUserSetting != undefined) {
        //     this.gisUserSetting.locationsIsActive = this.includeInactiveLocations;
        //     this.gisUserSetting.selectedLocationGroupId = this.locationGroupID;
        //     this.saveSubject.next({});
        // }
    }

    private refreshBasemapOnCustomerChange() {
        if (!this.baseLayers || !this.activeBaseMapId) {
            return;
        }

        const activeMap = this.baseMapOptions.find((x) => x.baseMapId === this.activeBaseMapId);

        // only token based basemaps need to be reset
        if (!activeMap.url.includes('token')) {
            return;
        }

        const allLayers = this.olMap.getLayers().getArray();
        const activeLayer = allLayers.find(v => v.get('name') === activeMap.name);

        if (activeLayer) {
            this.olMap.removeLayer(activeLayer);

        }

        setTimeout(() => {
            this.applyDynamicBasemap(activeMap.url, activeMap.name);
        }, 0);
    }

    /**
     * Method gets customer locations
     */
    public getLocations(visibleLocations?: Array<number>) {
        // set map loading state
        this.bpLoadingLocations = true;
        this.mapLoadingState = true;

        // ensure customer is set
        if (this.customerID == undefined || this.customerID < 1) {
            this.mapLoadingState = false;
            // this.cumulativeRainTotalLocations = 0;
            this.bpLoadingLocations = false;
            return;
        }

        // initialize location service parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
            locationGroupId: this.locationGroupID,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        this.assignedRainGauge = [];
        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        // setup API call to retrieve fresh locations
        const usersServiceSubscription = this.usersService.getLocationsList(locationArgs).subscribe((data) => {
            if (data) {
                this.bpLoadingLocations = false;
                // store location id from usersService to map with locationDashboardService data
                const tempLocationArray = new Array<number>();
                data.forEach((x) => {
                    if (activeInactiveHandler) {
                        if (x.viewable) {
                            tempLocationArray.push(x.locationId);
                        }
                    } else {
                        if (
                            x.viewable &&
                            (x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance)
                        ) {
                            tempLocationArray.push(x.locationId);
                        }
                    }
                });
                const locationsSubscription = this.locationDashboardService.getLocations(locationArgs).subscribe(
                    (result: Array<Locations>) => {
                        // ensure response
                        if (!result) {
                            return;
                        }
                        //  get the list of current active and acknowledged alarms
                        const alarmArgs = <GetAlarmsArgs>{
                            customerId: this.customerID,
                            Active: true,
                            Acknowledged: true,
                            IncludeInactiveLocations: this.includeInactiveLocations,
                        };

                        const alarmSubscription = this.alarmService.getAlarmsLite(alarmArgs).subscribe(
                            (alarms) => {
                                // set viewable locations
                                this.locations = Array<Locations>();
                                result.forEach((x) => {
                                    if (tempLocationArray.indexOf(x.locationId) > -1) {
                                        this.locations.push(x);
                                    }
                                });
                                this.setupRainGaugeLocations();
                                this.filteredLocations = this.locations.filter((x) => x.geographicLoc !== null);
                                // set customer loaded state
                                this.customerLocationsLoaded = true;

                                // ensure params
                                if (alarms) {
                                    this.updateActiveAlarmingLocations(alarms);
                                } else {
                                    this.updateActiveAlarmingLocations([]);
                                }

                                this.mapLoadingState = false;

                                // 33938 need to make sure that user settings are loaded before calling cumulative rain request
                                this.usersService.userSettings.pipe(filter(v => !!v), first()).subscribe(() => this.getCumulativeRainTotal());

                                this.showLocationMap(false);
                                this.showLocationMap(true);
                                this.uiUtilsService.safeChangeDetection(this.cdr);

                                if (!visibleLocations || visibleLocations.length < 1) {
                                    return;
                                }

                                this.onLocationsChange(visibleLocations);
                            },
                            (error) => {
                                this.mapLoadingState = false;
                                this.bpLoadingLocations = false;
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                            },
                        );

                        this.subscriptions.push(alarmSubscription);
                    },
                    (error) => {
                        this.mapLoadingState = false;
                        this.bpLoadingLocations = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    },
                );
                this.subscriptions.push(locationsSubscription);
            } else {
                this.bpLoadingLocations = false;
            }
        },
        () => {
            this.bpLoadingLocations = false;
        });
        this.subscriptions.push(usersServiceSubscription);
    }

    public checkAndHideWidgetsOnCustomerChange() {
        if (this.currentWidget !== this.widgetOptions.EVENT && this.currentWidget !== this.widgetOptions.CUMULATIVE_RAIN) {
            return;
        }

        this.subscriptions.push(
            this.usersService.isEventEditorOnCustomerAllowed.pipe(skip(1), first()).subscribe((data: { value: boolean }) => {
                if (!data || data.value === false && (this.currentWidget === this.widgetOptions.EVENT)) {
                    this.closeWidget();
                }
            })
        );

        this.subscriptions.push(
            this.usersService.isCumulativeRainAllowed.pipe(skip(1), first()).subscribe((data: { value: boolean }) => {
                if ((!data || data.value === false) && (this.currentWidget === this.widgetOptions.CUMULATIVE_RAIN)) {
                    this.closeWidget();
                }
            })
        );
    }

    public getEventsTotal(filters: EventRangeFilters) {
        if (!this.usersService.isEventEditorOnCustomerAllowed.getValue()) {
            return;
        }

        const filtersStartDate = filters.startDate ? filters.startDate : new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), 0, 0, 0);
        const filtersEndDate = filters.endDate ? filters.endDate : new Date(new Date().setHours(23, 59, 59, 999));

        const startStr = this.dateutilService.formatDateToUTC(filtersStartDate);
        const endStr = this.dateutilService.formatDateToUTC(filtersEndDate);

        this.eventService.getAllEvents(this.customerID, startStr, endStr).subscribe(data => {
            if (filters.fromEdit && this.eventsWidget) {
                const events = data.map(v => ({ ...v, duration: this.eventService.getDuration(v) }));
                this.eventsWidget.setDefaultDurations(events);

                if (this.eventsWidget.gisEventGuid) {
                    const currentGISevent = events.find(v => v.guid === this.eventsWidget.gisEventGuid);
                    this.eventsWidget.gisEventGuid = null;
                    this.eventsWidget.setGISevent(currentGISevent);
                }
            }
            this.events = data ? data : [];
            // Sort earlier events first
            this.events.sort((a, b) => (new Date(a.start).getTime() > new Date(b.start).getTime() ? -1 : 1));
            // need to show events count for last 30 days
            const start = new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), 0, 0, 0);
            const end = new Date(new Date().setHours(23, 59, 59, 999));
            this.last30daysEventsCount = this.events.filter(v => this.eventService.checkIfEventWithinDateRange(v, start, end)).length;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    /**
     * Method sets the total number of critical and low batteries for the current customer ID and group ID
     */
    private getBatteryStatusTotal() {
        this.batteryLoadingState = true;
        this.batteryTotalCount = {
            criticalBatteryTotal: 0,
            lowBatteryTotal: 0,
            customerID: this.customerID,
        };

        const params = <BatteryStatusReportArgs>{
            CustomerID: this.customerID,
            LocationGroupID: this.locationGroupID,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        const subscriptionGetBatteryStatusTotal = this.batteryStatusService.getBatteryStatusTotal(params).subscribe(
            (res: BatteryTotalCount) => {
                if (res) {
                    this.batteryTotalCount.criticalBatteryTotal = res.criticalBatteryTotal;
                    this.batteryTotalCount.lowBatteryTotal = res.lowBatteryTotal;
                }
                this.batteryLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.batteryLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );

        this.subscriptions.push(subscriptionGetBatteryStatusTotal);
    }

    /**
     * Method sets the total number of critical alarms for the current customer ID and group ID
     */
    public getAlarmsTotal() {
        this.alarmLoadingState = true;
        this.activeAlarmCount = null;

        const subscriptionGetActiveAlarms = this.alarmService
            .getAlarmCount(this.customerID, this.locationGroupID)
            .subscribe(
                (res: AlarmsTotal) => {
                    this.activeAlarmCount = res.alarmstotal;
                    this.alarmLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.alarmLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscriptionGetActiveAlarms);
    }

    /**
     * Method sets the total number of auto-review items
     */
    private getAutoDetectTotal() {
        this.autoReviewLoadingState = true;
        this.autoReviewCount = null;

        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        const endDate = startDate;
        const sDate = [startDate.getMonth() + 1, startDate.getDate(), startDate.getFullYear()].join('-');
        let eDate = [endDate.getMonth() + 1, endDate.getDate(), endDate.getFullYear()].join('-');
        eDate += ' 23:59:59';

        const params = <AutoDetectDetectCountArgs>{
            CustomerId: this.customerID,
            LocationGroupId: this.locationGroupID,
            startDate: sDate,
            endDate: eDate,
            AnomalyThreshold: 10,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        const subscriptionGetAutoDetectCount = this.autoScrubSummaryService.getAutoDetectCount(params).subscribe(
            (res: AnomaliesCount) => {
                this.autoReviewCount = res;
                this.autoReviewLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.autoReviewLoadingState = false;
            },
        );

        this.subscriptions.push(subscriptionGetAutoDetectCount);
    }

    /**
     * Method sets the total number of failed collections
     */
    private getFailedCollectsTotal() {
        this.collectionTileLoadingState = true;
        this.failedCollection = 0;

        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        const endDate = startDate;
        const args = <GetCollectHistoryArgs>{
            customerID: this.customerID,
            LocationGroupID: this.locationGroupID,
            StartTime: startDate,
            EndTime: endDate,
            Status: 3,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        const subscriptionGetFailedCollection = this.scheduleCollectionService.getCollectionHistory(args).subscribe(
            (result: Array<CollectionHistory>) => {
                if (result) {
                    this.failedCollection = result.length;
                }
                this.collectionTileLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.collectionTileLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(subscriptionGetFailedCollection);
    }

    private cumulativeRainDisplayDataPlaceholder() {
        const quickSpanSetting = this.usersService.getQuickSpanCumulativeRainSetting();
        this.cumulativeRainInfo = {
            value: 0,
            description: `${this.translate.instant('HOME.CUMULATIVE_RAIN_TILE.ITEM_TEXT')} ${this.dateService.quickSpanDescriptionForQuickSpan(quickSpanSetting)}`
        }
    }

    private getCumulativeRainTotal() {
        if(!this.usersService.isCumulativeRainAllowed.getValue()) {
            this.bpLoadingLocations = false;
            return;
        }

        if(this.cumulativeRainCachedInfo) {
            const quickSpanSetting = this.usersService.getQuickSpanCumulativeRainSetting();

            if(this.cumulativeRainCachedQuickSpan === quickSpanSetting) {
                this.cumulativeRainInfo = this.cumulativeRainCachedInfo;
                return;
            } else {
                this.cumulativeRainCachedQuickSpan = null;
                this.cumulativeRainCachedInfo = null;
                this.cumulativeRainDisplayDataPlaceholder();
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        }

        this.bpLoadingLocations = true;
        this.usersService.userSettings.subscribe((ignoreSettings) => {
            this.cumulativeRainDisplayDataPlaceholder();
            const dateRange = this.usersService.getQuickSpanCumulativeRainDateRange();
            const rainSub = this.usersService.getRainLocations(
                this.customerID,
                this.includeInactiveLocations,
                this.locationGroupID,
                true
            ).subscribe((rainLocationsRes) => {
                if(rainLocationsRes) {
                    const rainLocations = rainLocationsRes.map(v => v.locationId);

                    this.bpLoadingLocations = false;
                    if(!rainLocations || !rainLocations.length) {
                        this.cumulativeRainInfo = null;
                    } else {
                        this.usersService.userSettings.getValue();


                        const dateRangeUTC = this.usersService.convertDateRangeToUTCString(dateRange);

                        const req: CumulativeRainRequest = {
                            customerId: this.customerID,
                            locationIds: rainLocations,
                            start: dateRangeUTC.startDate,
                            end: dateRangeUTC.endDate,
                            includeInactiveLocations: this.includeInactiveLocations,
                            entityIds: [RAIN_ENTITY]
                        };

                        const sub = this.locationService.rainCumulativeGet(req).subscribe((res) => {
                            if(res) {
                                const quickSpanSetting = this.usersService.getQuickSpanCumulativeRainSetting();
                                this.cumulativeRainCachedQuickSpan = quickSpanSetting;
                                this.cumulativeRainInfo = {
                                    value: res.count,
                                    description: `${this.translate.instant('HOME.CUMULATIVE_RAIN_TILE.ITEM_TEXT')} ${this.dateService.quickSpanDescriptionForQuickSpan(quickSpanSetting)}`
                                }
                                this.cumulativeRainCachedInfo = this.cumulativeRainInfo;
                            }
                        },
                        () => {
                            this.cumulativeRainDisplayDataPlaceholder();
                        },
                        () => {
                            this.bpLoadingLocations = false;
                            sub.unsubscribe();
                        });
                    }
                } else {
                    this.bpLoadingLocations = false;
                    this.cumulativeRainDisplayDataPlaceholder();
                }
            },
            () => {
                this.cumulativeRainDisplayDataPlaceholder();
                this.bpLoadingLocations = false;
            },
            () => {
                rainSub.unsubscribe();
            });

        },
        () => { this.bpLoadingLocations = false; },
        () => { this.bpLoadingLocations = false; }
        ).unsubscribe();
    }

    public onCumulativeRainInfo(info: CumulativeRainInfo) {
        this.cumulativeRainInfo = info;
    }

    /**
     * Method sets the upcoming collections
     */
    private getUpcomingCollects() {
        this.collectionLoadingState = true;
        this.upcomingScheduleCollection = null;
        // update the collection on tiles
        const subscriptionGetUpcomingCollection = this.scheduleCollectionService
            .getUpcomingCollection(this.customerID)
            .subscribe(
                (res: string) => {
                    this.upcomingScheduleCollection = res;
                    this.collectionLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.collectionLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscriptionGetUpcomingCollection);
    }

    /**
     * Method sets the total number of blockage prediction items for current customer and group ID
     */
    private getBlockagePredictionTotal() {
        this.bpLoadingState = true;
        this.blockagePredictionCount = null;

        let startDate, endDate;
        const subscriptionGetBlockagePRedictionCount = this.blockagePredictionService
            .getTileBlockagePredictionCount(this.customerID, this.locationGroupID)
            .subscribe(
                (res: number) => {
                    this.blockagePredictionCount = res;
                    this.bpLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.bpLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscriptionGetBlockagePRedictionCount);
    }

    /**
     * Method updates the map with the locations whose IDs passed as argument
     * @param locationIds - locations to set the map to
     */
    public onLocationsChange(locationIds: number[]) {
        this.showLocationMap(false);

        if (!locationIds || locationIds.length === 0) {
            this.locations = this.filteredLocations;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            this.showLocationMap(true);
            return;
        }
        const filteredMarkers = [];
        if (this.filteredLocations) {
            for (const locationId of locationIds) {
                const filteredMarker = this.filteredLocations.find((location) => location.locationId === locationId);
                if (filteredMarker) {
                    filteredMarkers.push(filteredMarker);
                }
            }
        }

        this.locations = filteredMarkers;
        this.locations.sort((a: Locations, b: Locations) => {
            const alocationName = a.locationName.toLowerCase();
            const blocationName = b.locationName.toLowerCase();

            if (alocationName < blocationName) {
                return -1;
            } else if (alocationName > blocationName) {
                return 1;
            } else {
                return 0;
            }
        });

        this.showLocationMap(true);
    }

    public alarmStatusChange(event: AlarmStatusChange) {
        this.updateMapAndAlarms(event.toggleState);
    }

    private updateMapAndAlarms(toggleMap?: boolean) {
        const args = <GetAlarmsArgs>{
            customerId: this.customerID,
            LocationGroupID: this.locationGroupID,
            Active: true,
            Acknowledged: true,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        const alarmSub = this.alarmService.getAlarmsLite(args).subscribe(
            (response: Array<AlarmLiteResponseItem>) => {
                const activeAlarms = response || new Array<AlarmLiteResponseItem>();
                this.updateActiveAlarmingLocations(activeAlarms);
                // REMOVED AMP: don't want to remove locations from map which is what the below function does
                // this.onLocationsChange(activeAlarms.map(x => x.lid));
                this.updateAlarmTable = false;
                this.activeAlarmCount = activeAlarms.filter((alarm) => alarm.state === 0).length;
                this.uiUtilsService.safeChangeDetection(this.cdr);

                if (toggleMap !== undefined) {
                    this.showLocationMap(toggleMap);
                }
            },
            () => {
                this.updateAlarmTable = false;
            },
        );
        this.subscriptions.push(alarmSub);
    }

    private adjustFeatureAlarmsProperties(feature: Feature) {
        const featLid = feature.get('LocationID');

        if (featLid in this.alarmStateFinder) {
            if (feature.get('AlarmId')) {
                //alarmid already set
            } else {
                feature.set('AlarmId', true);
            }

            if (this.alarmStateFinder[featLid] == 1) {
                if (feature.get('AcknowledgedBy')) {
                    //AcknowledgedBy already set
                } else {
                    feature.set('AcknowledgedBy', true);
                }
            } else {
                feature.set('AcknowledgedBy', false);
            }
        } else {
            feature.set('AlarmId', undefined);
        }
    }

    private updateAlarmStateFinder(alarms: AlarmLiteResponseItem[]) {
        // 0 is unacknowledged, 1 is acknowledged, we need to set the worst alarm state, which is 0
        const getAlarmState = (oldState: 0 | 1, newState: 1 | 0) => {
            return (oldState === 0 || newState === 0) ? 0 : 1;
        }
        this.alarmStateFinder = alarms.reduce((acc, curr) => {
            if (curr.lid in acc) {
                acc[curr.lid] = getAlarmState(acc[curr.lid], curr.state);
            } else {
                acc[curr.lid] = curr.state;
            }

            return acc;
        }, {});

        if (this.AdsMonitorLayerSource == undefined) {
            return;
        }

        this.AdsMonitorLayerSource.forEachFeature((feature) => {
            this.adjustFeatureAlarmsProperties(feature);
        });
    }

    private updateActiveAlarmingLocations(alarms: Array<AlarmLiteResponseItem>) {
        this.updateAlarmStateFinder(alarms);

        this.showLocationMap(false);

        if (!alarms || alarms.length === 0) {
            if (this.locations) {
                this.locations
                    .filter((x) => x.alarmingState !== undefined)
                    .forEach((location) => {
                        delete location.alarmingState;
                    });
            }
            if (this.filteredLocations) {
                this.filteredLocations
                    .filter((x) => x.alarmingState !== undefined)
                    .forEach((filteredLoc) => {
                        delete filteredLoc.alarmingState;
                    });
            }
            this.updateAlarmTable = false;
            this.showLocationMap(true);
            return;
        }
        if (this.locations && this.locations.length > 0) {
            this.updateLocationsAlarmingState(alarms, this.locations);
        }

        if (this.filteredLocations && this.filteredLocations.length > 0) {
            this.updateLocationsAlarmingState(alarms, this.filteredLocations);
        }

        this.updateAlarmTable = false;
        this.showLocationMap(true);
    }

    private updateLocationsAlarmingState(alarms: AlarmLiteResponseItem[], locations: Locations[]) {
        const lowerstStatePerLocation: {
            [key: number]: number;
        } = alarms.reduce((acc, alarm) => {
            let state;
            if (acc[alarm.lid] !== undefined) {
                state = Math.min(acc[alarm.lid], alarm.state);
            } else {
                state = alarm.state;
            }

            return {
                ...acc,
                [alarm.lid]: state,
            };
        }, {});

        Object.entries(lowerstStatePerLocation).forEach(([lid, state]) => {
            const location = locations.find((x) => x && x.locationId.toString() === lid);
            if (location) {
                location.alarmingState = state;
            }
        });
    }
    /**
     * Method for closing a widget
     */
    public closeWidget() {
        this.currentlyDisplayedLocationIds$.next([]);
        this.displayMapRains.overrideTooltip = false;
        this.displayMapRains.customLegend = false;
        this.displayMapRains.whiteLocations$.next(false);
        this.displayMapRains.rains$.next(null);
        this.currentWidget = this.widgetOptions.ALL;
        this.domOperationUtilsService.homePageSelectedWidget = this.widgetOptions.ALL;

        this.showLocationMap(false);
        this.viewableLocationsIds = this.originalLocationsIds;
        if (this.AdsMonitorLayerSource) {
            this.loadMonitorLocations(false);
        }
        this.locations = this.filteredLocations;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.toggleWidgetSize(true);
        this.showLocationMap(true);
    }

    public onCumulativeRain(rains: MapRain[]) {
        this.displayMapRains.overrideTooltip = false;
        this.displayMapRains.customLegend = false;
        this.displayMapRains.whiteLocations$.next(false);
        this.displayMapRains.rains$.next(rains);

        const rainsIds = rains.map(r => r.lid);
        this.currentlyDisplayedLocationIds$.next(rainsIds);
        this.viewableLocationsIds = rainsIds;
        this.loadMonitorLocations(false);

        this.olMap.getView().setZoom(this.olMap.getView().getZoom() - 0.5);
    }

    public onGISevent(event: EventModel | null) {
        if (!event || !this.eventsWidget) {
            this.displayMapRains.rains$.next(null);
            this.currentlyDisplayedLocationIds$.next([]);
            this.displayMapRains.overrideTooltip = false;
            this.displayMapRains.customLegend = false;
            this.displayMapRains.whiteLocations$.next(false);
            this.monitorLocationUpdate(false);

            return;
        }

        const allRelatedEvents = this.eventService.getAllRelatedEventsToCurrent(event, this.events, this.eventsWidget.filteredTree);
        const allDisplayedLocationIds: number[] = Array.from(allRelatedEvents.reduce((acc: Set<number>, curr: EventModel) => {
            if (curr.lids && curr.lids.length) {
                curr.lids.forEach(v => acc.add(v));
            }

            return acc;
        }, new Set()));

        this.toggleWidgetSize(true);

        if (event.etype === EventTypes.Rain && event.res) {
            const allRainData: EventRain[] = allRelatedEvents.reduce((acc, curr) => {
                if (!curr.res || !curr.res.locs) return acc;

                return [...acc, ...curr.res.locs];
            }, []);
            this.displayMapRains.rains$.next(allRainData);
        } else {
            this.displayMapRains.rains$.next(null);
        }

        this.displayMapRains.overrideTooltip = true;
        this.displayMapRains.customLegend = true;
        this.displayMapRains.whiteLocations$.next(true);

        this.currentlyDisplayedLocationIds$.next(allDisplayedLocationIds);

        allDisplayedLocationIds.length === 1 ? this.zoomToSelectedLID(allDisplayedLocationIds[0]) : this.onGisMultipleMonitors(allDisplayedLocationIds);
        this.olMap.getView().setZoom(this.olMap.getView().getZoom() - 0.5);
    }

    private onGisMultipleMonitors(locationIds: number[]) {
        this.viewableLocationsIds = [...new Set(locationIds)] as Array<number>;

        if (this.AdsMonitorLayerSource) {
            this.loadMonitorLocations(false);
        }

        this.monitorsRefreshedSubject.pipe(filter(v => v === locationIds.length), first())
            .subscribe((v) => {
                this.olMap.getView().fit(this.initialMapExtent);
                this.olMap.getView().setZoom(this.olMap.getView().getZoom() - 0.5);
            });
    }

    /**
     * Method for hiding widgets' filters
     */
    public hideFilters() {
        this.showSearchAlarms(false);
        this.showSearchBatteryStatus(false);
        this.showSearchBlockagePrediction(false);
        this.showSearchAutoReviw(false);
        this.showSearchCollection(false);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Method for resetting a widget to collapsed state
     */
    public resetToggleState() {
        this.toggleState = true;
        this.domOperationUtilsService.isHomePageWidgetToggled = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public loadWidgetDetail(type) {
        if (this.currentWidget == type) {
            const cumulativeRainWidtgetClose = this.currentWidget === WIDGET_PARAM.CUMULATIVE_RAIN;

            this.currentWidget = this.widgetOptions.ALL;
            this.domOperationUtilsService.homePageSelectedWidget = this.widgetOptions.ALL;
            this.closeWidget();
            this.monitorLocationUpdate(false);

            if(cumulativeRainWidtgetClose) {
                this.cumulativeRainInfo = null;
                this.getCumulativeRainTotal();
            }
        } else {
            this.currentWidget = type;
            this.domOperationUtilsService.homePageSelectedWidget = type;
            this.monitorLocationUpdate(false);
        }
    }

    /**
     * Method for showing / hiding the map
     * @param value - show/hide map
     */
    public showLocationMap(value: boolean) {
        this.showMap = value;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Method sets battery alarms search filter to show /hide
     * @param value - show/hide alarms search filters
     */
    public showSearchAlarms(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#alarmWidgetCardContentId');
        if (value == null) {
            this.showAlarmSearch = !this.showAlarmSearch;
            return;
        }
        this.showAlarmSearch = value;
    }

    /**
     * Method sets battery status widget search filter to show /hide
     * @param value - show/hide battery status search filters
     */
    public showSearchBatteryStatus(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#batteryWidgetId');
        if (value == null) {
            this.showBatteryStatusSearch = !this.showBatteryStatusSearch;
            return;
        }
        this.showBatteryStatusSearch = value;
    }

    /**
     * Method sets blockage prediction widget search filter to show /hide
     * @param value - show/hide blockage prediction search filters
     */
    public showSearchBlockagePrediction(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#blockagePredictionWidgetCardContentId');
        if (value == null) {
            this.showBlockagePredictionSearch = !this.showBlockagePredictionSearch;
            return;
        }
        this.showBlockagePredictionSearch = value;
    }

    /**
     * Method sets colleciton widget search filter to show /hide
     * @param value - show/hide collection search filters
     */
    public showSearchCollection(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#collectionWidgetCardContentId');
        if (value == null) {
            this.showCollectionSearch = !this.showCollectionSearch;
            return;
        }
        this.showCollectionSearch = value;
    }

    public showEventFilters(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#eventWidget');
        if (value == null) {
            this.showEventSearch = !this.showEventSearch;
            return;
        }
        this.showEventSearch = value;
    }

    public showCumulativeRainFilters(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#eventWidget');
        if (value == null) {
            this.showCumulativeRainSearch = !this.showCumulativeRainSearch;
            return;
        }
        this.showCumulativeRainSearch = value;
    }

    /**
     * Method sets auto review search filters to show / hide
     * @param value - show/hide auto review search filters
     */
    public showSearchAutoReviw(value?: boolean) {
        this.domOperationUtilsService.scrollToTop('#autoReviewWidgetId');
        if (value == null) {
            this.showAutoReviwSearch = !this.showAutoReviwSearch;
            return;
        }
        this.showAutoReviwSearch = value;
    }

    /**
     * Method intiates a refresh of anomalies summary for the current customer
     */
    public initiateAnomaliesRefresh() {
        this.autoScrubSummaryService.postAnomaliesRefresh(this.customerID).subscribe(
            (response) => {
                if (response && response === 'Accepted') {
                    this.snackBar.open(this.anamoliesRefreshSuccessMsg, this.dismissBtn, { duration: 10000 });
                } else {
                    this.snackBar.open(this.anamoliesRefreshFailedMsg, this.dismissBtn, { duration: 10000 });
                }
            },
            (error) => {
                this.snackBar.open(this.anamoliesRefreshFailedMsg, this.dismissBtn, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
    }

    // Full screen or within default drawer sizing for widgets
    public toggleWidgetSize(toCollapse: boolean) {
        // toCollapse = true when want to collapse; true when want to expand
        this.showLocationMap(toCollapse);
        this.toggleState = toCollapse;
        this.domOperationUtilsService.isHomePageWidgetToggled = toCollapse;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public addEvent() {
        const editSubs = this.dialog.open(AddEventDialogComponent, {
            data: {
                customerId: this.customerID,
                includeInactiveLocations: this.includeInactiveLocations,
                locationGroupId: this.locationGroupID,
                events: this.events,
                hasPermission: true
            },
            disableClose: true
        }).afterClosed().subscribe((result: boolean) => {
            if (result) {
                // timeout is needed to unprioritize method call
                setTimeout(() => {
                    this.getEventsTotal({fromEdit: true});
                }, 0);
            }
        });

        this.subscriptions.push(editSubs);
    }

    // Functions to adjust map center/zoom
    public getGPSLocation() {
        // When the user wants to zoom to their current location
        const geoPosition = this.geolocation.getPosition();
        if (geoPosition) {
            this.positionFeature.setGeometry(new Point(geoPosition));
            this.olMap.getView().setCenter(geoPosition);
            this.olMap.getView().setZoom(18);
        } else {
            this.dialog.open(LocationStepsGPSComponent, { disableClose: true });
        }
    }
    public zoomIn() {
        this.olMap.getView().setZoom(this.olMap.getView().getZoom() + 1);
    }
    public zoomOut() {
        this.olMap.getView().setZoom(this.olMap.getView().getZoom() - 1);
    }
    public zoomToInital() {
        if (this.initialMapExtent && !this.initialMapExtent.some(x => x === Infinity || x === -Infinity)) {
            this.olMap.getView().fit(this.initialMapExtent);
        }

        this.zoomOut(); // Add a bit of padding around the map by zooming out 1x
    }

    public zoomToLocationIDs(locations: number[]) {
        if (locations === null || locations.length === 0) {
            this.viewableLocationsIds = this.originalLocationsIds;
        } else {
            this.viewableLocationsIds = [...new Set(locations)] as Array<number>;
        }
        if (this.AdsMonitorLayerSource) {
            this.loadMonitorLocations(false);
        }
        this.olMap.getView().fit(this.initialMapExtent);
    }

    public zoomToSelectedFeature(featureOrLayer: any) {
        const format = new GeoJSON();
        const selectionFeatureCollection = [];

        if (featureOrLayer[0].feature) {
            //feature
            const feature = featureOrLayer[0].feature;
            const geoJsonFeat = format.writeFeatureObject(feature);
            selectionFeatureCollection.push(geoJsonFeat);
        } else {
            //layer
            const selectionLayerName = featureOrLayer[0].name;
            this.SelectionLayerSource.forEachFeature((feature) => {
                const featureLayerName = feature.get('_layername');
                if (featureLayerName == selectionLayerName) {
                    const geoJsonFeat = format.writeFeatureObject(feature);
                    selectionFeatureCollection.push(geoJsonFeat);
                }
            });
        }

        const bbox = turf.bbox({
            type: 'FeatureCollection',
            features: selectionFeatureCollection,
        }) as any;

        this.olMap.getView().fit(bbox);
        if (selectionFeatureCollection.length > 1) {
            this.zoomOut();
        }
    }

    private zoomToSelectedLID(locationId: number) {
        const thisLocationFeature = this.AdsMonitorLayerSource.getFeatures().find(
            (x) => x.get('LocationID') === locationId,
        );
        if (!thisLocationFeature) {
            return;
        }

        const lonLat = [thisLocationFeature.get('Longitude'), thisLocationFeature.get('Latitude')];
        this.olMap.getView().setZoom(18);
        this.olMap.getView().setCenter(fromLonLat(lonLat));
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public zoomToSelectedLayer(layersList: mapLayerList[]) {
        if (layersList[0].name == MONITOR_LAYER_NAME) {
            this.zoomToInital();
        } else {
            const affectedLayer = this.olMap
                .getLayers()
                .getArray()
                .find((x) => x.get('name') === layersList[0].name);
            const extentToFit = (affectedLayer as any).esriExtent;
            this.olMap.getView().fit(extentToFit);
            this.zoomOut();
        }
    }
    // End functions to adjust map center/zoom

    // Sidebar functions 2
    public openSideBar2(sideBarOpen2: boolean) {
        this.sidebaropen2 = sideBarOpen2;
        if (this.gisUserSetting) {
            this.gisUserSetting.sidebarIsOn2 = sideBarOpen2;
            this.saveSubject.next({});
            this.updateMapSize();
            if (this.sidebaropen2) {
                //quick look
                this.featureselected(this.featureclick);
            }
        }
    }

    public sidebarPin2() {
        this.toggleSidePin2 = !this.toggleSidePin2;

        this.gisUserSetting.isSidebarPinOn2 = this.toggleSidePin2;
        this.saveSubject.next({});
    }

    public sidebarPinView2() {
        if (!this.toggleSidePin2 && !this.preventUC) {
            this.openSideBar2(false);
        }
    }

    // Sidebar functions
    public openSideBar(sideBarOpen: boolean) {
        this.sidebaropen = sideBarOpen;
        this.gisUserSetting.sidebarIsOn = sideBarOpen;
        this.saveSubject.next({});
        this.updateMapSize();
    }
    public sidebarPin() {
        this.toggleSidePin = !this.toggleSidePin;
        this.gisUserSetting.isSidebarPinOn = this.toggleSidePin;
        this.saveSubject.next({});
    }
    public sidebarPinView() {
        if (!this.toggleSidePin) {
            this.openSideBar(false);
        }
    }
    public clickOnSidebarTab(tabChangeEvent: MatTabChangeEvent): void {
        this.activeLayersTab = tabChangeEvent.index;
        this.gisUserSetting.activeLayersTabNo = tabChangeEvent.index;
        this.domOperationUtilsService.selectedGisTab.next(this.activeLayersTab);
        this.saveSubject.next({});
    }
    // End sidebar functions

    // Measuring functions
    public startMeasure(measureType: GeometryType) {
        // Disable double click zoom while drawing
        let dblClickInteraction;
        this.olMap
            .getInteractions()
            .getArray()
            .forEach(function (interaction) {
                if (interaction instanceof DoubleClickZoom) {
                    dblClickInteraction = interaction;
                }
            });
        this.olMap.removeInteraction(dblClickInteraction);

        this.measureDraw = new Draw({
            source: this.measureLayer.getSource(),
            type: measureType,
            style: (feature) => {
                return measureStyle(feature, this.measurementUnit);
            },
        });
        this.measureDraw.on('drawend', () => {
            this.olMap.removeInteraction(this.measureDraw);
            // Re-enable double click zoom when drawing is finished
            // use a time out to prevent the double click which ended the drawing from zooming in
            setTimeout(
                function () {
                    this.olMap.addInteraction(dblClickInteraction);
                }.bind(this),
            );
        });
        this.olMap.addInteraction(this.measureDraw);
    }
    public clearMeasure() {
        this.olMap.removeInteraction(this.measureDraw);
        this.measureLayer.getSource().clear();
    }
    // End measuring functions

    public resetMap(locationEvent: LocationDetailsModel) {
        if (locationEvent && locationEvent.isRefreshLocations) {
            // if (Number.isInteger(this.currentWidget)) {
            this.onLocationsChange(this.locations.map((x) => x.locationId));
            // } else {
            //     if (this.filteredLocations !== undefined && this.filteredLocations.length > 0) {
            //         this.onLocationsChange(this.filteredLocations.map(x => x.locationId));
            //     }
            // }
            // remove commented as its not refresing the map

            this.getLocations();
            this.customerLocationsLoaded = false;
        }
    }

    /*
     *Adding Measure interaction
     */

    public removeInteraction = () => {
        //this.olMap.removeInteraction(this.measureDraw);
    };

    public changeShownBaseMap(newActiveId: string) {
        const newActiveMap = this.baseMapOptions.find((x) => x.baseMapId === newActiveId);
        const newActiveMapName = newActiveMap.name;
        this.activeBaseMapId = newActiveId;
        this.gisUserSetting.baseMapId = newActiveId;

        const isVisibleMap: Map<string, boolean> = this.baseMapOptions.reduce((acc: Map<string, boolean>, curr) => acc.set(curr.name, curr.baseMapId === newActiveId), new Map());

        this.baseLayers.getLayers().forEach((layer) => {
            const layerName = layer.get('name');
            if (layerName === newActiveMapName) {
                layer.setVisible(true);
            } else {
                layer.setVisible(false);
            }
        });

        this.olMap.getLayers().getArray().forEach((layer) => {
            const name = layer.get('name');
            const isMapLayer = isVisibleMap.has(name);

            if (isMapLayer) {
                layer.setVisible(isVisibleMap.get(name));
            }
        });

        if (newActiveMap.url.includes('token') && !this.olMap.getLayers().getArray().map(v => v.get('name')).includes(newActiveMapName)) {
            this.applyDynamicBasemap(newActiveMap.url, newActiveMap.name);
        }

        this.setMapAttributionText();
        this.saveSubject.next({});
        this.generateOverviewMap();
    }
    /*
     * Search Loaction
     */
    public SortByName(x, y) {
        return x.name == y.name ? 0 : x.name > y.name ? 1 : -1;
    }

    // sort search items by type, locations go first, then features
    public sortByType(a, b) {
        if (a.type === 'location') {
            return -1;
        } else if (b.type === 'location') {
            return 1;
        }

        return 0;
    }

    public locationSearchDisplay(obj): string {
        return obj && obj.name.length > 0 ? obj.name : '';
    }

    public loadingLocSearchData(filterValue: string) {
        const locations = [];
        this.prismLayerList.forEach((layer: LayerSettings) => {
            const layerName = layer.name;
            const layers = this.gisService.layerToFeaturesRelation.get(layerName);

            if (layers) {
                const filteredFeatures = Array.from(layers.keys()).filter(v => String(v).toLowerCase().includes(filterValue));
                filteredFeatures.forEach(v => {
                    const searchItem = {
                        name: v,
                        type: 'feature',
                        layerName,
                        feat: layers.get(v),
                        unchecked: !layer.checked
                    };

                    locations.push(searchItem);
                });
            }
        });

        if (this.AdsMonitorLayerSource) {
            const monitorLayer = this.mapLayerLists.find(v => v.name === MONITOR_LAYER_NAME);
            const isMonitorLayerSelected = monitorLayer && monitorLayer.checked;

            const monitorFeature = this.AdsMonitorLayerSource.getFeatures();
            if (monitorFeature.length > 0) {
                monitorFeature.forEach((feat) => {
                    const prop = feat.getProperties();
                    if (prop.Name.toLowerCase().includes(filterValue) && prop.CustomerID === this.customerID) {
                        locations.push({
                            name: prop.Name,
                            id: prop.LocationID,
                            feat: feat,
                            type: 'location',
                            layerName: LayerNameOpts.monitor,
                            unchecked: !isMonitorLayerSelected
                        });
                    }
                });
            }
        }

        locations.sort(this.SortByName);
        locations.sort(this.sortByType);
        return locations;
    }

    public locationFilter(value) {
        // TODO AMP: why is this sometimes an object and sometimes a string?!
        let filteredValue = value;
        if (typeof value === 'object') {
            filteredValue = value.name;
        }

        filteredValue = String(filteredValue).toLowerCase();
        return this.loadingLocSearchData(filteredValue);
    }

    public getLocationSearchobj(obj: { name: string, type: 'location' | 'feature', feat: Feature, layerName?: string }) {
        this.zoomToSelectedFeature([{ ...obj, layername: obj.layerName, feature: obj.feat }]);
        this.clearSelection();

        if (obj.type === 'location') {
            this.featSelList.feat_database.feat_dataChange.pipe(skip(1), debounceTime(1000), first()).subscribe((data) => {
                const node = data.find((v) => v.item === LayerNameOpts.monitor).children[0];

                if (!node) return;

                this.featSelList.nodeClick(node, 0);
                const treeNode = this.featSelList.feat_treeControl.dataNodes.find(
                    (v) => v.item === LayerNameOpts.monitor && v.level === 0,
                );
                this.featSelList.feat_treeControl.expand(treeNode);
            });

            this.addingToSelection(obj.feat, LayerNameOpts.monitor);
        } else {
            this.addingToSelection(obj.feat, obj.layerName);
        }
        this.activeLayersTab = 2;
        this.domOperationUtilsService.selectedGisTab.next(this.activeLayersTab);
        this.featSelList.feat_loaddata(this.selectionListing);
    }

    /*LocationNameDisplay(locationoptions : locationSearchOption){
        return locationoptions && locationoptions.name ? locationoptions.name : '';
    }
    */
    /*
     *Selection
     *Selection /
     */
    clearSelection() {
        this.selectionListing = {};
        if (this.SelectionLayerSource) this.SelectionLayerSource.clear();
        if (this.HighlightLayerSource) this.HighlightLayerSource.clear();
        if (this.featSelList) this.featSelList.feat_loaddata(this.selectionListing);
        this.propertyItemList = [];
        this.activeMonitor = null;
    }

    startsSelection(type: GeometryType) {
        this.clearSelection();
        if (this.selectBoxInteraction) {
            this.olMap.removeInteraction(this.selectBoxInteraction);
        }

        if (this.selectDrawInteraction) {
            this.olMap.removeInteraction(this.selectDrawInteraction);
        }

        if (type === GeometryType.POINT) {
            this.enableSelection = true;
            this.selectBoxInteraction = new DragBox({
                condition: platformModifierKeyOnly,
            });
            this.olMap.addInteraction(this.selectBoxInteraction);
            this.selectBoxInteraction.on('boxend', (e) => {
                this.enableSelection = false;
                const feature = new Feature({
                    geometry: new Polygon(this.selectBoxInteraction.getGeometry().getCoordinates()),
                });
                this.layerFeatureSelection(feature, type);
            });
        } else if (type === GeometryType.POLYGON || type === GeometryType.CIRCLE) {
            //this.selectInteraction  = new Select();

            // Disable double click zoom while drawing
            this.enableSelection = false;
            let dblClickInteraction;
            this.olMap
                .getInteractions()
                .getArray()
                .forEach(function (interaction) {
                    if (interaction instanceof DoubleClickZoom) {
                        dblClickInteraction = interaction;
                    }
                });
            this.olMap.removeInteraction(dblClickInteraction);

            this.selectDrawInteraction = new Draw({
                type: type,
            });
            this.selectDrawInteraction.on('drawend', (e) => {
                this.layerFeatureSelection(e.feature, type);
                // Re-enable double click zoom when drawing is finished
                // use a time out to prevent the double click which ended the drawing from zooming in
                setTimeout(
                    function () {
                        if (dblClickInteraction) { this.olMap.addInteraction(dblClickInteraction); }
                        this.enableSelection = true;
                    }.bind(this),
                    1000,
                );
            });
            this.olMap.addInteraction(this.selectDrawInteraction);
        }
    }

    private getCurrentLayersAtrributes() {
        const currentLayersAtrributes = {};
        for (const item of this.prismLayerList) {
            currentLayersAtrributes[item.name] = item;
        }
        for (const item of this.mapLayerLists) {
            if (item.name) {
                currentLayersAtrributes[item.name] = item;
            }
        }
        return currentLayersAtrributes;
    }

    layerFeatureSelection(selectionarea: Feature<Geometry>, type: GeometryType) {
        const format = new GeoJSON();
        let geojsonCouldBeACircleWhoCaresSelectionArea;
        let geojsonSelectionArea;

        if (type === GeometryType.CIRCLE) {
            // Downstream function turf.intersect  cannot handle circle geometires
            // It needs to be converted to a polygon first
            // Once we know it is a circle, cast it to circle
            const selectionareaGeometry = selectionarea.getGeometry() as Circle;
            const selectionareaGeometryfromCircle = fromCircle(selectionareaGeometry);

            geojsonCouldBeACircleWhoCaresSelectionArea = format.writeGeometryObject(selectionareaGeometryfromCircle);
            geojsonSelectionArea = format.writeGeometryObject(selectionareaGeometryfromCircle);
        } else {
            geojsonCouldBeACircleWhoCaresSelectionArea = format.writeFeatureObject(selectionarea);
            geojsonSelectionArea = format.writeFeatureObject(selectionarea);
        }

        const extent = turf.bbox(geojsonSelectionArea as any).join();
        switch (type) {
            case GeometryType.POINT:
                break;
            case GeometryType.POLYGON:
                this.olMap.removeInteraction(this.selectDrawInteraction);
                break;
            case GeometryType.CIRCLE:
                this.olMap.removeInteraction(this.selectDrawInteraction);
                break;
        }

        const currentLayersAtrributes = this.getCurrentLayersAtrributes();

        if (
            currentLayersAtrributes[MONITOR_LAYER_NAME].selection === true &&
            currentLayersAtrributes[MONITOR_LAYER_NAME].checked === true
        ) {
            this.AdsMonitorLayerSource.forEachFeature((feat) => {
                const featParsed = format.writeGeometry(feat.getGeometry());
                const coord = JSON.parse(featParsed).coordinates;

                const containPoint = turf.inside(coord, geojsonCouldBeACircleWhoCaresSelectionArea);
                const layernames = LayerNameOpts.monitor;
                if (containPoint) {
                    this.addingToSelection(feat, layernames);
                }
            });
        }

        this.featSelList.feat_loaddata(this.selectionListing);

        const avoidLayers = ['basemap', 'measurement', 'selectionlayer', 'locations', MONITOR_LAYER_NAME, 'esriTileLayer'];

        const mapLayersArray = this.olMap.getLayers().getArray();
        mapLayersArray.forEach((lyr) => {
            const layername = lyr.get('name');

            if (avoidLayers.includes(layername) === false) {
                if (
                    currentLayersAtrributes[layername] &&
                    currentLayersAtrributes[layername].selection === true &&
                    currentLayersAtrributes[layername].checked === true
                ) {
                    (lyr as VectorLayer).getSource().forEachFeature((feat) => {
                        const geoJsonFromFeat = format.writeFeatureObject(feat);
                        const geoJsonFeature = geoJsonFromFeat.geometry;

                        if (geoJsonFeature.type === 'LineString'
                            || geoJsonFeature.type === 'MultiPolygon'
                            || geoJsonFeature.type === 'Polygon') {
                            const selectionareaGeometry = geoJsonFeature;

                            // check line intersection
                            const intersect = turf.lineIntersect(
                                selectionareaGeometry,
                                geojsonCouldBeACircleWhoCaresSelectionArea,
                            );
                            if (intersect.features.length > 0) {
                                this.addingToSelection(feat, layername);
                            } else {
                                // check line contained
                                const featPoint = turf.explode(geoJsonFeature).features[0].geometry;
                                const containPoint = turf.inside(featPoint, geojsonCouldBeACircleWhoCaresSelectionArea);
                                if (containPoint) {
                                    this.addingToSelection(feat, layername);
                                }
                            }
                        } else if (geoJsonFeature.type === 'Point') {
                            const coord = geoJsonFeature.coordinates;
                            const containPoint = turf.inside(coord, geojsonCouldBeACircleWhoCaresSelectionArea);
                            if (containPoint) {
                                this.addingToSelection(feat, layername);
                            }
                        } else {
                            // TODO: We do have more of shapes we eventualy want to handle, they was not implemented so far. If any of them happens, implement them !
                            console.error(`Type ${geoJsonFeature.type} was not implemented. Implement it !`);
                        }
                    });
                    this.featSelList.feat_loaddata(this.selectionListing);
                }
            }
        });
    }

    getWMSFeatureSelection(layer, polygon, extent) {
        const urls = layer.getSource().urls;
        const param = layer.getSource().getParams();
        const url =
            urls[0] +
            '?&INFO_FORMAT=application/json&' +
            'REQUEST=GetFeatureInfo&EXCEPTIONS=application/vnd.ogc.se_xml&SERVICE=WMS&VERSION=1.1.1&BBOX=' +
            extent +
            '&' +
            'LAYERS=' +
            param.LAYERS +
            '&QUERY_LAYERS=' +
            param.LAYERS +
            '&FEATURE_COUNT=100000&X=1&Y=1&CRS=' +
            OLMAPPROJECTION +
            '&STYLES=&WIDTH=1&HEIGHT=1';
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Basic SURFWDprM1IqdjJMeHEjRkQ=',
            },
        })
            .then(function (response) {
                return response.text();
            })
            .then((res) => {
                const response = JSON.parse(res);
                const features = new GeoJSON().readFeatures(response);
                features.forEach((feat) => {
                    const feat1 = new GeoJSON().writeFeatureObject(feat);
                    // TODO AMP: fix this
                    const intersect = turf.intersect(feat1 as any, polygon);

                    if (intersect) {
                        //const feat2 = new GeoJSON().readFeatures(feat);
                        this.addingToSelection(feat, param.LAYERS);
                    }
                });
                this.featSelList.feat_loaddata(this.selectionListing);
            });
    }

    addingToSelection(feat: Feature<Geometry>, layerName: LayerNameOpts | string) {
        if (!feat.set) return;

        const name = this.gisService.getFeatureNameProp(feat);
        feat.set('name', name);
        feat.set('_layername', layerName);
        const forSelection = feat.clone();
        this.SelectionLayerSource.addFeature(forSelection);
        this.highlightFeature(forSelection, layerName, false);
        if (this.selectionListing[layerName]) {
            const markers = this.selectionListing[layerName];
            // This is a patch, there is a bug causing features to be loaded multiple times
            // The real solution is to fix that but for now we just make sure the same
            // feature does not get added more than once to the selection
            // The problem is most likely on the arcgis side, features have no featureId
            // see:  https://github.com/openlayers/openlayers/issues/9819

            for (const item of markers.child) {
                if (item.name === name) {
                    return;
                }
            }
            markers.child.push({
                name: name,
                layername: layerName,
                feature: feat,
                child: [],
            });
        } else {
            this.selectionListing[layerName] = {
                name: layerName,
                layername: null,
                feature: null,
                child: [
                    {
                        name: name,
                        feature: feat,
                        layername: layerName,
                        child: [],
                    },
                ],
            };
        }
    }

    private highlightFeature(feature: Feature<Geometry>, layerName: LayerNameOpts | string, isSelected: boolean) {
        const feat = isSelected ? feature.clone() : feature;
        const type = feat.getGeometry().getType();
        let style: Style | Style[];
        if (layerName === LayerNameOpts.monitor) {
            const labelColor = this.pickMapMonitorColor(feat);
            style = monitorLocation(feat, labelColor, this.gisService.gisUserSettingsSubject$, this.displayMapRains.rains$.getValue(), isSelected ? 'purple' : 'blue');
        } else if (type === 'LineString' || type === 'Point') {
            style = highlightLineOrPoint(feat, this.measurementUnit, isSelected ? 'purple' : 'blue');
        } else if (isSelected) {
            this.HighlightLayerSource.clear();
            return;
        } else {
            return;
        }

        feat.setStyle(style);
        if (isSelected) {
            this.HighlightLayerSource.clear();
            this.HighlightLayerSource.addFeature(feat);
        }
    }

    featureselected(featureclick: featureSelList) {
        this.propertyItemList = [];

        if (!featureclick) {
            return;
        }

        const feat = featureclick[0].feature;
        const prop = feat.getProperties();
        let selectedname = feat.get('name');
        if (!selectedname && featureclick && featureclick[0] && featureclick[0].feature && featureclick[0].feature.values_) {
            selectedname = featureclick[0].feature.values_.Name;
        }
        this.locationId = feat.get('LocationID');
        this.featureclick = featureclick;
        const isMonitor = prop._layername === MONITOR_LAYER_NAME;
        if (this.sidebaropen2 && isMonitor) {
            this.activeMonitor = selectedname;
            setTimeout(() => {
                this.gisQuickLook.locationChange(featureclick);
            }, 100);
        }

        const layerName = feat.get('_layername');
        this.highlightFeature(feat, layerName, true);
        this.SelectionLayerSource.forEachFeature((feat) => {
            if (feat.get('name') == selectedname) {
                this.selectInteraction.getFeatures().clear();
                this.selectInteraction.getFeatures().push(feat);
                /*this.selectInteraction.features_ =[feat];
                this.selectInteraction.applySelectedStyle_(feat);*/
            }
        });
        Object.keys(prop).forEach((key) => {
            if (key != 'geometry') {
                this.propertyItemList.push({
                    name: key,
                    value: prop[key],
                });
            }
        });
    }

    private updateMonitorLayerOnEdit() {
        const subs = this.locationService.updateMonitorLayerSource.pipe(skip(1)).subscribe(() => {
            this.monitorFeatureNamesSet = new Set();
            this.AdsMonitorLayerSource.refresh();
        });

        this.subscriptions.push(subs);
    }

    featureLocationEdit(featureEdit: featureSelList) {
        const feat = featureEdit[0].feature;
        const properties = feat.getProperties();
        this.activeMonitor = properties.name;
        this.addEditLocation(true, properties.LocationID, properties.CustomerID, properties.LocationTypeID);
    }

    /*
     * Side bar
     */

    private updateMapSize() {
        // Multiple timeouts to resize right away but catch the correct resize
        // if the rest of the DOM takes a bit to update first
        setTimeout(() => {
            this.olMap.updateSize();
        }, 10);
        setTimeout(() => {
            this.olMap.updateSize();
        }, 500);
        setTimeout(() => {
            this.olMap.updateSize();
        }, 1000);
    }

    public createPrintMap() {
        this.generateImageOfMap().subscribe((resultingMap) => {
            const htmlComponent = <HTMLImageElement>document.getElementById('print-map-dashboard');
            htmlComponent.src = resultingMap.toDataURL();
        })
    }

    public downloadMap() {
        this.generateImageOfMap().subscribe((resultingMap) => {
            const link = <HTMLAnchorElement>document.getElementById('image-download');
            link.href = resultingMap.toDataURL();
            link.click();
        });
    }

    private generateImageOfMap(): Observable<HTMLCanvasElement> {
        const mapSize = this.olMap.getSize();
        const mapCanvas = <HTMLCanvasElement>document.getElementById('imageCanvas');
        const mapContext = mapCanvas.getContext('2d');

        mapCanvas.width = mapSize[0];
        mapCanvas.height = mapSize[1];

        // Draw the map layers first
        Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), (canvas) => {
            if (canvas.width > 0) {
                const opacity = canvas.parentNode.style.opacity;
                mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                const transform = canvas.style.transform;
                const matrix = transform
                    .match(/^matrix\(([^\(]*)\)$/)[1]
                    .split(',')
                    .map(Number);

                mapContext.save();
                mapContext.setTransform(...matrix);
                mapContext.drawImage(canvas, 0, 0);
                mapContext.restore();
            }
        });

        const scaleBarElement = document.querySelector('#scaleline') as HTMLDivElement;
        const attributionElement = document.querySelector('.map-attribution') as HTMLDivElement;

        const scaleBarCanvasObservable = from(
            html2canvas(scaleBarElement, {
                backgroundColor: null // transparent background
            })
        );

        const attributionCanvasObservable = from(
            html2canvas(attributionElement, {
                backgroundColor: null // transparent background
            })
        );

        return forkJoin([scaleBarCanvasObservable, attributionCanvasObservable]).pipe(
            tap(([scaleCanvas, attributionCanvas]) => {
                const attributionHeight = attributionCanvas.height;
                const scaleHeight = scaleCanvas.height;
                const scaleWidth = scaleCanvas.width;

                // Calculate scaling factor based on the canvas size
                const scalingFactor = Math.min(mapCanvas.width / window.innerWidth, mapCanvas.height / window.innerHeight);

                // Adjust dimensions based on the scaling factor
                const adjustedAttributionHeight = attributionHeight * scalingFactor;
                const adjustedScaleWidth = scaleWidth * scalingFactor;
                const adjustedScaleHeight = scaleHeight * scalingFactor;

                // Calculate relative positions
                const attributionY = mapCanvas.height - adjustedAttributionHeight;
                const scaleX = mapCanvas.width - adjustedScaleWidth - 15;
                const scaleY = attributionY - adjustedScaleHeight - 10;

                // Draw attribution at the bottom with adjusted dimensions
                mapContext.drawImage(attributionCanvas, 0, attributionY, mapCanvas.width, adjustedAttributionHeight);

                // Draw scaleline at the bottom right, above the attribution with adjusted dimensions
                mapContext.drawImage(scaleCanvas, scaleX, scaleY, adjustedScaleWidth, adjustedScaleHeight);
            }),
            map(() => mapCanvas)
        );
    }

    // #23600 updates Any Layer Selected state
    public updateAnyLayerSelectedStatus() {
        this.isAnyLayerSelected = this.mapLayerLists.some(
            x => (x.checked && (!x.child || x.child.length === 0))
                || (x.child.length > 0 && x.child.some(c => c.checked))
        );
    }

    public layersTabLayerAdjust(layersSelected: mapLayerList[], saveSettings: boolean) {
        const mapLayers = this.olMap.getLayers();
        const nameToLayerMap = new Map<string, BaseLayer>(
            flatMap(
                mapLayers.getArray().map((layer) => {
                    const layerName = layer.get('name');
                    if (layerName === LayerNameOpts.networkMap) {
                        const sublayers = (layer as LayerGroup).getLayers();
                        return sublayers.getArray().map((sub) => [sub.get('name'), sub]);
                    } else {
                        return [[layerName, layer]];
                    }
                }),
            ),
        );

        for (const layer of layersSelected) {
            const mapLayer = nameToLayerMap.get(layer.name);
            if (mapLayer) {
                mapLayer.setVisible(layer.checked);
                mapLayer.setOpacity(layer.opacity !== null ? layer.opacity : 1);
            }
            const esriLayerId = this.layerNameToEsriLayerId[layer.name];

            if (layer.checked === true) {
                this.checkedLayersList.add(esriLayerId);
            } else {
                this.checkedLayersList.delete(esriLayerId);
            }

            const itmlayer = {
                name: layer.name,
                isSelectable: layer.selection,
                isVisible: layer.checked
            };

            for (const item of this.prismLayerList) {
                if (item.name === layer.name) {
                    item.selection = layer.selection;
                    item.checked = layer.checked;
                    item.opacity = layer.opacity ? layer.opacity : 1;
                    break;
                }
            }

            for (const item of this.mapLayerLists) {
                if (item.name === layer.name) {
                    item.selection = layer.selection;
                    item.checked = layer.checked;
                    item.opacity = layer.opacity ? layer.opacity : 1;
                    break;
                }
            }

            if (saveSettings) {
                // Update the user settings if you're going to save
                const currentCustomer = this.gisUserSetting.customerSettings.find((x) => x.cid === this.customerID);
                const currentIndex = currentCustomer.layers.findIndex((x) => x.name === itmlayer.name);
                if (currentIndex === -1) {
                    currentCustomer.layers.push(itmlayer);
                } else {
                    currentCustomer.layers[currentIndex] = itmlayer;
                }
            }
        }

        this.updateAnyLayerSelectedStatus();

        this.searchControl.setValue(this.searchControl.value, {emitEvent: true});

        if (this.esriTileSource) {
            const filteredLayersList = Array.from(this.checkedLayersList).filter(function (el) {
                return el != null;
            });

            const esriTileSourceParams = this.esriTileSource.getParams();
            if (esriTileSourceParams.LAYERS.startsWith('show:')) {
                esriTileSourceParams.LAYERS = 'show:' + filteredLayersList.toString();
            } else {
                esriTileSourceParams.LAYERS = filteredLayersList.toString();
            }

            this.esriTileSource.updateParams(esriTileSourceParams);

            if (filteredLayersList.length > 0) {
                this.esriTileLayer.setVisible(true);
            } else {
                this.esriTileLayer.setVisible(false);
            }
        }

        if (saveSettings) {
            this.saveSubject.next({});
        }

        // if (this.userSettingLayerDetailList == undefined || this.userSettingLayerDetailList.length == 0) {
        //     var listlayer = []
        //     let itmlayer = { Id: 0, UserSettingId: 0, LayerName: layer.name, Selection: layer.selection, Checked: layer.checked, Legend: layer.legend };
        //     listlayer.push(itmlayer);
        //     this.userSettingLayerDetailList = listlayer;
        // }
        // else {
        //     var itemList = [];
        //     if (this.userSettingLayerDetailList.length > 0) {
        //         var layerdata = this.userSettingLayerDetailList.filter(d => d.layerName == layer.name);

        //         if (layerdata == undefined || layerdata.length == 0) {
        //             let itmlayer = { Id: 0, UserSettingId: 0, LayerName: layer.name, Selection: layer.selection, Checked: layer.checked, Legend: layer.legend };
        //             itemList.push(itmlayer);
        //         }
        //         this.userSettingLayerDetailList.forEach(itmlayer => {
        //             if (layer.name != itmlayer.layerName) {
        //                 itemList.push(itmlayer);
        //             }
        //             else {
        //                 let itmlayer = { Id: 0, UserSettingId: 0, LayerName: layer.name, Selection: layer.selection, Checked: layer.checked, Legend: layer.legend };
        //                 itemList.push(itmlayer);
        //             }
        //         });
        //         this.userSettingLayerDetailList = itemList;
        //     }
        // }
        // if (this.userSettingLayerDetailList != null && this.userSettingLayerDetailList != undefined && this.userSettingLayerDetailList.length > 0) {
        //     this.gisUserSetting.customerSettings = this.userSettingLayerDetailList;
        //     this.gisService.userSettings.customerSettings = this.userSettingLayerDetailList;
        //     //this.saveGisUserSettings();
        // }
        // if(this.layersParameterJson == undefined || this.layersParameterJson.data.length == 0) {
        //         var itemlyr = [];
        //         itemlyr.push(layer);
        //         var strlayer = '{"data":'+ JSON.stringify(itemlyr) +'}'
        //         this.layersParameterJson = JSON.parse(strlayer);
        //         //this.layersParameterJson.push(layer);
        //     }
        //     else {
        //         var itemList = [];
        //         if(this.layersParameterJson.data.length > 0) {
        //             var layerdata1 = this.layersParameterJson.data.filter(d => d.name == layer.name);
        //             if(layerdata1 == undefined || layerdata1.length == 0) {
        //                 itemList.push(layer);
        //             }
        //             this.layersParameterJson.data.forEach(itmlayer => {
        //                 if(layer.name != itmlayer.name ){
        //                     itemList.push(itmlayer);
        //                 }
        //                 else{
        //                     itemList.push(layer);
        //                 }
        //             });
        //             this.layersParameterJson.data = itemList;
        //         }
        //     }
        // if(this.layersParameterJson != null && this.layersParameterJson != undefined && this.layersParameterJson.data.length > 0) {
        //     this.gisUserSetting.layersParameterJson = JSON.stringify(this.layersParameterJson)
        //     this.saveGisUserSettings();
        // }
    }

    public updateMap() {
        this.updateLocationsList();
    }

    public addEditLocation(isEditMode = false, locationid: number, customerid: number, locTypeId: number) {
        if (locTypeId === 1) {
            //public addEditLocation(isEditMode = false, locationDetails = {}) {
            // ensure that rain gauge collection is present -locationid -customerid
            combineLatest(
                this.gisService.gisUserSettingsSubject$,
                this.mapService.getMarkerLocationDetails(locationid, customerid),
            )
                .pipe(first())
                .subscribe(
                    ([userSettings, result]) => {
                        if (userSettings) {
                            this.activeBaseMapId = userSettings.baseMapId;
                        }
                        if (result) {
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                            const locationDetails: LocationDetails = {
                                series: result.series,
                                serialNumber: result.serialNumber,
                                status: result.status,
                                modem: result.modem,
                                description: result.description,
                                manholeAddress: result.manholeAddress,
                                coordinate: { latitude: result.latitude, longitude: result.longitude, elevation: 1 },
                                locationID: result.locationID,
                                locationName: result.locationName,
                                assignedRainGaugeLocationId: result.assignedRainGaugeLocationId,
                                installationHeight: result.height,
                                installationWidth: result.width,
                                installationType: result.installationType,
                                installationId: result.installationID,
                                depthUnit: result.depthUnit,
                                flowUnit: result.flowUnit,
                                locationType: result.components ? 3 : 1,
                                components: result.components,
                                installationShape: result.installationShape,
                                ipaddress: result.connectionString,
                                range: result.range,
                                capacity: result.capacity,
                                width: result.width,
                                installationShapeTypeID: result.installationShapeTypeID,
                                manholedepth: result.manholeDepth,
                                length: result.length,
                                breadth: result.breadth,
                                coefficient: result.coefficient,
                                assignedRainGaugeLocationName: result.assignedRainGaugeLocationName,
                                entries: result.entries,
                                lastCollectedDate: '',
                            };

                            this.setupRainGaugeLocations();
                            const dialogOptions: MatDialogConfig = {
                                disableClose: true,
                                data: {
                                    isFromCustomerEditor: false,
                                    rainGaugeLocations: this.assignedRainGauge,
                                    customerId: this.customerID,
                                    editMode: isEditMode,
                                    locationDetails: locationDetails,
                                    enableSelectOnMap: true
                                },
                                hasBackdrop: false,
                            };

                            if (this.gisService.locationCardPosition) {
                                dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                            } else if (
                                userSettings &&
                                userSettings.locationPositionX &&
                                userSettings.locationPositionY
                            ) {
                                dialogOptions.position = this.locationCardService.getLocationCardPosition(true, userSettings);
                            }

                            if (this.mapService.addEditLocationDialog) {
                                this.mapService.addEditLocationDialog.close();
                            }

                            this.mapService.addEditLocationDialog = this.dialog.open(
                                TritonLocationDialogComponent,
                                dialogOptions,
                            );
                            this.mapService.addEditLocationDialog
                                .afterClosed()
                                .pipe(first())
                                .subscribe((result: boolean) => {
                                    this.mapService.addEditLocationDialog = null;

                                    if (result) {
                                        this.getAllLocations();
                                        this.monitorLocationUpdate(false);
                                    }
                                });
                            // let closeDialogSubscription = this.mapService.addEditLocationDialog.afterClosed()
                            //     .subscribe((dialogResult) => {
                            //         if (dialogResult && dialogResult.success) {
                            //             //his.locationIdEdited = location.locationId;
                            //             //this.getAllLocations(this.customerId);
                            //             this.uiUtilsService.safeChangeDetection(this.cdr);
                            //         }
                            //     });
                            // this.subscriptions.push(closeDialogSubscription);
                            // TODO: If name is changed then TOOLS > Monitors and not refreshing
                            // if(locationDetails.locationType === 1) {
                            //     this.mapService.openEditLocationDialog(dialogOptions, true)
                            //         .subscribe((dialogResult) => {
                            //             if (dialogResult && dialogResult.success) {
                            //                 //his.locationIdEdited = location.locationId;
                            //                 //this.getAllLocations(this.customerId);
                            //                 this.uiUtilsService.safeChangeDetection(this.cdr);
                            //             }
                            //     });
                            // } else if(locationDetails.locationType === 3) {
                            //     this.mapService.openEditCompositeDialog({
                            //         disableClose: true,
                            //         data: [locationDetails, this.locations, this.customerID]
                            //         }, true)
                            //         .subscribe((dialogResult) => {
                            //             if (dialogResult && dialogResult.success) {
                            //                 this.getAllLocations();
                            //                 this.uiUtilsService.safeChangeDetection(this.cdr);
                            //             }
                            //     });
                            // } else {
                            //     this.mapService.openEditMarkerDialog({
                            //         disableClose: true,
                            //         data: {
                            //           'openEditDialogFromCustomerEdit': true,
                            //           'CustomerId': this.customerID,
                            //           'locationList': this.locations,
                            //           'assignedRainGauge': this.assignedRainGauge,
                            //           'location': locationDetails.locationID
                            //         }
                            //       }, true).subscribe((dialogResult) => {
                            //         if (dialogResult && dialogResult.success) {
                            //             this.getAllLocations();
                            //             this.uiUtilsService.safeChangeDetection(this.cdr);
                            //         }
                            //     });
                            // }
                        }
                    },
                    (error) => {
                        this.isLoading = false;
                    },
                );
        } else if (locTypeId === 3) {
            this.customerLocationService.getLocationSummary(this.customerID, this.includeInactiveLocations).subscribe((items: any) => {
                let locations = new Array<CustomerLocationDetail>();
                if (items) {
                    locations = items.listCutomerLocations;
                }
                combineLatest(
                    this.gisService.gisUserSettingsSubject$,
                    this.mapService.getMarkerLocationDetails(locationid, this.customerID),
                )
                    .pipe(first())
                    .subscribe(
                        ([userSettings, result]) => {
                            if (result) {
                                this.isLoading = false;
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                                const locationDetails: LocationDetails = {
                                    locationID: result.locationID,
                                    locationName: result.locationName,
                                    description: result.description,
                                    manholeAddress: result.manholeAddress,
                                    coordinate: {
                                        latitude: result.latitude,
                                        longitude: result.longitude,
                                        elevation: 1,
                                    },
                                    status: result.status,
                                    modem: result.modem,
                                    series: result.series,
                                    serialNumber: result.serialNumber,
                                    ipaddress: result.ipaddress,
                                    lastCollectedDate: undefined,
                                    components: result.components,
                                    enableSelectOnMap: true
                                };

                                const dialogOptions: MatDialogConfig = {
                                    disableClose: true,
                                    data: [locationDetails, locations, this.customerID],
                                    hasBackdrop: false
                                };

                                if (this.gisService.locationCardPosition) {
                                    dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
                                } else if (
                                    userSettings &&
                                    userSettings.locationPositionX &&
                                    userSettings.locationPositionY
                                ) {
                                    dialogOptions.position = this.locationCardService.getLocationCardPosition(false, userSettings);
                                }

                                if (this.compositeLocationDialog) {
                                    this.compositeLocationDialog.close();
                                }
                                this.compositeLocationDialog = this.dialog.open(CompositeLocComponent, dialogOptions);

                                this.compositeLocationDialog.afterClosed().subscribe((result) => {
                                    if (result && result.success) {
                                        // this.locationIdEdited = location.locationId;
                                        this.getAllLocations();
                                        this.uiUtilsService.safeChangeDetection(this.cdr);
                                    }
                                });
                            }
                        },
                        (error) => {
                            this.isLoading = false;
                        },
                    );
            });
        }
        // this.setupRainGaugeLocations();
        // const editTritonLocationComponentMapData = <AddLocationModalData>{
        //   rainGaugeLocations: this.assignedRainGauge,
        //   customerId: this.customerID,
        //   editMode: isEditMode,
        //   isFromCustomerEditor: false,
        //   locationDetails: locationDetails
        // };

        //   const dialogOptions: MatDialogConfig = {
        //     disableClose: true,
        //     data: editTritonLocationComponentMapData,
        //     hasBackdrop: false,
        //   }

        //   const userSettings = this.gisService.userSettings

        //   if (userSettings && userSettings.locationPositionX) {
        //     dialogOptions.position = { top: userSettings.locationPositionY + 'px', left: userSettings.locationPositionX + 'px' }
        //   }

        //   this.mapService.addEditLocationDialog = this.dialog.open(AddTritonLocationComponent, dialogOptions);

        //   this.mapService.addEditLocationDialog.componentInstance.refreshLocations
        //     .subscribe((location: LocationDetailsModel) => {
        //       // this.refreshLocations(location);
        //     });
        //     this.mapService.addEditLocationDialog.afterClosed().subscribe(res => {
        //     // if user add new location successfully, then refresh map.
        //     if (res && res.success) {
        //     }
        //   });
    }
    private setupRainGaugeLocations() {
        // check if rain guage locations are already filled and if so exit immediately
        if (this.assignedRainGauge && this.assignedRainGauge.length > 0) {
            return;
        }

        // check if locations exist
        if (this.locations) {
            // make the filtered array for assignedRainGauge.
            this.assignedRainGauge = this.locations.filter((location) => {
                if (location && location.series && location.series.toLowerCase().indexOf('rainalert') > -1) {
                    return location;
                }
            });
        }
    }

    public LocationDashboard() {
        const customerId = isNaN(this.customerID) ? 0 : this.customerID;
        this.router.navigate(['/pages/menuDashboard/viewData'], {
            queryParams: {
                c: customerId,
                lg: this.locationGroupID,
                lid: this.locationId,
            },
            relativeTo: this.activatedRoute,
        });
    }

    public setPreventStatus(status) {
        this.preventUC = status;
    }
}
