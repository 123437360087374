import { EventModel, EventTypes } from "app/shared/models/event";
import { AnnotationOptions, BasicSeriesData } from "app/shared/models/hydrographNEW";
import { DisplayGroupScales } from "app/shared/models/user-settings";
import { ConfirmationEntitiesEnum } from "app/shared/models/view-data-filter";

export interface HydrographBuildDataConfig {
    graphGrouping: number[][];
    annotationOptions: AnnotationOptions;
    seriesData: BasicSeriesData[];
    events: EventModel[];
    locationId: number;
    showAllMarkers: boolean;
    selectedDateRange: number[];
    showEditMenu?: () => boolean;
    displayGroupScales?: DisplayGroupScales[];
    selectedConfirmationEntity?: ConfirmationEntitiesEnum;
    seriesVisibility?: { [entityId: number]: boolean };
}

export enum ChartDashStyle {
    None = 0,
    Dash = 1,
    Solid = 2
}

export enum ChartSerieType {
    Line = 1,
    Column = 2,
    ScatterConfirmation = 3,
    ScatterOiriginalEdits = 4
}

export enum ChartPointShape {
    Triangle = 1,
    Circle = 2,
    Square = 3
}

/**
 * Chart configuration that is not depend on any chart solution, it can be used by any chart solution
 */
export interface GraphChartConfiguration {
    yAxis: GraphYAxisConfiguration[],
    xAxis?: {
        floor: number;
        ceiling: number;
        min: number;
        max: number;
    },
    series: GraphSerieConfiguration[];
    eventSeries?: GraphEventSerieConfiguration[];
    annotationSeries?: GraphAnnotationSerieConfiguration[];
    graphGrouping: GraphYAxisConfiguration[][];
}

export interface GraphYAxisConfiguration {
    plotLines?: {};
    title: string;
    chartIndex: number;
}

export interface GraphSerieConfiguration {
    id: number;

    displayGroupId?: number;
    dataType?: number;
    entityId?: number;
    entityName?: string;


    name: string;
    serieType: ChartSerieType;
    shape?: ChartPointShape;

    lid?: number;

    visible?: boolean;

    yAxis?: number;

    showInLegend: boolean;
    hasLegend: boolean;
    hasTooltip: boolean;

    color?: string;
    unitOfMeasure?: string;
    precision?: number;

    data: GraphDataConfiguration[]
}

export interface GraphDataConfiguration {
    x: number;
    y: number;

    flagged?: boolean;
    edited?: boolean;
    segmentColor?: string;
    color?: string;
    quality?: number;
}

/** Represent Event serie: General, Work Order, Rain, ... */
export interface GraphEventSerieConfiguration {
    name: string;
    fillColor: string;
    color: string;
    eventSymbol: string;
    showInLegend: boolean;
    eventRange: boolean;
    etype: EventTypes;
    data: GraphEventDataConfiguration[];
}

/** Represent Event serie data, as events starts and ends based on time, it does not have any Y value */
export interface GraphEventDataConfiguration {
    guid: string;
    x: number;
    start: number;
    description: string;
    duration: string;
    xto?: number;
    low: number;
    high: number;
}

export interface GraphAnnotationSerieConfiguration {
    hasTooltip: boolean,
    unitOfMeasure?: string;
    precision?: number;
    name: string;
    color: string;
    yAxis: number;
    dashStyle: ChartDashStyle;
    data: GraphAnnotationDataConfiguration[];
    displayGroupId: number;

}
export interface GraphAnnotationDataConfiguration {
    x: number;
    y: number;
}
