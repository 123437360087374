import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { VaultService } from 'app/pages/vault';
import { FileType, IFileView } from 'app/shared/models/file-view';
import { FileService } from 'app/shared/services/file.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
    selector: 'app-file-view',
    templateUrl: './file-view.component.html',
    styleUrls: ['./file-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class FileViewComponent implements OnInit {
    public title: string;
    public type: FileType;
    public rawURI: string;
    public fileViewLoadingState: boolean;
    public fileContent: any;
    public showImg = false;
    public uri: string;
    public absoluteUri: string;
    constructor(
        private dialogRef: MatDialogRef<FileViewComponent>,
        @Inject(MAT_DIALOG_DATA) private model: IFileView,
        private domSanitizer: DomSanitizer,
        private fileService: FileService,
        private vaultService: VaultService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
    ) {
        // get the name by cutting of the tail following the equal sign
        this.title = decodeURI(model.name);
        this.type = model.type;
        this.absoluteUri = model.absoluteUri;
        this.uri = model.uri;
    }

    public ngOnInit() {
        if (this.type === FileType.Image) {
            this.fileContent = this.absoluteUri;
            this.showImg = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.filePreview();
        }
    }
    public filePreview() {
        const files = [];
        const self = this;
        files.push(this.uri);
        self.fileViewLoadingState = true;
        self.uiUtilsService.safeChangeDetection(self.cdr);
        this.vaultService.download(files).subscribe(
            (res) => {
                const blob = new Blob([res], { type: res.type });
                const reader = new FileReader();
                reader.readAsText(blob, 'UTF-8');
                reader.onload = (e) => {
                    self.fileContent = reader.result;
                    self.fileViewLoadingState = false;
                    self.uiUtilsService.safeChangeDetection(self.cdr);
                };
            },
            (error) => {
                return files[0];
            },
        );
    }

    public download() {
        const files = [];
        files.push(this.uri);
        if (files && files.length > 0) {
            const splitFile = files[0].split('/');
            const fileName = splitFile.find((x) => x.includes('.'));
            this.vaultService.downloadFile(files).subscribe((res) => {
                const data = new Blob(['\ufeff', res], { type: 'text/csv;charset=utf-8' });
                importedSaveAs(data, fileName);
            });
        }
    }
}
