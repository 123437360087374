import { ColorHEX, SolidFill } from "@arction/lcjs";


export class ChartPointSize {
    public static Confirmation = 15;
    public static OriginalEdit = 10;
    public static DataPoint = 5;
}

export const LC_DOUBLE_CLICK_TOLERANCE = 200;

export const Y_AXIS_FONT_SIZE = 12;
export const LABEL_OVERLAP_THRESHOLD = 0.05;
export const CHART_COLOR_SELECTIONBOX_FILL = '#99999955';
export const CHART_COLOR_SELECTIONBOX_STROKE = '#33333355';
export const CHART_COLOR_ZOOMBANDCHART_SERIES = '#333333';
export const CHART_COLOR_DRAGG_POINT = '#ddaa33';

export const LC_YAXIS_THICKNESS_LEFT = 70;
export const LC_YAXIS_THICKNESS_RIGHT = 70;

export const CHART_SERIES_TOP_GAP = 1.2;

export const TOOLTIP_BOX_STYLES = {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#ffffff',
    border: '1px solid #000',
    padding: '5px',
    minWidth: '150px'
}

export const TOOLTIP_BOX_DM_STYLES = {
    ...TOOLTIP_BOX_STYLES,
    backgroundColor: '#333',
    border: '1px solid #ccc'
}

export const TOOLTIP_INFO_STYLES = {
    fontSize: '12px',
    position: 'absolute',
    backgroundColor: '#ffffff',
    padding: '5px'
}

export const TOOLTIP_INFO_DM_STYLES = {
    ...TOOLTIP_INFO_STYLES,
    backgroundColor: '#333'
}

export const HG_TOOLTIP_ARROW_STYLE = {
    position: 'absolute',
    height: '18px',
    width: '18px',
    backgroundColor: '#ff0000',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    opacity: '0.5',
    // Prevent image from being dragged or selected
    'user-drag': 'none',
    '-webkit-user-drag': 'none',
    'user-select': 'none',
    '-moz-user-select': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none'
}

export const HG_TOOLTIP_INFO = {
    left: 400,
    topModifier: -40
}

export const HG_TOOLTIP_PADDING = {
    x: -20,
    y: -50
}

export const NEW_CHART_TOP_RIGHT_SCREEN_POINT = { clientX: 500, clientY: 500 };

export const HTML_DISPLAY_BLOCK = 'block';
export const HTML_DISPLAY_NONE = 'none';
export const HTML_BR = '<br />';
export const HTML_CIRCLE = '&#9679;';

export const MIN_Y_RANGE = 0.1;
export const MIN_Y_RAIN_RANGE = 0.02;

// #37228 If a gap between data is above constant period then do not join lines
export const MAX_X_JOIN_LINE_RANGE = 2 * 60 * 60 * 1000;

export const LC_MAIN_CHART_MAJOR_TICK_COLOR = '#777';
export const LC_MAIN_CHART_MINOR_TICK_COLOR = '#ccc';

export const LC_ZOOM_BAND_MAJOR_TICK_COLOR = '#333';
export const LC_ZOOM_BAND_MINOR_TICK_COLOR = '#666';

export const LC_TOOLTIP_MIN_WIDTH = 200;

export const LC_RAIN_THICKNESS = 4;
export const LC_RAIN_OVERVIEW_ALPHA = 55;

export const LC_ZOOM_BAND_SERIES_BACKGROUND = '#444444';
export const LC_ZOOM_BAND_OVERLAY = '#22222299';
export const LC_SERIES_THICKNESS = 2;
export const LC_POINTSERIES_THICKNESS = 1;


export const JUMP_SCROLL_PADDING_X = 40;
export const JUMP_SCROLL_Y = 55;
export const JUMP_SCROLL_FONT_SIZE = 26;

export const JUMP_SCROLL_FONT_ENABLED_DARK = new SolidFill({color: ColorHEX('#ffffff')});
export const JUMP_SCROLL_FONT_ENABLED = new SolidFill({color: ColorHEX('#000000')});
export const JUMP_SCROLL_FONT_DISABLED = new SolidFill({color: ColorHEX('#aaaaaa')});

export const ZOOMBAND_ONE_CHART_SIZE = 1/3;
export const ZOOMBAND_TWO_CHARTS_SIZE = 2/3;
export const ZOOMBAND_THREE_CHARTS_SIZE = 1;
