import { DatePipe } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { MatLegacySlideToggle as MatSlideToggle} from '@angular/material/legacy-slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { catchError, concatMap, finalize, toArray } from 'rxjs/operators';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { BasinStormResult } from 'app/shared/models/sliicer';
import * as _ from 'underscore';
import {
    SliicerCaseStudy,
    DryDayData,
    StormSettings,
    BasinDefinition,
    BasinStormSettings,
    Overrides,
    BasinOutlier,
    OutlierDay,
    OutlierType,
    StormEvent,
    DayOfWeekGroupDays,
    WeekGroup,
    PrecompensationType,
} from 'app/shared/models/sliicer';
import { MultiSeriesData } from 'app/shared/models/sliicer-data';
import { BasinOverrideArea, BasinQvsISettingsOverrides, OverridesPartialResponse, OverridesStormEntry, StormEventAdjustment } from 'app/shared/models/sliicer/overrides';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DryDaysComponent } from './dry-days/dry-days.component';
import {
    StormPeriodAdjustment,
    ExcludeStormChanged,
    PrecompensationChanged,
    SeasonChange,
    RegimeChange,
} from './flow-monitor.model';
import { ExportStormComponent } from '../study-results/export-storm/export-storm.component';
import { AdsFlowMonitorHydroGraphComponent } from '../../shared/components/flow-monitor-hydrograph/flow-monitor-hydrograph.component';
import { UpdateInfo } from '../../shared/components/updates-widget/updates-widget.models';
import { UpdatesWidgetService } from '../../shared/components/updates-widget/updates-widget.service';
import { Preferences, Regime, Settings } from 'app/shared/models/sliicer/settings';
import { getSeasons, Season, getYears } from '../study-settings/seasons-settings/seasons-settings.utils';
import { ListItem } from 'app/shared/models/selected-Item';
import { UsersService } from 'app/pages/admin/users.service';
import { BasinFilter, BasinFiltersRequest, QVI_GROUP_ALL_STORMS, QVI_GROUP_ALL_STORMS_CONFIG } from 'app/shared/models/sliicer/basins';
import { QvsIConfigurations, StormChooserModel } from 'app/shared/models/sliicer/results/storm-events';
import { concat, EMPTY, forkJoin, from, Observable, of, Subject, Subscription } from 'rxjs';
import { SignalRService } from 'app/shared/services/signalr.service';
import { SignalRMessageType, SliicerMessageType, SliicerSignalRMessage, VAULT_MOVE_FAIL_MESSAGE, VAULT_MOVE_SUCCESS_MESSAGE } from 'app/shared/models/signalr-message';
import { CustomerService } from 'app/shared/services/customer.service';
import { UnitsService } from 'app/shared/services/units.service';
import { StormSettingsDialogData } from './storm-events/storm-settings-dialog/storm-settings-dialog.utils';
import { AdsStormEventsComponent } from './storm-events/storm-events.component';
import { BasinStateEnum, StudyCalcState } from 'app/shared/models/sliicer/metadata';
import { BasinQviStatsComponent } from './basin-qvi-stats/basin-qvi-stats.component';
import { DesignStormItem } from 'app/shared/models/sliicer/design-storm';

const multicolorSeries = require('highcharts-multicolor-series/js/multicolor_series');
const UPDATES_MODEL = 'studyFlowMonitor';
const QVSI_FILTER_GROUP_CHANGE = 'EDITED_QVSI_GROUP_CHANGE';
const EXCLUDED_STORMS_REMOVED = 'EXCLUDED_STORMS_REMOVED';
const ACTIVE_DESIGN_STORMS_CHANGE = 'ACTIVE_DESIGN_STORMS';
const DESIGN_STORMS_CHANGE = 'DESIGN_STORMS';


// full = full study update
// basin = basin level update
// null = no updates needed
type UPDATE_CALL_TYPE = 'basin' | 'full' | null;
multicolorSeries(Highcharts);

const ALL = 'All';
const FLOW = 'Flow';

@Component({
    providers: [ExportStormComponent],
    selector: 'app-flow-monitor',
    templateUrl: './flow-monitor.component.html',
    styleUrls: ['./flow-monitor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FlowMonitorComponent implements OnInit, OnDestroy {
    @ViewChild('hydroGraphChart', { static: true }) public hydroGraphChart: AdsFlowMonitorHydroGraphComponent;
    @ViewChild(AdsStormEventsComponent) private stormEvensComponent: AdsStormEventsComponent;
    @ViewChild(BasinQviStatsComponent) private qviStatsComponent: BasinQviStatsComponent;

    public static API_SLIICER_DATE_FORMAT = 'YYYY-MM-DD';

    // these are properties given to my children
    public customerId: number;
    public caseStudyId: string;
    public telemetryKey: string;
    public exportErrMsg: string;

    public dayOfWeekGroups: WeekGroup[] = [];
    public stepLength = 0;
    public basinList: ListItem[] = [];
    public currentBasinDryDays: DryDayData;
    public originalCurrentBasinDryDays: DryDayData;
    public stormEvents: StormEvent[];
    public stormBasinResults: BasinStormResult[] = [];
    public excludedStorms: number[];
    public defaultPrecompType: PrecompensationType;
    public selectedStormId = 0;
    public dismissText: string;
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG',
    ];
    public qvsIConfigurations: QvsIConfigurations[];
    public originalQvsIConfigurations: QvsIConfigurations[];

    // translation variables
    private requestFailureMessage: string;
    private dryDaySaveMessage: string;
    private applyUpdatesMessage: string;
    private dryDaySaveErrorMessage: string;
    public startDateString: string;
    public endDateString: string;
    public tooltipEnabled = true;

    private subscriptions = [];

    // undoable items
    public basinStormSettings: BasinStormSettings[] = [];
    public stormSettings: StormSettings[] = [];
    private originalBasinStormSettings: BasinStormSettings[] = [];
    private originalStormSettings: StormSettings[] = [];
    private basinOutliers: BasinOutlier[] = [];
    private originalBasinOutliers: BasinOutlier[] = [];
    public addedStormEvents: Array<StormEventAdjustment> = [];
    private originalAddedStormEvents: StormEventAdjustment[] = [];
    public removedStormEvents: Array<StormEventAdjustment> = [];
    private originalRemovedStormEvents: StormEventAdjustment[] = [];
    public basinQvsISettings: BasinQvsISettingsOverrides[] = [];
    public originalBasinQvsISettings: BasinQvsISettingsOverrides[] = [];
    public basinOutlierDays: OutlierDay[] = [];
    public seasons: Season[] = [];
    public regimes: Regime[] = [];
    public seasonFilter: boolean;

    public isLoading = true; // is the API being called for the main hydrograph
    public stormEventsLoading = true;
    private isRequestProcessing = false; // restrict UI from sending multiple request to API.
    private ignoreNextUpdate = false; // after successful update we do not need to reload _everything_

    /**
     * Flag indicating if the user is Editing the dry days or zooming. Tied to the
     * TODO: move this into the flow-monitor-hydrograph component
     */
    public isDryDaysEditActive = false;
    public isStormAddActive = false;

    private tempSelectedBasin: string;
    private changeToSelectedBasin: string;
    public selectedBasin: string;

    public isStudyLocked: boolean;
    private rainfallOnTop: boolean;
    private startDate: string;
    private endDate: string;
    private dateFormat: string;

    public basinDaysLoading = true;
    public availableSeasons = [];
    public selectedSeasons = [];
    public availableYears: number[] = [];
    public selectedYears = [];
    public selectedRegime = [];

    public shouldApplyFilters = false;

    originalStormEvents: StormEvent[];
    allSeasons: Season[];
    hasYears: boolean;
    hasRegimes: boolean;
    hasSeasons: boolean;

    public originalQvsiFilter: string;
    public qvsiFilter: string;
    public qvsiFilterAfterUpdate: string;

    public filtersEnabled = false;
    public subTabsIndex = 0;

    public designStorms: DesignStormItem[] = [];
    public originalDesignStorms: DesignStormItem[] = [];
    public activeDesignStorms: string[] = [];
    public originalActiveDesignStorms: string[] = [];

    public refreshStorm$ = new Subject<void>();

    constructor(
        private dateutilService: DateutilService,
        private translateService: TranslateService,
        private sliicerService: SliicerService,
        private dialog: MatDialog,
        private utilService: UiUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
        private cdr: ChangeDetectorRef,
        public translate: TranslateService,
        private datePipe: DatePipe,
        public updatesWidgetService: UpdatesWidgetService,
        private userService: UsersService,
        private signalRService: SignalRService,
        private customerService: CustomerService,
        private unitsService: UnitsService
    ) {

        const translateSubs = translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.exportErrMsg = translateValues['VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG'];
        });

        this.subscriptions.push(translateSubs);
    }

    public ngOnInit() {
        this.sliicerService.basinHgFlowData.next(null);
        this.applyTranslations();
        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case 'undoChanges':
                            this.discardChanges();
                            break;
                        case 'applyChanges':
                            this.applyChanges();
                            break;
                    }
                }
            }),
        );



        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
                this.checkFiltersEnable();
            }),
        );

        // used to ensure last calculation time of first basin, to avoid extra calls
        let isFirstCall = true;

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
                const didStudyChange = this.caseStudyId !== caseStudyDetails.id;
                this.checkFiltersEnable();
                if (this.ignoreNextUpdate) {
                    this.ignoreNextUpdate = false;
                    return;
                }

                this.startDate = caseStudyDetails.config.startDate;
                this.endDate = caseStudyDetails.config.endDate;
                this.subscriptions.push(
                    this.dateutilService.dateFormat.subscribe(() => {
                        this.dateFormat = this.dateutilService.getDateFormat();
                        this.startDateString = moment(this.startDate).format(this.dateFormat);
                        this.endDateString = moment(this.endDate).format(this.dateFormat);
                    }),
                );

                this.customerId = caseStudyDetails.customerId;
                this.caseStudyId = caseStudyDetails.id;
                this.telemetryKey = caseStudyDetails.meta.telemetryDatabaseName;
                this.stepLength = caseStudyDetails.config.stepLengthMinutes;
                this.rainfallOnTop = caseStudyDetails.rainfallGraphFlag;
                this.dayOfWeekGroups = caseStudyDetails.settings.dayGroups;
                this.defaultPrecompType = caseStudyDetails.settings.defaultPrecompType;
                this.qvsIConfigurations = caseStudyDetails.settings.qvsIConfigurations ? [...caseStudyDetails.settings.qvsIConfigurations] : [];
                this.originalQvsIConfigurations = JSON.parse(JSON.stringify(this.qvsIConfigurations));
                this.seasons = getSeasons(caseStudyDetails, true);
                this.allSeasons = getSeasons(caseStudyDetails, false);
                this.sliicerService;
                this.availableSeasons = [
                    ...new Map(this.allSeasons.map((item) => [item['name'], item.name]) as ReadonlyArray<any>).values(),
                ];
                this.availableYears = getYears(caseStudyDetails);
                this.hasYears = this.sliicerService.hasYears();
                this.hasSeasons = this.sliicerService.hasSeasons();
                this.hasRegimes = this.sliicerService.hasRegimes();
                this.seasonFilter = this.hasYears || this.hasSeasons || this.hasRegimes ? true : false;
                this.regimes = caseStudyDetails.settings.regimes || [];

                this.activeDesignStorms = caseStudyDetails?.preferences?.activeDesignStorms || [];
                this.originalActiveDesignStorms = [...this.activeDesignStorms];

                const activeDesignStormNames = new Set(this.activeDesignStorms);

                const designStorms = caseStudyDetails?.settings?.designStorms?.map((s) => ({ ...s, isActive: activeDesignStormNames.has(s.name) })) || [];
                this.originalDesignStorms = designStorms;
                this.designStorms = JSON.parse(JSON.stringify(designStorms));

                this.basinList = FlowMonitorComponent.BindBasins(caseStudyDetails.basinDefinition);
                this.subscriptions.push(this.customerService.getCustomerUnits(this.customerId).subscribe((units) => {
                    this.sliicerService.units.next(units);
                }))

                this.subscriptions.push(this.unitsService.getUnitsCategories(this.customerId, [2, 5, 7]).subscribe(res => {
                    this.sliicerService.unitsPrecision.next(Object.assign({}, ...res.map((x) => ({
                        [`(${x.unitSuffix})`
                        ]: `1.${x.precision}-${x.precision}`
                    }))));
                }));

                if(didStudyChange) {
                    this.qvsiFilter = null;
                    this.originalQvsiFilter = null;
                    this.qvsiFilterAfterUpdate = null;
                }

                if (this.basinList.length > 0) {
                    const basinName = this.selectedBasin ? this.selectedBasin : this.basinList[0].text;
                    const selectedBasin = caseStudyDetails.basinDefinition.basins.find(v => v.name === basinName);

                    if (selectedBasin.filters) {
                        const { years, seasons, regimes } = selectedBasin.filters;
                        const selectedYears = years && years.length ? years.map(v => Number(v)) : [];

                        this.selectedSeasons = selectedBasin.filters.seasons || [];
                        this.selectedYears = selectedYears;
                        this.selectedRegime = selectedBasin.filters.regimes || [];

                        this.shouldApplyFilters = false;
                    }

                    this.bindSelectedBasin(basinName, isFirstCall);
                    isFirstCall = false;
                    this.fetchHydrographData();
                }

                this.bindCaseStudyOverrides(caseStudyDetails.overrides);
                // start fetching storm events
                this.loadStormEvents();
                this.loadStormBasinResults();
                if (this.hydroGraphChart) {
                    this.hydroGraphChart.setDryDayPlotBands(this.currentBasinDryDays, this.basinOutlierDays);
                }
            }),
        );

        this.subscriptions.push(
            this.sliicerService.qvsiSelectedConfig.subscribe((config) => this.qvsiFilterChange(config)
        ));

    }

    private checkFiltersEnable() {
        const study = this.sliicerService.caseStudyDetails.getValue();

        if (this.isStudyLocked) {
            this.filtersEnabled = false;
        } else {
            this.filtersEnabled = study.meta.authors.some(v => v.id === this.userService.userID.getValue());
        }
    }

    public ngOnDestroy() {
        this.sliicerService.bustStormCache();
        this.sliicerService.basinHgFlowData.next(null);
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /**
     * Dry day removed event handler.
     *
     * Adds the removed dry day to the list of outliers.
     * Fires dry day removed change that should update the flow monitor hdrograph and removed plot bands.
     */
    public onDryDayRemoved(date: string) {
        const dateFormat = `${String(this.dateutilService.getFormat()).toUpperCase()}`;
        const parsedDate = moment(date, dateFormat).valueOf();
        // This will call blotBandRemoved (emit signal listener)
        this.hydroGraphChart.removePlotBand(parsedDate);
    }

    private applyFiltersForBasin() {
        const study = this.sliicerService.caseStudyDetails.getValue();
        const basinDefinition = study.basinDefinition.basins.find(v => v.name === this.selectedBasin);

        if (!basinDefinition.filters) {
            this.sliicerService.filterInfo$.next({
                seasons: [],
                years: [],
                regime: [],
            });

            this.selectedSeasons = [];
            this.selectedYears = [];
            this.selectedRegime = [];

            this.shouldApplyFilters = false;
            return;
        }

        const { years, seasons, regimes } = basinDefinition.filters;
        const selectedYears = years && years.length ? years.map(v => Number(v)) : [];

        this.selectedSeasons = basinDefinition.filters.seasons || [];
        this.selectedYears = selectedYears;
        this.selectedRegime = basinDefinition.filters.regimes || [];

        this.shouldApplyFilters = false;
    }

    /**
     * Called from the UI when the selected basin has changed
     */
    public onBasinChanged(newBasin: string): void {

        // see if we have updates that need to be handled prior to
        // allowing the user to change the basin
        if (this.updatesWidgetService.updates) {
            // Keep track of the basin that the user wanted to
            // change to
            this.changeToSelectedBasin = newBasin;

            // Put the selected Basin back so that the UI does
            // not update
            this.selectedBasin = this.tempSelectedBasin;

            this.setUpdatesInfo();
        } else {
            this.sliicerService.basinHgFlowData.next(null);

            // we do not have updates...
            // check basin last calculation time, and if it will not match with study calc time, call recalc for this basin
            const study = this.sliicerService.caseStudyDetails.getValue();
            const { calculationRunId, calculationRunTime, basinState } = study.meta;

            if (basinState !== BasinStateEnum.ready) {
                this.checkBasinCalcTime(calculationRunId, calculationRunTime, newBasin);

                return;
            }

            this.selectedBasin = newBasin;
            if (!!this.selectedBasin) {
                this.applyFiltersForBasin();
                // reset hydrographChart plot bands
                this.bindSelectedBasin(this.selectedBasin);
            }
            this.fetchHydrographData();

            this.getBasinStorms();
            this.refreshQviGraph();
        }
    }

    private checkBasinCalcTime(calculationRunId: string, calculationRunTime: string, newBasin: string) {
        this.sliicerService.getBasinCalcTime(this.customerId, this.caseStudyId, newBasin, calculationRunId)
            .subscribe((lastCalcTime: string) => {
                if (lastCalcTime === calculationRunTime) {
                    this.selectedBasin = newBasin;
                    if (!!this.selectedBasin) {
                        this.applyFiltersForBasin();
                        // reset hydrographChart plot bands
                        this.bindSelectedBasin(this.selectedBasin);
                    }
                    this.fetchHydrographData();

                    this.getBasinStorms();
                } else {
                    this.recallBasinOverrides(newBasin);
                }
            });

    }

    private getBasinStorms() {
        this.sliicerService.getAllStormBasinResults(this.customerId, this.caseStudyId, this.selectedBasin)
        .subscribe(
            (results) => {
                if (results && results.length > 0) {
                    const filterInfo = this.sliicerService.filterInfo$.getValue();
                    if (filterInfo.seasons.length) {
                        results = results.filter(
                            (x) =>
                                filterInfo.seasons.indexOf(
                                    this.sliicerService.seasonFromDate(new Date(x.stormStartTime)),
                                ) !== -1,
                        );
                    }
                    if (filterInfo.years.length) {
                        results = results.filter(
                            (x) => filterInfo.years.indexOf(new Date(x.stormStartTime).getFullYear()) !== -1,
                        );
                    }
                    this.stormBasinResults = results;
                } else {
                    this.stormBasinResults = null;
                }

                if(this.stormEvents) {
                    // To trigger changes in child components
                    this.stormEvents = [...this.stormEvents];
                    this.originalStormEvents = JSON.parse(JSON.stringify(this.stormEvents));
                }
            },
        );
    }

    /**
     * Tooltip enabled button event handler.
     */
    public onTooltipEnabledChanged() {
        this.tooltipEnabled = !this.tooltipEnabled;
        this.hydroGraphChart.setTooltip(this.tooltipEnabled);
    }

    private applyFiltersToDryDays() {
        const tempBasinDryDays = Object.assign({}, JSON.parse(JSON.stringify(this.originalCurrentBasinDryDays)));

        if (this.selectedSeasons.length && this.hasSeasons) {
            tempBasinDryDays.dayOfWeekGroupDays = tempBasinDryDays.dayOfWeekGroupDays.filter(
                (x) => x.season === 'Default' || this.selectedSeasons.indexOf(x.season) !== -1,
            );
        }

        if (this.selectedYears.length && this.hasYears) {
            tempBasinDryDays.dayOfWeekGroupDays = tempBasinDryDays.dayOfWeekGroupDays.filter(
                (x) => x.year === 'All' || this.selectedYears.indexOf(Number(x.year)) !== -1,
            );
        }

        if (this.selectedRegime.length && this.hasRegimes) {
            tempBasinDryDays.dayOfWeekGroupDays = tempBasinDryDays.dayOfWeekGroupDays.filter(
                (x) => x.regime === 'Default' || this.selectedRegime.indexOf(x.regime) !== -1,
            );
        }

        this.currentBasinDryDays = tempBasinDryDays;
    }

    public refreshStormBasinResults(storms: BasinStormResult[]) {
        this.stormBasinResults = storms;
    }

    public filterChange() {
        const preValue = this.sliicerService.filterInfo$.getValue();

        if (this.selectedSeasons.length || this.selectedYears.length || this.selectedRegime.length) {
            this.sliicerService.filterInfo$.next({
                seasons: this.selectedSeasons,
                years: this.selectedYears,
                regime: this.selectedRegime,
            });
            this.applyFiltersToDryDays();
        } else {
            this.currentBasinDryDays = this.originalCurrentBasinDryDays;
            this.stormEvents = JSON.parse(JSON.stringify(this.originalStormEvents));
            this.sliicerService.filterInfo$.next({
                seasons: [],
                years: [],
                regime: [],
            });
        }

        this.shouldApplyFilters = false;
        this.filterSave();

    }

    public qvsiFilterChange(filter: {conf: QvsIConfigurations, afterupdate: boolean}) {
        if(!filter) filter = {conf: null, afterupdate: false};
        if(filter.conf == null) filter.conf = QVI_GROUP_ALL_STORMS_CONFIG;

        if(filter.afterupdate) {
            this.qvsiFilterAfterUpdate = filter.conf.name;
        } else {
            this.qvsiFilter = filter.conf.name;
            this.qvsiFilterAfterUpdate = null;

            this.setUpdatesInfo();
        }
    }

    private filterSave(qvsiUpdate = false) {
        const study = this.sliicerService.caseStudyDetails.getValue();
        const basins = study.basinDefinition.basins;
        const filterInfo = this.sliicerService.filterInfo$.getValue();

        const filters: BasinFilter = {
            regimes: filterInfo.regime,
            seasons: filterInfo.seasons,
            years: filterInfo.years,
            qvsiGroup: basins ? basins.find(v => v.name === this.selectedBasin)?.filters?.qvsiGroup : null
        };

        const basinFilterRequest : BasinFiltersRequest = {
            basinName: this.selectedBasin,
            filters: filters
        };

        basins.find(v => v.name === this.selectedBasin).filters = filters;

        this.sliicerService.putBasinFilterDefinition(this.customerId, this.caseStudyId, basinFilterRequest).subscribe(response => {
            if (qvsiUpdate) {
                this.afterQvsIfilterGroupChange();
            }

            this.updatesWidgetService.updatesLoader = false;
        }, (error) => {
              this.updatesWidgetService.updatesLoader = false;
              if(error.status == 304)
              {
                basins.forEach((basin) => {
                    if(basin && basin.filters)
                    {
                        basin.filters.regimes = filters.regimes,
                        basin.filters.seasons = filters.seasons,
                        basin.filters.years = filters.years
                    }
                } );

                if (qvsiUpdate) {
                    this.afterQvsIfilterGroupChange();
                }
              }
        });

        this.utilService.safeChangeDetection(this.cdr);
    }

    private afterQvsIfilterGroupChange() {
        this.originalQvsiFilter = this.qvsiFilterAfterUpdate ? this.qvsiFilterAfterUpdate : this.qvsiFilter;
        const conf = this.originalQvsIConfigurations.find((conf) => conf.name === this.originalQvsiFilter);
        if(!conf) {
            this.originalQvsiFilter = null;
        }
        this.qvsiFilter = this.originalQvsiFilter;
        this.qvsiFilterAfterUpdate = null;

        if(conf) {
            this.sliicerService.qvsiSelectedConfig.next({conf: conf, afterupdate: false});
        } else {
            this.sliicerService.qvsiSelectedConfig.next({conf: QVI_GROUP_ALL_STORMS_CONFIG, afterupdate: false});
        }

        this.setUpdatesInfo();
    }

    public exportBasinQvsIStats() {
        this.isLoading = true;
        this.sliicerService
            .vaultExportBasicQvsiStatistics(this.customerId, this.caseStudyId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                },
                (err) => {
                    this.snackBarNotificationService.raiseNotification(this.exportErrMsg, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    this.isLoading = false;
                },
            );
    }

    /**
     * This method will call from dry day component to select dry day on hydropgraph chart
     * @param {string} selectedDay: received date as string to select
     * @param {boolean} remove: Should the highlight be removed
     */
    public getDryDayDate({ selectedDay, remove = false }: { selectedDay: string; remove?: boolean }): void {
        this.hydroGraphChart.highlightPlotLineDay({ day: selectedDay, remove });
    }

    /**
     * This method is called when user clicks Discard button on the apply/discard/cancel
     * changes dialog. The user has indicated that the changes made should be discarded
     * and the expectation is that the action that triggered the dialog (e.g., changing
     * the basin) should happen.
     */
    public discardChanges(): void {
        // set the storm events
        this.basinOutliers = Object.values(Object.assign({}, JSON.parse(JSON.stringify(this.originalBasinOutliers))));
        this.basinStormSettings = Object.values(
            Object.assign({}, JSON.parse(JSON.stringify(this.originalBasinStormSettings))),
        );
        this.stormEvents = JSON.parse(JSON.stringify(this.originalStormEvents));
        this.stormSettings = Object.values(Object.assign({}, JSON.parse(JSON.stringify(this.originalStormSettings))));
        this.setEditedStormEvents({
            addedStormEvents: this.originalAddedStormEvents,
            removedStormEvents: this.originalRemovedStormEvents,
        });
        if (this.changeToSelectedBasin) {
            // reset hydrographChart plot bands
            this.bindSelectedBasin(this.changeToSelectedBasin);
        } else {
            this.basinOutlierDays = FlowMonitorComponent.GetBasinOutlierDays(this.selectedBasin, this.basinOutliers);
            this.hydroGraphChart.setDryDayPlotBands(this.currentBasinDryDays, this.basinOutlierDays);
        }

        this.qvsIConfigurations = Object.values(Object.assign({}, JSON.parse(JSON.stringify(this.originalQvsIConfigurations))));
        this.basinQvsISettings = JSON.parse(JSON.stringify(this.originalBasinQvsISettings));

        this.activeDesignStorms = [...this.originalActiveDesignStorms];
        this.designStorms = JSON.parse(JSON.stringify(this.originalDesignStorms));
        this.qvsiFilter = this.originalQvsiFilter;
        this.qvsiFilterAfterUpdate = null;

        let conf = this.qvsIConfigurations ? this.qvsIConfigurations.find((conf) => conf.name === this.qvsiFilter) : null;
        if(!conf) conf = QVI_GROUP_ALL_STORMS_CONFIG;
        this.sliicerService.qvsiSelectedConfig.next({conf: conf, afterupdate: false});

        if (this.hydroGraphChart) {
            this.hydroGraphChart.stormEvents = this.stormEvents;

            this.hydroGraphChart.hideStorms();
            this.hydroGraphChart.addStorms();
        }

        this.setUpdatesInfo();
    }

    private invertDeattachPrecompValue(stormSettings: BasinStormSettings[]) {
        return stormSettings.map(v => ({ ...v, deattachPrecomp: v.deattachPrecomp !== undefined ? !v.deattachPrecomp : undefined }));
    }

    public updateActiveDesignStorms(activeStorms: DesignStormItem[]) {
        this.designStorms = activeStorms;
        this.activeDesignStorms = activeStorms.filter(v => v.isActive).map(v => v.name);

        this.setUpdatesInfo();
    }

    ///
    /// EVENTS EMITTED FROM COMPONENTS
    ///

    /**
     * This method is called when user clicks Save Changes button on the apply/discard/cancel
     * changes dialog. The user has indicated that the changes made should be applied
     * and the expectation is that the action that triggered the dialog (e.g., changing
     * the basin) should happen once the changes are applied.
     */

    public applyChanges(isFromDelete = false): void {
        const updateQvsIConfigurations = !_.isEqual(this.qvsIConfigurations, this.originalQvsIConfigurations);
        const qvsiFilterChange =
            (this.originalQvsiFilter !== this.qvsiFilter
                && !(this.qvsiFilter === QVI_GROUP_ALL_STORMS && !this.originalQvsiFilter)
                && !(!this.qvsiFilter && this.originalQvsiFilter === QVI_GROUP_ALL_STORMS)
            )
            || (this.qvsiFilterAfterUpdate && this.qvsiFilterAfterUpdate !== this.originalQvsiFilter);



        let adjustedBasinOutlier: BasinOutlier = null;
        if (this.basinOutlierDays && this.basinOutlierDays.length > 0) {
            adjustedBasinOutlier = {
                basinName: this.selectedBasin,
                days: this.basinOutlierDays,
            };
        }
        const outlierBasinIndex = this.basinOutliers.findIndex((bo) => bo.basinName === this.selectedBasin);
        if (outlierBasinIndex >= 0) {
            if (adjustedBasinOutlier) {
                this.basinOutliers[outlierBasinIndex].days = adjustedBasinOutlier.days;
            } else {
                this.basinOutliers.splice(outlierBasinIndex, 1);
            }
        } else if (adjustedBasinOutlier !== null) {
            this.basinOutliers.push(adjustedBasinOutlier);
        }
        const overrides: Overrides = {
            basinOutliers: this.basinOutliers,
            basinStormSettings:
            // #33201 since we have detach value on the API and attach on the UI, need to invert it
                this.basinStormSettings ? this.invertDeattachPrecompValue(this.basinStormSettings) : null,
            stormSettings: this.stormSettings ? this.stormSettings : null,
            addedStormEvents: this.addedStormEvents,
            removedStormEvents: this.removedStormEvents,
            basinQVsISettings: this.basinQvsISettings
        };
        this.updatesWidgetService.updatesLoader = true;

        const settings: Settings = {
            qvsIConfigurations: this.qvsIConfigurations
        }
        const serviceCallQvsiConfiguration = updateQvsIConfigurations && settings.qvsIConfigurations;

        const study = this.sliicerService.caseStudyDetails.getValue();
        const basinDef = study.basinDefinition;

        if(qvsiFilterChange) {
            const basins = basinDef.basins;
            let qviGroup = this.qvsiFilterAfterUpdate ? this.qvsiFilterAfterUpdate : this.qvsiFilter;
            if(qviGroup === QVI_GROUP_ALL_STORMS) qviGroup = null;

            const filters: BasinFilter = {
                seasons: this.selectedSeasons,
                years: this.selectedYears,
                regimes: this.selectedRegime,
                // #32540 We need quotation marks here, so we'll be able to pass NULL, otherwise Angular will cut off this field
                "qvsiGroup": qviGroup
            };
            basins.find(v => v.name === this.selectedBasin).filters = filters;
        }

        const activeDesignStormsChange = !_.isEqual(this.activeDesignStorms, this.originalActiveDesignStorms);
        const designStormsChange = FlowMonitorComponent.GetDesignStormsChanged(this.designStorms, this.originalDesignStorms);

        const designStormCalls: Observable<SliicerCaseStudy>[] = [];
        
        let shouldRecalcDesignStorms = false;
        if (designStormsChange) {
            shouldRecalcDesignStorms = true;
            designStormCalls.push(this.sliicerService.updateCaseStudyDesignStorms(this.customerId, this.caseStudyId, this.designStorms));
        }

        if (activeDesignStormsChange) {
            const study = this.sliicerService.caseStudyDetails.getValue();
            const preferences = study?.preferences || {};
        
            preferences.activeDesignStorms = this.activeDesignStorms;
            study.preferences = preferences;
        
            designStormCalls.push(this.sliicerService.updateStudyPreferences(this.customerId, this.caseStudyId, preferences));
        }

        if (designStormCalls.length > 0) {
            from(designStormCalls)
                .pipe(
                    concatMap((observable) => observable),
                    toArray(),
                    finalize(() => {
                        this.originalActiveDesignStorms = [...this.activeDesignStorms];
                        this.originalDesignStorms = JSON.parse(JSON.stringify(this.designStorms));
        
                        this.sliicerService.bustQviCache();
                        if (shouldRecalcDesignStorms) {
                            this.recallBasinOverrides(null, true);
                        } else {
                            this.getBasinStorms();
                            this.refreshQviGraph();
                        }
        
                        this.setUpdatesInfo();
        
                        this.sliicerService.showToast(this.applyUpdatesMessage);
                        this.updatesWidgetService.updatesLoader = false;
                        this.isLoading = false;
                    })
                )
                .subscribe({
                    error: () => {
                        this.updatesWidgetService.updatesLoader = false;
                        this.isLoading = false;
                        this.sliicerService.showToast(this.dryDaySaveErrorMessage, true);
                    }
                });
        }
        const updates = this.updatesWidgetService.updatesInfo;
        const callFiltersUpdate = updates.length === 1 && updates[0].title === QVSI_FILTER_GROUP_CHANGE;

        if (callFiltersUpdate) {
            this.sliicerService.filterInfo$.next({
                seasons: this.selectedSeasons,
                years: this.selectedYears,
                regime: this.selectedRegime,
            });
            this.filterSave(true);

            return;
        }

        const updatesCallType = this.getOverridesCallType();

        let updateCall$: Observable<SliicerCaseStudy | OverridesPartialResponse>;

        if (updatesCallType === 'basin') {
            updateCall$ = this.sliicerService.putBasinChanges(this.customerId, this.caseStudyId, this.formatBasinOverrides(overrides, isFromDelete), this.selectedBasin);
        } else if (updatesCallType === 'full') {
            updateCall$ = this.sliicerService.putStudy(this.customerId, this.caseStudyId, overrides, settings, basinDef)
        }

        if (!updateCall$) {
            return;
        }

        updateCall$.subscribe({
            next: (res: OverridesPartialResponse) => {
                this.updateStudyCalcState();

                this.updatesWidgetService.updatesLoader = false;
                this.isLoading = false;

                if (this.updatesWidgetService.updatesInfo && this.updatesWidgetService.updatesInfo.length && this.updatesWidgetService.updatesInfo.find(v => v.title === EXCLUDED_STORMS_REMOVED)) {
                    this.originalBasinStormSettings = JSON.parse(JSON.stringify(this.basinStormSettings));
                }

                if (updatesCallType === 'basin') {
                    this.applyOverridesChanges(res)
                } else if (updatesCallType === 'full') {
                    this.handleOverridesResponse(res as any, serviceCallQvsiConfiguration, true);
                }

                this.sliicerService.showToast(this.applyUpdatesMessage);
            },
             error: () => {
                    this.updatesWidgetService.updatesLoader = false;
                    this.isLoading = false;

                    // Since there was an error, discard the request to change basins
                    this.changeToSelectedBasin = null;

                    this.sliicerService.showToast(this.dryDaySaveErrorMessage, true);
                },
            });
    }

    private updateStudyCalcState() {
        this.sliicerService.getStudyCalcState(this.customerId, this.caseStudyId).subscribe((data: StudyCalcState) => {
            const study = this.sliicerService.caseStudyDetails.getValue();

            study.meta.basinState = data.basinState;
            study.meta.calculationRunId = data.calculationRunId;
            study.meta.calculationRunTime = data.calculationRunTime;
        });
    }

    private applyOverridesChanges(response: OverridesPartialResponse) {
        let shouldUpdateQvi = false;
        let shouldUpdateDryDays = false;

        if (response.addedStormsAdd.length) {
            this.onNewStormAdded(response.addedStormsAdd[0]);

            shouldUpdateQvi = true;
        }

        if (response.stormSettingsAdd.length) {
            this.onStormChange(response.stormSettingsAdd);

            shouldUpdateQvi = true;
        }

        if (response.removedStormsAdd.length) {
            this.onRemoveStorm(response.removedStormsAdd[0]);

            shouldUpdateQvi = true;
        }

        if (response.addedStormsRemove.length) {
            this.onRemoveStorm(response.addedStormsRemove[0]);

            shouldUpdateQvi = true;
        }

        if (response.basinOutliersAdd.length) {
            this.onDryDaysAdded(response.basinOutliersAdd);

            shouldUpdateDryDays = true;
        }

        if (response.basinOutliersRemove.length) {
            // this is the same action as basinOutliersAdd, but the type is flipped
            const flipped = response.basinOutliersRemove.map(v => ({ ...v, type: v.type === OutlierType.DRY ? OutlierType.NONE : OutlierType.DRY }));

            this.onDryDaysAdded(flipped);

            shouldUpdateDryDays = true;
        }

        if (response.basinStormSettingsAdd.length) {
            this.onStormExclude(response.basinStormSettingsAdd);

            shouldUpdateQvi = true;
        }

        if (response.basinQvsISettingsAdd.length) {
            this.refreshBasinQvsISetting(response.basinQvsISettingsAdd);
        }

        this.setUpdatesInfo();

        if (shouldUpdateQvi) {
            this.sliicerService.bustQviCache();

            this.getBasinStorms();
            this.refreshQviGraph();
        }

        if (shouldUpdateDryDays) {
            this.loadBasinDryDays(false);
        }
    }

    private onNewStormAdded(addedStorm: OverridesStormEntry) {
        this.sliicerService.bustStormCache();
        this.originalAddedStormEvents = JSON.parse(JSON.stringify(this.addedStormEvents));
        // newly created storm was already added to storm events but with no id
        const stormDetails = this.stormEvents.find(v => v.stormId === undefined);

        // we predict new storm id to be + 1 to existing ids
        let newStormId = (Math.max(...this.stormEvents.filter(v => !!v.stormId).map(v => v.stormId)) + 1);

        newStormId = isFinite(newStormId) ? newStormId : 1;

        if (stormDetails) {
            stormDetails.stormId = newStormId;
            stormDetails.manuallyAdded = true;
        }

        // to trigger changes in children components
        this.stormEvents = [...this.stormEvents];
        this.loadStormEvents();
    }

    private onRemoveStorm(removedStorm: OverridesStormEntry) {
        this.sliicerService.bustStormCache();

        this.originalRemovedStormEvents = JSON.parse(JSON.stringify(this.removedStormEvents));
        this.originalAddedStormEvents = JSON.parse(JSON.stringify(this.addedStormEvents));
        this.originalBasinStormSettings = JSON.parse(JSON.stringify(this.basinStormSettings));

        const study = this.sliicerService.caseStudyDetails.getValue();
        study.overrides.removedStormEvents = this.removedStormEvents;

        // remove storm plot band from main hydrograph
        this.hydroGraphChart.onRemoveStorm(removedStorm.stormStartTime);

        this.stormEvents = this.stormEvents.filter(v => new Date(v.stormStartTime).getTime() !== new Date(removedStorm.stormStartTime).getTime());
        this.stormBasinResults = this.stormBasinResults ? this.stormBasinResults.filter(v => new Date(v.stormStartTime).getTime() !== new Date(removedStorm.stormStartTime).getTime()) : null;
        this.loadStormEvents();
    }

    private onDryDaysAdded(dryDays: OutlierDay[]) {
        dryDays.forEach(item => {
            const dayIndex = new Date(item.date).getDay();

            const weekGroup = this.dayOfWeekGroups.find(v => v.days.includes(dayIndex));

            this.addDryDayToBasin(item.date, weekGroup.name, item.type === OutlierType.DRY);
        });

        this.currentBasinDryDays = JSON.parse(JSON.stringify(this.currentBasinDryDays));
        this.originalBasinOutliers = JSON.parse(JSON.stringify(this.basinOutliers));
    }

    private addDryDayToBasin(date: string, groupName: string, isAdd: boolean) {
        const ts = new Date(date).getTime();
        const study = this.sliicerService.caseStudyDetails.getValue();

        const seasons = getSeasons(study, false);

        const regimes = study.settings.regimes || [];
        const availableYears = getYears(study);

        let currentSeason = ALL;
        let currentRegime = ALL;
        let currentYear = ALL;

        if (seasons && seasons.length) {
            currentSeason = seasons.find(v => new Date(v.periodStart).getTime() <= ts && new Date(v.periodEnd).getTime() >= ts).name;
        }

        if (regimes && regimes.length) {
            currentRegime = regimes.find(v => new Date(v.periodStart).getTime() <= ts && new Date(v.periodEnd).getTime() >= ts).name;
        }

        if (availableYears && availableYears.length > 1) {
            currentYear = String(availableYears.find(v => new Date(date).getFullYear() === v));
        }


        let currentGroup: DayOfWeekGroupDays;

        const defaultGroup = this.currentBasinDryDays.dayOfWeekGroupDays
            .find(v => v.dayOfWeekGroupName === groupName && v.regime === ALL && v.season === ALL && v.year === ALL);

        const groupsForRegimeAndSeason = this.currentBasinDryDays.dayOfWeekGroupDays
            .filter(group => group.dayOfWeekGroupName === groupName && group.regime === currentRegime && group.season === currentSeason);


        if (defaultGroup) {
            currentGroup = defaultGroup;
        } else if (groupsForRegimeAndSeason.length === 1) {
            currentGroup = groupsForRegimeAndSeason[0];
        } else {
            currentGroup = groupsForRegimeAndSeason.find(group => group.year === currentYear);
        }

        if (!currentGroup) return;

        if (isAdd) {
            currentGroup.days.push(date);
        } else {
            currentGroup.days = currentGroup.days.filter(v => v !== date);
        }
    }

    private onStormExclude(storms: BasinStormSettings[]) {
        this.originalBasinStormSettings = JSON.parse(JSON.stringify(this.basinStormSettings));

        storms.forEach(s => {
            const fromResults = this.stormBasinResults.find(v => v.stormId === s.stormId);

            if (fromResults) {
                fromResults.exclude = s.exclude;
                fromResults.precompDuration = s.precompLength;
                fromResults.preCompType = s.precompType;

                this.sliicerService.bustStormCache();
                this.refreshStorm$.next();

            }
        });

        this.stormBasinResults = [...this.stormBasinResults];
    }

    private onStormChange(storms: StormPeriodAdjustment[]) {
        this.originalStormSettings = JSON.parse(JSON.stringify(this.stormSettings));
        this.originalBasinStormSettings = JSON.parse(JSON.stringify(this.basinStormSettings));

        const shouldRefetchStorm = storms.find(v => v.stormId === this.selectedStormId);
        const isStormRemoved = !this.stormEvents.find(v => v.stormId === this.selectedStormId);

        if (!shouldRefetchStorm && isStormRemoved) {
            return;
        }

        this.sliicerService.bustStormCache();
        this.sliicerService.getStormBasinResults(this.customerId, this.caseStudyId, this.selectedStormId, this.selectedBasin).subscribe((res) => {
            if (!res) return;

            const event = this.stormEvents.find(v => v.stormId === res.stormId);
            const storm = storms.find(v => v.stormId === res.stormId);

            if (event && storm) {
                event.recovery1PeriodLength = storm.recovery1PeriodLength;
                event.recovery2PeriodLength = storm.recovery2PeriodLength;

                event.stormPeriodLength = storm.stormPeriodLength;
                event.stormStartTime = storm.stormStartTime;
            }

            this.stormEvents = [...this.stormEvents];

            this.stormBasinResults = this.stormBasinResults.map(v => v.stormId === res.stormId ? res : v);


            // just a null check
            if (this.stormEvensComponent && this.stormEvensComponent.stormStatsComponent
                && this.stormEvensComponent.stormStatsComponent.stormStatsDataSource && this.stormEvensComponent.stormStatsComponent.stormStatsDataSource.data
                && this.stormEvensComponent.stormStatsComponent.stormStatsDataSource.data.length
                && this.stormEvensComponent.stormStatsComponent.stormStatsDataSource.data[0].stormId === res.stormId) {


                    this.stormEvensComponent.stormStatsComponent.stormStatsDataSource.data = [res];


            }

            if (this.hydroGraphChart) {
                this.hydroGraphChart.stormEvents = this.stormEvents;

                this.hydroGraphChart.hideStorms();
                this.hydroGraphChart.addStorms();
            }

            setTimeout(() => {
                if (this.stormEvensComponent && this.stormEvensComponent.expandedStormChooserComponent) {
                    this.stormEvensComponent.expandedStormChooserComponent.updateStormEntry(res);
                }
            }, 0);
        });
    }

    private refreshQviGraph() {
        if (this.stormEvensComponent && this.stormEvensComponent.qviComponent) {
            const qviComp = this.stormEvensComponent.qviComponent;

            qviComp.basinStats = qviComp.groupBasinStats(this.stormBasinResults);

            qviComp.fetchData();
        }
    }

    private refreshBasinQvsISetting(updates: BasinQvsISettingsOverrides[]) {
        this.originalBasinQvsISettings = JSON.parse(JSON.stringify(this.basinQvsISettings));

        this.qviStatsComponent.applyOverridesResult(updates);

        const study = this.sliicerService.caseStudyDetails.getValue();

        if (study && study.overrides && study.overrides.basinQVsISettings && study.overrides.basinQVsISettings.length) {
            const existingSettings = study.overrides.basinQVsISettings;

            existingSettings.forEach(v => {
                const updated = updates.find(x => v.basinName === x.basinName && v.mode === x.mode && v.type === x.type && v.configuration === v.configuration);

                if (updated) {
                    v.useAlt = updated.useAlt;
                }
            });
        }
    }

    private handleOverridesResponse(response: SliicerCaseStudy, serviceCallQvsiConfiguration: QvsIConfigurations[], qvsiFilterChange: boolean) {
        this.sliicerService.bustCache();
        this.sliicerService.bustQviCache();
        this.sliicerService.bustStormCache();
        if (response) {
            this.sliicerService.caseStudyDetails.next(response);
            this.bindCaseStudyOverrides(response.overrides);
            this.setUpdatesInfo();
            this.updatesWidgetService.updatesLoader = false;
        }

        if(serviceCallQvsiConfiguration) {
            this.originalQvsIConfigurations = Object.values(Object.assign({}, JSON.parse(JSON.stringify(this.qvsIConfigurations))));
        }

        this.loadStormEvents();
        // #33966 Storms are considered removed based on StormBasinResults, we have to load those as well
        this.loadStormBasinResults();

        if (qvsiFilterChange) {
            this.originalQvsiFilter = this.qvsiFilterAfterUpdate ? this.qvsiFilterAfterUpdate : this.qvsiFilter;
            const conf = this.originalQvsIConfigurations.find((conf) => conf.name === this.originalQvsiFilter);
            if(!conf) {
                this.originalQvsiFilter = null;
            }
            this.qvsiFilter = this.originalQvsiFilter;
            this.qvsiFilterAfterUpdate = null;

            if(conf) {
                this.sliicerService.qvsiSelectedConfig.next({conf: conf, afterupdate: false});
            } else {
                this.sliicerService.qvsiSelectedConfig.next({conf: QVI_GROUP_ALL_STORMS_CONFIG, afterupdate: false});
            }
        }

        this.refreshQviGraph();
    }

    public recallBasinOverrides(newBasin: string = null, refreshQvi = false) {
        const overrides: Overrides = {
            basinOutliers: null,
            basinStormSettings: null,
            stormSettings: null,
            addedStormEvents: null,
            basinOverrideArea: BasinOverrideArea.storms
        };

        if (this.qviStatsComponent) {
            this.qviStatsComponent.selectedBasin = newBasin ? newBasin : this.selectedBasin;
        }

        if (this.stormEvensComponent && this.stormEvensComponent.stormStatsComponent) {
            this.stormEvensComponent.stormStatsComponent.selectedBasin = newBasin ? newBasin : this.selectedBasin;
        }

        this.sliicerService.putBasinChanges(this.customerId, this.caseStudyId, overrides, newBasin ? newBasin : this.selectedBasin)
            .subscribe((res: OverridesPartialResponse) => {
                if (newBasin) {
                    this.selectedBasin = newBasin;
                }

                if (refreshQvi && this.stormEvensComponent && this.stormEvensComponent.qviComponent) {
                    const qviComp = this.stormEvensComponent.qviComponent;
                    qviComp.basinStats = qviComp.groupBasinStats(this.stormBasinResults);        
                    qviComp.fetchBasinQvIDataAndPlot();
                }
            },
            () => {
                if (newBasin) {
                    this.selectedBasin = newBasin;
                }

                this.updatesWidgetService.updatesLoader = false;
                this.isLoading = false;

                // Since there was an error, discard the request to change basins
                this.changeToSelectedBasin = null;

                this.sliicerService.showToast(this.dryDaySaveErrorMessage, true);
            }
        );
    }

    private getOverridesCallType(): UPDATE_CALL_TYPE {
        const baseBasinOutlierDays = FlowMonitorComponent.GetBasinOutlierDays(
            this.selectedBasin,
            this.originalBasinOutliers,
        );
        const basinOutlierChange = !_.isEqual(this.basinOutlierDays, baseBasinOutlierDays);
        const basinStormsChange = !_.isEqual(this.basinStormSettings, this.originalBasinStormSettings);
        const stormsChange = !_.isEqual(this.stormSettings, this.originalStormSettings);
        const addedStormEventsChange = !_.isEqual(this.addedStormEvents, this.originalAddedStormEvents);
        const removedStormEventsChange = !_.isEqual(this.removedStormEvents, this.originalRemovedStormEvents);

        const updateQvsIConfigurations = !_.isEqual(this.qvsIConfigurations, this.originalQvsIConfigurations);
        const basinQvsISettingsChange = !_.isEqual(this.basinQvsISettings, this.originalBasinQvsISettings);

        if (updateQvsIConfigurations) {
            return 'full';
        }

        if (addedStormEventsChange || basinOutlierChange || basinStormsChange || stormsChange || basinQvsISettingsChange || removedStormEventsChange) {
            return 'basin';
        }

        return null;
    }

    private formatBasinOverrides(overrides: Overrides, isFromDelete = false): Overrides {
        const basinOutliers = (overrides.basinOutliers && overrides.basinOutliers.length) ? overrides.basinOutliers : null;
        let basinStormSettings = (overrides.basinStormSettings && overrides.basinStormSettings.length) ? overrides.basinStormSettings : null;
        let stormSettings = (overrides.stormSettings && overrides.stormSettings.length) ? overrides.stormSettings : null;
        const basinQVsISettings = (overrides.basinQVsISettings && overrides.basinQVsISettings.length) ? overrides.basinQVsISettings : null;
        const removedStormEvents = (overrides.removedStormEvents && overrides.removedStormEvents.length) ? overrides.removedStormEvents : null;


        let addedStormEvents;

        if (overrides.addedStormEvents && overrides.addedStormEvents.length) {
            addedStormEvents = overrides.addedStormEvents;
        } else {
            addedStormEvents = isFromDelete ? [] : null;
        }

        if (stormSettings === null && isFromDelete) {
            stormSettings = [];
        }

        if (basinStormSettings === null && isFromDelete) {
            basinStormSettings = [];
        }

        return { basinOutliers, basinStormSettings, stormSettings, addedStormEvents, basinQVsISettings, removedStormEvents, delayNotification: true };
    }

    checkUpdate(update: Overrides) {
        if (!update) return false;

        if (update.addedStormEvents && update.addedStormEvents.length) {
            return true;
        } else if (update.basinOutliers && update.basinOutliers.length) {
            return true;
        } else if (update.removedStormEvents && update.removedStormEvents.length) {
            return true;
        } else if (update.basinStormSettings && update.basinStormSettings.length) {
            return true;
        } else if (update.stormSettings && update.stormSettings.length) {
            return true;
        } else if (update.basinQVsISettings && update.basinQVsISettings.length) {
            return true;
        }

        return false;
    }

    /**
     * Event triggered when user enables or disables Edit Dry Days on the hydrograph
     * @param event
     */
    public onEditDryDayToggle(event: MatSlideToggle): void {
        this.isDryDaysEditActive = event['checked'];
    }

        /**
     * Event triggered when user enables or disables Edit Dry Days on the hydrograph
     * @param event
     */
      public onAddStormsToggle(event: MatSlideToggle): void {
        this.isStormAddActive = event['checked'];
      }

    /**
     * This event is called when the user changes the precompensation method for a basin-storm
     * @param change The PrecompensationChanged object from the storm-events component
     */
    public precompensationMethodChanged(change: PrecompensationChanged) {
        const remove = change.precompType === this.defaultPrecompType;

        let index = -1;
        if (this.basinStormSettings !== null) {
            index = this.basinStormSettings.findIndex(
                (bs) => bs.basinName === this.selectedBasin && bs.stormId === change.stormId,
            );
        }
        if (index >= 0) {
            if (remove) {
                this.basinStormSettings[index].precompType = null;
                if (
                    this.basinStormSettings[index].exclude === null &&
                    this.basinStormSettings[index].precompLength === null
                ) {
                    this.basinStormSettings.slice(index, 1);
                }
            } else {
                this.basinStormSettings[index].precompType = change.precompType;
            }
        } else {
            if (!remove) {
                this.basinStormSettings.push({
                    basinName: this.selectedBasin,
                    stormId: change.stormId,
                    precompType: change.precompType,
                });
            }
        }

        this.setUpdatesInfo();
    }

    public checkEditedBasinQvsI(change: BasinQvsISettingsOverrides) {
        const qviFound = this.basinQvsISettings.find((qvi) => qvi.mode === change.mode &&
            qvi.type === change.type && qvi.basinName === change.basinName);

        if (qviFound) {
            qviFound.useAlt = change.useAlt;
            qviFound.basinName = change.basinName;
            qviFound.configuration = change.configuration;
            qviFound.configurationGroup = change.configurationGroup;
        } else {
            this.basinQvsISettings.push({
                mode: change.mode,
                type: change.type,
                useAlt: change.useAlt,
                basinName: change.basinName,
                configurationGroup: change.configurationGroup,
                configuration: change.configuration
            })
        }

        this.setUpdatesInfo();
    }

    /**
     * This event is called when the user toggles a storm to be excluded from basin calculations
     * @param change The ExcludedStormChanged object from the storm-events component
     */
    public excludedStormToggled(change: ExcludeStormChanged): void {
        let basinStormSetting: BasinStormSettings = null;
        if (this.basinStormSettings !== null) {
            basinStormSetting = this.basinStormSettings.find(
                (bs) => bs.basinName === this.selectedBasin && bs.stormId === change.stormId,
            );
        }

        if (change.excluded) {
            if (basinStormSetting) {
                if (basinStormSetting.exclude) {
                    // I am being asked to exclude a storm and I believe the storm to already be excluded
                } else {
                    basinStormSetting.exclude = true;
                }
            } else {
                this.basinStormSettings.push({
                    basinName: this.selectedBasin,
                    stormId: change.stormId,
                    exclude: true,
                });
            }
        } else {
            if (basinStormSetting) {
                if (!basinStormSetting.exclude) {
                    // I am being asked to no longer exclude a storm and I believe the storm to be included
                } else {
                    basinStormSetting.exclude = false;
                }
                if (
                    basinStormSetting.exclude === false &&
                    FlowMonitorComponent.CheckIfExcludedOnlyAdjustment(basinStormSetting)
                ) {
                    // the change is empty. Ignore it
                    const index = this.basinStormSettings.findIndex(
                        (bs) => bs.basinName === this.selectedBasin && bs.stormId === change.stormId,
                    );
                    this.basinStormSettings.splice(index, 1);
                }
            } else {
                // default behavior is to not exclude a storm
            }
        }

        this.setUpdatesInfo();
    }

    /**
     * This event is called when the user adjusts the storm event periods.
     * @param adjustment The StormPeriodAdjustment object from the storm-events component
     */
    public updatedStormEventPeriods(adjustment: StormPeriodAdjustment): void {
        if (adjustment.previousStartTime) {
            const manualIndex = this.addedStormEvents.findIndex(v => new Date(v.stormStartTime).getTime() === new Date(adjustment.previousStartTime).getTime());

            if (manualIndex !== -1) {
                this.addedStormEvents[manualIndex].stormStartTime = adjustment.stormStartTime;
            }

            if (this.stormEvents && this.stormEvents.length) {
                this.stormEvents.forEach(s => {
                    if (new Date(adjustment.previousStartTime).getTime() === new Date(s.stormStartTime).getTime()) {
                        s.stormStartTime = adjustment.stormStartTime;
                    }
                });
            }

            if (this.stormBasinResults && this.stormBasinResults.length) {
                this.stormBasinResults.forEach(s => {
                    if (new Date(adjustment.previousStartTime).getTime() === new Date(s.stormStartTime).getTime()) {
                        s.stormStartTime = adjustment.stormStartTime;
                    }
                });
            }
        }

        // precomp period length is applied on the basin-storm level
        if (adjustment.precompPeriodLength !== null) {
            let basinStormSetting: BasinStormSettings = null;
            if (this.basinStormSettings !== null) {
                basinStormSetting = this.basinStormSettings.find(
                    (bs) => bs.basinName === this.selectedBasin && bs.stormId === adjustment.stormId,
                );
            }
            if (!basinStormSetting) {
                basinStormSetting = {
                    basinName: this.selectedBasin,
                    stormId: adjustment.stormId,
                };
                this.basinStormSettings.push(basinStormSetting);
            }

            if (basinStormSetting) {
                basinStormSetting.precompLength = adjustment.precompPeriodLength;
                basinStormSetting.deattachPrecomp = adjustment.deattachPrecomp;
                if (!adjustment.deattachPrecomp) {
                basinStormSetting.altPrecompEnd = adjustment.altPrecompEnd;
                basinStormSetting.altPrecompStart = adjustment.altPrecompStart;
                }
            }

            if (this.stormBasinResults && this.stormBasinResults.length) {
                const fromBasinResults = this.stormBasinResults.find(v => v.stormId === adjustment.stormId);

                if (fromBasinResults) {
                    basinStormSetting.exclude = fromBasinResults.exclude;
                }
            }
        }

        // all other storm periods are applied at the storm-level
        let stormSettings: StormSettings = null;
        if (this.stormSettings !== null) {
            stormSettings = this.stormSettings.find((ss) => ss.stormId === adjustment.stormId);
        }
        if (!stormSettings) {
            stormSettings = {
                stormId: adjustment.stormId,
            };
            this.stormSettings.push(stormSettings);
        }
        if (adjustment.stormStartTime !== null) {
            // #38691 Storm is in T format, had to apply it here otherwise will spam user with yellow box because those are different
            stormSettings.stormStartTime = adjustment.stormStartTime.replace(' ', 'T');
        }
        if (adjustment.stormPeriodLength !== null) {
            stormSettings.stormPeriodLength = adjustment.stormPeriodLength.valueOf();
        }
        if (adjustment.recovery1PeriodLength !== null) {
            stormSettings.recovery1PeriodLength = adjustment.recovery1PeriodLength.valueOf();
        }
        if (adjustment.recovery2PeriodLength !== null) {
            stormSettings.recovery2PeriodLength = adjustment.recovery2PeriodLength.valueOf();
        }

        this.setUpdatesInfo();
    }

    public selectDryDays = null;

    public plotBandSelected(day: { day: string, selected: boolean }) {
        this.selectDryDays = day;
        this.utilService.safeChangeDetection(this.cdr);
    }

    /**
     * The event is called when the user adds a plot band (dry day) to the hydrograph
     * @param date
     */
    public plotBandAdded(date: string): void {
        // is this already in my list of dry days?
        const theDate = new Date(date);
        const dateValue = theDate.valueOf();
        const alreadyIncluded = FlowMonitorComponent.DayIsIdentifiedDryDay(this.currentBasinDryDays, dateValue);

        if (alreadyIncluded) {
            if (this.basinOutlierDays) {
                // remove it from basin outliers
                const foundIndex = this.basinOutlierDays.findIndex((bo) => bo.date === date);
                if (foundIndex >= 0) {
                    this.basinOutlierDays.splice(foundIndex, 1);
                }
            }
        } else {
            if (!this.basinOutlierDays) {
                this.basinOutlierDays = [];
            }
            const foundIndex = this.basinOutlierDays.findIndex((bo) => bo.date === date);
            if (foundIndex >= 0) {
                this.basinOutlierDays.splice(foundIndex, 1);
            } else {
                this.basinOutlierDays.push({
                    date: date,
                    type: OutlierType.DRY,
                });
            }
        }

        this.setUpdatesInfo();
    }

    /**
     * The event is called when the user removes a plot band (dry day) from the hydrograph
     * @param date
     */
    public plotBandRemoved(date: string): void {
        const dateValue = moment(date, FlowMonitorComponent.API_SLIICER_DATE_FORMAT).valueOf();
        const alreadyIncluded = FlowMonitorComponent.DayIsIdentifiedDryDay(this.currentBasinDryDays, dateValue);
        if (alreadyIncluded) {
            if (!this.basinOutlierDays) {
                this.basinOutlierDays = [];
            }
            const foundIndex = this.basinOutlierDays.findIndex((bo) => bo.date === date);
            if (foundIndex >= 0) {
                this.basinOutlierDays.splice(foundIndex, 1);
            } else {
                this.basinOutlierDays.push({
                    date: date,
                    type: OutlierType.NONE,
                });
            }
        } else {
            if (this.basinOutlierDays) {
                const foundIndex = this.basinOutlierDays.findIndex((bo) => bo.date === date);
                // remove it from basin outliers
                if (foundIndex >= 0) {
                    this.basinOutlierDays.splice(foundIndex, 1);
                }
            }
        }

        this.setUpdatesInfo();
    }

    public addNewStorm(event: { overrides: Overrides, storm: StormSettingsDialogData}) {
        const { overrides, storm } = event;

        const newStormEvent: StormEvent = {
            stormId: storm.adjustment.stormId,
            stormStartTime: storm.adjustment.stormStartTime,
            recovery1PeriodLength: storm.adjustment.recovery1PeriodLength,
            recovery2PeriodLength: storm.adjustment.recovery2PeriodLength,
            stormPeriodLength: storm.adjustment.stormPeriodLength,
        };

        this.stormEvents.push({...newStormEvent});
        this.sliicerService.newlyCreatedStormStartTime = storm.adjustment.stormStartTime;

        this.checkEditedStormEvents(overrides);
        this.applyChanges();
    }

    public deleteStorm(storm: StormChooserModel) {
        let removedStormEvents = this.removedStormEvents;
        let addedStormEvents = this.addedStormEvents;
        let stormSettings = this.stormSettings;
        let basinStormSettings = this.basinStormSettings;

        const isManuallyCreatedStorm = addedStormEvents.some(v => new Date(v.stormStartTime).getTime() === new Date(storm.stormStartTime).getTime());

        if (isManuallyCreatedStorm) {
            addedStormEvents = addedStormEvents.filter(v => new Date(v.stormStartTime).getTime() !== new Date(storm.stormStartTime).getTime());
        } else {
            removedStormEvents = [...removedStormEvents, { stormId: storm.stormId, stormStartTime: storm.stormStartTime }]
        }

        // check for any storms in the storm settings and basin storm settings that line up with the start time of the storm parameter that was deleted
        // if any storms have a start time greater, decrement their storm ID in the overrides.stormSettings
        for (let i = 0; i < stormSettings.length; i++) {
            if (stormSettings[i].stormStartTime > storm.stormStartTime) {
                stormSettings[i].stormId--;

                if (basinStormSettings[i]) {
                    basinStormSettings[i].stormId--;
                }
            }
        }

        stormSettings = stormSettings ? stormSettings.filter(v => new Date(v.stormStartTime).getTime() !== new Date(storm.stormStartTime).getTime()) : stormSettings;
        basinStormSettings = basinStormSettings ? basinStormSettings.filter(v => v.stormId !== storm.stormId) : basinStormSettings;

        const overrides: Overrides = { removedStormEvents, addedStormEvents, stormSettings, basinStormSettings };

        this.checkEditedStormEvents(overrides);
        this.applyChanges(true);

        if (this.stormEvensComponent) {
            this.stormEvensComponent.selectedStormId = null;
        }
    }

    // This method only used when start time of manually created storm is changed, in this case we change it straight in addedStormEvents
    public onManuallyCreatedStormEdit({storm, oldStartTime}: { storm: StormPeriodAdjustment, oldStartTime: string }) {
        const editedIndex = this.addedStormEvents.findIndex(v => new Date(v.stormStartTime).getTime() === new Date(oldStartTime).getTime());

        const addedStormEvents = this.addedStormEvents.map((item, index) => {
            if (index === editedIndex) {
                return { stormStartTime: storm.stormStartTime };
            }

            return item;
        });

        const overrides: Overrides = { addedStormEvents };
        this.checkEditedStormEvents(overrides);
    }

    // This will handle the event form the child when storm events have been added or removed
    public checkEditedStormEvents(editedStormEvents: Overrides): void {
        this.setEditedStormEvents(editedStormEvents);
        this.setUpdatesInfo();
    }

    /**
     * Show storms toggle click event handler.
     */
    public showStormsToggle(e) {
        this.hydroGraphChart.showStorms(e.checked);
    }

    public showDryDaysToggle(e) {
        this.hydroGraphChart.showDryDays(e.checked);
    }

    public handleSelectedIdStormChange(stormId: number): void {
        this.selectedStormId = stormId;
        this.utilService.safeChangeDetection(this.cdr);
    }

    public handleSeasonChange(seasonChange: SeasonChange): void {
        const stormIndex = this.basinStormSettings.findIndex((b) => b.stormId === seasonChange.stormId);
        if (stormIndex > -1) {
            this.basinStormSettings[stormIndex].season = seasonChange.season;
        } else {
            this.basinStormSettings.push({
                basinName: this.selectedBasin,
                stormId: seasonChange.stormId,
                season: seasonChange.season,
            });
        }
        this.setUpdatesInfo();
    }

    public handleqvsiConfigurationChange(res) {
        if (res) {
            this.qvsIConfigurations = res;
            this.setUpdatesInfo();
        } else {
            this.qvsIConfigurations = JSON.parse(JSON.stringify(this.originalQvsIConfigurations));
        }

    }

    public handleRegimeChange(regimeChange: RegimeChange): void {
        const stormIndex = this.basinStormSettings.findIndex((b) => b.stormId === regimeChange.stormId);
        if (stormIndex > -1) {
            this.basinStormSettings[stormIndex].regime = regimeChange.regime;
        } else {
            this.basinStormSettings.push({
                basinName: this.selectedBasin,
                stormId: regimeChange.stormId,
                regime: regimeChange.regime,
            });
        }
        this.setUpdatesInfo();
    }

    /********************************************************************************/
    /* PRIVATE                                                                      */
    /********************************************************************************/

    // This will set added and removed storm events
    private setEditedStormEvents(editedStormEvents: Overrides): void {
        if (editedStormEvents.addedStormEvents) this.addedStormEvents = [...editedStormEvents.addedStormEvents];
        if (editedStormEvents.removedStormEvents) this.removedStormEvents = [...editedStormEvents.removedStormEvents];
        if (editedStormEvents.basinStormSettings) this.basinStormSettings = [...editedStormEvents.basinStormSettings];
        if (editedStormEvents.stormSettings) this.stormSettings = [...editedStormEvents.stormSettings];
    }

    // This will check all changes and structure udpates info data if any change is detected
    private setUpdatesInfo() {
        const updatesInfo: Array<UpdateInfo> = [];
        const baseBasinOutlierDays = FlowMonitorComponent.GetBasinOutlierDays(
            this.selectedBasin,
            this.originalBasinOutliers,
        );

        const activeDesignStormsChange = !_.isEqual(this.activeDesignStorms, this.originalActiveDesignStorms);
        const designStormsChange = FlowMonitorComponent.GetDesignStormsChanged(this.designStorms, this.originalDesignStorms);
        const basinOutlierChange = !_.isEqual(this.basinOutlierDays, baseBasinOutlierDays);
        const basinStormsChange = !_.isEqual(this.basinStormSettings, this.originalBasinStormSettings);
        const stormsChange = !_.isEqual(this.stormSettings, this.originalStormSettings);
        const addedStormEventsChange = !_.isEqual(this.addedStormEvents, this.originalAddedStormEvents);
        const removedStormEventsChange = !_.isEqual(this.removedStormEvents, this.originalRemovedStormEvents);

        const updateQvsIConfigurations = !_.isEqual(this.qvsIConfigurations, this.originalQvsIConfigurations);
        const basinQvsISettingsChange = SliicerService.FindDifferentElements(this.basinQvsISettings, this.originalBasinQvsISettings).length > 0;
        const qvsiFilterChange =
            (this.originalQvsiFilter !== this.qvsiFilter
                && !(this.qvsiFilter === QVI_GROUP_ALL_STORMS && !this.originalQvsiFilter)
                && !(!this.qvsiFilter && this.originalQvsiFilter === QVI_GROUP_ALL_STORMS)
            )
            || (this.qvsiFilterAfterUpdate && this.qvsiFilterAfterUpdate !== this.originalQvsiFilter);

        if (basinOutlierChange) {
            FlowMonitorComponent.AddUpdateInfo(
                updatesInfo,
                this.getDryDaysUpdateInfo(this.basinOutlierDays, baseBasinOutlierDays),
            );
        }
        if (basinQvsISettingsChange) {
            FlowMonitorComponent.AddUpdateInfo(
                updatesInfo,
                this.getBasinQvsIUpdateInfo(this.basinQvsISettings)
            );
        }
        if (basinStormsChange) {
            FlowMonitorComponent.AddUpdateInfo(
                updatesInfo,
                this.getBasinSettingsUpdateInfo(this.basinStormSettings, this.originalBasinStormSettings),
            );
        }
        if (basinStormsChange || stormsChange) {
            const newUpdateInfo = FlowMonitorComponent.GetStormSettingsUpdateInfo(
                this.datePipe,
                this.dateutilService,
                this.stormEvents,
                this.stormSettings,
                this.originalStormSettings,
                this.basinStormSettings,
                this.originalBasinStormSettings
            );

            if (newUpdateInfo !== null) {
                FlowMonitorComponent.AddUpdateInfo(updatesInfo, newUpdateInfo);
            }
        }
        if (addedStormEventsChange || removedStormEventsChange) {
            FlowMonitorComponent.AddUpdateInfo(
                updatesInfo,
                this.getAddedRemovedStormEvents(
                    this.addedStormEvents,
                    this.originalAddedStormEvents,
                    this.removedStormEvents,
                    this.originalRemovedStormEvents,
                ),
            );
        }
        if (updateQvsIConfigurations) {
            const diffQvsIFilters = SliicerService.FindDifferentElements(this.qvsIConfigurations, this.originalQvsIConfigurations).map(
                (filter) => filter.name,
            );
            if (diffQvsIFilters) {
                const newUpdateInfo = {
                    title: 'EDITED_QVSI_FILTERS',
                    values: diffQvsIFilters
                };
                if (newUpdateInfo !== null) {
                    FlowMonitorComponent.AddUpdateInfo(updatesInfo, newUpdateInfo);
                }
            }
        }

        if(qvsiFilterChange) {
            const newUpdateInfo = {
                title: QVSI_FILTER_GROUP_CHANGE,
                values: [this.qvsiFilterAfterUpdate ? this.qvsiFilterAfterUpdate : this.qvsiFilter]
            }
            FlowMonitorComponent.AddUpdateInfo(updatesInfo, newUpdateInfo);
        }

        if (activeDesignStormsChange) {
            const newUpdateInfo = FlowMonitorComponent.GetActiveDesignStormsUpdateInfo(this.activeDesignStorms, this.originalActiveDesignStorms);
            FlowMonitorComponent.AddUpdateInfo(updatesInfo, newUpdateInfo);
        }

        if (designStormsChange) {
            const newUpdateInfo = FlowMonitorComponent.GetDesignStormsUpdateInfo(this.designStorms, this.originalDesignStorms);
            FlowMonitorComponent.AddUpdateInfo(updatesInfo, newUpdateInfo);
        }

        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
    }

    // This will structure and return the basin settings update info
    private getBasinSettingsUpdateInfo(
        currentSettings: Array<BasinStormSettings>,
        baseSettings: Array<BasinStormSettings>,
    ): Array<UpdateInfo> {
        const updatesInfo: Array<UpdateInfo> = [];
        let excludedStormsAdded = currentSettings.filter(
            (c) =>
                c.hasOwnProperty('exclude') &&
                !baseSettings.find((b) => b['basinName'] === c['basinName'] && b['stormId'] === c['stormId']) && c['stormId']
        );
        let excludedStormsRemoved = baseSettings.filter(
            (b) =>
                b.hasOwnProperty('exclude') &&
                !currentSettings.find((c) => !c['stormId'] || (c['basinName'] === b['basinName'] && c['stormId'] === b['stormId'])),
        );
        const excludedStormsChanged = this.getBasinSettingsChanged(currentSettings, baseSettings);
        excludedStormsAdded = excludedStormsAdded.concat(excludedStormsChanged.filter((e) => e['exclude']));
        excludedStormsRemoved = excludedStormsRemoved.concat(excludedStormsChanged.filter((e) => !e['exclude']));

        if (excludedStormsAdded.length > 0) {
            const excludedMap: Map<number, BasinStormSettings> = excludedStormsAdded.reduce((acc, curr) => acc.set(curr.stormId, curr), new Map<number, BasinStormSettings>());
            const values = this.stormEvents.filter(v => excludedMap.has(v.stormId)).map(v => this.getDateInUserFormat(v.stormStartTime));

            updatesInfo.push({
                title: 'EXCLUDED_STORMS_ADDED',
                values,
            });
        }
        if (excludedStormsRemoved.length > 0) {
            const excludedMap: Map<number, BasinStormSettings> = excludedStormsRemoved.reduce((acc, curr) => acc.set(curr.stormId, curr), new Map<number, BasinStormSettings>());
            const values = this.stormEvents.filter(v => excludedMap.has(v.stormId)).map(v => this.getDateInUserFormat(v.stormStartTime));

            updatesInfo.push({
                title: EXCLUDED_STORMS_REMOVED,
                values,
            });
        }
        return updatesInfo;
    }

    private getBasinSettingsChanged(
        currentSettings: Array<BasinStormSettings>,
        baseSettings: Array<BasinStormSettings>,
    ) {
        return currentSettings.filter((c) => {
            const exclude = c.hasOwnProperty('exclude');

            const fromBase = baseSettings.find(
                (b) => b['basinName'] === c['basinName'] && b['stormId'] === c['stormId'],
            );

            return c['stormId'] && exclude && (!fromBase || Object.keys(c).some((v) => fromBase[v] !== c[v]));
        });
    }

    // This will structure and return the dry days update info
    private getDryDaysUpdateInfo(
        currentOutlierDays: Array<OutlierDay>,
        baseOutlierDays: Array<OutlierDay>,
    ): Array<UpdateInfo> {
        const updatesInfo: Array<UpdateInfo> = [];

        const dryDaysAdded = SliicerService.FindDifferentElements(currentOutlierDays, baseOutlierDays).map((day) =>
            this.getDateInUserFormat(day['date']),
        );
        const dryDaysRemoved = SliicerService.FindDifferentElements(baseOutlierDays, currentOutlierDays).map((day) =>
            this.getDateInUserFormat(day['date']),
        );
        if (dryDaysAdded.length > 0) {
            updatesInfo.push({ title: 'DRY_DAYS_ADDED', values: dryDaysAdded });
        }
        if (dryDaysRemoved.length > 0) {
            updatesInfo.push({ title: 'DRY_DAYS_REMOVED', values: dryDaysRemoved });
        }
        return updatesInfo;
    }

    private getBasinQvsIUpdateInfo(
        overrides: BasinQvsISettingsOverrides[]
    ): Array<UpdateInfo> {
        const updatesInfo: Array<UpdateInfo> = [];
        updatesInfo.push({ title: 'BASIN_QVI_ALTERNATE_REGRESSION_MODIFIED', values: ['TODO:'] });
        return updatesInfo;
    }

    // This will sturcture and return the added and removed storm events update info
    private getAddedRemovedStormEvents(
        added: Array<StormEventAdjustment>,
        originalAdded: Array<StormEventAdjustment>,
        removed: Array<StormEventAdjustment>,
        originalRemoved: Array<StormEventAdjustment>,
    ): Array<UpdateInfo> {
        const updatesInfo: Array<UpdateInfo> = [];
        const diffAdded = SliicerService.FindDifferentElements(added, originalAdded);
        const diffRemoved = SliicerService.FindDifferentElements(removed, originalRemoved);
        if (diffAdded.length > 0) {
            updatesInfo.push({
                title: 'STORMS_ADDED',
                values: diffAdded.map((data) => this.getDateInUserFormat(data['stormStartTime'])),
            });
        }
        if (diffRemoved.length > 0) {
            updatesInfo.push({
                title: 'STORMS_REMOVED',
                values: diffRemoved.map((data) => this.getDateInUserFormat(data['stormStartTime'])),
            });
        }
        return updatesInfo;
    }

    // This will return the date in the user format
    private getDateInUserFormat(date: string): string {
        return FlowMonitorComponent.GetDateInUserFormat(this.datePipe, this.dateutilService, date);
    }

    private bindSelectedBasin(basinName: string, isFirstCall = false): void {
        if (isFirstCall) {
            const study = this.sliicerService.caseStudyDetails.getValue();
            const { calculationRunId, calculationRunTime, basinState } = study.meta;

            if (basinState !== BasinStateEnum.ready) {
                this.checkBasinCalcTime(calculationRunId, calculationRunTime, basinName);
            }
        }

        this.selectedBasin = this.tempSelectedBasin = basinName;
        this.changeToSelectedBasin = null;

        this.excludedStorms = [];
        this.currentBasinDryDays = null;
        this.originalCurrentBasinDryDays = null;

        if (!basinName) {
            return;
        }


        const study = this.sliicerService.caseStudyDetails.getValue();
        const basins = study.basinDefinition.basins;
        const basinDef =  basins.find(b => b.name === basinName);

        const qvsiGroup = basinDef && basinDef.filters ? basinDef.filters.qvsiGroup : null;
        let qvsiConf = null;
        if(qvsiGroup === undefined || qvsiGroup === null || qvsiGroup === QVI_GROUP_ALL_STORMS) {
            qvsiConf = QVI_GROUP_ALL_STORMS_CONFIG
        } else {
            const conf = this.qvsIConfigurations.find((q) => q.name === qvsiGroup);
            qvsiConf = conf ? conf : QVI_GROUP_ALL_STORMS_CONFIG;
        }

        this.basinOutlierDays = FlowMonitorComponent.GetBasinOutlierDays(this.selectedBasin, this.basinOutliers);

        this.originalQvsiFilter = qvsiConf.name;
        this.qvsiFilter = this.originalQvsiFilter;

        this.sliicerService.qvsiSelectedConfig.next({conf: qvsiConf, afterupdate: false});

        if (this.basinStormSettings) {
            this.excludedStorms = this.basinStormSettings
                .filter((bs) => bs.basinName === basinName && bs.exclude === true)
                .map((bs) => bs.stormId);
        }

        this.loadBasinDryDays();
    }

    private bindCaseStudyOverrides(overrides: Overrides): void {
        if (overrides && overrides.basinStormSettings) {

            // #33201 since we have detach value on the API and attach on the UI, need to invert it
            const withInvertedDeattachPrecomp = this.invertDeattachPrecompValue(overrides.basinStormSettings);
            this.basinStormSettings = Object.values(
                Object.assign({}, JSON.parse(JSON.stringify(withInvertedDeattachPrecomp))),
            );
            this.originalBasinStormSettings = withInvertedDeattachPrecomp;
        } else {
            this.basinStormSettings = [];
            this.originalBasinStormSettings = [];
        }

        if (overrides && overrides.basinQVsISettings) {
            this.basinQvsISettings = JSON.parse(JSON.stringify(overrides.basinQVsISettings));
            this.originalBasinQvsISettings = JSON.parse(JSON.stringify(overrides.basinQVsISettings));
        } else {
            this.basinQvsISettings = [];
            this.originalBasinQvsISettings = [];
        }

        if (overrides && overrides.stormSettings) {
            this.stormSettings = Object.values(Object.assign({}, JSON.parse(JSON.stringify(overrides.stormSettings))));
            this.originalStormSettings = overrides.stormSettings;
        } else {
            this.stormSettings = [];
            this.originalStormSettings = [];
        }

        if (overrides && overrides.basinOutliers) {
            this.basinOutliers = Object.values(Object.assign({}, JSON.parse(JSON.stringify(overrides.basinOutliers))));
            this.originalBasinOutliers = Object.values(
                Object.assign({}, JSON.parse(JSON.stringify(overrides.basinOutliers))),
            );
            this.basinOutlierDays = FlowMonitorComponent.GetBasinOutlierDays(this.selectedBasin, this.basinOutliers);
        } else {
            this.basinOutliers = [];
            this.originalBasinOutliers = [];
        }
        if ((overrides && overrides.addedStormEvents) || (overrides && overrides.removedStormEvents)) {
            if (overrides && overrides.addedStormEvents) {
                this.originalAddedStormEvents = [...overrides.addedStormEvents];
            } else {
                this.originalAddedStormEvents = [];
            }
            if (overrides && overrides.removedStormEvents) {
                this.originalRemovedStormEvents = [...overrides.removedStormEvents];
            } else {
                this.originalRemovedStormEvents = [];
            }
            this.setEditedStormEvents({
                addedStormEvents: this.originalAddedStormEvents,
                removedStormEvents: this.originalRemovedStormEvents,
            });
        }
    }

    private loadBasinDryDays(withLoader = true): void {
        if (withLoader) {
            this.basinDaysLoading = true;
        }
        this.sliicerService.getBasinDryDays(this.customerId, this.caseStudyId, this.selectedBasin).subscribe(
            (result) => {
                if (result) {
                    this.originalCurrentBasinDryDays = Object.assign({}, JSON.parse(JSON.stringify(result)));
                    this.applyFiltersToDryDays();
                } else {
                    this.currentBasinDryDays = null;
                    this.originalCurrentBasinDryDays = null;
                }
                this.basinDaysLoading = false;
                this.utilService.safeChangeDetection(this.cdr);
            },
            () => {
                this.currentBasinDryDays = null;
                this.originalCurrentBasinDryDays = null;
                this.basinDaysLoading = false;
                this.utilService.safeChangeDetection(this.cdr);
            },
        );
    }

    private loadStormEvents(): void {
        this.stormEventsLoading = true;
        this.sliicerService.getStorms(this.customerId, this.caseStudyId).subscribe(
            (result) => {
                if (result) {
                    this.originalStormEvents = result.storms ? JSON.parse(JSON.stringify(result.storms)) : [];
                    this.stormEvents = result.storms ? [...result.storms] : [];
                } else {
                    this.stormEvents = [];
                    this.originalStormEvents = [];
                }
            },
            () => {
                this.stormEvents = [];
                this.originalStormEvents = [];

            },
            () => {
                this.stormEventsLoading = false;
                this.utilService.safeChangeDetection(this.cdr);
            }
        );
    }

    private loadStormBasinResults(): void {
        this.sliicerService
            .getAllStormBasinResults(this.customerId, this.caseStudyId, this.selectedBasin)
            .subscribe(
                (results) => {
                    if (results && results.length > 0) {
                        this.stormBasinResults = results;
                    }
                },
            );
    }

    // This method set translation keywords
    private applyTranslations(): void {
        const translateKeys: Array<string> = [
            'COMMON.API_FAILURE_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAY_SAVE_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAY_SAVE_ERROR_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.SLIICER_EDITS_SAVED_MESSAGE'
        ];

        const translateSubs = this.translateService.get(translateKeys).subscribe((values) => {
            this.requestFailureMessage = values['COMMON.API_FAILURE_MESSAGE'];
            this.dryDaySaveMessage = values['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAY_SAVE_MESSAGE'];
            this.applyUpdatesMessage = values['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.SLIICER_EDITS_SAVED_MESSAGE'];
            this.dryDaySaveErrorMessage =
                values['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAY_SAVE_ERROR_MESSAGE'];
        });

        this.subscriptions.push(translateSubs);
    }

    // This method will render Flow monitor hydrograph
    private fetchHydrographData(): void {
        if (!!this.isRequestProcessing) {
            return;
        }
        // make sure we have data before we attempt to get graph data
        if (this.caseStudyId === null) {
            return;
        }

        this.isRequestProcessing = true;
        this.isLoading = true;
        if (this.hydroGraphChart) {
            this.hydroGraphChart.setIsLoading(true);
            this.hydroGraphChart.setShowGraph(false);
        }
        this.sliicerService
            .getBasinHydrographWithRainfall(this.customerId, this.caseStudyId, this.selectedBasin, true, true)
            .subscribe(
                (graphData: MultiSeriesData) => {
                    this.isRequestProcessing = false;
                    this.isLoading = false;
                    if (graphData && graphData.series && graphData.series.length > 0 && graphData.series.some(serie => serie?.data?.length > 0)) {
                        this.sliicerService.basinHgFlowData.next(graphData.series.find(v => v.seriesName.includes(FLOW)));

                        this.hydroGraphChart.setSeriesData(
                            this.selectedBasin,
                            this.startDate,
                            this.endDate,
                            graphData,
                            this.rainfallOnTop,
                        );

                        // This change will be broadcasted to the flow-monitor-hydrograph
                        // that, in turn, will render its chart
                        this.hydroGraphChart.setShowGraph(true);
                    }

                    this.utilService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.isRequestProcessing = false;
                    this.isLoading = false;
                    this.utilService.safeChangeDetection(this.cdr);
                    this.sliicerService.showToast(this.requestFailureMessage, true);
                },
            );
    }

    /********************************************************************************/
    /* PRIVATE STATIC METHODS                                                       */
    /********************************************************************************/

    /**
     * Static wrapper around private function that does the same
     * @param pipe
     * @param dateUtilService
     * @param date
     * @constructor
     */
    private static GetDateInUserFormat(pipe: DatePipe, dateUtilService: DateutilService, date: string): string {
        return pipe.transform(date, dateUtilService.getFormat());
    }

    /**
     * This method will check if the basin storm settings only have adjustments on the excluded key
     * @param basinStormSettings
     */
    private static CheckIfExcludedOnlyAdjustment(basinStormSettings: BasinStormSettings): boolean {
        return (
            Object.keys(basinStormSettings).length == 3 &&
            basinStormSettings.hasOwnProperty('stormId') &&
            basinStormSettings.hasOwnProperty('basinName') &&
            basinStormSettings.hasOwnProperty('exclude')
        );
    }

    /**
     * This will handle the update to the updatesInfo array
     * since the updateInfo can be Object or Array depending on what is updated
     * @param updatesInfo
     * @param updateInfo
     * @constructor
     */
    private static AddUpdateInfo(updatesInfo: Array<UpdateInfo>, updateInfo: any) {
        if (updateInfo instanceof Array) {
            for (let i = 0; i < updateInfo.length; i++) {
                updatesInfo.push(updateInfo[i]);
            }
        } else {
            updatesInfo.push(updateInfo);
        }
    }

    /**
     * This will structure and return the storm settings update info
     * @param datePipe
     * @param dateUtilService
     * @param stormEvents
     * @param currentStormSettings
     * @param baseStormSettings
     * @param currentBasinSettings
     * @param baseBasinSettings
     * @constructor
     */
    private static GetStormSettingsUpdateInfo(
        datePipe: DatePipe,
        dateUtilService: DateutilService,
        stormEvents: StormEvent[],
        currentStormSettings: Array<StormSettings>,
        baseStormSettings: Array<StormSettings>,
        currentBasinSettings: Array<BasinStormSettings>,
        baseBasinSettings: Array<BasinStormSettings>
    ): UpdateInfo {
        const differentStormEvents = SliicerService.FindDifferentElements(currentStormSettings, baseStormSettings).map(
            (setting) => FlowMonitorComponent.GetDateInUserFormat(datePipe, dateUtilService, setting.stormStartTime),
        );

        const differentStormBasins = currentBasinSettings
            .filter(
                (c) =>
                    !FlowMonitorComponent.CheckIfExcludedOnlyAdjustment(c) &&
                    baseBasinSettings.findIndex((b) => c.stormId === b.stormId) === -1,
            )
            .concat(
                currentBasinSettings.filter((c) =>
                    baseBasinSettings.find(
                        (b) =>
                            b.basinName === c.basinName &&
                            b.stormId === c.stormId &&
                            (b.precompType !== c.precompType || b.regime !== c.regime || b.season !== c.season),
                    ),
                ),
            )
            .map((data) => {
                const event = stormEvents.find((event) => event.stormId === data.stormId);

                return event ? FlowMonitorComponent.GetDateInUserFormat(
                    datePipe,
                    dateUtilService,
                    event.stormStartTime,
                ) : null
            }).filter(v => !!v);

        if (differentStormEvents.length > 0 || differentStormBasins.length > 0) {
            return {
                title: 'EDITED_STORM_EVENTS',
                values: differentStormEvents.concat(
                    SliicerService.FindDifferentElements(differentStormBasins, differentStormEvents),
                ),
            };
        }
        return null;
    }

    private static GetActiveDesignStormsUpdateInfo(current: string[], original: string[]): UpdateInfo {
        const originalSet = new Set(original);
        const differences = current.filter(v => !originalSet.has(v));

        return {
            title: ACTIVE_DESIGN_STORMS_CHANGE,
            values: differences
        };
    }

    private static GetDesignStormsUpdateInfo(current: DesignStormItem[], original: DesignStormItem[]): UpdateInfo {
        // need to remove isActive property, because we check it differently
        const formattedCurrent = current.map(({ isActive, ...rest }) => ({ ...rest }));
        const formattedOriginal = original.map(({ isActive, ...rest }) => ({ ...rest }));

        const differences = SliicerService.FindDifferentElements(formattedCurrent, formattedOriginal);

        return {
            title: DESIGN_STORMS_CHANGE,
            values: differences
        }
    }

    private static GetDesignStormsChanged(current: DesignStormItem[], original: DesignStormItem[]): boolean {
        // need to remove isActive property, because we check it differently
        const formattedCurrent = current.map(({ isActive, ...rest }) => ({ ...rest }));
        const formattedOriginal = original.map(({ isActive, ...rest }) => ({ ...rest }));

        return !_.isEqual(formattedCurrent, formattedOriginal);
    }

    private static GetBasinOutlierDays(selectedBasin: string, basinOutliers: BasinOutlier[]): OutlierDay[] {
        let outlierDays: OutlierDay[] = [];
        if (basinOutliers && basinOutliers.length > 0) {
            const theOutlier = basinOutliers.find((bo) => bo.basinName === selectedBasin);
            if (theOutlier && theOutlier.days && theOutlier.days.length > 0) {
                outlierDays = theOutlier.days.map((od) => {
                    let date = od.date;

                    // TODO: WPS - this should really be a regex.
                    const index = od.date.indexOf('T00:00:00');
                    if (index >= 0) {
                        date = date.substring(0, index);
                    }

                    return {
                        date: date,
                        type: od.type,
                    };
                });
            }
        }
        return outlierDays;
    }

    /**
     * Bind the list of basins to the basin drop down
     * @param basinDefinition
     */
    private static BindBasins(basinDefinition: BasinDefinition): ListItem[] {
        if (basinDefinition && basinDefinition.basins && basinDefinition.basins.length > 0) {
            const names = basinDefinition.basins.map((b) => b.name).sort();
            return names.map((name) => {
                return {
                    text: name,
                    id: name,
                };
            });
        }
        return [];
    }

    private static DayIsIdentifiedDryDay(dryDayData: DryDayData, dateValue: number): boolean {
        if (dryDayData) {
            return dryDayData.dayOfWeekGroupDays.some((dowg: DayOfWeekGroupDays) =>
                dowg.days.some((d) => {
                    const parsedDate: number = moment(d, FlowMonitorComponent.API_SLIICER_DATE_FORMAT).valueOf();
                    return parsedDate === dateValue;
                }),
            );
        } else {
            return false;
        }
    }
}
