<div id="CaseStudyDetails">
    <app-loader [isLoading]="isLoadingState"></app-loader>
    <div class="summary-details">
        <div class="locationDetails border-b">
            <div class="auto-margin pad-l-15">
                <mat-icon
                    *ngIf="!editConfig"
                    [ngClass]="{ disabledAction: updatesWidgetService.updates }"
                    class="backIcon"
                    (click)="updatesWidgetService.updates || navigateToDashboard()"
                >arrow_back
                </mat-icon>
            </div>

            <div class="auto-margin">{{ caseStudyName }}</div>
            <div
                class="auto-margin case-study-id"
                *ngIf="caseStudyId"
            >
                <span>{{ ('SLICER_PAGE.CASE_STUDY_ID' | translate) + ' : ' + caseStudyId }}</span>
                <mat-icon
                    [matTooltip]="'SLICER_PAGE.COPY_STUDY_ID' | translate"
                    (click)="copyStudyId(caseStudyId)"
                >content_copy
                </mat-icon>
            </div>
            <div
                class="auto-margin case-study-state-title"
                *ngIf="caseStudyId && (!inStudyWizard || studyState === StudyState.CalculationFailed)"
            >
                {{ 'SLICER_PAGE.STATE' | translate }}
            </div>
            <div
                class="auto-margin case-study-state"
                *ngIf="caseStudyId && (!inStudyWizard || studyState === StudyState.CalculationFailed)"
            >
                <span
                    class="vmiddle icon"
                    *ngIf="
                        studyState === StudyState.CalculationStarted || studyState === StudyState.TelemetryCopyStarted
                    "
                >
                    <mat-icon>lock</mat-icon>
                </span>
                <span
                    class="label"
                    [ngClass]="
                        studyState === StudyState.CalculationStarted || studyState === StudyState.TelemetryCopyStarted
                            ? 'color-running'
                            : studyState === StudyState.CalculationFailed ||
                              studyState === StudyState.TelemetryCopyFailed
                            ? 'color-failed'
                            : ''
                    "
                >{{
                    (studyState === StudyState.CalculationStarted || studyState === StudyState.TelemetryCopyStarted
                    ? 'SLICER_PAGE.STUDY_STATES.RUNNING'
                    : studyState === StudyState.CalculationFailed ||
                    studyState === StudyState.TelemetryCopyFailed
                    ? 'SLICER_PAGE.STUDY_STATES.FAIL'
                    : 'SLICER_PAGE.STUDY_STATES.READY'
                    ) | translate
                    }}</span>
            </div>
            <updates-widget></updates-widget>

            <!-- PowerBI code is temporarly commented out -->
            <!-- Fullscreen -->
            <!-- <div
                #pbiFullscreenContainer
                class="pbi-fullscreen app-flex-filler custom-app-flex-filter"
                *ngIf="inResultsTab"
            >
                <button
                    mat-button
                    (click)="powerBiFullscreen()"
                >
                    <mat-icon>fullscreen</mat-icon>
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.FULLSCREEN_POWERBI' | translate }}
                </button>
            </div> -->

            <div class="app-flex-filler custom-app-flex-filter">
                <div
                    class="slide-wrapper"
                    *ngIf="!editConfig && !inStudyWizard"
                >
                    <button
                        mat-button
                        [matMenuTriggerFor]="menu"
                        [disabled]="updatesWidgetService.updates"
                        (click)="optionsToggleExpanded = !optionsToggleExpanded"
                        #t="matMenuTrigger"
                    >
                        {{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.OPTIONS' | translate }}
                        <mat-icon *ngIf="t.menuOpen">expand_more</mat-icon>
                        <mat-icon *ngIf="t.menuOpen === false">expand_less</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <mat-slide-toggle
                                [checked]="preliminary"
                                [disabled]= "isOptionsLocked || isLocked"
                                (change)="markStudyPreliminary($event)"
                            >
                                {{
                                'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_MAKE_PRELIMINARY'
                                | translate
                                }}
                            </mat-slide-toggle>
                        </button>
                        <button *ngIf="!isOptionsLocked" mat-menu-item>
                            <mat-slide-toggle
                                [checked]="readOnlyAccess"
                                (change)="markStudyReadonly($event)"
                            >
                                {{ 'SLIICER_TABLE.NON_AUTHOR_ACCESS' | translate }}</mat-slide-toggle>
                        </button>
                        <button mat-menu-item>
                            <mat-slide-toggle
                                [checked]="isLocked"
                                (change)="lockstudy($event)"
                                [disabled]= "isOptionsLocked"
                            >
                                {{
                                'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_LOCK_BUTTON' | translate
                                }}
                            </mat-slide-toggle>
                        </button>
                        <input type="file" name="" id="fileInput" (change)="uploadFile($event.target.files)" class="file-display">
                        <button *ngIf="isOptionVisible" mat-menu-item  class="btn-colr" (click)="importCaseStudy()" >
                            <span>{{
                                    'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_IMPORT' | translate
                                }}</span>
                        </button>
                        <button *ngIf="isOptionVisible" mat-menu-item  class="btn-colr" (click)="exportCaseStudy()" >
                            <span>{{
                                    'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_EXPORT' | translate
                                }}</span>
                        </button>
                        <div>
                            <button
                                [disabled]="isLocked"
                                mat-menu-item
                                class="btn-colr"
                                (click)="runAutoCalculations()"
                                [disabled]= "isOptionsLocked"
                            >
                                {{
                                'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_RUN_CALCULATIONS'
                                | translate
                                }}
                            </button>
                        </div>
                        <!-- <div>
                            <button [disabled]="isLocked" mat-menu-item class="btn-colr" (click)="editConfiguration()">
                                {{
                                    'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_EDIT_CONFIGURATION'
                                        | translate
                                }}
                            </button>
                        </div> -->
                    </mat-menu>
                </div>
            </div>
            <div
                *ngIf="isBetaFlag"
                class="betaLabel"
            >{{ 'COMMON.BETA_LABEL' | translate }}</div>
        </div>

        <div class="study-setup">
            <ads-sliicer-wizard
                (wizardComplete)="wizardComplete()"
                *ngIf="inStudyWizard"
            ></ads-sliicer-wizard>
            <div
                class="preliminary-banner"
                *ngIf="preliminary && !editConfig && !inStudyWizard"
            >
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PRELIMINARY_DATA' | translate }}
            </div>
            <!-- <ads-sliicer-edit-config (editComplete)="editComplete()" *ngIf="editConfig"></ads-sliicer-edit-config> -->
            <mat-tab-group
                class="bg-color"
                [ngClass]="{ 'btm-border': preliminary }"
                (selectedTabChange)="tabChange($event, tabs)"
                *ngIf="!editConfig && !inStudyWizard"
                [class.case-study-locked]="isLocked"
                #tabs
            >
                <!-- config -->
                <mat-tab
                    [disabled]="updatesWidgetService.updates "
                    class="step-label-width"
                >
                    <ng-template mat-tab-label>
                        <span class="step-label-width" [ngClass]="{'tab-error': !isConfigurationFormValid}">{{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_TAB_1' | translate }}</span>
                    </ng-template>
                    <ng-template matTabContent>
                        <ads-sliicer-edit-config (configurationFormValidation)="onConfigurationFormValidation($event)" [isStudyLocked]="isLocked" [inStudyWizard]="inStudyWizard" (recalculateStudy)="runAutoCalculations()"></ads-sliicer-edit-config>
                    </ng-template>
                </mat-tab>
                <!-- Settings, lazy-loaded -->
                <mat-tab
                    [disabled]="updatesWidgetService.updates"
                    label="{{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_TAB_2' | translate }}"
                >
                    <ng-template matTabContent>
                        <app-study-settings></app-study-settings>
                    </ng-template>
                </mat-tab>
                <!-- Basin Definition -->
                <mat-tab
                    [disabled]="updatesWidgetService.updates "
                    label="{{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_TAB_3' | translate }}"
                >
                    <ng-template matTabContent>
                        <app-basin-definition></app-basin-definition>
                    </ng-template>
                </mat-tab>
                <!-- Rainfall Monitor, lazy-loaded  -->
                <mat-tab
                    [disabled]="updatesWidgetService.updates "
                    label="{{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_TAB_4' | translate }}"
                >
                    <ng-template matTabContent>
                        <app-rainfall-monitor [customerId]="caseStudyCustomerId" [caseStudyId]="caseStudyId">
                        </app-rainfall-monitor>
                    </ng-template>
                </mat-tab>
                <!-- Flow Monitor, lazy loaded -->
                <mat-tab
                    [disabled]="updatesWidgetService.updates "
                    label="{{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_TAB_5' | translate }}"
                >
                    <ng-template matTabContent>
                        <app-flow-monitor></app-flow-monitor>
                    </ng-template>
                </mat-tab>
                <!-- Study Results, lazy loaded -->
                <mat-tab
                    [disabled]="updatesWidgetService.updates "
                    label="{{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_TAB_6' | translate }}"
                >
                    <ng-template matTabContent>
                        <div class="setUpStudy study-tab">
                            <div class="custom-vertical-tab setting-page">
                                <mat-tab-group class="ink-bar-none" [(selectedIndex)]="resultsSubTab">
                                    <!-- US #30063 TASK #30441 SLiiCER - Results Page - Remove DASHBOARD menu from UI -->
                                    <!-- <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.DASHBOARD' | translate }}">
                                        <div
                                            *ngIf="powerBIEnabled"
                                            class="pbi-report"
                                        >
                                            <div
                                                #reportContainer
                                                style="width: 98%; height: 98%"
                                            ></div>
                                        </div>
                                        <div *ngIf="!powerBIEnabled">
                                            <p *ngIf="powerBIError">Error retrieving results.</p>
                                            <p *ngIf="!powerBIError">Waiting for results.</p>
                                        </div>
                                    </mat-tab> -->

                                    <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAY_STATS' | translate }}">
                                        <app-dry-day-pivot-table></app-dry-day-pivot-table>
                                    </mat-tab>
                                    <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.DRY_DAY_HYDROGRAPH' | translate }}">
                                        <app-dry-day-hydrograph-pivot *ngIf="resultsSubTab === 1"></app-dry-day-hydrograph-pivot>
                                    </mat-tab>
                                    <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_EVENTS' | translate }}">
                                        <app-storms-pivot-table *ngIf="resultsSubTab === 2"></app-storms-pivot-table>
                                    </mat-tab>
                                    <mat-tab label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.BASIN_QVI' | translate }}">
                                        <app-basin-qvsi-pivot-table *ngIf="resultsSubTab === 3"></app-basin-qvsi-pivot-table>
                                    </mat-tab>
                                    <mat-tab label="{{'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_RESULT_DATA' | translate}}">
                                        <span class="export-storm-tab" *ngIf="resultsSubTab === 4">
                                            <ads-export-storm></ads-export-storm>
                                        </span>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>
