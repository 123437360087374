import { SlimLocationDetails } from './../../models/location-details';
import { OnInit, ViewChild, ElementRef, Injectable, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    NotificationTreeComponent,
    NotificationTreeComponentData,
} from 'app/shared/components/notification-tree/notification-tree.component';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {
    NotificationLocationOptions,
    NotificationUser,
    NotificationLevel,
    NotificationValidation,
    NotificationDetailsLite,
    NotificationValidationError,
    NotificationResponseValidation,
} from 'app/shared/models/notifications';
import { NotificationDashboardService } from 'app/shared/services/notification.service';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import { Subscription } from 'rxjs';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { GISService } from 'app/shared/services/gis-service';
import { first } from 'rxjs/operators';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationService } from 'app/shared/services/location.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { MapService } from 'app/shared/services/map.service';
import { AlarmTypes } from 'app/shared/models/alarms';
import { AppQueryParams, CustomerFeatures, customerQueryParam, genericIdQueryParam } from 'app/shared/models/customer';
import { TritonLocationDialogComponent } from '../location-card/components/triton-location-dialog/triton-location-dialog.component';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { GetPermissionsResponseFeature } from 'app/shared/models/users-permission';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationCardService } from '../location-card/services/location-card.service';

export interface UIAlarmNotificationValidation extends NotificationValidation {
    fieldWarnings: { field: string; message: string }[];
}

// It is Injectable, not Component according to this: https://github.com/angular/angular/issues/16566
@Injectable()
export abstract class BaseNotificationForm<T extends NotificationDetailsLite> implements OnDestroy {
    @ViewChild('enabledToggle') public enabledToggle: MatSlideToggle;
    @ViewChildren('enabledLevelToggle') public enabledLevelToggle: QueryList<MatSlideToggle>;

    // FOR ALL TABS
    protected subscriptions = [new Subscription()];
    public PAGE_TEXT: any;
    public notificationObject: T;
    public backupNotificationObject: T;
    public selectedIndex = 0;
    protected customerId: number;
    public notificationId: string;
    public loadingItems = { page: true, locations: true, validations: true };
    public subscribeOnly = true; // Can't edit unless admin or customer admin
    protected userID: string;

    // FOR LOCATIONS TAB
    public locationsOptions: NotificationLocationOptions[] = [];
    public dispLocationsOptions: NotificationLocationOptions[] = [];
    public allLocationsChecked = false;
    public locationGroupOptions: string[];
    public selectedLocationGroup: string;
    public locationSearchString: string;

    /** Null means was not loaded yet */
    public isWetDryAvailable: boolean | null = null;

    // For notification tree tab
    public notificationTreeColumns: string[] = ['name', 'delay', 'userCount', 'enable', 'options'];
    public userOptions: NotificationUser[];

    // FOR VALIDATION TAB
    public notificationValidated = false;
    public showErrors = true;
    public showWarnings = true;
    public validationInfo: UIAlarmNotificationValidation = {
        warnings: [],
        fieldWarnings: [],
        errors: [],
        fieldErrors: [],
    };
    public AlarmTypes = AlarmTypes;
    public NotificationValidationError = NotificationValidationError;

    // FOR CONFIRMATION TAB
    public confirmationShowUsers: boolean[] = [];
    public subscribeUser: { alarm: boolean; rtn: boolean }[] = [];

    constructor(
        protected translate: TranslateService,
        protected locationGroupService: LocationGroupService,
        protected locationService: LocationService,
        protected customerService: CustomerService,
        protected notificationService: NotificationDashboardService,
        protected usersService: UsersService,
        protected mapService: MapService,
        protected matDialog: MatDialog,
        protected snackBar: MatSnackBar,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected gisService: GISService,
        protected domOperationUtilsService: DomOperationUtilsService,
        private locationCardService: LocationCardService
    ) {
        this.translate.get('NOTIFICATION_DASHBOARD').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
        this.userID = this.usersService.userID.getValue().toUpperCase();
        this.subscribeOnly = !this.usersService.userRoles
            .getValue()
            .some((x) => x === USER_ROLES.ADMIN || x === USER_ROLES.CUSTOMER_ADMIN || x === USER_ROLES.CUSTOMER_USER_MANAGER);

        // Set current customer
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                const customerId = Number(params.get(customerQueryParam));
                const notificationId = params.get(genericIdQueryParam);

                if (this.notificationId !== notificationId || this.customerId !== customerId) {
                    // Only need to update if customer or id has changed
                    this.customerId = customerId;
                    this.notificationId = notificationId;

                    this.getNotificationForEditing();
                }
            }),
        );

        this.subscriptions.push(this.locationGroupService.locGroupListUpdate$.subscribe(() => this.loadLocations()));
        this.notificationObject = this.notificationModelEmpty();
    }

    /** Return field for notification name */
    abstract nameFieldGet(): ElementRef<any>;
    /** Filter visible locations */
    abstract locationsFilter(
        locations: SlimLocationDetails[],
        locationGroups: any,
        selectedLocs: number[],
    ): NotificationLocationOptions[];
    /** Whenever allow 2nd tab (Configuration/Alarm) - one specific for form */
    abstract allowSecondTab(): boolean;
    /** Empty notification model */
    abstract notificationModelEmpty(): T;
    /** Called on form init */
    abstract initNewNotification();
    /** Called on form init */
    abstract initExistingNotification(res: T);
    /** Called after assign of new or edit notifcation */
    abstract onNotificationLoadFinished();

    // #22382 - it is needed. This component is Injectable ONLY because ANGULAR inheritance issue
    public ngOnInit() { // On page load
        if (this.subscribeOnly) {
            this.selectedIndex = 4;
        } else if (this.notificationId) { // Admin and edit notification
            this.selectedIndex = 0;
        } else { // Admin and new notification
            this.selectedIndex = 0;
        }

        this.customerService.currentCustomerFeatures
            .subscribe((res: Array<GetPermissionsResponseFeature>) => {
                this.isWetDryAvailable = res.some(x => x.id === CustomerFeatures.WetDryOverflow);
            });

        setTimeout(() => this.nameFieldGet().nativeElement.focus(), 50);
    }

    // Page load functions
    private getNotificationForEditing() {
        this.loadingItems.page = true;
        const notificationSubscription = this.notificationService
            .getSingleNotificationDetails(this.customerId, this.notificationId)
            .subscribe(
                (res: T) => {
                    if (res) {
                        this.notificationObject = { ...res };
                        res.levels.forEach((lvl, ind) => {
                            const userInLevel = lvl.users.find((x) => x.id === this.userID);
                            if (userInLevel) {
                                this.subscribeUser[ind] = { alarm: userInLevel.alarm, rtn: userInLevel.rtn };
                            } else {
                                this.subscribeUser[ind] = { alarm: false, rtn: false };
                            }

                            lvl.users = lvl.users.filter(x => x.rtn || x.admin || x.alarm);
                        });
                        this.initExistingNotification(res);
                    } else {
                        // Notification not found aka create new one
                        this.notificationId = undefined;
                        this.notificationObject = this.notificationModelEmpty();
                        this.initNewNotification();
                    }

                    // Once we have a notification (or blank template if no notification)
                    // we can populate all of the options that the page will need for each tab
                    this.backupNotificationObject = JSON.parse(JSON.stringify(this.notificationObject));
                    this.loadingItems.page = false;
                    this.populatePageOptions();
                    this.onNotificationLoadFinished();
                },
                (error) => {
                    this.loadingItems.page = false;
                },
            );
        this.subscriptions.push(notificationSubscription);
    }
    private populatePageOptions() {
        this.loadLocations();
        this.loadUsersForTree();
    }
    public loadLocations() {
        // Clear out locations before getting new ones
        this.loadingItems.locations = true;
        this.locationGroupOptions = [];
        this.locationsOptions = [];
        this.dispLocationsOptions = [];

        // Need location groups in order to know which group each location belongs in
        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.customerId).subscribe(
            (locGrpRes) => {
                const locationGroups = locGrpRes.locationGroups;

                // Once you have location groups, get the actual locations
                const locationsSubscription = this.locationService.getSlimLocations(this.customerId, false).subscribe(
                    (locRes) => {
                        if (locRes) {
                            const selectedLocs = this.notificationObject.lids;
                            this.locationsOptions = this.locationsFilter(locRes, locationGroups, selectedLocs);

                            this.locationGroupOptions = uniqBy(this.locationsOptions, 'group')
                                .map((x) => x.group)
                                .filter(Boolean)
                                .sort();
                            this.locationGroupOptions.unshift('All');
                            this.selectedLocationGroup = this.locationGroupOptions[0];
                            this.locationSetSearchAndGroup(); // Populates screen and handles filtering / check all functionality
                        }
                        this.loadingItems.locations = false;
                    },
                    (error) => {
                        this.loadingItems.locations = false;
                    },
                );
                this.subscriptions.push(locationsSubscription);
            },
            (error) => {
                this.loadingItems.locations = false;
            },
        );
        this.subscriptions.push(locationGroupSubscription);
    }

    public loadUsersForTree() {
        this.userOptions = [];
        if (!this.subscribeOnly) {
            const usersSubscription = this.usersService
                .getCustomerUsersV2(this.customerId, false, null, null, null, false)
                .subscribe(
                    (res) => {
                        if (res && res.payload) {
                            this.userOptions = res.payload
                                .sort((u1, u2) => {
                                    const lnc = u1.lastName.localeCompare(u2.lastName);
                                    if (lnc !== 0) return lnc;

                                    const fnc = u1.firstName.localeCompare(u2.firstName);
                                    if (fnc !== 0) return lnc;

                                    return u1.userID > u2.userID ? 1 : u1.userID < u2.userID ? -1 : 0;
                                })
                                .map((usr) => {
                                    return {
                                        id: usr.userID.toUpperCase(),
                                        dispName:
                                            usr.firstName || usr.lastName
                                                ? usr.firstName.concat(' ', usr.lastName)
                                                : usr.userName,
                                        admin: usr.role === USER_ROLES.ADMIN,
                                        alarm: false,
                                        rtn: false,
                                    };
                                });
                        }
                    },
                    (error) => {},
                );
            this.subscriptions.push(usersSubscription);
        }
    }

    public toggleNotificationEnabled(event: MatSlideToggleChange) {
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_NOTIFICATION_CONFIRM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.notificationObject.enabled = false;
                    } else {
                        this.notificationObject.enabled = true;
                        this.enabledToggle.checked = true; // To visually force it back to the previous state
                    }
                });
        } else {
            this.notificationObject.enabled = true;
        }
    }
    public toggleNotificationLevelEnabled(event: MatSlideToggleChange, element: NotificationLevel) {
        const affectedInd = this.notificationObject.levels.findIndex((x) => x.name === element.name);
        const affectedElement = this.notificationObject.levels[affectedInd];
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_LEVEL_CONRIM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        affectedElement.enabled = false;
                    } else {
                        affectedElement.enabled = true;
                        event.source.checked = true;
                    }
                });
        } else {
            affectedElement.enabled = true;
        }
    }
    public backToAllNotifications(): void {
        // Make sure you capture any changes in active tab
        if (this.selectedIndex === 0) {
            this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        } else if (this.selectedIndex === 1) {
            // TODO: TAB1: Implement as abstract
        }
        if (isEqual(this.backupNotificationObject, this.notificationObject)) {
            // No changes have been made, just go back
            this.actuallyGoBack();
        } else {
            this.backToAllLeavePopup();
        }
    }
    protected backToAllLeavePopup() {
        // Confirm that you want to discard changes
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.BACK_HEADER,
                    message: this.PAGE_TEXT.BACK_TEXT,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.actuallyGoBack();
                }
            });
    }
    private actuallyGoBack() {
        const route = '/pages/menuDashboard/notifications';
        const appQueryParams: AppQueryParams = { c: this.customerId };

        this.router.navigate([route], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    // ALL TAB FUNCTIONS
    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.selectedIndex = tabChangeEvent.index;
        this.handleTabChange();
    }
    public handleTabChange() {
        // Update notification object or validate as necessary
        this.notificationValidated = false;
        this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        // TODO: TAB1: Update structures

        if (this.selectedIndex === 3) {
            this.validateNotification();
        }
    }
    public allowNext(currentTabIndex: number) {
        let condition = true;
        switch (currentTabIndex) {
            // Validation tab
            case 3:
            default:
                condition =
                    condition &&
                    this.notificationValidated &&
                    this.validationInfo.errors.length === 0 &&
                    this.validationInfo.fieldErrors.length === 0;
            // Levels Tab
            case 2:
                condition = condition && this.notificationObject.levels.length > 0;
            // Second tab
            case 1:
                condition = condition && this.allowSecondTab();
            // Location tab
            case 0:
                condition =
                    condition &&
                    this.locationsOptions.some((x) => x.checked) &&
                    this.notificationObject.name !== null &&
                    this.notificationObject.name !== '';
        }
        return condition;
    }
    public disableTab(tabIndex: number) {
        switch (tabIndex) {
            case 0: {
                // Location tab
                // Disable if subscribe only
                return this.subscribeOnly;
            }
            case 1: // Second tab
            case 2: // Levels tab
            case 3: {
                // Validation tab
                // Disable if subscribe only OR new add and in earlier tab
                return this.subscribeOnly || (!this.notificationId && this.selectedIndex < tabIndex);
            }
            case 4:
            default: {
                return this.selectedIndex !== 4;
            }
        }
    }
    public uiChangeTab(changeTab: boolean, direction?: boolean) {
        if (changeTab) {
            direction ? (this.selectedIndex += 1) : (this.selectedIndex -= 1);
            this.domOperationUtilsService.selectedStandarAlarmTab.next(this.selectedIndex);
        }
    }

    public checkName() {
        if (this.notificationObject.name !== null) {
            this.notificationObject.name = this.notificationObject.name.trim();
        }
        if (this.selectedIndex === 3) {
            this.validateNotification();
        }
    }
    // END ALL TAB FUNCTIONS

    // LOCATIONS TAB FUNCTIONS
    public checkAllLocations() {
        // Update displayed locations only
        this.allLocationsChecked = !this.allLocationsChecked;
        this.dispLocationsOptions.forEach((loc) => (loc.checked = this.allLocationsChecked));
        // Have to "touch" locationOptions so pipe at summary will refresh
        this.locationsOptions = this.locationsOptions.map((loc) => loc);
    }
    public updateLocation(location: NotificationLocationOptions) {
        this.locationsOptions = this.locationsOptions.map((loc) => {
            if (loc.id === location.id) {
                loc.checked = !loc.checked;
            }
            return loc;
        });
        this.locationSetSearchAndGroup(); // Update displayed location and whether 'check all' should be checked
    }
    public locationSetSearchAndGroup() {
        // Set displayed locations by group first
        if (this.selectedLocationGroup === this.locationGroupOptions[0]) {
            // 'All' option
            this.dispLocationsOptions = uniqBy(this.locationsOptions, 'id');
        } else {
            this.dispLocationsOptions = this.locationsOptions.filter((loc) => loc.group === this.selectedLocationGroup);
        }

        // Next filter by search string
        if (this.locationSearchString && this.locationSearchString !== '') {
            this.dispLocationsOptions = this.dispLocationsOptions.filter((loc) =>
                loc.name && loc.name.toLowerCase().includes(this.locationSearchString.toLowerCase()),
            );
        }

        // Finally update whether all displayed locations should be checked
        this.allLocationsChecked =
            this.dispLocationsOptions.length > 0 && this.dispLocationsOptions.every((x) => x.checked);
    }
    // END LOCATIONS TAB FUNCTIONS

    // NOTIFICATION TREE TAB FUNCTIONS
    public addNotificationLevel() {
        const names = this.notificationObject.levels.map((lvl) => lvl.name);
        this.openNotificationDialog(names);
    }
    public editNotificationLevel(element: NotificationLevel) {
        const names = this.notificationObject.levels.map((lvl) => lvl.name).filter((name) => name !== element.name);
        this.openNotificationDialog(names, element);
    }
    public deleteNotificationLevel(element: NotificationLevel) {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.BACK_HEADER,
                    message:
                        this.PAGE_TEXT.DELETE_FIRST +
                        element.name +
                        this.PAGE_TEXT.DELETE_LEVEL +
                        this.PAGE_TEXT.DELETE_SECOND,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.notificationObject = {
                        ...this.notificationObject,
                        levels: this.notificationObject.levels.filter((x) => x.name !== element.name),
                    };
                }
            });
    }

    public getNotificationDialogOptions(names: string[], element?: NotificationLevel): NotificationTreeComponentData {
        return {
            users: this.userOptions,
            selectedUsers: element ? element.users : [],
            name: element ? element.name : undefined,
            names: names,
            delay: element ? element.delay : 0,
            enabled: element ? element.enabled : true,
        };
    }

    public openNotificationDialog(names: string[], element?: NotificationLevel) {
        this.matDialog
            .open(NotificationTreeComponent, {
                disableClose: true,
                data: this.getNotificationDialogOptions(names, element),
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.save && result.data) {
                    const resLevel: NotificationLevel = {
                        name: result.data.name,
                        delay: result.data.delay,
                        enabled: result.data.enabled,
                        users: result.data.selectedUsers,
                    };

                    if (element) {
                        // Update existing level
                        this.notificationObject = {
                            ...this.notificationObject,
                            levels: this.notificationObject.levels.map((lvl) => {
                                return lvl.name === element.name ? resLevel : lvl;
                            }),
                        };
                    } else {
                        // Create a new level
                        this.notificationObject = {
                            ...this.notificationObject,
                            levels: this.notificationObject.levels.concat(resLevel),
                        };
                    }

                    this.notificationObject.levels = sortBy(this.notificationObject.levels, ['delay']);
                }
            });
    }
    // END NOTIFICATION TREE TAB FUNCTIONS

    // VALIDATION TAB FUNCTIONS
    private getAlarmName(itm): string {
        if (itm.alarmType) {
            const alarmDesc = this.notificationService.getSpecificAlarmType(itm.alarmType);
            if (alarmDesc && alarmDesc.name) {
                return alarmDesc.name;
            }
        }

        // beacuse it can be 0 (zero)
        if (itm.dailyReportType !== null && itm.dailyReportType !== undefined) {
            const alarmDesc = this.notificationService.getSpecificDailyAlarmType(itm.dailyReportType);
            if (alarmDesc && alarmDesc.name) {
                return alarmDesc.name;
            }
        }

        return this.PAGE_TEXT.UNKNOWN;
    }

    public validateNotification() {
        this.notificationValidated = false;
        this.loadingItems.validations = true;

        // Check that all of the required items are present don't validate if not
        if (!this.checkAllRequiredInputs()) {
            return;
        }

        // Once everything that is required is present, validate the alarm
        const validationSubscription = this.notificationService
            .validateNotification(this.customerId, this.notificationObject)
            .subscribe(
                (res: NotificationResponseValidation) => {
                    this.validationInfo = { warnings: [], fieldWarnings: [], errors: [], fieldErrors: [] };

                    const noUserWillBeNotfied = this.notificationObject.levels.every(
                        (x) => !x.enabled || x.users.length === 0,
                    );
                    if (noUserWillBeNotfied) {
                        this.validationInfo.fieldWarnings.push({
                            field: 'notifications',
                            message:
                                'No users was selected to receive notifications. Levels are disabled or do not contain users.',
                        });
                    }

                    if (res) {
                        if (res.fieldErrors) {
                            for (const error of res.fieldErrors) {
                                this.validationInfo.fieldErrors.push({
                                    field: error.fieldName,
                                    message: error.fieldMessage,
                                });
                            }
                        }

                        if (res.payload) {
                            const locKeys = Object.keys(res.payload);
                            const payload = res.payload;
                            locKeys.forEach((loc) => {
                                const warningLocItem = {
                                    location: payload[loc][0].locationName,
                                    lid: payload[loc][0].locationID,
                                    items: [],
                                };
                                const errorLocItem = {
                                    location: payload[loc][0].locationName,
                                    lid: payload[loc][0].locationID,
                                    items: [],
                                };

                                payload[loc].forEach((itm) => {
                                    console.log('error', itm);
                                    switch (itm.error) {
                                        case NotificationValidationError.AlarmInvalid: {
                                            warningLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.PAGE_TEXT.ERROR_TEXT.ALARM_INVALID.concat(
                                                    this.getAlarmName(itm),
                                                ).concat(this.PAGE_TEXT.ERROR_TEXT.ALARM),
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.AlarmNotConfigured: {
                                            const text =
                                                itm.alarmType === AlarmTypes.WET_OVERFLOW_ALARM
                                                    ? this.notificationService.getWDONotConfigured()
                                                    : this.getAlarmName(itm).concat(
                                                          this.PAGE_TEXT.ERROR_TEXT.ALARM_NOT_CONFIGURED,
                                                      );

                                            errorLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: text,
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.DuplicateNotification: {
                                            errorLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.getAlarmName(itm).concat(
                                                    this.PAGE_TEXT.ERROR_TEXT.DUPLICATE_NOTIFICATION,
                                                ),
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.ConfigurationInvalid: {
                                            warningLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.getAlarmName(itm).concat(
                                                    this.PAGE_TEXT.ERROR_TEXT.CONFIGURATION_INVALID,
                                                ),
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.CommunicationTypeInvalid: {
                                            errorLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.PAGE_TEXT.ERROR_TEXT.NOT_TCPIP_COMMUNICATION,
                                            });
                                            break;
                                        }
                                    }
                                });

                                if (warningLocItem.items.length > 0) {
                                    this.validationInfo.warnings.push(warningLocItem);
                                }
                                if (errorLocItem.items.length > 0) {
                                    this.validationInfo.errors.push(errorLocItem);
                                }
                            });
                        }
                    }

                    // Any locations with errors need full location data for location cards
                    this.notificationValidated = true;
                    this.loadingItems.validations = false;
                },
                (error) => {
                    this.loadingItems.validations = false;
                },
            );
        this.subscriptions.push(validationSubscription);
    }
    public openLocationCard(lid: number) {
        const locCardSub = this.locationService.getLocationDetailsV2(this.customerId, lid).subscribe(
            (locRes) => {
                const locationDetails = {
                    series: locRes[0].series,
                    serialNumber: locRes[0].serialNumber,
                    isActive: locRes[0].isActive,
                    modem: locRes[0].modem,
                    description: locRes[0].description,
                    qFinalEntityId: locRes[0].qFinalEntityID,
                    manholeAddress: locRes[0].manholeAddress,
                    coordinate: { latitude: locRes[0].latitude, longitude: locRes[0].longitude, elevation: 1 },
                    locationID: locRes[0].locationID,
                    locationName: locRes[0].locationName,
                    assignedRainGaugeLocationId: locRes[0].assignedRainGaugeLocationId,
                    installationHeight: locRes[0].height,
                    installationWidth: locRes[0].width,
                    installationType: locRes[0].installationType,
                    installationId: locRes[0].installationID,
                    depthUnit: locRes[0].depthUnit,
                    flowUnit: locRes[0].flowUnit,
                    locationType: locRes[0].components ? 3 : 1,
                    components: locRes[0].components,
                    installationShape: locRes[0].installationShape,
                    ipaddress: locRes[0].connectionString,
                    range: locRes[0].range,
                    capacity: locRes[0].capacity,
                    width: locRes[0].width,
                    installationShapeTypeID: locRes[0].installationShapeTypeID,
                    manholedepth: locRes[0].manholeDepth,
                    length: locRes[0].length,
                    breadth: locRes[0].breadth,
                    coefficient: locRes[0].coefficient,
                    assignedRainGaugeLocationName: locRes[0].assignedRainGaugeLocationName,
                    entries: locRes[0].entries,
                    lastCollectedDate: '',
                };

                const dialogOptions: MatDialogConfig = {
                    disableClose: true,
                    data: {
                        rainGaugeLocations: [],
                        customerId: this.customerId,
                        editMode: true,
                        locationDetails: locationDetails,
                        isFromCustomerEditor: false,
                        isFromNotificationDash: true,
                    },
                };
                if (this.gisService.locationCardPosition) {
                    dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                }

                if (this.mapService.addEditLocationDialog) {
                    this.mapService.addEditLocationDialog.close();
                }

                this.mapService.addEditLocationDialog = this.matDialog.open(
                    TritonLocationDialogComponent,
                    dialogOptions,
                );
                this.mapService.addEditLocationDialog
                    .afterClosed()
                    .pipe(first())
                    .subscribe((res) => {
                        this.mapService.addEditLocationDialog = null;
                        // if (res) {
                        //     const comp = this.mapService.addEditLocationDialog.componentInstance;
                        //     const activated = comp.isMonitorActivationRequired;

                        //     if(! activated) {
                        //         this.matDialog.open(ConfirmDialogComponent, {
                        //             disableClose: true,
                        //             data: {
                        //                 title: this.PAGE_TEXT.WARNING,
                        //                 message: this.PAGE_TEXT.WARNING_NOT_ACTIVE
                        //             }
                        //         });
                        //     }
                        //     setTimeout(() => {
                        //         this.validateNotification();
                        //     }, 1000);
                        // }
                    });
            },
            (error) => {},
        );
        this.subscriptions.push(locCardSub);
    }

    public removeLocationFromList(locId: number) {
        this.locationsOptions = this.locationsOptions.map((x) => {
            if (x.id === locId) {
                x.checked = false;
            }
            return x;
        });
        this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        this.validationInfo.errors = this.validationInfo.errors.filter((x) => x.lid !== locId);

        if (this.notificationObject.lids.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LOCATIONS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            this.selectedIndex = 0;
        }
    }

    // CONFIRMATION TAB FUNCTIONS
    public convertIDtoUserNames(users: NotificationUser[]) {
        const usersByName = users.map((usr) => {
            const correctUser = this.userOptions.find((x) => x.id === usr.id);
            if (correctUser) {
                return correctUser.dispName;
            }
            return 'Unknown user';
        });
        return usersByName.sort();
    }
    public checkAllRequiredInputs(): boolean {
        if (this.notificationObject.name === null || this.notificationObject.name.trim() === '') {
            this.snackBar.open(this.PAGE_TEXT.MISSING_NAME, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => this.nameFieldGet().nativeElement.focus(), 0);
            return false;
        }

        this.notificationObject = {
            ...this.notificationObject,
            name: this.notificationObject.name.trim(),
            desc: this.notificationObject.desc ? this.notificationObject.desc.trim() : '',
        };
        const obj = this.notificationObject;
        if (obj.lids.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LOCATIONS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 0), 500);
            return false;
        } else if (obj.levels.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LEVELS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 2), 500);
            return false;
        }

        return true;
    }
    public updateSubscription(ind: number, alarm: boolean) {
        const alarmUser = this.subscribeUser[ind];
        if (alarm) {
            alarmUser.alarm = !alarmUser.alarm;
        } else {
            alarmUser.rtn = !alarmUser.rtn;
        }

        const levelUsers = this.notificationObject.levels[ind].users;
        let myUser = levelUsers.find((x) => x.id === this.userID);
        if (myUser) {
            myUser.alarm = alarmUser.alarm;
            myUser.rtn = alarmUser.rtn;
        } else {
            levelUsers.push({ id: this.userID, alarm: alarmUser.alarm, rtn: alarmUser.rtn, admin: false });
        }
        const subscribed = alarm ? alarmUser.alarm : alarmUser.rtn;
        const updateSubscription = this.notificationService
            .updateNotification(this.customerId, this.notificationObject)
            .subscribe(
                () => {
                    let responseText = subscribed
                        ? this.PAGE_TEXT.SUBSCRIBE_TEXT.SUBSCRIBE_SUCCESS
                        : this.PAGE_TEXT.SUBSCRIBE_TEXT.UNSUBSCRIBE_SUCCESS;
                    responseText = responseText.concat(this.notificationObject.levels[ind].name);
                    this.snackBar.open(responseText, this.PAGE_TEXT.DISMISS, { duration: 10000 });
                },
                (error) => {
                    let responseText = subscribed
                        ? this.PAGE_TEXT.SUBSCRIBE_TEXT.SUBSCRIBE_ERROR
                        : this.PAGE_TEXT.SUBSCRIBE_TEXT.UNSUBSCRIBE_ERROR;
                    responseText = responseText.concat(this.notificationObject.levels[ind].name, error);
                    this.snackBar.open(responseText, this.PAGE_TEXT.DISMISS);

                    // Reset icons back to how they were
                    if (alarm) {
                        alarmUser.alarm = !alarmUser.alarm;
                    } else {
                        alarmUser.rtn = !alarmUser.rtn;
                    }
                    myUser = { ...myUser, alarm: alarmUser.alarm, rtn: alarmUser.rtn };
                },
            );
        this.subscriptions.push(updateSubscription);

        if(myUser && (!myUser.alarm && !myUser.rtn)) {
            this.notificationObject.levels[ind].users = this.notificationObject.levels[ind].users.filter(x => x.rtn || x.alarm);
        }
    }
    public confirm() {
        let cnt = 1;
        this.notificationObject.levels.forEach((x) => (x.level = cnt++));

        const saveSubscription = this.notificationService
            .updateNotification(this.customerId, this.notificationObject)
            .subscribe(
                (res: any) => {
                    if (res && res.isError === false) {
                        this.actuallyGoBack();
                    } else {
                        this.snackBar.open(this.PAGE_TEXT.CONFIRM_ERROR, this.PAGE_TEXT.DISMISS);
                    }
                },
                (error) => {
                    this.snackBar.open(this.PAGE_TEXT.CONFIRM_ERROR, this.PAGE_TEXT.DISMISS);
                },
            );
        this.subscriptions.push(saveSubscription);
    }
    // END CONFIRMATION TAB FUNCTIONS

    ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.forEach((v) => v.unsubscribe());
    }
}
