import { Injectable } from '@angular/core';
import { Config } from './config';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UnitOfMeasureType } from '../constant';
import { TranslateService } from '@ngx-translate/core';
import { GetPermissionsResponseCustomer, GetPermissionsResponseFeature } from '../models/users-permission';
import {
    Customer,
    customerEditorQueryParam,
    customerQueryParam,
    GetCustomers,
    GetCustomerSummary,
    ProjectDetails,
    SliicerUserConfig,
    SubscriptionsInfo,
    SubscriptionTier,
    UpdateProjectDetails,
} from '../models/customer';
import { CustomerUnits } from '../models/location-details';
import { EMPTY, merge } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

export const FEATURE_IDS = {
    ADVANCED_REPORT: 112,
};

export const CUSTOMER_FEATURE_NAMES = {
    AUTO_REVIEW: 'AutoReview',
    BLOCKAGE_PREDICTION: 'BlockagePrediction',
};

@Injectable()
export class CustomerService {
    /**
     * LogedIn User Role
     */
    public loggedInUserRole = new BehaviorSubject<string[]>(null);
    /**
     * Unit Type -checking metric or non metric
     */
    public isUnitTypeMetric = new BehaviorSubject<boolean>(false);
    public unitValue = new BehaviorSubject<string>('');
    /**
     * Reprsents an observable with customers with locations assigned to the logged in user
     */
    public userCustomersWithLocations = new BehaviorSubject<Array<GetPermissionsResponseCustomer>>([]);

    /**
     * Represents all customers assigned to the logged in user
     */
    public userCustomers = new BehaviorSubject<Array<GetPermissionsResponseCustomer>>([]);

    public currentCustomerFeatures = new BehaviorSubject<Array<GetPermissionsResponseFeature>>([]);

    /**
     * Represents all customers.
     */
    public customers = new BehaviorSubject<Array<Customer>>([]);

    public customer: Customer;

    public fetchCustomerId$ = new BehaviorSubject<number>(null);
    public lastFetchCustomerId: number = null;

    public customerId = new BehaviorSubject<number>(0);
    public summaryRowCount: number;
    public customerSummary: Array<GetCustomerSummary>;

    public unit: CustomerUnits;
    constructor(
        public http: HttpClient,
        public activatedRoute: ActivatedRoute,
        public statusCodeService: StatusCodeService,
        public translate: TranslateService,
        public route: ActivatedRoute,
    ) {
        this.getCustomers();
        this.getCustomers(true);

        merge(this.route.queryParams.pipe(map((queryParams) => queryParams['c'])), this.fetchCustomerId$).subscribe(
            (customerIDString) => {
                if (customerIDString !== undefined) {
                    const customerID = parseInt(customerIDString as string, 10);

                    if (Number.isInteger(customerID)) {
                        if (this.lastFetchCustomerId && this.lastFetchCustomerId === customerID) return;

                        this.lastFetchCustomerId = customerID;
                        this.getCustomer(customerID).subscribe((features) => {
                            this.currentCustomerFeatures.next(features);
                        });
                    }
                }
            },
        );
    }

    public fetchCustomerId(customerId: number) {
        this.fetchCustomerId$.next(customerId);
    }

    public updateCustomerFeatures(customerID) {
        this.getCustomer(customerID).subscribe((features) => {
            this.currentCustomerFeatures.next(features);
        });
    }

    public getCustomer(cid: number): Observable<Array<GetPermissionsResponseFeature>> {
        if (!cid) return EMPTY;

        return this.http.get<Array<GetPermissionsResponseFeature>>(
            Config.urls.permissionCustomerv2 + `?customerId=${cid}`,
        );
    }

    /**
     * Method returns either only customers with locations or all customers
     * @param customersWithLocationsOnly - set true to return only customers with locations
     */
    public getCustomers(getOnlyCustomersWithLocations?: boolean): Observable<Array<Customer>> {
        if (getOnlyCustomersWithLocations) {
            return this.http
                .get<Array<any>>(
                    Config.serviceUrl + Config.urls.getOnlyCustomersWithLocations + getOnlyCustomersWithLocations,
                )
                .pipe(
                    map((response) => {
                        if (response[0].customerID && this.customerId !== response[0].customerID) {
                            this.customerId.next(response[0].customerID);
                        }

                        return response;
                    }),
                );
        } else {
            return this.http.get<Array<Customer>>(Config.serviceUrl + Config.urls.locationGroup.prefixUrl).pipe(
                map((response) => {
                    this.customers.next(response);
                    return response;
                }),
            );
        }
    }
    /**
     * Active/ Inactive wet dry overflow
     */
    public activeInactiveWetDry(cid, lid, enabled) {
        const headers = new Headers();
        return this.http.put(
            Config.serviceUrl + Config.urls.enableDisableAlarms + `?cid=${cid}` + `&lid=${lid}` + `&enabled=${enabled}`,
            { headers: headers },
        );
    }

    /**
     * Method returns either only customers with locations or all customers
     * @param customersWithLocationsOnly - set true to return only customers with locations
     */
    public getActiveCustomersWithLocations(
        getOnlyCustomersWithLocations?: boolean,
        getActiveCustomersOnly?: boolean,
    ): Observable<Array<Customer>> {
        if (getOnlyCustomersWithLocations && getActiveCustomersOnly) {
            return this.http
                .get<Array<any>>(
                    Config.serviceUrl +
                    Config.urls.getOnlyCustomersWithLocations +
                    getOnlyCustomersWithLocations +
                    Config.urls.getActiveCustomersOnlyFlag +
                    getActiveCustomersOnly,
                )
                .pipe(
                    map((response) => {
                        if (
                            response &&
                            response[0] &&
                            response[0].customerID &&
                            this.customerId !== response[0].customerID
                        ) {
                            this.customerId.next(response[0].customerID);
                        }

                        return response;
                    }),
                );
        }
    }

    /**
     * Method returns all customers
     * @param getActiveCustomersOnly - set false to return all customers
     */
    public getAllCustomers(getActiveCustomersOnly?: boolean): Observable<Array<Customer>> {
        return this.http
            .get<Array<any>>(Config.serviceUrl + Config.urls.getActiveCustomersOnly + getActiveCustomersOnly)
            .pipe(
                map((response) => {
                    if (response[0].customerID && this.customerId !== response[0].customerID) {
                        this.customerId.next(response[0].customerID);
                    }

                    return response;
                }),
            );
    }
    /**
     * Method sets customer ID for selected customer
     * @param customerId
     */
    public selectCustomer(customerId: number) {
        this.customerId.next(customerId);
    }

    public getCustomerById(customerId: number) {
        return this.http.get<Customer>(Config.serviceUrl + Config.urls.locationGroup.prefixUrl + `${customerId}`).pipe(
            map((res) => {
                if (res) {
                    this.customer = res;
                }
                return res;
            }),
        );
    }

    public addCustomer(newCustomer: Customer) {
        return this.http.post<Customer>(Config.serviceUrl + Config.urls.locationGroup.prefixUrl, newCustomer);
    }

    public updateCustomer(newCustomer: Customer, customerId: number) {
        return this.http.put<Customer>(
            Config.serviceUrl + Config.urls.locationGroup.prefixUrl + `${customerId}`,
            newCustomer,
        );
    }

    public archiveCustomer(customerID: number) {
        return this.http
            .put<Customer>(Config.serviceUrl + Config.urls.locationGroup.prefixUrl + `${customerID}/archive`, null)
            .pipe(map((response) => <Customer>response));
    }

    public getTimeZone() {
        return this.http.get<{ value: string; text: string; utcValue: string }[]>(Config.urls.timeZone);
    }

    public getHydrograph() {
        return this.http.get(Config.urls.hydrograph);
    }

    public getPipeElements() {
        return this.http.get(Config.urls.customers.pipeElements);
    }

    public getFlowDataGraphics() {
        return this.http.get(Config.urls.customers.flowDataGraphics);
    }

    public getIsoQLines() {
        return this.http.get(Config.urls.customers.isoQLines);
    }

    public getDayStatusAnalysis() {
        return this.http.get(Config.urls.customers.dayStatusAnalysis);
    }

    public getAnalysisBatch() {
        return this.http.get(Config.urls.customers.analysisBatch);
    }

    public getDayStausReviewers() {
        return this.http.get(Config.urls.customers.dayStausReviewers);
    }

    /**
     * Iterates over a collection of customers and attempts to mark the active customer as well as updating the observable.
     * @param selectedCustomerId the selected customer identifier.
     * @param customers the collection of customers.
     */
    public updatedGetCustomersWithLocations(
        selectedCustomerId?: number,
        customers?: Array<GetPermissionsResponseCustomer>,
    ) {
        // attempt to mark active customers
        this.markActiveCustomer(selectedCustomerId, customers);

        // emit only associated customers
        this.userCustomersWithLocations.next(customers);
    }

    /**
     * Iterates over a collection of customers and attempts to mark the active customer.
     * @param selectedCustomerId the selected customer identifier.
     * @param customers the collection of customers.
     */
    public markActiveCustomer(selectedCustomerId: number, customers: Array<GetPermissionsResponseCustomer>) {
        // iterate over each customer
        customers.forEach((customer: GetPermissionsResponseCustomer) => {
            // check if selected id's match

            // check if selected id's match
            if (customer.customer !== undefined && customer.customer.customerID === selectedCustomerId) {
                // set id for matched customer
                customer.isCurrentlySelected = true;

                // exit immediatly
                return;
            } else {
                if (customer.customerID === selectedCustomerId) {
                    // set id for matched customer
                    customer.isCurrentlySelected = true;

                    // exit immediatly
                    return;
                }
            }
        });
    }
    /**
     * Customer summary details
     */
    // TODO: Old endpoint, remove in future
    public getCustomerSummary(
        startPage?: number,
        pageSize?: number,
        serachValue?: string,
        isActive?: string,
        assignedFeatureCustomers?,
        includeDisabled?: boolean,
    ) {
        let postFixUrl,
            isActiveFilter,
            featureUrl = '';
        const url = '?PageSize=' + pageSize + '&StartPage=' + startPage + '&includeDisabled=' + includeDisabled;
        serachValue ? (postFixUrl = '&SearchValue=' + serachValue) : (postFixUrl = '');
        isActive ? (isActiveFilter = '&IsActive=' + isActive) : (isActiveFilter = '');
        if (assignedFeatureCustomers) {
            for (let i = 0; i < assignedFeatureCustomers.length; i++) {
                featureUrl += '&Features=' + assignedFeatureCustomers[i];
            }
        }
        return this.http.get(Config.urls.customerSummary + url + postFixUrl + isActiveFilter + featureUrl).pipe(
            map((customers: GetCustomers) => {
                this.summaryRowCount = customers.totalCount;
                this.customerSummary = customers.customSummary.map(this.toCustomerSummary);
                return this.customerSummary;
            }),
        );
    }

    public getCustomerSummaryV2(
        startPage?: number,
        pageSize?: number,
        serachValue?: string,
        isActive?: string,
        subscriptionLevels?: number[],
        assignedAddOns?: number[],
        includeDisabled?: boolean,
    ) {
        let postFixUrl,
            isActiveFilter,
            subscriptionLevelsUrl = '',
            assignedAddOnsUrl = '';
        const url = '?PageSize=' + pageSize + '&StartPage=' + startPage + '&includeDisabled=' + includeDisabled;
        serachValue ? (postFixUrl = '&SearchValue=' + serachValue) : (postFixUrl = '');
        isActive ? (isActiveFilter = '&IsActive=' + isActive) : (isActiveFilter = '');
        if (subscriptionLevels) {
            for (let i = 0; i < subscriptionLevels.length; i++) {
                subscriptionLevelsUrl += '&subscriptionLevels=' + subscriptionLevels[i];
            }
        }
        if (assignedAddOns) {
            for (let i = 0; i < assignedAddOns.length; i++) {
                assignedAddOnsUrl += '&addOns=' + assignedAddOns[i];
            }
        }
        return this.http.get(Config.urls.customerSummaryV2 + url + postFixUrl + isActiveFilter + subscriptionLevelsUrl + assignedAddOnsUrl).pipe(
            map((customers: GetCustomers) => {
                this.summaryRowCount = customers.totalCount;
                this.customerSummary = customers.customSummary.map(this.toCustomerSummary);
                return this.customerSummary;
            }),
        );
    }

    /**
     * Custom Alarms Summary details
     */
    public getCustomAlarms(lid) {
        let currentCustomerID;

        this.statusCodeService.addNewCustomer.subscribe((addNewCustomerFlag: boolean) => {
            // get current query params for customer
            if (addNewCustomerFlag) {
                currentCustomerID = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));
            } else {
                currentCustomerID = Number(this.activatedRoute.snapshot.queryParamMap.get(customerEditorQueryParam));
            }
        });

        // setup request options
        // creating HttpParams for HttpClient request

        const httpParams: HttpParams = new HttpParams();
        return this.getCustomAlarmsForCustomer(lid, currentCustomerID);
    }

    public getCustomAlarmsForCustomer(lid: number, cid: number) {
        const httpParams: HttpParams = new HttpParams();
        return this.http.get(
            Config.serviceUrl + Config.urls.customAlarms + '?customerId=' + cid + '&locationId=' + lid,
            { params: httpParams },
        );
    }

    /**
     * Get All RainGauge Details
     */
    public getAllRainGauges(params: any) {
        // setup request options
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams

        Object.keys(params).forEach(function (key) {
            httpParams = httpParams.append(key, params[key]);
        });

        return this.http.get(Config.urls.rainLocations+ `?cid=${params.CID}&includeInactiveLocations=${params.IncludeInactiveLocations}&validateLocations=false`);
    }

    public enableCustomAlarms(customAlarms: any, customerId: number) {
        return this.http.post(Config.serviceUrl + Config.urls.customAlarms + `?customerId=${customerId}`, customAlarms);
    }

    public updateCustomAlarms(customAlarms: any, customerId: number) {
        return this.http.put(Config.serviceUrl + Config.urls.customAlarms + `?customerId=${customerId}`, customAlarms);
    }

    /**users => users.map(this.toUser)
     * Convert user info from the API to our standard/format
     */
    private toCustomerSummary(customer): GetCustomerSummary {
        return {
            id: customer.id,
            name: customer.name,
            summary: customer.summary,
            activeLocations: customer.summary.active || 0,
            inactiveLocations: customer.summary.inactive || 0,
            active: customer.active,
            addOns: customer.addOns,
            subscriptionLevel: customer.subscriptionLevel
        };
    }
    /**
     * Customer summary details
     */
    public updateCustomerFeature(data: any) {
        return this.http.post(Config.urls.permission, data);
    }

    /**
     * Get Storm day event details of customer
     * @param customerID
     */
    public getStormDefinition(customerId: number) {
        const query = `cid=${customerId}`;
        return this.http.get(`${Config.urls.stormDefinition}?${query}`);
    }

    public getDepthDuration(customerId: number) {
        const query = `cid=${customerId}`;
        return this.http.get(`${Config.urls.depthDurationFrequency}?${query}`);
    }
    public saveDepthDuration(customerId: number, ddfData) {
        const query = `cid=${customerId}`;
        return this.http.post(`${Config.urls.depthDurationFrequency}?${query}`, ddfData);
    }
    public getWasteWaterMethod(customerId: number) {
        const query = `cid=${customerId}`;
        return this.http.get(`${Config.urls.WasteWaterMethod}?${query}`);
    }
    public saveWasteWaterMethod(customerId: number, method) {
        const query = `cid=${customerId}`;
        return this.http.post(`${Config.urls.WasteWaterMethod}?${query}`, method);
    }
    /**
     * Get Dry day event details of customer
     * @param customerID
     */
    public getDryDayDefinition(customerId: number) {
        const query = `cid=${customerId}`;
        return this.http.get(`${Config.urls.dryDayDefinition}?${query}`);
    }

    /**
     * create Dry day definition
     * @param customerID
     */
    public createDryDefinition(customerId: number, dryDefinitionDetails) {
        const query = `cid=${customerId}`;
        return this.http.post(`${Config.urls.dryDayDefinition}?${query}`, dryDefinitionDetails);
    }

    /**
     * create storm definition
     * @param customerID
     */
    public createStormDefinition(customerId: number, stormDefinitionDetails) {
        const query = `cid=${customerId}`;
        return this.http.post(`${Config.urls.stormDefinition}?${query}`, stormDefinitionDetails);
    }
    /**
     * Activates / deactivate customers
     */
    public activateStatus(customerId: number, activeState: boolean) {
        const postfixActivestatus = '/activestatus?isActive=';
        const headers = new Headers();
        headers.append('Content-Type', 'charset=utf-8');
        const url = '/' + Number(customerId) + postfixActivestatus + activeState;
        const res = this.http.put<any>(Config.serviceUrl + Config.urls.activateCustomer + url, { headers: headers });

        catchError(() => {
            return res;
        });
        return res;
    }

    public getCustomerUnits(customerId: number) {
        const gpdUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.GPD_TRACER_UNITS');
        const lpdUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.LPD_TRACER_UNITS');
        const lpdVolumeUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_UNITS');
        const velocityUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.VELOCITY_UNITS');
        const velocityUsUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.VELOCITY_US_UNITS');
        const cfsUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.CFS_TRACER_UNITS');
        const cfsLowerFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.CFS_LOWER_UNITS');
        const cfsVolumeUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.QFLOW_UNITS');
        const cfsSliicerVolumeFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.CUBIC_FEET');
        const mgdUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS');
        const mgdVolumeUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.MGD_FLOW_UNITS');
        const flowUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS');
        const metricUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS');
        const usUnitsFromJson = this.translate.instant('HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS');

        return this.http.get<Customer>(Config.serviceUrl + Config.urls.locationGroup.prefixUrl + `${customerId}`).pipe(
            map((res) => {
                if (res) {
                    this.customer = res;
                    if (Number(this.customer.unitsType) === UnitOfMeasureType.CFS) {
                        this.unit = <CustomerUnits>{
                            depthUnit: `(${usUnitsFromJson})`,
                            velocityUnit: `(${velocityUsUnitsFromJson})`,
                            volumeUnit: `(${cfsVolumeUnitsFromJson})`,
                            sliicerVolumeUnit: `(${cfsSliicerVolumeFromJson})`,
                            sliicerFlowUnit: `(${cfsLowerFromJson})`,
                            flowUnit: `(${cfsUnitsFromJson})`,
                            flowSmallerUnit: `(${gpdUnitsFromJson})`,
                        };
                    } else if (Number(this.customer.unitsType) === UnitOfMeasureType.MGD) {
                        this.unit = <CustomerUnits>{
                            depthUnit: `(${usUnitsFromJson})`,
                            velocityUnit: `(${velocityUsUnitsFromJson})`,
                            flowUnit: `(${mgdUnitsFromJson})`,
                            sliicerFlowUnit: `(${mgdUnitsFromJson})`,
                            volumeUnit: `(${mgdVolumeUnitsFromJson})`,
                            sliicerVolumeUnit: `(${mgdVolumeUnitsFromJson})`,
                            flowSmallerUnit: `(${gpdUnitsFromJson})`,
                        };
                    } else {
                        this.unit = <CustomerUnits>{
                            depthUnit: `(${metricUnitsFromJson})`,
                            velocityUnit: `(${velocityUnitsFromJson})`,
                            volumeUnit: `(${lpdVolumeUnitsFromJson})`,
                            sliicerVolumeUnit: `(${lpdVolumeUnitsFromJson})`,
                            flowUnit: `(${flowUnitsFromJson})`,
                            sliicerFlowUnit: `(${flowUnitsFromJson})`,
                            flowSmallerUnit: `(${lpdUnitsFromJson})`,
                        };
                    }
                    return this.unit;
                }
            }),
        );
    }

    public getProjectDetails(customerId: number): Observable<ProjectDetails> {
        return this.http.get<ProjectDetails>(`${Config.serviceUrl}${Config.urls.customerProject}?cid=${customerId}`);
    }

    public updateProjectDetails(customerId: number, data: UpdateProjectDetails): Observable<void> {
        return this.http.put<void>(`${Config.serviceUrl}${Config.urls.customerProject}?cid=${customerId}`, data);
    }

    public getSliicerUserConfig(customerId: number): Observable<SliicerUserConfig> {
        return this.http.get<SliicerUserConfig>(`${Config.serviceUrl}${Config.urls.sliicerUserConfig}/${customerId}`);
    }

    public postSliicerUserConfig(customerId: number, config: SliicerUserConfig) {
        return this.http.post(`${Config.serviceUrl}${Config.urls.sliicerUserConfig}/${customerId}`, config);
    }

    public getSubscriptionTiers() {
        return this.http.get<SubscriptionTier[]>(`${Config.urls.subscriptionTiers}`);
    }

    public getSubscriptionInfo() {
        return this.http.get<SubscriptionsInfo>(`${Config.urls.subscriptionsInfo}`);
    }
}
