import { formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BillingReportsService } from 'app/shared/services/billing-reports.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import saveAs from 'file-saver';
import moment from 'moment';
import { Subscription } from 'rxjs';

const today = new Date();

@Component({
  selector: 'app-admin-cost-allocation',
  templateUrl: './admin-cost-allocation.component.html',
  styleUrls: ['./admin-cost-allocation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminCostAllocationComponent implements OnInit, OnDestroy {
    public startDate = new Date(
        today.getFullYear() - (today.getMonth() > 0 ? 0 : 1),
        (today.getMonth() - 1 + 12) % 12,
        1,
    );
    public endDate = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1);

    private subscriptions: Subscription[] = [];

    public translations = {
        generatingReportText: '',
        dateRangePlaceholder: '',
        dismissText: '',
        noData: '',
    }

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private snackBar: MatSnackBar,
        private snackBarService: SnackBarNotificationService,
        private billingReportService: BillingReportsService,
        public dialogRef: MatDialogRef<AdminCostAllocationComponent>,
        private translate: TranslateService,
    ) {

    }

    ngOnInit(): void {
        this.translations.generatingReportText = this.translate.instant('ADMIN.ADMIN_COST_ALLOCATION.GENERATING_REPORT');

        this.translations.dismissText = this.translate.instant('COMMON.DISMISS_TEXT');
        this.translations.noData = this.translate.instant('ADMIN.ADMIN_COST_ALLOCATION.NO_COST_ALLOCATION_DATA');
        this.translations.dateRangePlaceholder = this.translate.instant('LOCATION_DASHBOARD.DATE_RANGE_SPAN');
    }

    public onStartDateChange(event: Date) {
        this.startDate = event;
    }

    public onEndDateChange(event: Date) {
        this.endDate = event;
    }

    public generateReport() {
        this.snackBar.open(this.translations.generatingReportText, this.translations.dismissText, {
            duration: 5000,
        });

        // TODO: Replace with correct API call
        this.billingReportService.getCostAllocationReportForTimeSpan(this.startDate, this.endDate).subscribe((file) => {
            if (file) {
                console.log('file', file)
                const nameDateFormat = 'YYYYMMDD';
                const startDate = moment(this.startDate).format(nameDateFormat);
                const endDate = moment(this.endDate).format(nameDateFormat);

                const name = `Cost_Allocation_Report_${startDate}-${endDate}.csv`;
                const blob = new Blob([file], {type: 'text/csv' });

                saveAs(blob, name);
            }
            else {
                const start = formatDate(this.startDate, 'yyyy-MM-dd', this.locale);
                const end = formatDate(this.endDate, 'yyyy-MM-dd', this.locale);
                this.snackBarService.raiseNotification(
                    `${this.translations.noData} ${start} and ${end}.`,
                    this.translations.dismissText,
                    null,
                    false,
                );
            }

        });

        this.close();
    }

    public cancel() {
        this.close();
    }

    private close() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
