import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Config } from './config';
import { HttpClient } from '@angular/common/http';
import { UsersInfo } from '../models/users';

@Injectable()
export class SharedService {
    /**
     * Use to avoid extra entity calls
     */
    public isEntityRequired = new BehaviorSubject<boolean>(false);
    /**
     * Represents adminUserData which needs to be emitted to admin detail screen.
     */

    public adminUserData = new BehaviorSubject<Object>({});

    /**
     * Represents userInfo corresponding to User.
     */

    constructor(private http: HttpClient) {}

    /**
     * Represents userInfo corresponding to User.
     */
    public userInfo = new BehaviorSubject<UsersInfo>({});
    /**
     * Represents Close button click event in collection widget schedule component.
     */
    private closeDataSource = new BehaviorSubject<boolean>(false);
    public currentValue = this.closeDataSource.asObservable();

    /**
     * Represents vault directory change status.
     */
    private clearDataSource = new BehaviorSubject<boolean>(false);
    public currentClearValue = this.clearDataSource.asObservable();

    /**
     * Represents location group editor close status.
     */
    private locationGroupDataSource = new BehaviorSubject<boolean>(false);
    public currentLocationGroupValue = this.locationGroupDataSource.asObservable();

    /**
     * Represents customer user association close status.
     */
    private userAssociationDataSource = new BehaviorSubject<boolean>(false);
    public currentUserAssociationValue = this.userAssociationDataSource.asObservable();
    /**
  /* File upload display message
  */
    private displayMessageSource = new BehaviorSubject<string>('');
    public currentMessage = this.displayMessageSource.asObservable();

    /**
     * Below function will set the initial value for the statusCodeFlag
     */
    public setCloseFlagValue(flag: boolean) {
        this.closeDataSource.next(flag);
    }

    /**
     * Below function will set the initial value for the clearDataSource
     */
    public changeMessage(flag: boolean) {
        this.clearDataSource.next(flag);
    }

    /**
     * Below function will set the initial value for the locationGroupDataSource
     */
    public closeLocationGroup(flag: boolean) {
        this.locationGroupDataSource.next(flag);
    }

    /**
     * Below function will set the initial value for the userAssociationDataSource
     */
    public closeUserAssociation(flag: boolean) {
        this.userAssociationDataSource.next(flag);
    }

    /* Will set the upload message
     * @param message
     */
    public setFileUploadMessage(message: string) {
        this.displayMessageSource.next(message);
    }

    /**
     * Get all features for user & customer
     */
    public getFeatures() {
        return this.http.get(Config.urls.features1);
    }

    public getAllFeatures() {
        return this.http.get(Config.urls.allAvailableFeatures);
    }
}
