export const environment = {
    production: true,
    apiUrl: window.location.origin + '/',
    serviceUrl: 'https://prod-api.adsprism.net/',
    geoserverUrl: 'https://prod-api.adsprism.net/',
    domainURL: 'https://www.adsprism.com/',
    legacySliicerImportUrl: 'https://ads-sliicer-legacy-import.adsprod3.p.azurewebsites.net/',
    powerBIServiceUrl: 'https://adspowerbi.adsprod3.p.azurewebsites.net/',
    exportStormUrl: 'https://ads-sliicer-data-export-api.azurewebsites.net/',
    adalConfig: {
        tenant: 'adswastewater.onmicrosoft.com',
        clientId: '6b3c3863-2d40-4328-8bd8-9f65a21980ab',
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: window.location.origin + '/',
    },
    mapApiKey: 'AIzaSyBlTbBKMgykRlUEwoqM1oH8XslO9lrCVhk',
    showOnlyCrowdcore: false,
    showImportExportButtons: false,
    disableTestUser: true,
    disableTestItems: true,
    appInsights: {
        instrumentationKey: 'e963a06b-5423-44ea-8f04-8533c6292d57',
    },
    enableSliicer: true,
    enableDataEditing: false,
    enableFlowMonitor: false,
    exportStormUserId: 'ADS',
    exportStormPassword: 'PintaNinaSantaMaria',
    workOrderLink: 'https://adsenv.quickbase.com/db/bpnhhkbd6',
    cookiesScriptUrl: 'https://cdn-cookieyes.com/client_data/6af1af4ac0e136fd46c25619/script.js',
    flexmonsterLicense: 'Z7UN-XH061T-5J1J3J-2L0L1I-5A1U3J-3J5Z58-5G2S1V-0Y011R-6O3D3J',
    lcLicenseNumber: '0002-vWuASiHbeA5vT5IPmC8B8fGHkYkvtwBqgEw1/XIWA8CAdDgk3ZAOTI7vg3ldjXJzi10U+pB6UvE2kjGn4tHzo5nTLc5es2pm86fiFFIPzowYR3iohJ5AKfl374DjzWHTpSgKimqsCQs8lXXOp0PNcbBnAFNUxvOfktlhV0gDxwjyGWuLQmU3tGU2EsHiGvDwB1//+qG/jmK03KwZE5V+oo2CY/CxNhqFcuNc7cDKBhf+3lImEvhUWjQUey2kNyAPsOXLnGHZMoRmQiEubRs=-MEYCIQDsU613LxHC6AGmyKY+/TFgS7OWQ7IPFjZwZSmto69CpgIhALE1FzaRhdj/6Hlh9dSwRlBA5exeP5xxCOgKZuRepFjz',
    clarityKey: 'jngi57pznj'
};
