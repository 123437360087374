export const environment = {
    production: false,
    apiUrl: 'https://alpha2-api.adsprism.net/',
    serviceUrl: 'https://alpha2-api.adsprism.net/',
    geoserverUrl: 'https://alpha2-api.adsprism.net/',
    domainURL: 'https://alpha2.adsprism.net/',
    legacySliicerImportUrl: 'https://ads-sliicer-legacy-import-dev.adsprod3.p.azurewebsites.net/',
    powerBIServiceUrl: 'https://adspowerbi-dev.adsprod3.p.azurewebsites.net/',
    exportStormUrl: 'https://ads-sliicer-data-export-api-dev.azurewebsites.net/',
    adalConfig: {
        tenant: 'adswastewater.onmicrosoft.com',
        clientId: '6b3c3863-2d40-4328-8bd8-9f65a21980ab',
        redirectUri: window.location.origin + '/',
        postLogoutRedirectUri: window.location.origin + '/',
    },
    mapApiKey: 'AIzaSyBlTbBKMgykRlUEwoqM1oH8XslO9lrCVhk',
    showOnlyCrowdcore: false,
    appInsights: {
        instrumentationKey: 'e963a06b-5423-44ea-8f04-8533c6292d57',
    },
    disableTestUser: false,
    showImportExportButtons: true,
    disableTestItems: false,
    enableSliicer: true,
    enableDataEditing: true,
    enableFlowMonitor: true,
    exportStormUserId: 'ADS',
    exportStormPassword: 'PintaNinaSantaMaria',
    workOrderLink: 'https://adsenv.quickbase.com/db/bpnhhkbd6',
    cookiesScriptUrl: 'https://cdn-cookieyes.com/client_data/cfd74dfd640c097c71f1f1a7/script.js',
    flexmonsterLicense: 'Z7DQ-XJ2C2T-1J1V4T-455J5Y-0Q6105-545H6I-2K3L1R-5Z1N2U-4S4G1Z-6C5Z6S-1Z1Q6B',
    lcLicenseNumber: '0002-vV8d4aezuLk6ooyyWwgSkRCpyjVGNwFeHeezlLmnEWdshzNMJqxWjQxL6vDE0nco/9ufqBEa9OlzqfivgJHlVv+NIoCAe/240B9GlomHl05NNhT248zkcWEqq2Sq0nQ3FtHHSy+EwVuDnjWa0dHjtMlmK0WmUJ8oKP7/rB/N0rLdNMKSxXopJPIRWt+y0FkWRokPDCDO+g7zCJzKd5uBMPWlp65yjR8sxZVlWp9v3dv35XPJQpiNeTUYA2+htmqQX7fL/1geczqLBAN3K7as55isvqRjXySNOF4zvc6l5YAAa79SAcwkpmg8k0Ou+oUyeq0N2QB/vAK5cJg1dAfV2kRvMYRg8GZ1OlScA+vDWkWoI6i6dvm7CeJHOw3qKzthFvFUpLO6IIwQnA7kNP3omIiWrWSBvHwnC1xIyUDGiuX/c/KgExZwy5hMxlrnbw==-MEYCIQD6PaJSzHfxQ8YBvZOD4MvqmgJ7nnIqIHWLWhrIc8iwYgIhAIMWD07/ZzHwJDc1AAhWJ7xkiCgSTy/7mJXQd9HPpKI0',
    clarityKey: ''
};
