<div id="bpDailySummaryReportId">
    <div class="topmenu">
    </div>
    <mat-card>
        <mat-card-header>
            <div class="flex align-center gap-10">
                <span class="daily-title-hint">{{ 'BLOCKAGE_PREDICT.DAILY_SUMMARY' | translate }}</span>
                <span>
                    <mat-form-field class="full-width start-date">
                        <input matInput [readonly]="true" [value]="reportDate | date: customerDateFormat" [max]="today"
                            (change)="setDate($event)" tabindex="-1" class="datepickerStyles" appDatetimepickerPosition
                            [matDatetimepickerComp]="bpdatepicker"  />
                        <input hidden [matDatetimepicker]="bpdatepicker" (dateInput)="setDate($event)" [max]="today" />
                        <mat-datetimepicker-toggle matSuffix [for]="bpdatepicker">
                        </mat-datetimepicker-toggle>
                        <mat-datetimepicker #bpdatepicker timeInterval="1" type="date">
                        </mat-datetimepicker>
                    </mat-form-field>
                </span>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button class="daily-download-hint" mat-button (click)="downloadCSV()">
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
        </mat-card-header>

        <mat-card-content>
            <mat-form-field class="userSearch ml-15">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input matInput type="text" placeholder="{{ 'ADMIN.AUDIT_LOG.USER_SEARCH_PLACEHOLDER' | translate }}"
                    [formControl]="searchString" />
                <mat-icon class="clickable" matTooltip="Clear" matSuffix *ngIf="searchString && searchString.value" (click)="clearSearch()"
                    style="vertical-align: middle;">clear</mat-icon
                >
            </mat-form-field>
            <div *ngIf="isLoading; else loaded">
                <app-loader [isLoading]="isLoading"></app-loader>
            </div>

            <ng-template #loaded>
                <div class="bp-summary-content">
                    <div id="location-dashbord-synopsis-leaderBoard" class="bp-summary-synopsys-wrapper">
                        <mat-tab-group>
                            <mat-tab class="hide">
                                <ng-template mat-tab-label class="hide"> </ng-template>
                            </mat-tab>

                            <mat-tab disabled *ngFor="let item of synopsisData; index as i">
                                <ng-template mat-tab-label>
                                    <div class="location-dashbord-synopsis" [ngClass]="item.className">
                                        <div class="lB-item-top">
                                            <div class="lB-item-top-rgt">
                                                <div class="item-col">
                                                    <div class="item-content">
                                                        <div class="item-percent">
                                                            {{ item.percent }}
                                                        </div>
                                                        <div class="item-total-label">
                                                            {{ item.total }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="lB-item-but">
                                            <span>{{ item.name }}</span>
                                        </div>
                                    </div>
                                </ng-template>
                            </mat-tab>
                        </mat-tab-group>
                    </div>

                    <div class="bp-divider"></div>

                    <div id="dataTable" *ngIf="!isLoading && dataSource.data.length > 0">
                        <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

                            <ng-container matColumnDef="customerName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.CUSTOMER_NAME' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.custname }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="locationName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.LOCATION_NAME' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.lname }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cid">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.CID' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.cid }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lid">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.LID' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.lid }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="series">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.SERIES' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.ms }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="pipeInfo">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.PIPE_INFO' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.lookupdesc }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="bpScore">
                                <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ 'BLOCKAGE_PREDICT.BP_SCORE_TOOLTIP' | translate }}">{{ 'BLOCKAGE_PREDICT.BP_SCORE' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.bpscore }}%
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="bpFlag">
                                <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ 'BLOCKAGE_PREDICT.BP_MODE_TOOLTIP' | translate }}">{{ 'BLOCKAGE_PREDICT.BP_FLAG' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.bpflag + ' - ' }} {{ row.bpflag | bpMode }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="bpType">
                                <mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ 'BLOCKAGE_PREDICT.BP_MODE_TOOLTIP' | translate }}">{{ 'BLOCKAGE_PREDICT.BP_TYPE' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.bptype + ' - ' }} {{ row.bptype | bpMode }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="bpDate">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'BLOCKAGE_PREDICT.BP_DATE' | translate }}</mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    {{ row.bpdate | date : bpDateFormat }}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let row" class="word-overflow-break">
                                    <div class="bp-summary-action">
                                        <button mat-icon-button>
                                            <img src="/assets/images/icons/map-icon.png" alt="">
                                        </button>
                                        <button mat-icon-button>
                                            <img src="/assets/images/icons/cardio-icon.png" alt="">
                                        </button>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
                        </mat-table>
                    </div>
                    <div class="notabledataFound custom-table" *ngIf="!isLoading && dataSource.data.length === 0">
                        {{ 'ADMIN.NO_DATA_AVAILABLE' | translate }}
                    </div>
                    <div class="notabledataFound custom-table" *ngIf="isLoading">
                        {{ 'COMMON.LOADING' | translate }}
                    </div>
                </div>
            </ng-template>
        </mat-card-content>
        <mat-card-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <mat-paginator [length]="paginationConfig.length" [pageIndex]="paginationConfig.pageIndex" [pageSize]="paginationConfig.pageSize"
                    [pageSizeOptions]="[50, 100, 1000]"
                    (page)="changePage($event)">
                </mat-paginator>
            </div>
        </mat-card-actions>
    </mat-card>
</div>