import { environment } from 'app/environments/environment';
import { MenuItem } from './menu-item';
import { DialogQueryParams } from 'app/shared/models/customer'

export enum NavigationMenuTitles {
  HOME = 'NAVIGATION.HOME',
  DASHBOARDS = 'NAVIGATION.DASHBOARDS',
  CUSTOM_DASHBOARD = 'NAVIGATION.CUSTOM_DASHBOARD',
  LOCATIONS = 'NAVIGATION.LOCATIONS',
  NOTIFICATION_DASHBOARD = 'NAVIGATION.NOTIFICATION_DASHBOARD',
  SCHEDULE_COLLECT = 'NAVIGATION.SCHEDULE_COLLECT',
  VAULT = 'NAVIGATION.VAULT',
  REPORTS = 'NAVIGATION.REPORTS',
  AUDIT_LOG = 'NAVIGATION.AUDIT_LOG',
  DAILY_SUMMARY = 'NAVIGATION.DAILY_SUMMARY',
  DATA_COMMUNICATION = 'NAVIGATION.DATA_COMMUNICATION',
  DATA_EDITING_AUDIT_REPORT = 'NAVIGATION.DATA_EDITING_AUDIT_REPORT',
  PERCENT_FULL = 'NAVIGATION.PERCENT_FULL',
  UPTIME = 'NAVIGATION.UPTIME',
  ANALYTICS = 'NAVIGATION.ANALYTICS',
  SLIICER = 'NAVIGATION.SLICER',
  ADMIN = 'NAVIGATION.ADMIN',
  USERS = 'NAVIGATION.USERS',
  ALERTS = 'NAVIGATION.ALERTS',
  COST_ALLOCATION = 'COST ALLOCATION',
  USAGE = 'NAVIGATION.USAGE',
  BLOCKAGE_PREDICT = 'NAVIGATION.BLOCKAGE_PREDICT',
  CUSTOMER_USER_MANAGER = 'NAVIGATION.CUSTOMER_USER_MANAGER',
  LOCATION_NOTIFICATION_CONFIGURATION = 'NAVIGATION.LOCATION_NOTIFICATION_CONFIGURATION',
  LOCATION_CONFIGURATION_REPORT = 'NAVIGATION.LOCATION_CONFIGURATION_REPORT',
  RAIN_TOTAL_REPORT = 'NAVIGATION.RAIN_TOTAL_REPORT',
  RAIN_FALL_FREQUENCY = 'NAVIGATION.RAIN_FALL_FREQUENCY',
  HUMAN_REVIEW = 'NAVIGATION.HUMAN_REVIEW'
}


// TODO AMP: quick dirty way to pull 'data_editing_audit_report' from menu in prod
// delete bottom function and uncomment this to restore

/*export const menuItemSetup: MenuItem[] =
  environment.showOnlyCrowdcore === true ?

    [new MenuItem({ title: 'CrowdCore', link: '/crowdcore', icon: 'poll' })]
    :
    [
      new MenuItem({ title: NavigationMenuTitles.HOME, link: '/', icon: 'home' }),
      new MenuItem(NavigationMenuTitles.DASHBOARDS, null, [
        new MenuItem({ title: NavigationMenuTitles.CUSTOM_DASHBOARD, link: '/pages/menuDashboard/custom-dashboard', icon: '' }),
        new MenuItem({ title: NavigationMenuTitles.LOCATIONS, link: '/pages/menuDashboard', icon: '' }),
        new MenuItem({ title: NavigationMenuTitles.NOTIFICATION_DASHBOARD, link: '/pages/menuDashboard/notifications', icon: '' }),
        new MenuItem({ title: NavigationMenuTitles.SCHEDULE_COLLECT, link: '/pages/menuDashboard/schedule-collect/overview', icon: '' }),
      ], null, null, 'description'),
      new MenuItem({ title: NavigationMenuTitles.VAULT, link: '/pages/vault/list', icon: 'folder' }),
      new MenuItem(NavigationMenuTitles.REPORTS, null, [
        new MenuItem({ title: 'NAVIGATION.AUDIT_LOG', link: '/pages/report/audit', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.DAILY_SUMMARY', link: '/pages/report', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.DATA_COMMUNICATION', link: '/pages/datacomm/report', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.DATA_EDITING_AUDIT_REPORT', link: '/pages/report/data-editing-audit', icon: '' }),
        new MenuItem({ title: 'FLOW_MONITORING.TITLE', link: '/pages/report/flowMonitoringReport', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.PERCENT_FULL', link: '/pages/report/percent-full/overview', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.UPTIME', link: '/pages/report/uptime/overview', icon: '' })
      ], null, null, 'description'),
      new MenuItem('NAVIGATION.ANALYTICS', null, [
        new MenuItem({ title: 'NAVIGATION.SLICER', link: '/pages/sliicer', icon: '' })
      ], null, null, ''),
      new MenuItem('NAVIGATION.ADMIN', null, [
        new MenuItem({ title: 'NAVIGATION.USERS', link: '/pages/admin', icon: '' }),
      ], null, null, 'supervisor account')
    ];
*/

export const menuItemSetup: MenuItem[] = environment.disableTestItems
    ? // Hide a few options in production
      environment.showOnlyCrowdcore === true
        ? [new MenuItem({ title: 'CrowdCore', link: '/crowdcore', icon: 'poll' })]
        : [
              new MenuItem({ title: NavigationMenuTitles.HOME, link: '/', icon: 'home' }),
              new MenuItem(
                  NavigationMenuTitles.DASHBOARDS,
                  null,
                  [
                      new MenuItem({
                          title: NavigationMenuTitles.CUSTOM_DASHBOARD,
                          link: '/pages/menuDashboard/custom-dashboard',
                          icon: '',
                      }),
                      new MenuItem({ title: NavigationMenuTitles.LOCATIONS, link: '/pages/menuDashboard', icon: '' }),
                      new MenuItem({
                          title: NavigationMenuTitles.NOTIFICATION_DASHBOARD,
                          link: '/pages/menuDashboard/notifications',
                          icon: '',
                      }),
                      new MenuItem({
                          title: NavigationMenuTitles.SCHEDULE_COLLECT,
                          link: '/pages/menuDashboard/schedule-collect/overview',
                          icon: '',
                      }),
                  ],
                  null,
                  null,
                  'description',
              ),
              new MenuItem({ title: NavigationMenuTitles.VAULT, link: '/pages/vault/list', icon: 'folder' }),
              new MenuItem(
                  NavigationMenuTitles.REPORTS,
                  null,
                  [
                      new MenuItem({ title: NavigationMenuTitles.AUDIT_LOG, link: '/pages/report/audit', icon: '' }),
                      new MenuItem({ title: NavigationMenuTitles.DAILY_SUMMARY, link: '/pages/report', icon: '' }),
                      new MenuItem({
                        title: NavigationMenuTitles.RAIN_TOTAL_REPORT,
                        link: '/pages/report/daily-rain-report',
                        icon: '',
                      }),
                      new MenuItem({
                          title: NavigationMenuTitles.DATA_EDITING_AUDIT_REPORT,
                          link: '/pages/report/data-editing-audit',
                          icon: '',
                      }),
                      new MenuItem({
                          title: 'FLOW_MONITORING.TITLE',
                          link: '/pages/report/flowMonitoringReport',
                          icon: '',
                      }),
                      new MenuItem({ title: NavigationMenuTitles.LOCATION_NOTIFICATION_CONFIGURATION, link: '/pages/report/notificationConfiguration', icon: '' }),
                      new MenuItem({
                        title: NavigationMenuTitles.LOCATION_CONFIGURATION_REPORT,
                        link: '/pages/report/locationConfiguration',
                        icon: '',
                      }),
                      new MenuItem({
                          title: NavigationMenuTitles.PERCENT_FULL,
                          link: '/pages/report/percent-full/overview',
                          icon: '',
                      }),
                      new MenuItem({
                        title: NavigationMenuTitles.RAIN_FALL_FREQUENCY,
                        link: '/pages/report/rainFallReport',
                        icon: '',
                      }),
                      new MenuItem({ title: NavigationMenuTitles.UPTIME, link: '/pages/report/uptime/overview', icon: '' }),
                  ],
                  null,
                  null,
                  'description',
              ),
              new MenuItem(
                  NavigationMenuTitles.ANALYTICS,
                  null,
                  [new MenuItem({ title: NavigationMenuTitles.SLIICER, link: '/pages/sliicer', icon: '' })],
                  null,
                  null,
                  '',
              ),
              new MenuItem(
                  NavigationMenuTitles.ADMIN,
                  null,
                  [
                    new MenuItem({ title: NavigationMenuTitles.USERS, link: '/pages/admin', icon: '' }),
                    new MenuItem({ title: NavigationMenuTitles.ALERTS, link: '/pages/adminalert', icon: '' }),
                    new MenuItem(NavigationMenuTitles.BLOCKAGE_PREDICT, null, [
                      new MenuItem({ title: NavigationMenuTitles.DAILY_SUMMARY, link: '/pages/blockage-predict/daily-summary', icon: '' }),
                      new MenuItem({ title: NavigationMenuTitles.HUMAN_REVIEW, link: '/pages/blockage-predict/daily-summary', icon: '' }),
                    ]),
                    new MenuItem(NavigationMenuTitles.REPORTS, null, [
                        new MenuItem({ title: NavigationMenuTitles.COST_ALLOCATION, link: '/', queryParams: {dialog: DialogQueryParams.CostAllocation}, icon: ''}),
                        new MenuItem({ title: NavigationMenuTitles.USAGE, link: '/', queryParams: {dialog: DialogQueryParams.Usage}, icon: ''})
                    ]),
                  ],
                  null,
                  null,
                  'supervisor account',
              ),
              new MenuItem({ title: NavigationMenuTitles.CUSTOMER_USER_MANAGER, link: '/pages/customerUserManager', icon: 'group' }),
          ]
    : environment.showOnlyCrowdcore === true
    ? [new MenuItem({ title: 'CrowdCore', link: '/crowdcore', icon: 'poll' })]
    : [
          new MenuItem({ title: NavigationMenuTitles.HOME, link: '/', icon: 'home' }),
          new MenuItem(
              NavigationMenuTitles.DASHBOARDS,
              null,
              [
                  new MenuItem({
                      title: NavigationMenuTitles.CUSTOM_DASHBOARD,
                      link: '/pages/menuDashboard/custom-dashboard',
                      icon: '',
                  }),
                  new MenuItem({ title: NavigationMenuTitles.LOCATIONS, link: '/pages/menuDashboard', icon: '' }),
                  new MenuItem({
                      title: NavigationMenuTitles.NOTIFICATION_DASHBOARD,
                      link: '/pages/menuDashboard/notifications',
                      icon: '',
                  }),
                  new MenuItem({
                      title: NavigationMenuTitles.SCHEDULE_COLLECT,
                      link: '/pages/menuDashboard/schedule-collect/overview',
                      icon: '',
                  }),
              ],
              null,
              null,
              'description',
          ),
          new MenuItem({ title: NavigationMenuTitles.VAULT, link: '/pages/vault/list', icon: 'folder' }),
          new MenuItem(
              NavigationMenuTitles.REPORTS,
              null,
              [
                  new MenuItem({ title: NavigationMenuTitles.AUDIT_LOG, link: '/pages/report/audit', icon: '' }),
                  new MenuItem({ title: NavigationMenuTitles.DAILY_SUMMARY, link: '/pages/report', icon: '' }),
                  new MenuItem({
                    title: NavigationMenuTitles.RAIN_TOTAL_REPORT,
                    link: '/pages/report/daily-rain-report',
                    icon: '',
                  }),
                  new MenuItem({
                      title: NavigationMenuTitles.DATA_EDITING_AUDIT_REPORT,
                      link: '/pages/report/data-editing-audit',
                      icon: '',
                  }),
                  new MenuItem({
                      title: 'FLOW_MONITORING.TITLE',
                      link: '/pages/report/flowMonitoringReport',
                      icon: '',
                  }),
                  new MenuItem({ title: NavigationMenuTitles.LOCATION_NOTIFICATION_CONFIGURATION, link: '/pages/report/notificationConfiguration', icon: '' }),
                  new MenuItem({
                    title: NavigationMenuTitles.LOCATION_CONFIGURATION_REPORT,
                    link: '/pages/report/locationConfiguration',
                    icon: '',
                  }),
                  new MenuItem({
                      title: NavigationMenuTitles.PERCENT_FULL,
                      link: '/pages/report/percent-full/overview',
                      icon: '',
                  }),
                  new MenuItem({
                    title: NavigationMenuTitles.RAIN_FALL_FREQUENCY,
                    link: '/pages/report/rainFallReport',
                    icon: '',
                  }),
                  new MenuItem({ title: NavigationMenuTitles.UPTIME, link: '/pages/report/uptime/overview', icon: '' }),
              ],
              null,
              null,
              'description',
          ),
          new MenuItem(
              NavigationMenuTitles.ANALYTICS,
              null,
              [new MenuItem({ title: NavigationMenuTitles.SLIICER, link: '/pages/sliicer', icon: '' })],
              null,
              null,
              '',
          ),
          new MenuItem(
              NavigationMenuTitles.ADMIN,
              null,
              [
                new MenuItem({ title: NavigationMenuTitles.USERS, link: '/pages/admin', icon: '' }),
                new MenuItem({ title: NavigationMenuTitles.ALERTS, link: '/pages/adminalert', icon: '' }),
                new MenuItem(NavigationMenuTitles.BLOCKAGE_PREDICT, null, [
                  new MenuItem({ title: NavigationMenuTitles.DAILY_SUMMARY, link: '/pages/blockage-predict/daily-summary', icon: '' }),
                  new MenuItem({ title: NavigationMenuTitles.HUMAN_REVIEW, link: '/pages/blockage-predict/daily-summary', icon: '' }),
                ]),
                new MenuItem(NavigationMenuTitles.REPORTS, null, [
                    new MenuItem({ title: NavigationMenuTitles.COST_ALLOCATION, link: '/', queryParams: {dialog: DialogQueryParams.CostAllocation}, icon: ''}),
                    new MenuItem({ title: NavigationMenuTitles.USAGE, link: '/', queryParams: {dialog: DialogQueryParams.Usage}, icon: ''})
                ]),
              ],
              null,
              null,
              'supervisor account',
          ),
          new MenuItem({ title: NavigationMenuTitles.CUSTOMER_USER_MANAGER, link: '/pages/customerUserManager', icon: 'group' }),
      ];
