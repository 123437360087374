import { BehaviorSubject } from "rxjs";

export interface BasicSeriesData {
    entityId: number;
    displayGroupId: number;
    entityName: string;
    axisName: string;
    unitOfMeasure: string;
    dataType: DataType;
    color: string;
    precision: number;
    data: HGGraphData[];
    annotations: {
        id: number;
        name: string;
        color: string;
        unitOfMeasure: string;
        precision: number;
        data?: HGGraphData[];
    }[];
    start?: Date;
    end?: Date;
    lid?: number;
    originLocationId?: number;      // when user loads other locations data, this will indicate the location on the location dashboard at time
    syncDates?: boolean;            // used only with secondary locations data, to know whether we need to sync its date range with LD's date range
    id?: number;                    // used only with secondary locations data
}
export interface HGEditParams {
    entity: BasicSeriesData;
    action: ActionTypes;
}
export interface HGGraphData {
    x: number;
    y: number;
    correctedY?: number;
    quality?: number;
    edited?: boolean;
    flagged?: boolean;
    ignored?: boolean;
    interpolated?: boolean;
    selected?: boolean;
    snapped?: boolean;
    interpolationAccepted?: boolean;
    index?: number;
}

export enum DataType {
    Annotation = -1,
    Other = 0, // Scalar, PressK, Angle, Float
    Depth = 1,
    Velocity = 2,
    Quantity = 3, // Previously flow
    PumpFlow = 4,
    Rain = 5,
    RainIntensity = 6,
    Temperature = 7,
    Voltage = 8,
    Sample = 9,
    ElapsedTime = 13,
    Feet = 14,
    Sound = 15,
    Elevation = 16,
    TotalFlow = 99,
}


export enum AnnotationType {
    ManholeDepth = 104,
    PipeHeight = 100,
    MaxHeightWeir = 101,
    MaxHeightFlume = 102,
    MaxHeightChannel = 103,
    Silt = 1707,
    HighHigh = 2,
    HighLevel = 1,
    LowDepth = 3,
    HighFlow = 4
}
// #38811 Pipe height and theirs replacements for Locations that does not have Pipe Height
export const AnnotationPipeHeights = [AnnotationType.PipeHeight, AnnotationType.MaxHeightWeir, AnnotationType.MaxHeightFlume, AnnotationType.MaxHeightChannel];

export enum AnnotationStringType {
    ManholeDepth = 'manholeDepth',
    PipeHeight = 'pipeHeight',
    Silt = 'silt',
    HighHigh = 'highHigh',
    HighLevel = 'highLevel',
    HighFlow = 'highFlow',
    LowDepth = 'lowDepth',
}

export interface AnnotationOptions {
    manholeDepth: boolean;
    pipeHeight: boolean;
    silt: boolean;
    showEdits: boolean;
    highHigh: boolean;
    highFlow: boolean;
    highLevel: boolean;
    lowDepth: boolean;
    confirmationPoints: boolean;
    showDataQuality: boolean;
    flipRain: boolean;
    isShowEvents: boolean;
}

export interface GraphExtents {
    min: number;
    max: number;
}
export interface GraphLines {
    title: string;
    value: number;
}
export interface EditButton {
    tooltip: string;
    icon?: string;
    class?: string;
    iconUrl?: string;
    enabled: boolean;
    action: ActionTypes;
    permission: 'basic' | 'advanced';
    /** Whenever button should be disabled */
    inactive?: () => boolean;
}

export enum ActionTypes {
    CopyPaste,
    DragDrop,
    Flag,
    MultiPointEdit,
    Unflag,
    Zoom,
    Interpolate,
    ClearSelection,
}

export enum ManipulationActions {
    Add = 'ADD_TEXT',
    Subtract = 'SUBTRACT_TEXT',
    Multiply = 'MULTIPLY_TEXT',
    Divide = 'DIVIDE_TEXT',
    Equal = 'EQUAL_TEXT',
}

export interface CopyPasteDestinationAction {
    entity: CopyPasteEntity;
    date: Date;
    action?: ManipulationActions;
    value?: number;
}

export interface CopyPasteEntity {
    entityId: number;
    name: string;
}
