import {
    Component,
    ViewChild,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CustomerFeatureComponent } from 'app/pages/admin/customer-feature/customer-feature.component';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { GetCustomerFeatureResponse, GetCustomerSummary } from 'app/shared/models/customer';
import { CustomerService } from 'app/shared/services/customer.service';
import { SharedService } from 'app/shared/services/shared.service';
import { WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-error-report',
    templateUrl: './error-report.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorReportComponent implements OnInit, OnDestroy {
    public searchString = new FormControl();
    public customers = new Array<GetCustomerSummary>();
    public customerSummary = new Array<GetCustomerSummary>();
    private subscriptions = new Array<Subscription>();
    public customerDataChange: BehaviorSubject<GetCustomerSummary[]> = new BehaviorSubject<GetCustomerSummary[]>([]);
    public initialData = new BehaviorSubject<GetCustomerSummary[]>([]);
    public customerDataSource: MatTableDataSource<GetCustomerSummary>;
    public totalPaginationLength: number;
    @ViewChild(MatPaginator) public userPaginator: MatPaginator;
    @ViewChild(MatSort) public customerSort: MatSort;
    public customerFilterColumns = ['name', 'activeLocations', 'inactiveLocations', 'features', 'editCustomer'];
    public customerEdited: number;

    /**
     * Indicates the loading state of the component.
     */
    public isLoading: boolean;
    public allCustomerFeatures: GetCustomerFeatureResponse;

    public customerID: number;
    public locationGroupID: number;

    public displayFilters: boolean;

    /**
     * Variable which represents the start date
     */
    public startDate: Date;

    /**
     * Variable which represents the end date
     */
    public endDate: Date;

    constructor(
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private sharedService: SharedService,
        private customerService: CustomerService,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
    ) {
        /*  Search by user name & roles  */
        this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterCustomers(res);
            });
    }

    public ngOnInit() {
        this.isLoading = true;
        this.getCustomerSummary();
        this.statusCodeService.customerIdInEditScreen.subscribe((customerId: number) => {
            this.customerEdited = customerId;
        });
        // get all features for user/customer
        const customeFeatureSubscription = this.sharedService
            .getFeatures()
            .subscribe((response: GetCustomerFeatureResponse) => {
                this.allCustomerFeatures = response;
            });
        this.subscriptions.push(customeFeatureSubscription);
        this.clearCustomerSelection();
    }

    public showFilter() {
        this.displayFilters = !this.displayFilters;
    }

    /**
     * Checks on Change of Filter Data;
     * @param filtersData
     */
    public notifyReport(filtersData: WidgetFilterData) {
        let startDate = null;
        let endDate = null;
        if (filtersData.startDate) {
            startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('/');
        }
        if (filtersData.endDate) {
            endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('/');
        }
        this.endDate = endDate;
        this.startDate = startDate;
    }

    public getCustomerSummary() {
        // get all customer summary
        const customersummarySubscription = this.customerService.getCustomerSummary().subscribe(
            (customerSummaryData: GetCustomerSummary[]) => {
                if (customerSummaryData) {
                    this.customers = this.customerSummary = customerSummaryData;
                    this.customerDataChange.next(this.customers);
                    this.initialData.next(this.customers);
                    this.generateUserTable();
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
        this.subscriptions.push(customersummarySubscription);
    }

    /**
     * Method takes a sting as an argument and filters out customers whose name include the string
     * @param searchString - string to search for in customer name
     */
    public filterCustomers(searchString: string) {
        this.initialData.subscribe((result) => {
            this.customers = result;
        });
        if (!searchString || searchString.trim() === '') {
            this.customers = this.customers;
        } else {
            searchString = searchString.trim();
            this.customers = this.customers.filter((x) => x.name.toLowerCase().includes(searchString.toLowerCase()));
            this.userPaginator.pageIndex = 0;
        }
        this.generateUserTable();
    }

    /**
     * Method generates a table of customers
     */
    public generateUserTable() {
        this.customerDataSource = null;
        this.customerSummary = [];
        this.customerDataChange = new BehaviorSubject<GetCustomerSummary[]>([]);
        this.customers.forEach((customer, index) => {
            const customerCopiedData = this.customerSummary;

            // Flag to show the edited row
            let highLight = false;

            if (this.customerEdited === customer.id && this.customerEdited !== null) {
                const pageIndex = index / this.userPaginator.pageSize;
                const finalPageIndex = +pageIndex.toString().split('.')[0];
                this.userPaginator.pageIndex = finalPageIndex;
                highLight = true;
            }
            const data = {
                id: customer.id,
                name: customer.name,
                activeLocations: +customer.activeLocations,
                inactiveLocations: +customer.inactiveLocations,
                summary: customer.summary,
                highLight: highLight,
                addOns: customer.addOns,
                active: customer.active,
            };
            customerCopiedData.push(data);
            this.customerDataChange.next(customerCopiedData);
        });

        this.customerDataSource = new MatTableDataSource(this.customerSummary);
        this.customerDataSource.paginator = this.userPaginator;
        this.customerDataSource.sort = this.customerSort;
        this.totalPaginationLength = this.customerSummary.length;
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Destroy the subscription data
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Method brings up a pop up window with customer Features
     * @param customer the customer data object.
     */
    public editCustomerFeatures(customer: GetCustomerSummary) {
        // Open the dialog component with the features list
        this.matDialog
            .open(CustomerFeatureComponent, {
                disableClose: true,
                data: {
                    customer: customer,
                    featureList: this.allCustomerFeatures,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.success) {
                    this.isLoading = true;
                    this.getCustomerSummary();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
    }

    public clearCustomerSelection() {
        this.customerEdited = null;
    }
}
