import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationButton, ConfirmationDialogComponent } from '../../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UUID } from 'angular2-uuid';
import { Observable, Subscription } from 'rxjs';
import { CustomerService } from 'app/shared/services/customer.service';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { BehaviorSubject } from 'rxjs';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { FEATURE_IDS, USER_ROLES, UsersService } from 'app/pages/admin/users.service';
import { FormControl, NgForm } from '@angular/forms';
import { UsersInfo, UsersDetailInfo, IFeatures } from 'app/shared/models/users';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { CompositeLocComponent } from 'app/shared/components/map/composite-loc/composite-loc.component';
import { environment } from 'app/environments/environment';
const LOCATION_DETAIL_HINT = 'LOCATION_DETAIL_HINT';
import { UnitOfMeasureType } from 'app/shared/constant';
import { IDLE_MESSAGE_MINUTES } from 'app/shared/constant';
import { MapService } from 'app/shared/services/map.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import {
    AppQueryParams,
    CollectProcessType,
    Customer,
    customerEditorQueryParam,
    customerQueryParam,
    GetCustomerSummary,
    ProjectDetailsAdditionalFeature,
    SUBSCRIPTION_LEVELS,
    SubscriptionLevel,
    SubscriptionTier,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
    userSearchParam,
} from 'app/shared/models/customer';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { AddLocationModalData } from 'app/shared/models/add-location';
import { GetUsersResponse } from 'app/shared/models/users-permission';
import { AddLocationComponent } from 'app/shared/components/map/add-location/add-location.component';
import { debounceTime, distinctUntilChanged, first, tap } from 'rxjs/operators';
import { GISService } from 'app/shared/services/gis-service';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { AutoCompleteItem, Selectable } from 'app/shared/models/selectable';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { MatLegacySelectChange } from '@angular/material/legacy-select';

const ROUGHNESS_COEFFICIENT_DEFAULT_VALUE = 0.013;
const nullDateYear = '0001-01-01T00:00:00';

@Component({
    selector: 'app-edit-customer-editor',
    templateUrl: './edit-customer-editor.component.html',
    styleUrls: ['./edit-customer-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class EditCustomerEditorComponent implements OnInit, OnDestroy {
    public showUpdateCustomerEditor: boolean;
    public showNewCustomerEditor: boolean;
    public customers: UsersDetailInfo[];
    public customer: Customer;
    public pageIndex: number;
    public isValidCustomerName: boolean;
    public isCustomerNameErrorDisplay: boolean;
    public customerNameHeader: string;
    public isValidCustomerShortName: boolean;
    public customerName: string;
    public shortName: string;
    public flowViewFlag: boolean;
    public featureChange: boolean;
    public rainfallProfileChange: boolean;
    public usersChange: boolean;
    public units: number;
    public customerID: number;
    public dataTTL = 12;
    public isActive: boolean;
    public unitsofMeasure: any[];
    public dateFormatType: any[];
    public timeFormatType: any[];
    public timeZoneType: Array<Object>;
    public dateFormat: string;
    public timeFormat: string;
    public workOrderLink: string;
    public idleTimeOut: number;
    public timeZone: any;
    public daylightSavingFlag: boolean;
    public headerName = 'CUSTOMER EDITOR';
    public uuid: UUID;
    public pagedItems = new Array<Customer>();
    public setFirstPage: boolean;
    public showPagination = true;
    public apiError: string;
    private subscriptions = new Array<Subscription>();
    public showNewLocationEditor: boolean;
    public disableAddLocationBtn: boolean;
    public customerNameLengthIsInvalid: boolean;
    public customerShortNameLengthIsInvalid: boolean;
    public customersLoadingState: boolean;
    public customerEditorDisplayedColumns = ['customerName', 'editDeleteCustomerEditor'];
    public customerEditorFilterColumns = ['customerName'];
    public customerEditorDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator) public customerEditorPaginator: MatPaginator;
    @ViewChild(MatSort) public customerEditorSort: MatSort;
    public customerEditorDataChange: BehaviorSubject<UsersDetailInfo[]> = new BehaviorSubject<UsersDetailInfo[]>([]);
    public userRole: boolean;
    public get customerEditorData(): UsersDetailInfo[] {
        return this.customerEditorDataChange.value;
    }
    public totalPaginationLength: number;
    public customerStatus: boolean;
    public metricValue = 3;
    /**
     * Reg Ex. Pattern for alpha numeric with -, _ and at least 2 letters and first character must be a letter or number
     */
    public customerNamePattern = REGEX_CONFIG.customerNamePattern;

    /**
     * Reg Ex. Pattern for alpha numeric without space
     */
    public alphaNumericWithoutSpacePattern = REGEX_CONFIG.alphaNumericWithoutSpacePattern;

    /**
     * Reg Ex. Pattern for alpha numeric without space
     */
    public numeric3_36Pattern = REGEX_CONFIG.numeric3_36Pattern;

    /**
     * Reg Ex. Pattern for alpha numeric without space
     */
    public validLinkPattern = REGEX_CONFIG.validLinkPattern;

    public numericWith5DecimalPlaces = REGEX_CONFIG.numericWith5DecimalPlaces;
    /**
     * Reprsents customer search input
     */
    public searchString = new FormControl();

    /**
     * Represents saved list of customers
     */
    public initialCustomersData = new Array<UsersDetailInfo>();

    /**
     * Represents the last edited customer
     */
    public selectedCustomerID: number;

    public isApiKeyEnabled: boolean;

    public isCustomAlarmingEnabled: boolean;

    public isCustomAlaramRainGaugesAvailable: boolean;

    public startPage: number;
    public pageSize: number;
    public searchValue: string;
    public dismissText: string;
    public customerEditorHeader: string;
    public addCustomerHeader: string;
    public customerUpdateSuccess: string;
    public customerUpdateErr: string;
    public customerCreateErr: string;
    public customerText: string;
    public archiveDialogTitle: string;
    public archieveDialogMsgStart: string;
    public archieveDialogMsgEnd: string;
    public successfullyText: string;
    public createdMsg: string;
    public updatedMsg: string;
    public archiveBtn: string;
    public cancelBtn: string;
    public archivedSuccessMsg: string;
    public editCustomerHeader: string;
    public sliicerPagepermission: boolean;
    public isAssignUserVisible: boolean;
    public hasSliicerEnabled: boolean;
    public isMonitorCanAddEdit: boolean;
    public tabIndex = 0;
    public redirectUser: boolean;

    public roughness: number = ROUGHNESS_COEFFICIENT_DEFAULT_VALUE;

    public usersSelectable: Array<AutoCompleteItem>;

    //GIS
    public gisServiceId: number;
    public servicelist: [{ id: number; name: string; service: string }];
    public contractNumber: string;
    public subscriptionLevel: number;
    public originalContractStart: Date;
    public currentContractStart: Date;
    public currentContractEnd: Date;
    public originalNamesAutoComplete = {
        dataAnalyst: null,
        regionalManager: null,
        salesPerson: null,
        projectManager: null
    }
    public originalSubscriptionLevel: number;
    public dataAnalyst: AutoCompleteItem;
    public regionalManager: AutoCompleteItem;
    public salesPerson: AutoCompleteItem;
    public projectManager: AutoCompleteItem;
    public markedInactiveOn: Date;
    public subscriptionLevelAlternatives = SUBSCRIPTION_LEVELS;
    public customerDateFormat: string;
    public users: string[] = [];
    /** #39310 Whenever auto complete fields was NOT modified */
    public autoCompleteModified = false;
    public fieldsModified = false;
    public additionalFeaturesList: ProjectDetailsAdditionalFeature[];
    public SubscriptionLevel = SubscriptionLevel;

    @ViewChild('addCustomerForm') public customerForm: NgForm;

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private customerService: CustomerService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private usersService: UsersService,
        private dialog: MatDialog,
        private locationDashboardSvc: LocationDashboardService,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private mapService: MapService,
        private gisService: GISService,
        private locationCardService: LocationCardService
    ) {
        /*  Search by user email address changes  */
        const searchStrgSubscription = this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterCustomers(res);
            });
        this.subscriptions.push(searchStrgSubscription);
        this.hasSliicerEnabled = environment.enableSliicer || false;
    }

    public ngOnInit() {
        this.mapService.isMapLocationEdit = false;
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_EDITOR_HEADING').subscribe((res: string) => {
            this.customerEditorHeader = res;
            this.headerName = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ADD_CUSTOMER_HEADING').subscribe((res: string) => {
            this.addCustomerHeader = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_UPDATE_SUCCESS').subscribe((res: string) => {
            this.customerUpdateSuccess = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_UPDATE_ERR').subscribe((res: string) => {
            this.customerUpdateErr = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_CREATE_ERR').subscribe((res: string) => {
            this.customerCreateErr = res;
        });
        this.translate.get('COMMON.CUSTOMER_TEXT').subscribe((res: string) => {
            this.customerText = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVE_DIALOG_TITLE').subscribe((res: string) => {
            this.archiveDialogTitle = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVE_DIALOG_MSG_START').subscribe((res: string) => {
            this.archieveDialogMsgStart = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVE_DIALOG_MSG_END').subscribe((res: string) => {
            this.archieveDialogMsgEnd = res;
        });
        this.translate.get('COMMON.SUCCESSFULLY_TEXT').subscribe((res: string) => {
            this.successfullyText = res;
        });
        this.translate.get('COMMON.CREATED_TEXT').subscribe((res: string) => {
            this.createdMsg = res;
        });
        this.translate.get('COMMON.UPDATED_TEXT').subscribe((res: string) => {
            this.updatedMsg = res;
        });
        this.translate.get('COMMON.ARCHIVE_BTN').subscribe((res: string) => {
            this.archiveBtn = res;
        });
        this.translate.get('COMMON.CANCEL_BUTTON').subscribe((res: string) => {
            this.cancelBtn = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVED_SUCCESS').subscribe((res: string) => {
            this.archivedSuccessMsg = res;
        });
        this.translate.get('CUSTOMER_EDITOR.EDIT_CUSTOMER_HEADING').subscribe((res: string) => {
            this.editCustomerHeader = res;
        });
        this.usersService.isMonitorEditor.subscribe((response) => {
            this.isMonitorCanAddEdit = response;
        });
        this.statusCodeService.customerStatus.subscribe((customerStatus: boolean) => {
            this.customerStatus = customerStatus;
        });
        this.statusCodeService.editedCustomerData.subscribe((custmerResponse: GetCustomerSummary) => {
            this.customerNameHeader = custmerResponse.name;
        });
        this.customersLoadingState = true;
        this.isApiKeyEnabled = false;
        this.isCustomAlarmingEnabled = false;
        this.isCustomAlaramRainGaugesAvailable = false;
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.searchValue = params.get(userSearchParam);
            this.pageIndex = Number(params.get(userSearchPageIndexParam));
            this.pageSize = Number(params.get(userSearchPageSizeParam));
        });
        this.tabIndex = this.statusCodeService.directUser ? 3 : 0;
        this.domOperationUtilsService.editCustomerTabIndex.next(this.tabIndex);
        // Subscribing to the isCustomAlarmsEnabled flag for no raingauges found
        const customAlarmsSubscription = this.statusCodeService.isCustomAlarmEnabled.subscribe((response: boolean) => {
            this.manageCustomAlarmsTab(response);
        });
        this.subscriptions.push(customAlarmsSubscription);

        // Subscribing to the result value from permission API

        const userInfoSubscription = this.statusCodeService.userInfo.subscribe(
            (result: UsersInfo) => {
                if (result.customers) {
                    this.customers = result.customers.map((x) => x.customer);
                }

                if (result.userRole) {
                    this.userRole = result.userRole.toString() === USER_ROLES.ADMIN;
                    this.isAssignUserVisible =
                        result.userRole.toString() === USER_ROLES.ADMIN || result.userRole.toString() === USER_ROLES.CUSTOMER_ADMIN;
                } else {
                    this.userRole = false;
                }

                if(result?.userRole?.toString() === USER_ROLES.CUSTOMER_ADMIN && !this.userRole){
                    const featuresSubscription = this.usersService.getAllFeatures().subscribe((features: Array<IFeatures>) => {
                        this.userRole = features?.some((x) => !x.canassigncustomer && x.id === FEATURE_IDS.CUSTOMER_EDITOR);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.subscriptions.push(featuresSubscription);
                    });
                }

                this.initialCustomersData = this.customers;

                this.generateCustomerEditorTable();
                if (this.searchString.value !== '') {
                    this.filterCustomers(this.searchString.value);
                }
                this.customersLoadingState = false;
            },
            () => {
                this.customersLoadingState = false;
            },
        );
        this.subscriptions.push(userInfoSubscription);
        this.unitsofMeasure = [
            { value: 1, text: 'US Standard CFS' },
            { value: 3, text: 'US Standard MGD' },
            { value: 2, text: 'Metric' },
        ];
        this.dateFormatType = [
            { value: 'MM/DD/YYYY', text: 'MM/DD/YYYY' },
            { value: 'DD/MM/YYYY', text: 'DD/MM/YYYY' },
            { value: 'YYYY/MM/DD', text: 'YYYY/MM/DD' },
        ];
        this.timeFormatType = [
            { value: 'h:mm:ss tt', text: '12 hours' },
            { value: 'hh:mm:ss', text: '24 hours' },
        ];
        const timeZoneSubscription = this.customerService.getTimeZone().subscribe((res) => {
            this.timeZoneType = res;
        });
        this.subscriptions.push(timeZoneSubscription);

        this.showNewLocationEditor = false;
        this.generateCustomerEditorTable();
        this.statusCodeService.directUser = false;

        // Called when user edits customer on customer editor screen.
        this.subscriptions.push(this.statusCodeService.customerIdEdited.pipe(distinctUntilChanged()).subscribe((customerID: number) => {
            if (customerID > 0) {
                this.getCustomerPermission(customerID);
            }
        }));
        // get current query params for customer
        const currentCustomerID = Number(this.activatedRoute.snapshot.queryParamMap.get(customerEditorQueryParam));

        this.updateCustomer(currentCustomerID);
        if (this.customerID > 0) {
            this.usersService.isSliicerAllowed.subscribe((isAllowed) => {
                this.sliicerPagepermission = isAllowed.value;
            });
        }
        this.setUnit();
        this.servicelist = [{ id: 1, name: 'WFS', service: 'https://geo-dev.adsprism.net/geoserver/WFS' }];

        this.dateutilService.dateFormat.subscribe(() => {
            this.customerDateFormat = this.dateutilService.getFormat();
        });

        if (currentCustomerID) {
            this.customersLoadingState = true;
            const customerusersSubscription: Subscription = this.usersService
                .getAllUsers(1, 1, null, currentCustomerID)
                .subscribe((response: GetUsersResponse) => {
                    if (response && response.payload && response.payload.length > 0) {
                        const allUsersSubscription: Subscription = this.usersService
                            .getAllUsers(response.count, 1, null, currentCustomerID)
                            .subscribe(
                                (secondResponse: GetUsersResponse) => {
                                    if (secondResponse && secondResponse.payload && secondResponse.payload.length > 0) {
                                        this.users = secondResponse.payload
                                            .filter((user) => user.isActive)
                                            .map((user) => `${user.firstName}  ${user.lastName}  (${user.userName})`)
                                            .filter((user) => StringUtils.removeWhitespace(user).length)
                                            .sort(StringUtils.caseInsensitiveSort);
                                        this.usersSelectable = this.users.map(u => {return {name: u}});
                                    } else {
                                        this.users = [];
                                        this.usersSelectable = [];
                                    }

                                    this.customersLoadingState = false;
                                },
                                () => {},
                            );
                        this.subscriptions.push(allUsersSubscription);
                    } else {
                        this.users = [];
                        this.usersSelectable = [];
                    }
                });
            this.subscriptions.push(customerusersSubscription);
        }

    }

    public ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public scrollTop() {
        this.domOperationUtilsService.scrollToTop('#customerGroupEditorTable');
    }

    public onPaginateChange() {
        this.scrollTop();
    }

    public openCreateCustomerEditor() {
        this.scrollTop();
        this.headerName = this.addCustomerHeader;
        this.showNewCustomerEditor = true;
        this.showUpdateCustomerEditor = false;
        this.resetCustomerForm();
    }

    /**
     * Method takes a sting as an argument and filters out customers whose names include the string
     * @param searchString - string to search for customer
     */
    public filterCustomers(searchString: string) {
        if (!searchString || searchString.trim() === '') {
            this.customers = this.initialCustomersData;
        } else {
            searchString = searchString.trim();
            this.customers = this.initialCustomersData.filter((x) =>
                x.customerName.toLowerCase().includes(searchString.toLowerCase()),
            );
            this.customerEditorPaginator.pageIndex = 0;
        }
        this.generateCustomerEditorTable();
    }

    public showCustomerPageRecords(pagedItems: Customer[]) {
        this.pagedItems = pagedItems;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public featureselected(featureclick: boolean) {
        this.featureChange = featureclick;
    }

    public rainfallProfileChanged(rainfallProfileSelected: boolean) {
        this.rainfallProfileChange = rainfallProfileSelected;
    }

    public usersSelected(usersSelected: boolean) {
        this.usersChange = usersSelected;
    }


    public validateDuplicateCustomerName() {
        this.customerName = this.customerName.trim();
        this.customerName = StringUtils.capitalizedStringWithSpace(this.customerName);
        this.customerNameLengthIsInvalid = this.customerName.length < 6;
        if (this.customerName !== null) {
            if (this.customers.find((x) => x.customerName.toLowerCase() === this.customerName.toLowerCase())) {
                if (this.showUpdateCustomerEditor && this.customer.customerName === this.customerName) {
                    this.isValidCustomerName = true;
                } else {
                    this.isValidCustomerName = false;
                }
            } else {
                this.customerName = this.customerName.charAt(0).toUpperCase() + this.customerName.slice(1);
                this.isValidCustomerName = true;
            }
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public validateShortDuplicateCustomerName() {
        this.shortName = this.shortName.trim();
        this.customerShortNameLengthIsInvalid = this.shortName.length < 3;
        if (this.customers.find((x) => x.shortName && x.shortName.toLowerCase() === this.shortName.toLowerCase())) {
            this.isValidCustomerShortName = false;
        } else {
            this.isValidCustomerShortName = true;
        }
    }

    public addCustomer() {
        if(this.isSubscirpitionLevelChange()) {
            this.dialogConfirmSubscriptionLevel().subscribe((btn) => {
                if(btn.whichButtonWasPressed === ConfirmationButton.ok) {
                    this.apiSendCustomer();
                }
            })
        } else {
            this.apiSendCustomer();
        }
    }

    public onSaveAdditionalFeatures(features: ProjectDetailsAdditionalFeature[]) {
        this.additionalFeaturesList = features;

        this.customersLoadingState = true;
        this.apiUpdateProjectDetails().subscribe({
            next: () => {
                this.customersLoadingState = false;

                this.snackBar.open(this.customerName + this.customerUpdateSuccess, this.dismissText, {
                    duration: 10000,
                });
                this.statusCodeService.customerIdInEditScreen.next(this.customerID);
                this.statusCodeService.customerIdInEditName.next(this.customerName);

                this.exitCustomerEditor(new Event('click'));
            },
            error: (error) => {
                this.customersLoadingState = false;
                this.apiError = error._body;
                this.snackBar.open(this.apiError, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                });
            }
    });
    }

    public apiUpdateProjectDetails() {
        return this.customerService
            .updateProjectDetails(this.customer.customerID, {
                contractNumber: this.contractNumber,
                currentContractEnd: this.currentContractEnd,
                currentContractStart: this.currentContractStart,
                dataAnalyst: this.dataAnalyst?.name,
                markedInactiveOn: this.markedInactiveOn,
                originalContractStart: this.originalContractStart,
                projectManager: this.projectManager?.name,
                regionalManager: this.regionalManager?.name,
                salesPerson: this.salesPerson?.name,
                subscriptionLevel: this.subscriptionLevel,
                additionalFeaturesList: this.subscriptionLevel === SubscriptionLevel.PrismLimited ? [] : this.additionalFeaturesList
            })
            .pipe(
                tap(() => {
                this.originalNamesAutoComplete.dataAnalyst = this.dataAnalyst?.name;
                this.originalNamesAutoComplete.projectManager = this.projectManager?.name;
                this.originalNamesAutoComplete.regionalManager = this.regionalManager?.name;
                this.originalNamesAutoComplete.salesPerson = this.salesPerson?.name;

                this.originalSubscriptionLevel = this.subscriptionLevel;
            }));
    }

    public apiSendCustomer() {
        this.statusCodeService.customerIdInEditName.next(this.customerName);
        this.statusCodeService.customerIdInEditScreen.next(this.customerID);
        this.apiError = null;
        this.customersLoadingState = true;
        this.headerName = this.customerEditorHeader;
        const newCustomer = new Customer();
        newCustomer.customerName = this.customerName;
        if (!this.showUpdateCustomerEditor) {
            newCustomer.shortName = this.shortName;
        } else {
            newCustomer.customerID = this.customer.customerID;
            newCustomer.shortName = this.customer.shortName;
        }
        newCustomer.dataTTL = this.dataTTL;
        newCustomer.isActive = this.customerStatus;
        newCustomer.isNewCollect = false;
        newCustomer.collectProcessType = this.flowViewFlag
            ? CollectProcessType.FlowViewAndCore
            : CollectProcessType.Core;
        newCustomer.unitsType = this.units;
        newCustomer.dateFormat = this.dateFormat;
        newCustomer.timeFormat = this.timeFormat;
        newCustomer.timeZone = this.timeZone;
        newCustomer.isDaylightSavings = this.daylightSavingFlag || false;
        newCustomer.workOrderLink = this.workOrderLink;
        newCustomer.idleTimeOut = this.idleTimeOut || IDLE_MESSAGE_MINUTES;
        newCustomer.roughness = this.roughness;

        let message = null;

        if (this.showUpdateCustomerEditor) {
            message = this.updatedMsg;
            const subscription = this.customerService.updateCustomer(newCustomer, this.customer.customerID).subscribe(
                () => {
                    this.customersLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    // display toast message on successfully customer updation
                    this.snackBar.open(this.customerName + this.customerUpdateSuccess, this.dismissText, {
                        duration: 10000,
                    });
                    const event = new Event('click');
                    this.exitCustomerEditor(event);
                    this.dateutilService.dateFormat.next(newCustomer.dateFormat);
                    this.dateutilService.timeFormat.next(newCustomer.timeFormat);
                    this.selectedCustomerID = newCustomer.customerID;
                },
                (error) => {
                    this.customersLoadingState = false;
                    this.apiError = error._body;
                    this.snackBar.open(this.apiError, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
            this.subscriptions.push(subscription);
            this.apiUpdateProjectDetails().subscribe(() => {});
        } else {
            message = this.createdMsg;

            const addCustomerSubscription = this.customerService.addCustomer(newCustomer).subscribe(
                (response) => {
                    this.customersLoadingState = false;
                    if (response.customerID > 0) {
                        this.customer = response;
                        const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                            this.customerName + ' ' + this.customerText + message + this.successfullyText,
                            this.dismissText,
                        );
                        setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                        this.showNewCustomerEditor = false;
                        this.selectedCustomerID = response.customerID;
                        this.customerEditorPaginator.pageIndex = 0;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.searchString.setValue('');
                        const sub = this.customerService
                            .updateProjectDetails(response.customerID, {
                                contractNumber: this.contractNumber,
                                currentContractEnd: this.dateutilService.getLocalDateFromUTCDate(
                                    this.currentContractEnd,
                                ),
                                currentContractStart: this.dateutilService.getLocalDateFromUTCDate(
                                    this.currentContractStart,
                                ),
                                dataAnalyst: this.dataAnalyst?.name,
                                markedInactiveOn: this.markedInactiveOn,
                                originalContractStart: this.dateutilService.getLocalDateFromUTCDate(
                                    this.originalContractStart,
                                ),
                                projectManager: this.projectManager?.name,
                                regionalManager: this.regionalManager?.name,
                                salesPerson: this.salesPerson?.name,
                                subscriptionLevel: this.subscriptionLevel,
                            })
                            .subscribe(() => {
                                this.originalNamesAutoComplete.dataAnalyst = this.dataAnalyst?.name
                                this.originalNamesAutoComplete.projectManager = this.projectManager?.name
                                this.originalNamesAutoComplete.regionalManager = this.regionalManager?.name
                                this.originalNamesAutoComplete.salesPerson = this.salesPerson?.name

                                this.originalSubscriptionLevel = this.subscriptionLevel;
                            });

                        this.subscriptions.push(sub);
                    } else {
                        this.snackBar.open(this.customerCreateErr + this.customerName + '.', this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                },
                (error) => {
                    this.apiError = error._body;
                    this.snackBar.open(this.apiError, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                    this.customersLoadingState = false;
                },
            );

            this.subscriptions.push(addCustomerSubscription);
        }
    }

    public emitCustomerEditor() {
        this.searchString.setValue('');
    }

    private updateCustomer(customerId: number) {
        this.customersLoadingState = true;
        // set customer id for editing
        this.statusCodeService.customerIdEdited.next(customerId);
        this.selectedCustomerID = customerId;
        this.customerID = customerId;
        this.openCreateCustomerEditor();
        this.showUpdateCustomerEditor = true;
        this.scrollTop();
        this.headerName = this.editCustomerHeader;
        const getCustomerByIdSubscription = this.customerService.getCustomerById(customerId).subscribe((res) => {
            this.customersLoadingState = false;
            this.customer = res;
            this.customerName = this.customer.customerName;
            this.shortName = this.customer.shortName;
            this.customerID = this.customer.customerID;
            this.dataTTL = this.customer.dataTTL;
            this.isActive = this.customer.isActive;
            this.flowViewFlag = this.customer.collectProcessType === CollectProcessType.FlowViewAndCore;
            const findUnitType = this.unitsofMeasure.find((x) => Number(x.value) === Number(this.customer.unitsType));
            if (!findUnitType) {
                this.units = this.unitsofMeasure[0].value;
            } else {
                this.units = findUnitType.value;
            }
            // checking units are metric or non metric
            const isMetric = this.units && Number(this.units) === UnitOfMeasureType.METRIC;
            this.customerService.isUnitTypeMetric.next(isMetric);

            this.dateFormat = this.customer.dateFormat;
            this.timeFormat = this.customer.timeFormat;
            this.timeZone = this.customer.timeZone;
            this.workOrderLink = this.customer.workOrderLink ? this.customer.workOrderLink : environment.workOrderLink;
            this.idleTimeOut = this.customer.idleTimeOut || IDLE_MESSAGE_MINUTES;
            this.daylightSavingFlag = this.customer.isDaylightSavings;
            this.isValidCustomerName = true;
            this.isValidCustomerShortName = true;
            this.roughness = res.roughness.toString() ? res.roughness : ROUGHNESS_COEFFICIENT_DEFAULT_VALUE;
        });
        this.subscriptions.push(getCustomerByIdSubscription);

        const getProjectDetailsSubscription = this.customerService.getProjectDetails(customerId).subscribe(
            (res) => {
                this.contractNumber = res.contractNumber;
                this.subscriptionLevel = res.subscriptionLevel;
                this.originalContractStart =
                    res.originalContractStart && res.originalContractStart !== nullDateYear
                        ? new Date(res.originalContractStart)
                        : null;
                this.currentContractStart =
                    res.currentContractStart && res.currentContractStart !== nullDateYear
                        ? new Date(res.currentContractStart)
                        : null;
                this.currentContractEnd =
                    res.currentContractEnd && res.currentContractEnd !== nullDateYear
                        ? new Date(res.currentContractEnd)
                        : null;
                if(res.dataAnalyst) {
                    this.dataAnalyst = {name: res.dataAnalyst};
                } else {
                    this.dataAnalyst = undefined;
                }
                if(res.projectManager) {
                    this.projectManager = {name: res.projectManager};
                } else {
                    this.projectManager = undefined;
                }
                if(res.regionalManager) {
                    this.regionalManager = {name: res.regionalManager};
                } else {
                    this.regionalManager = undefined;
                }
                if(res.salesPerson) {
                    this.salesPerson = {name: res.salesPerson};
                } else {
                    this.salesPerson = undefined;
                }

                this.additionalFeaturesList = res?.additionalFeaturesList ?? [];

                this.originalNamesAutoComplete.dataAnalyst = res.dataAnalyst;
                this.originalNamesAutoComplete.projectManager = res.projectManager;
                this.originalNamesAutoComplete.regionalManager = res.regionalManager;
                this.originalNamesAutoComplete.salesPerson = res.salesPerson;

                this.originalSubscriptionLevel = this.subscriptionLevel;

                this.markedInactiveOn = res.markedInactiveOn;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                if (error.status === 404) {
                    //
                }
            },
        );

        this.subscriptions.push(getProjectDetailsSubscription);
    }

    private setSelectedCustomer(customerID: number) {
        if (customerID !== undefined) {
            this.customers.forEach((element) => {
                element.isSelected = element.customerID === customerID;
            });
            this.initialCustomersData.forEach((element) => {
                element.isSelected = element.customerID === customerID;
            });
            this.customerEditorData.forEach((element) => {
                element.isSelected = element.customerID === customerID;
            });
        }
    }

    public archiveCustomer(customerId: number, customerName: string) {
        const dialogSubsription = this.dialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.archiveDialogTitle,
                    message: this.archieveDialogMsgStart + customerName + this.archieveDialogMsgEnd,
                    okText: this.archiveBtn,
                    cancelText: this.cancelBtn,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === 'ok') {
                    this.customersLoadingState = true;
                    const subscription = this.customerService.archiveCustomer(customerId).subscribe(
                        (response) => {
                            this.customer = response;
                            this.customersLoadingState = false;
                        },
                        (error) => {
                            this.customersLoadingState = false;
                        },
                    );
                    this.subscriptions.push(subscription);

                    const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                        customerName + this.archivedSuccessMsg,
                        this.dismissText,
                    );
                    setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
        this.subscriptions.push(dialogSubsription);
    }
    public exitCustomerEditor(event) {
        event.preventDefault();
        // get current query params for customer
        const currentCustomerID = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));
        this.router.navigate(['/pages/customers'], {
            queryParams: <AppQueryParams>{
                c: currentCustomerID,
                s: this.searchValue ? this.searchValue : undefined,
                pi: !this.pageIndex ? 0 : this.pageIndex,
                ps: this.pageSize || undefined,
            },
            relativeTo: this.activatedRoute,
        });

        this.scrollTop();
        this.headerName = this.customerEditorHeader;
        this.showNewCustomerEditor = false;
        this.showUpdateCustomerEditor = false;
        this.setFirstPage = true;
        this.isValidCustomerName = null;
        this.isValidCustomerShortName = null;
        this.apiError = null;
        this.resetCustomerForm();
        this.setSelectedCustomer(currentCustomerID);
    }

    public resetCustomerForm() {
        this.customerNameLengthIsInvalid = false;
        this.customerShortNameLengthIsInvalid = false;

        if (this.showUpdateCustomerEditor) {
            this.customerName = this.customer.customerName;
            this.flowViewFlag = false;
            this.units = this.customer.unitsType;
            this.dateFormat = this.customer.dateFormat;
            this.timeFormat = this.customer.timeFormat;
            this.timeZone = this.customer.timeZone;
            this.workOrderLink = this.customer.workOrderLink;
            this.idleTimeOut = this.customer.idleTimeOut || IDLE_MESSAGE_MINUTES;
            this.daylightSavingFlag = this.customer.isDaylightSavings;
            this.isValidCustomerName = true;
            this.dataTTL = this.customer.dataTTL;
        } else {
            this.customerName = null;
            this.shortName = null;
            this.flowViewFlag = false;
            this.units = null;
            this.dateFormat = null;
            this.timeFormat = null;
            this.timeZone = null;
            this.workOrderLink = environment.workOrderLink;
            this.idleTimeOut = IDLE_MESSAGE_MINUTES;
            this.daylightSavingFlag = false;
            this.dataTTL = 12;
        }
    }

    public openNewLocationEditor() {
        //  disable "add new location" button on customer editor to prevent multiple dialogs being open
        this.disableAddLocationBtn = true;
        const getLocationsSubscription = this.locationDashboardSvc
            .getLocations(<LocationArgs>{ customerId: this.customerID })
            .subscribe((locations: Array<Locations>) => {
                // Variable to store rain gauge locations
                let rainGaugeLocations = new Array<Locations>();
                // check if locations exist
                if (locations) {
                    // Filter and set rain gauge locations
                    rainGaugeLocations =
                        locations.filter(
                            (location) => location.series && location.series.toLowerCase().indexOf('rainalert') > -1,
                        ) || new Array<Locations>();
                    rainGaugeLocations.sort(this.uiUtilsService.sortLocations);
                }
                // open add location dialog
                const addDialogSubscription = this.dialog
                    .open(AddLocationComponent, <MatDialogConfig>{
                        data: <AddLocationModalData>{
                            rainGaugeLocations: rainGaugeLocations,
                            customerId: this.customerID,
                            isFromCustomerEditor: true,
                        },
                        //  enable "add new location" button
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.disableAddLocationBtn = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.statusCodeService.isLocationCreated.next(true);
                    });
                this.subscriptions.push(addDialogSubscription);
            });
        this.subscriptions.push(getLocationsSubscription);
    }

    clearRegionalManager(event: MouseEvent) {
        this.regionalManager = undefined;
        this.originalNamesAutoComplete.regionalManager = undefined;
        this.autoCompleteCheckChanges();
        event.stopPropagation();
        this.customerForm.controls.contractNumber.markAsDirty();
      }

      clearDataAnalyst(event: MouseEvent) {
        this.dataAnalyst = undefined;
        this.originalNamesAutoComplete.dataAnalyst = undefined;
        this.autoCompleteCheckChanges();
        event.stopPropagation();
        this.customerForm.controls.contractNumber.markAsDirty();
      }

      clearProjectManager(event: MouseEvent) {
        this.projectManager = undefined;
        this.originalNamesAutoComplete.projectManager = undefined;
        this.autoCompleteCheckChanges();
        event.stopPropagation();
        this.customerForm.controls.contractNumber.markAsDirty();
      }

      clearSalesPerson(event: MouseEvent) {
        this.salesPerson = undefined;
        this.originalNamesAutoComplete.salesPerson = undefined;
        this.autoCompleteCheckChanges();
        event.stopPropagation();
        this.customerForm.controls.contractNumber.markAsDirty();
      }

    public addCompositeLocation() {
        // ensure that rain gauge collection is present
        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: Number(this.customerID),
        };

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
        }
        this.dialog
            .open(CompositeLocComponent, dialogOptions)
            .afterClosed()
            .subscribe((res) => {
                if (res.success) {
                    this.statusCodeService.isLocationCreated.next(true);
                }
            });
    }

    public closeNewLocationEditor() {
        this.showNewLocationEditor = false;
    }

    public generateCustomerEditorTable() {
        this.customerEditorDataSource = null;
        this.customerEditorDataChange = new BehaviorSubject<Customer[]>([]);
        if (this.customers) {
            for (const customer of this.customers) {
                const customerEditorCopiedData = this.customerEditorData.slice();
                const data = {
                    customerName: customer.customerName,
                    customerID: customer.customerID,
                };
                customerEditorCopiedData.push(data);
                this.customerEditorDataChange.next(customerEditorCopiedData);
            }

            this.customerEditorDataSource = new FilterDataSource(
                this.customerEditorDataChange,
                this.customerEditorData,
                this.customerEditorPaginator,
                this.customerEditorSort,
                this.customerEditorFilterColumns,
            );
            this.totalPaginationLength = this.customerEditorData.length;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.totalPaginationLength = 1;
        }
        this.setSelectedCustomer(this.selectedCustomerID);
    }

    private getCustomerPermission(customerId: number) {
        this.subscriptions.push(
            this.customerService.getCustomer(customerId).subscribe((features) => {
                this.isApiKeyEnabled = features.some((f) => f.id === FEATURE_IDS.API_KEYS);
                this.isCustomAlarmingEnabled = features.some((f) => f.id === FEATURE_IDS.API_KEYS);
            }),
        );
    }

    private manageCustomAlarmsTab(result: boolean) {
        if (result) {
            this.isCustomAlarmingEnabled = false;
        } else {
            this.isCustomAlarmingEnabled = true;
        }
    }
    public setUnit() {
        const unitData = this.units === this.metricValue ? 'mm' : 'in';
        this.customerService.unitValue.next(unitData);
    }

    private isTabChangeConfirmationShown = false;
    private oldTabIndex = 0;

    public onSelectedTabChange(event: MatTabChangeEvent, tabGroup: MatTabGroup) {
        if (this.domOperationUtilsService.hintPageName === LOCATION_DETAIL_HINT) {
            this.domOperationUtilsService.showLocationDashboardDetailHintOverlay();
        }
        if (this.isTabChangeConfirmationShown) return;

        if(!(!this.autoCompleteModified && (this.customerForm.form.invalid || this.customerForm.form.pristine || !this.isValidCustomerShortName || !this.isValidCustomerName))
            || this.featureChange || this.usersChange || this.rainfallProfileChange){
            this.isTabChangeConfirmationShown = true;
            tabGroup.selectedIndex = this.oldTabIndex;

            this.warningDialogOpen().subscribe((data: { whichButtonWasPressed: ConfirmationButton }) => {
                this.isTabChangeConfirmationShown = false;

                if (data && data.whichButtonWasPressed === ConfirmationButton.ok) {
                    tabGroup.selectedIndex = event.index;
                    this.featureChange = false;
                    this.usersChange = false;
                    this.rainfallProfileChange = false;
                    this.customerForm.form.markAsPristine();
                    this.tabChangeHandler(event);
                } else {
                    this.resetCustomerForm();
                }
            });
        } else {
            this.domOperationUtilsService.currentScreenNo = event.index;
            this.tabChangeHandler(event);
        }
        this.domOperationUtilsService.editCustomerTabIndex.next(event.index);
    }


    private tabChangeHandler(event: MatTabChangeEvent) {
        this.oldTabIndex = event.index;
    }

    public warningDialogOpen(): Observable<IComponentDialogConfirmationResult> {
        const message = true
            ? this.translate.instant('CUSTOMER_EDITOR.NAVIGATE_AWAY_WARNING')
            : this.translate.instant('SLIICER.COMMON.NAVIGATE_AWAY_WARNING');

        return this.dialog.open<ConfirmationDialogComponent, IComponentDialog, IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
            data: {
                title: this.translate.instant('SLIICER.COMMON.WARNING'),
                message: message,
                cancelText: this.translate.instant('SLIICER.COMMON.CANCEL_BUTTON_TITLE'),
                okText: this.translate.instant('SLIICER.COMMON.CONFIRM'),
                messageClass: 'danger'
            },
            disableClose: true,
        }).afterClosed();
    }

    public dialogConfirmSubscriptionLevel(): Observable<IComponentDialogConfirmationResult> {
        const message = this.translate.instant('CUSTOMER_EDITOR.SAVE_SUBSCRIPTION_LEVEL') + SUBSCRIPTION_LEVELS[this.subscriptionLevel]?.text ?? '-';

        return this.dialog.open<ConfirmationDialogComponent, IComponentDialog, IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
            data: {
                title: this.translate.instant('CUSTOMER_EDITOR.SAVE_CUSTOMER_CHANGES'),
                message: message,
                cancelText: this.translate.instant('SLIICER.COMMON.CANCEL_BUTTON_TITLE'),
                okText: this.translate.instant('SLIICER.COMMON.CONFIRM')
            },
            disableClose: true,
        }).afterClosed();
    }

    public addTritonLocation() {
        //  disable "add new location" button on customer editor to prevent multiple dialogs being open
        this.disableAddLocationBtn = true;

        const getLocationsSubscription = this.locationDashboardSvc
            .getLocations(<LocationArgs>{ customerId: this.customerID })
            .subscribe((locations: Array<Locations>) => {
                // Variable to store rain gauge locations
                let rainGaugeLocations = new Array<Locations>();

                // check if locations exist
                if (locations) {
                    // Filter and set rain gauge locations
                    rainGaugeLocations =
                        locations.filter(
                            (location) => location.series && location.series.toLowerCase().indexOf('rainalert') > -1,
                        ) || new Array<Locations>();
                    rainGaugeLocations.sort(this.uiUtilsService.sortLocations);
                }

                // open add location dialog
                const dialogOptions: MatDialogConfig = {
                    disableClose: true,
                    data: {
                        isFromCustomerEditor: true,
                        rainGaugeLocations: rainGaugeLocations,
                        customerId: this.customerID,
                        editMode: false,
                        locationDetails: {},
                    },
                };

                if (this.gisService.locationCardPosition) {
                    dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                }

                if (this.mapService.addEditLocationDialog) {
                    this.mapService.addEditLocationDialog.close();
                }

                this.mapService.addEditLocationDialog = this.dialog.open(TritonLocationDialogComponent, dialogOptions);
                this.mapService.addEditLocationDialog
                    .afterClosed()
                    .pipe(first())
                    .subscribe((result: boolean) => {
                        this.mapService.addEditLocationDialog = null;
                        if (result) {
                            this.disableAddLocationBtn = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                            this.statusCodeService.isLocationCreated.next(true);
                        }
                    });
            });
        this.subscriptions.push(getLocationsSubscription);
    }

    public setOriginalContractStart(event: MatDatepickerInputEvent<Date | string>) {
        if (event.target) {
            if (event.target.value) {
                if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                    this.originalContractStart = new Date(event.target.value);
                } else if (!isNaN((event.target.value as Date).getTime())) {
                    this.originalContractStart = event.target.value as Date;
                }
            } else {
                this.originalContractStart = null;
            }
        }

        // need to mark the form as dirty and can't have this field as a part of the form...
        this.customerForm.controls.contractNumber.markAsDirty();
    }

    public setCurrentContractStart(event: MatDatepickerInputEvent<Date | string>) {
        if (event.target) {
            if (event.target.value) {
                if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                    this.currentContractStart = new Date(event.target.value);
                } else if (!isNaN((event.target.value as Date).getTime())) {
                    this.currentContractStart = event.target.value as Date;
                }
            } else {
                this.currentContractStart = null;
            }
        }

        this.customerForm.controls.contractNumber.markAsDirty();
    }

    public setCurrentContractEnd(event: MatDatepickerInputEvent<Date>) {
        if (event.target) {
            if (event.target.value) {
                if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                    this.currentContractEnd = new Date(event.target.value);
                } else if (!isNaN((event.target.value as Date).getTime())) {
                    this.currentContractEnd = event.target.value as Date;
                }
            } else {
                this.currentContractEnd = null;
            }
        }

        this.customerForm.controls.contractNumber.markAsDirty();
    }

    public dataAnalystSelected(event: AutoCompleteItem[]) {
        this.dataAnalyst = event.length ? event[0] : undefined;
        this.autoCompleteCheckChanges();
    }
    public projectManagerSelected(event: AutoCompleteItem[]) {
        this.projectManager = event.length ? event[0] : undefined;
        this.autoCompleteCheckChanges();
    }
    public regionalManagerSelected(event: AutoCompleteItem[]) {
        this.regionalManager = event.length ? event[0] : undefined;
        this.autoCompleteCheckChanges();
    }
    public salesPersonSelected(event: AutoCompleteItem[]) {
        this.salesPerson = event.length ? event[0] : undefined;
        this.autoCompleteCheckChanges();
    }

    private autoCompleteCheckSingleChange(nameField: string, acField: AutoCompleteItem) {
        return !((nameField === undefined && acField === undefined) || nameField === acField?.name);
    }

    public autoCompleteCheckChanges() {
        this.autoCompleteModified =
            this.autoCompleteCheckSingleChange(this.originalNamesAutoComplete.dataAnalyst, this.dataAnalyst)
            || this.autoCompleteCheckSingleChange(this.originalNamesAutoComplete.projectManager, this.projectManager)
            || this.autoCompleteCheckSingleChange(this.originalNamesAutoComplete.regionalManager, this.regionalManager)
            || this.autoCompleteCheckSingleChange(this.originalNamesAutoComplete.salesPerson, this.salesPerson);
    }

    public isSubscirpitionLevelChange() {
        return this.originalSubscriptionLevel !== this.subscriptionLevel;
    }

    public fieldsCheckChanges() {
        this.fieldsModified = this.isSubscirpitionLevelChange();
    }

    public onSubscriptionLevelChange(event: MatLegacySelectChange) {
        this.fieldsCheckChanges();
    }
}
