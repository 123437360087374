import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnChanges,
    SimpleChanges,
    HostListener,
    ElementRef,
    Self,
    Optional,
    ChangeDetectorRef,
} from '@angular/core';
import _ from 'lodash';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';

// Supports both template driven and reactive form usage
@Component({
    selector: 'app-entity-selector',
    templateUrl: './entity-selector.component.html',
})
export class EntitySelectorComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() public disabled: boolean;
    @Input() private availableEntities: EntitySelectorObject[] = [];
    @Input() private availableANSREntities: EntitySelectorObject[] = [];
    @Input() public single = false;
    @Input() public oneEntityPerGroup = false;
    @Input() private selectedEntitiyIds = [];
    @Input() private defaultEntities = [];
    @Input() public staticPlaceholder: string;
    @Output() public selectedEntities = new EventEmitter<EntitySelectorEntity[]>();
    @Output() public addedEntities = new EventEmitter<EntitySelectorEntity[]>();
    @Output() public isTouched = new EventEmitter();
    @Output() public selectedEntitiesList = new EventEmitter();
    @Output() public emitBlur = new EventEmitter();

    public selectedEntsListPlaceholder: string;
    public selectedEntsListString: string;

    public isSearching = false;

    public filteredAvailableEntities: EntitySelectorObject[] = [];
    public filteredANSREntities: EntitySelectorEntity[] = [];

    public get allAvailableEntities(): EntitySelectorEntity[] {
        return [
            ...(!this.availableANSREntities
                ? []
                : this.availableANSREntities.reduce((acc, e) => [...acc, ...e.entities], [])),
            ...(!this.availableEntities ? [] : this.availableEntities.reduce((acc, e) => [...acc, ...e.entities], [])),
        ];
    }

    public selectedEntsListObject: EntitySelectorEntity[] = []; // this one won't be loose entities if they no longer are available
    public entitiesAdded : EntitySelectorEntity[] = [];
    public availabeSelectedEntsListObject: EntitySelectorEntity[] = []; // this one will

    @HostListener('focusout', ['$event']) public blur(event: FocusEvent) {
        // Only emit changes back to parent if leave focus on whole component
        const relatedTarget = event.relatedTarget as Element;
        if (this.elementRef.nativeElement === event.relatedTarget) {
            return;
        }
        if (this.elementRef.nativeElement.contains(relatedTarget)) {
            return;
        }

        this.isSearching = false;
        this.buildStringAndPlaceholder();
        this.emitSelectedEntities();
        this.emitBlur.emit();
    }

    constructor(
        @Self() @Optional() public ngControl: NgControl,
        private elementRef: ElementRef,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    public writeValue(value: EntitySelectorEntity[]) {
        this.selectedEntsListObject = value;
    }

    public registerOnChange(fn: (value: EntitySelectorEntity[]) => void) {
        this.onChange = fn;
    }

    public registerOnTouched() {}

    private onChange(value: EntitySelectorEntity[]) {}

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public ngOnInit() {
        this.updateSelected();
        this.filterEntities();
        this.buildStringAndPlaceholder();
        this.emitSelectedEntities();
    }
    public ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.availableANSREntities && changes.availableANSREntities.currentValue !== undefined && changes.availableANSREntities.currentValue.length &&
                JSON.stringify(changes.availableANSREntities.currentValue) !==
                    JSON.stringify(changes.availableANSREntities.previousValue)) ||
            (changes.availableEntities && changes.availableEntities.currentValue !== undefined && changes.availableEntities.currentValue.length &&
                JSON.stringify(changes.availableEntities.currentValue) !==
                    JSON.stringify(changes.availableEntities.previousValue))
        ) {
            this.filterEntities();
            this.updateSelected();
        }

        if (
            changes.selectedEntitiyIds &&
            JSON.stringify(changes.selectedEntitiyIds.currentValue) !==
                JSON.stringify(changes.selectedEntitiyIds.previousValue)
        ) {
            this.updateSelected();
        }

        if (!this.ngControl) {
            this.buildStringAndPlaceholder();
        }
    }

    private notPresent(array: EntitySelectorEntity[], arrayToAdd: EntitySelectorEntity[]) {
        return !arrayToAdd
            ? []
            : arrayToAdd.filter((entityToAdd) => !array.some((entity) => entity.id === entityToAdd.id));
    }

    public updateSelected() {
        const availableAndPastSelectedEntities = [
            ...this.allAvailableEntities,
            ...this.notPresent(this.allAvailableEntities, this.selectedEntsListObject),
        ];

        let availableAndPastSelectedAndDefaultEntities = [
            ...availableAndPastSelectedEntities,
            ...this.notPresent(availableAndPastSelectedEntities, this.defaultEntities),
        ];

        availableAndPastSelectedAndDefaultEntities = availableAndPastSelectedAndDefaultEntities.filter(
            (v, i) => i === availableAndPastSelectedAndDefaultEntities.findIndex((x) => x.id === v.id),
        );

        availableAndPastSelectedAndDefaultEntities.forEach((v) => (v.isChecked = false));
        this.selectedEntsListObject = availableAndPastSelectedAndDefaultEntities.filter((e: EntitySelectorEntity) =>
            this.selectedEntitiyIds.includes(e.id),
        );

        this.selectedEntsListObject.forEach((selected) => {
            selected.isChecked = true; // make sure that all selected entities are checked
        });

        this.availabeSelectedEntsListObject = this.selectedEntsListObject.filter((e: EntitySelectorEntity) =>
            this.allAvailableEntities.some((ave) => ave.id === e.id),
        );

        this.buildStringAndPlaceholder();
        this.emitSelectedEntities();
    }

    private maintainSelectedEntObject(ent: EntitySelectorEntity) {
        if (this.single) {
            this.selectedEntsListObject = [];
        }

        if (this.oneEntityPerGroup && ent.isChecked) {
            this.selectedEntsListObject.forEach((v) => {
                if (v.groupId === ent.groupId) {
                    v.isChecked = false;
                }
            });
            this.selectedEntsListObject = this.selectedEntsListObject.filter((v) => v.groupId !== ent.groupId);
        }

        if (ent.isChecked) {
            this.selectedEntsListObject.push(ent);
            this.entitiesAdded.push(ent);
            this.addedEntities.emit(this.entitiesAdded);
        } else if (!this.single) {
            const objectIndex = this.selectedEntsListObject.findIndex((x) => x.id === ent.id);
            const removeAddedEntityIndex = this.entitiesAdded.findIndex((x) => x.id === ent.id);
            if (objectIndex !== -1) {
                if (this.selectedEntsListObject.length === 1) {
                    this.selectedEntsListObject = [];
                } else {
                    this.selectedEntsListObject.splice(objectIndex, 1);
                }
            
            if(removeAddedEntityIndex !== -1){
                this.entitiesAdded.splice(removeAddedEntityIndex, 1);
                this.addedEntities.emit(this.entitiesAdded);
            }
            }
        } else if (this.single) {
        }

        this.availabeSelectedEntsListObject = this.selectedEntsListObject.filter((e: EntitySelectorEntity) =>
            this.allAvailableEntities.some((ave) => ave.id === e.id),
        );
    }

    private buildStringAndPlaceholder() {
        this.selectedEntsListString = '';
        this.selectedEntsListPlaceholder = 'Entity: 0 selected';
        if (this.availabeSelectedEntsListObject.length > 0) {
            this.availabeSelectedEntsListObject.forEach(
                (x) => (this.selectedEntsListString = this.selectedEntsListString.concat(x.name, ', ')),
            );
            this.selectedEntsListString = this.selectedEntsListString.slice(0, -2);
            this.selectedEntsListPlaceholder = 'Entity: '.concat(
                this.availabeSelectedEntsListObject.length.toString(),
                ' selected',
            );
        }
    }
    private updateNumSelected() {
        if (this.availabeSelectedEntsListObject.length > 0) {
            this.selectedEntsListPlaceholder = 'Entity: '.concat(
                this.availabeSelectedEntsListObject.length.toString(),
                ' selected',
            );
        } else {
            this.selectedEntsListPlaceholder = 'Entity: 0 selected';
        }
    }
    public onSelectionChange(groupId: number, entId: number, isANSR: boolean, forceChecked = false) {
        this.isTouched.emit();
        let affectedEnt: EntitySelectorEntity;
        if (isANSR) {
            affectedEnt = this.filteredANSREntities.find((x) => x.groupId === groupId && x.id === entId);
        } else {
            if (groupId === 0) {
                // There's multiple entity groups with groupId 0
                const groups = this.availableEntities.filter((group) => group.groupId === 0);

                for (const group of groups) {
                    affectedEnt = group.entities.find((x) => x.id === entId);
                    if (affectedEnt) {
                        break;
                    }
                }
            } else {
                affectedEnt = this.availableEntities
                    .find((x) => x.groupId === groupId)
                    .entities.find((x) => x.id === entId);
            }
        }
        affectedEnt.isChecked = forceChecked ? true : !affectedEnt.isChecked;
        this.maintainSelectedEntObject(affectedEnt);
        this.updateNumSelected();

        if (this.single && affectedEnt.isChecked) {
            this.isSearching = false;
        }
        this.buildStringAndPlaceholder();

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    public clearSelected() {
        this.selectedEntsListObject = [];
        this.availableEntities.forEach(v => v.entities.forEach(i => i.isChecked = false));
        this.filteredANSREntities.forEach(v => v.isChecked = false);
        this.availabeSelectedEntsListObject = [];
        this.buildStringAndPlaceholder();
    }
    public emitSelectedEntities() {
        this.onChange(this.selectedEntsListObject);
        this.selectedEntities.emit(this.selectedEntsListObject);
        this.selectedEntitiesList.emit(this.selectedEntsListString);
    }
    public selectSearchBox() {
        this.selectedEntsListString = '';
        this.filterEntities();
        this.isSearching = true;
    }
    public searchBoxStringEntered() {
        this.filterEntities(this.selectedEntsListString.toLowerCase());
    }

    private filterEntities(searchString?: string): void {
        this.filteredAvailableEntities = [];
        if (this.availableEntities) {
            this.availableEntities.forEach((entGroup) => {
                entGroup.entities.forEach((entity) => {
                    if (this.selectedEntsListObject.some((z) => z.id === entity.id)) {
                        entity.isChecked = true;
                    }
                });

                // If there is a search string filter
                if (searchString && searchString !== '') {
                    if (entGroup.groupName.toLowerCase().indexOf(searchString) !== -1) {
                        // If group name matches string, show all entities
                        this.filteredAvailableEntities.push(entGroup);
                    } else {
                        // Otherwise filter entities in the group
                        const filteredEntities = entGroup.entities.filter(
                            (ent) => ent.name.toLowerCase().indexOf(searchString) !== -1,
                        );
                        // Then only show group if at least 1 matching entity
                        if (filteredEntities.length > 0) {
                            this.filteredAvailableEntities.push({ ...entGroup, entities: filteredEntities });
                        }
                    }
                } else {
                    // No search string, show everything
                    this.filteredAvailableEntities.push(entGroup);
                }
            });
        }
        this.filterANSREntities();
    }

    private filterANSREntities(searchString?: string): void {
        this.filteredANSREntities = [];

        let entities = [];
        // If there is a search string AND the string is not matched by the header for the group (which shows whole group)
        if (
            searchString &&
            searchString !== '' &&
            'ANSR Entities'.toLowerCase().indexOf(searchString) === -1 &&
            this.availableANSREntities
        ) {
            entities = this.availableANSREntities.filter(
                (ent) => ent.groupName.toLowerCase().indexOf(searchString) !== -1,
            );
        } else {
            entities = this.availableANSREntities;
        }

        this.filteredANSREntities = entities?.reduce(
            (previousValue: EntitySelectorEntity[], currentValue: EntitySelectorObject): EntitySelectorEntity[] => {
                previousValue.push(...currentValue.entities);
                return previousValue;
            },
            [],
        );
    }
}
