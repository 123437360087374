<div id="ViewDataLocationDetails">
    <app-loader [isLoading]="isLoading"></app-loader>

    <div class="locationDetails">
        <div class="pad-l-15">
            <mat-icon class="backIcon" (click)="backRedirect()">arrow_back</mat-icon>
        </div>
        <div>
            {{ locationDetails.locationName }} -
            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.LOCATION_1_DETAILS' | translate }}
        </div>
        <app-location-selection-bar
            *ngIf="selectableLocations.length"
            [selectableLocations]="selectableLocations"
            [locationId]="locationId"
            (emitChangeLocation)="onChangeLocation($event)"
            [customerId]="customerId"
        >
        </app-location-selection-bar>
        <div class="edit-button custom-flat-button">
            <button
                mat-button
                matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                (click)="editLocation()"
                [disabled]="!locationsLoaded"
                class="edit-button-hint"
            >
                <mat-icon>edit</mat-icon>{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.EDIT' | translate }}
            </button>
        </div>
    </div>

    <!--Redesign location details page  -->
    <div class="details-page hint-page">
        <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="onSelectedTabChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="details-hint">
                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VIEW_LOCATION_DETAILS' | translate }}
                    </span>
                </ng-template>

                <div class="this-tab">
                    <div class="locationmap" style="width: 50%" *ngIf="locationDetails">
                        <div id="locationmap" style="height: 100%; width: 100%"></div>
                        <div class="map-attribution">
                            <span>{{ attributionText }}</span>
                            <span>
                                {{ 'HOME.MAP.ATTRIBUTIONS.POWERED_BY' | translate }}
                                <a href="https://www.esri.com" target="_blank" rel="noopener noreferrer" class="esri-attribution">
                                    {{ 'HOME.MAP.ATTRIBUTIONS.ESRI_ATTRIBUTION' | translate }}
                                </a>
                            </span>
                        </div>
                        <div id="scaleline" class="scaleline map-scale-hint"
                        ></div>
                        <button mat-button class="mapbuttons zoominbtn" [disabled]="olMap && olMap.getView() && olMap.getView().getZoom() === 19" (click)="zoomIn()">+</button>
                        <button mat-button class="mapbuttons zoomoutbtn" (click)="zoomOut()">-</button>
                        <button
                            mat-button
                            class="mapbuttons zoommaxbtn"
                            (click)="zoomtoInital()"
                            matTooltip="{{ 'GIS_TOOLTIPS.HOME_ICON' | translate }}"
                        >
                            <mat-icon class="mapicons">home</mat-icon>
                        </button>
                        <button
                            mat-button
                            class="mapbuttons"
                            style="top: 150px"
                            (click)="getGPSLocation()"
                            matTooltip="{{ 'GIS_TOOLTIPS.CURRENT_LOCATION' | translate }}"
                        >
                            <mat-icon class="mapicons">gps_not_fixed</mat-icon>
                        </button>
                    </div>
                    <div class="side">
                        <div class="locationdet" *ngIf="locationDetails">
                            <div class="dateRangeHeader">
                                <div class="dateRangeText auto-margin">
                                    {{ locationDetails.locationName }}
                                </div>
                                <div class="available-date-range" *ngIf="locationDetails.telemetryStart">
                                    <p>{{ 'LOCATION_DASHBOARD.AVAILABLE_DATA_RANGE' | translate }}</p>
                                    <span
                                        >{{ locationDetails.telemetryStart | date: customerDateFormat }} -
                                        {{ locationDetails.telemetryEnd | date: customerDateFormat }}</span
                                    >
                                </div>
                                <div class="dateRangeHeader">
                                    <div class="app-flex-filler"></div>
                                    <div class="auto-margin custom-flat-button">
                                        <button
                                            class="edit export-button-hint"
                                            mat-button
                                            matTooltip="{{ 'COMMON.EXPORT_TEXT' | translate }}"
                                            (click)="exportLocationXml()"
                                        >
                                            {{ 'COMMON.EXPORT_TEXT' | translate }}
                                        </button>
                                    </div>
                                    <div class="auto-margin custom-flat-button" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                                        <button
                                            class="edit schedule-collect-button-hint"
                                            mat-button
                                            matTooltip="{{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SCHEDULE_COLLECT'
                                                    | translate
                                            }}"
                                            [disabled]="
                                                communicationType === 1 ||
                                                communicationType === 6 ||
                                                !isScheduleCollectAllowed
                                            "
                                            (click)="navigateToScheduleCollectDashboard(locationDetails.locationID)"
                                        >
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SCHEDULE_COLLECT'
                                                    | translate
                                            }}
                                        </button>
                                    </div>
                                    <div class="auto-margin custom-flat-button" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                                        <button
                                            class="edit collect-button-hint"
                                            mat-button
                                            matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}"
                                            [disabled]="communicationType === 1 || communicationType === 6"
                                            (click)="collectLocation(locationDetails.locationID)"
                                        >
                                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.COLLECT' | translate }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="location_series">
                                <div class="location-detail-section">
                                    <div class="each-details">
                                        <div class="head">
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.MONITOR_SERIES'
                                                    | translate
                                            }}
                                        </div>
                                        <div class="body">{{ monitorSeries ? monitorSeries : locationDetails.series }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.IP_ADDRESS' | translate }}
                                        </div>
                                        <div class="body" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">{{ locationDetails.ipaddress }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head">
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.LOCATION_DESCRIPTION'
                                                    | translate
                                            }}
                                        </div>
                                        <div class="body">{{ locationDetails.description }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL  && locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.MANHOLE_ADDRESS'
                                                    | translate
                                            }}
                                        </div>
                                        <div class="head" *ngIf="locationDetails.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL  || locationDetails.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex">
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ADDRESS'
                                                    | translate
                                            }}
                                        </div>
                                        <div class="body">{{ locationDetails.manholeAddress }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head">
                                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.LATITUDE' | translate }}
                                        </div>
                                        <div class="body">{{ locationDetails.coordinate.latitude }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head">
                                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.LONGITUDE' | translate }}
                                        </div>
                                        <div class="body">{{ locationDetails.coordinate.longitude }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head">
                                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.TYPE_SHAPE' | translate }}
                                        </div>
                                        <div class="body">{{ installationDetails }}</div>
                                    </div>

                                    <!--fields for installation type pipe  -->
                                    <ng-container *ngIf="locationDetails.installationType == 'Pipe'">
                                        <div class="each-details">
                                            <div class="head">{{ pipeHeightPlaceHolder }}</div>
                                            <div class="body">
                                                {{ locationDetails.installationHeight | number: '1.2-2' }}
                                            </div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">{{ pipeWidthPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.width | number: '1.2-2' }}</div>
                                        </div>
                                    </ng-container>

                                    <!--fields for installation type flume  -->
                                    <ng-container *ngIf="locationDetails.installationType == 'Flume'">
                                        <div class="each-details">
                                            <div class="head">{{ flumeRangePlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.range }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">{{ capacityPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.capacity }}</div>
                                        </div>

                                        <div class="each-details">
                                            <div class="head">{{ widthPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.width }}</div>
                                        </div>
                                    </ng-container>

                                    <!--fields for installation type Weir  -->
                                    <ng-container *ngIf="locationDetails.installationType == 'Weir'">
                                        <div class="each-details">
                                            <div class="head">{{ flumeRangePlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.range }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">{{ capacityPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.capacity }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">{{ heightPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.installationHeight }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">
                                                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CONSTANT' | translate }}
                                            </div>
                                            <div class="body">{{ locationDetails.coefficient }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">{{ lengthPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.length }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">{{ breadthPlaceHolder }}</div>
                                            <div class="body">{{ locationDetails.breadth }}</div>
                                        </div>
                                    </ng-container>

                                    <!--fields for installation type channel  -->
                                    <ng-container *ngIf="locationDetails.installationType == 'Channel'">
                                        <div class="each-details">
                                            <div class="head">
                                                {{
                                                    'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRIES_LABEL' | translate
                                                }}
                                            </div>
                                            <div class="body">{{ locationDetails?.entries?.length }}</div>
                                        </div>

                                        <ng-container *ngFor="let entry of this.locationDetails.entries">
                                            <div class="each-details">
                                                <div class="head">
                                                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH' | translate }} ({{locationDetails.depthUnit}})
                                                </div>
                                                <div class="body">{{ entry.depth | number : depthPrecision }}</div>
                                            </div>
                                            <div class="each-details">
                                                <div class="head">{{ quantityPlaceHolder }} ({{locationDetails.flowUnit}})</div>
                                                <div class="body">{{ entry.quantity | number : flowPrecision }}</div>
                                            </div>
                                        </ng-container>
                                    </ng-container>

                                    <!--fields for installation type elevation  -->
                                    <ng-container *ngIf="locationDetails.installationType === INSTALLATION_TYPE.ELEVATION">
                                        <div class="each-details">
                                            <div class="head">
                                                {{
                                                  'HOME.MAP.MARKER_LOCATION_DETAIL.REFERENCE_POINT' | translate  
                                                }} ({{ elevationUnit }})
                                            </div>
                                            <div class="body">{{ locationDetails.referencePoint }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">
                                                {{
                                                    'HOME.MAP.MARKER_LOCATION_DETAIL.PHYSICAL_OFFSET' | translate
                                                }} ({{ elevationUnit }})
                                            </div>
                                            <div class="body">{{ locationDetails.physicalOffset }}</div>
                                        </div>
                                        <div class="each-details">
                                            <div class="head">
                                                {{
                                                    'HOME.MAP.MARKER_LOCATION_DETAIL.DATUM' | translate
                                                }}
                                            </div>
                                            <div class="body">{{ locationDetails.datum }}</div>
                                        </div>
                                    </ng-container>

                                    <!-- 
                                        end section for installation fields
                                    -->

                                    <div class="each-details" [hidden]="isRainAlertIII" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL">
                                        <div class="head">{{ manholeDepthPlaceholderFromJson }}</div>
                                        <div class="body">{{ locationDetails.manholedepth }}</div>
                                    </div>
                                    <div class="each-details" [hidden]="isRainAlertIII" *ngIf="(locationDetails.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL && locationDetails.installationType !== INSTALLATION_TYPE.ELEVATION) || locationDetails.series?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex">
                                        <div class="head">{{ heightPlaceHolder }}</div>
                                        <div class="body">{{ locationDetails.height }}</div>
                                    </div>
                                    <div class="each-details">
                                        <div class="head">
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ASSIGNED_RAINGAUGE'
                                                    | translate
                                            }}
                                        </div>
                                        <div class="body">{{ locationDetails.assignedRainGaugeLocationName }}</div>
                                    </div>

                                    <div class="each-details">
                                        <div class="head">
                                            {{
                                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SERIAL_NUMBER'
                                                    | translate
                                            }}
                                        </div>
                                        <div class="body">{{ locationDetails.serialNumber }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Graph container -->
                        <div class="reportGraphContentContainer">
                            <div class="reportGraphContent">
                                <mat-card  *ngIf="!isElevationInstallType">
                                    <app-percent-full
                                        [isLoading]="percentFullLoading"
                                        [data]="percentFullData | async"
                                        [locationId]="locationId"
                                        [dateFormat]="dateFormatGraph"
                                        [oneDayStartDate]="oneDayStartDate | adsDate: confirmationDateFormat"
                                        [oneDayEndDate]="oneDayEndDate | adsDate: confirmationDateFormat"
                                    >
                                    </app-percent-full>
                                </mat-card>
                                <mat-card>
                                    <app-daily-summary
                                        [isLoading]="dailySummaryLoading"
                                        [data]="dailySummaryData | async"
                                        [reportLocationName]="locationDetails.locationName"
                                        [dateFormat]="dateFormat"
                                        [locationId]="locationId"
                                        [oneDayStartDate]="oneDayStartDate | adsDate: confirmationDateFormat"
                                        [oneDayEndDate]="oneDayEndDate | adsDate: confirmationDateFormat"
                                    >
                                    </app-daily-summary>
                                </mat-card>
                                <mat-card>
                                    <app-uptime
                                        [isLoading]="uptimeLoading"
                                        [data]="uptimeData | async"
                                        [reportLocationName]="locationDetails.locationName"
                                        [dateFormat]="dateFormat"
                                        [locationId]="locationId"
                                        [entityIds]="uptimeEntityIds"
                                        [oneDayStartDate]="oneDayStartDate | adsDate: confirmationDateFormat"
                                        [oneDayEndDate]="oneDayEndDate | adsDate: confirmationDateFormat"
                                        [isRainGauge]="isRainGauge"
                                    >
                                    </app-uptime>
                                </mat-card>
                            </div>
                        </div>
                        <div class="pipeDiagram" *ngIf="locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && locationDetails.series?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                            <div *ngIf="pipeDetails" class="title">
                                {{ installationDetails }}
                                ({{ locationDetails.installationHeight | number: '1.2-2' }} x {{ locationDetails.width | number: '1.2-2' }})
                            </div>
                            <div *ngIf="!pipeDetails" class="title">
                                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NO_PIPE_DATA' | translate }}
                            </div>
                            <canvas #pipeCanvas></canvas>
                            <div *ngIf="pipeDetails" class="export">
                                <button mat-button (click)="pipeExportTable()">{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.EXPORT_PIPE_DATA' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="ansr-hint">
                        {{ 'CALCULATED_ENTITY_DIALOG.ANSR' | translate }}
                        <span class="fallback-font">™</span>
                        {{ 'CALCULATED_ENTITY_DIALOG.ENGINE' | translate }}
                    </span>
                </ng-template>
                <div class="associated-entity" *ngIf="!showCreateSection">
                    <div class="dateRangeHeader" *ngIf="isANSRFeatureAvailable">
                        <div class="dateRangeText auto-margin">
                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ASSOCIATED_ENTITY' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin custom-flat-button">
                            <button
                                class="edit add-entity-hint"
                                mat-button
                                (click)="openCalcuatedEntityModal()"
                                matTooltip="{{
                                    'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ADD_CALCULATED_ENTITY' | translate
                                }}"
                            >
                                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ADD_CALCULATED_ENTITY' | translate }}
                            </button>
                        </div>
                    </div>
                    <mat-card-content>
                        <div
                            id="locationpagetable"
                            *ngIf="!showCreateSection && totalPaginationLength > 0 && isANSRFeatureAvailable"
                        >
                            <mat-table #calculatedEntitiestable [dataSource]="viewDataLocationDetailsDataSource">
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef>
                                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let CalculatedEntitiesModel"
                                        >{{ CalculatedEntitiesModel.name }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="description">
                                    <mat-header-cell *matHeaderCellDef>
                                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.DESCRIPTION' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let CalculatedEntitiesModel">
                                        {{ CalculatedEntitiesModel.description }}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="action">
                                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                    <mat-cell *matCellDef="let element; let i = index" class="text-right">
                                        <button mat-icon-button>
                                            <mat-icon matTooltip="Edit" [ngClass]="{'ansr-edit-hint': i === 0}" (click)="editCalculatedEntity(element)">
                                                mode_edit
                                            </mat-icon>
                                        </button>
                                        <button mat-icon-button>
                                            <mat-icon matTooltip="Delete" [ngClass]="{'ansr-delete-hint': i === 0}" (click)="deleteCalculatedEntity(element)">
                                                delete</mat-icon
                                            >
                                        </button>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="viewDataLocationDetailsColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: viewDataLocationDetailsColumns"></mat-row>
                            </mat-table>
                        </div>
                    </mat-card-content>
                    <mat-card-content>
                        <div class="page-marketing-screen" *ngIf="!isANSRFeatureAvailable">
                            <img src="assets/images/ansr.png" class="pad-b-15" alt="flow" />
                            <h3 class="width-30-p text-size auto-margin">
                                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ADS_ANSR_ENGINE' | translate }}
                            </h3>
                            <p class="width-30-p content-color auto-margin">
                                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ADS_ANSR_GAUGE' | translate }}
                            </p>
                            <button mat-button class="contact-button" (click)="contactUs()">
                                {{ 'COMMON.CONTACT_US' | translate }}
                            </button>
                        </div>
                    </mat-card-content>
                    <div class="customFooter">
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
                            <mat-paginator
                                [length]="totalPaginationLength"
                                [pageIndex]="0"
                                [pageSize]="10"
                                (page)="onPageChange($event)"
                                [pageSizeOptions]="[10, 25, 100]"
                            >
                            </mat-paginator>
                        </div>
                    </div>
                    <div
                        class="notabledataFound custom-table"
                        *ngIf="totalPaginationLength <= 0 && isANSRFeatureAvailable"
                    >
                        {{ 'COMMON.NO_DATA_AVAILABLE' | translate }}
                    </div>
                </div>
                <div *ngIf="showCreateSection">
                    <app-calculated-entity
                        [ansrEntities]="ansrEntities"
                        [data]="calculatedEntityData"
                        (renderSection)="renderCreateSection($event, false)"
                    ></app-calculated-entity>
                </div>
            </mat-tab>
            <mat-tab *ngIf="!isRainGauge" [disabled]="isConfirmationsEnabled">
                <ng-template mat-tab-label>
                    <span class="confirmation-hint">
                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VIEW_LOCATION_CONFIRMATION' | translate }}
                    </span>
                </ng-template>
                <div class="plotting-confirmations">
                    <div class="dateRangeHeader">
                        <div class="dateRangeText auto-margin">
                            {{ 'PLOTTING_CONFIRMATION.CONFIRMATION_POINTS' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin custom-flat-button">
                            <button class="edit add-confirmation-hint" mat-button (click)="openVelocityGainTable()">
                                {{ 'COMMON.VELOCITY_GAIN_EDITOR' | translate }}
                            </button>
                            <button class="edit add-confirmation-hint" mat-button (click)="exportConfirmationsToCSV()" [disabled]="isConfirmationsEnabled">
                                Download
                            </button>
                        </div>
                        <div class="auto-margin custom-flat-button">
                            <button
                                class="edit download-confirmation-hint"
                                [disabled]="isConfirmationsEnabled || !isManageConfirmationPointsAllowed"
                                mat-button
                                (click)="openPlottingConfirmation()"
                            >
                                {{ 'PLOTTING_CONFIRMATION.ADD_CONFIRMATION' | translate }}
                            </button>
                        </div>
                    </div>
                    <mat-card-content>
                        <div id="locationpagetable">
                            <mat-table *ngIf="confirmationTableData" multiTemplateDataRows [dataSource]="confirmationTableData">
                                <ng-container matColumnDef="blankExpandLabel">
                                    <td mat-cell *matCellDef="let entity"></td>
                                </ng-container>
                                <ng-container matColumnDef="expandLabel">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let entity"
                                        >
                                        <span *ngIf="entity?.expand" class="material-icons">expand_more</span>
                                        <span *ngIf="!entity?.expand" class="material-icons">chevron_right</span>
                                    </td>
                                </ng-container>>

                                <ng-container matColumnDef="time">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.TIMESTAMP' | translate }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;" style="white-space: nowrap;">
                                        {{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].time | date: customerDateFormat}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.TYPE' | translate }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;">
                                        {{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].type}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="depth">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.DEPTH' | translate }} ({{ confirmationData.du }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].depth ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].depth | number: '1.' + confirmationData.dp + '-' + confirmationData.dp)
                                            : '--'
                                        }}</td>
                                </ng-container>
                                <ng-container matColumnDef="depth_diff">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.DEPTH_DIFF' | translate }} ({{ confirmationData.du }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].depth_diff || element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].depth_diff === 0 ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].depth_diff | number: '1.' + confirmationData.dp + '-' + confirmationData.dp)
                                            : '--'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="peak_velocity">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.PEAK_VELOCITY' | translate }} ({{ confirmationData.vu }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].peak_velocity ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].peak_velocity | number: '1.' + confirmationData.vp + '-' + confirmationData.vp)
                                            : '--'
                                        }}</td>
                                </ng-container>
                                <ng-container matColumnDef="ap_ratio">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.AVG_TO_PEAK_RATIO' | translate }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;">
                                        {{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].ap_ratio ? element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].ap_ratio : '--'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="calculated_avg_velocity">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.AVG_VELOCITY' | translate }} ({{ confirmationData.vu }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculated_avg_velocity ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculated_avg_velocity | number: '1.' + confirmationData.vp + '-' + confirmationData.vp)
                                            : '--'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="velocity_diff">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.VELOCITY_DIFF' | translate }} ({{ confirmationData.vu }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].velocity_diff || element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].velocity_diff === 0 ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].velocity_diff | number: '1.' + confirmationData.vp + '-' + confirmationData.vp)
                                            : '--'}}</td>
                                </ng-container>
                                <ng-container [matColumnDef]="CALCULATED_GAIN">
                                    <th matTooltip="{{ 'COMMON.CALCULATED_GAIN_HELP' | translate }}" mat-header-cell *matHeaderCellDef>{{ 'COMMON.CALCULATED_GAIN' | translate }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{
                                            element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculatedGain &&
                                            element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculatedGain !== 'NaN' &&
                                            element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculatedGain !== 'Infinity' ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculatedGain | number: '1.2-2')
                                            : '--'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="silt">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.SILT' | translate }} ({{ confirmationData.du }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;">
                                        {{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].silt ? element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].silt : '--'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="measured_flow">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.FLOW' | translate }} {{ flowSmallerUnit }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].measured_flow ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].measured_flow | number: '1.' + confirmationData.fp + '-' + confirmationData.fp)
                                            : '--'}}</td>
                                </ng-container>
                                <ng-container matColumnDef="calculated_flow">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.CALCULATED_FLOW' | translate }} ({{ confirmationData.fu }})</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculated_flow || element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculated_flow === null ?
                                            element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculated_flow ?
                                            (element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].calculated_flow | number: '1.' + confirmationData.fp + '-' + confirmationData.fp)
                                            : '' : '--'}}</td>
                                </ng-container>


                                <ng-container matColumnDef="blankIgnore"><td mat-cell *matCellDef="let entity"></td></ng-container>
                                <ng-container matColumnDef="ignore">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.IGNORE' | translate }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;" (click)="$event.stopPropagation()"
                                    ><mat-slide-toggle
                                        [disabled]="!isManageConfirmationPointsAllowed"
                                        [checked]="element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].ignore"
                                        (change)="changeConfirmationPointStatus($event, element)"></mat-slide-toggle></td>
                                </ng-container>

                                <ng-container matColumnDef="comment">
                                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.COMMENT' | translate }}</th>
                                    <td mat-cell *matCellDef="let element; let i = dataIndex; let ii = renderIndex;" class="break-word"
                                        >{{ element.data[ii-i*CONFIRMATION_ROWS_IN_SUMMARY].comment}}</td>
                                </ng-container>

                                <ng-container matColumnDef="blankAction"><td mat-cell *matCellDef="let entity"></td></ng-container>
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element" class="text-right w-55">
                                        <button
                                            mat-icon-button
                                            class="w-25"
                                            (click)="openPlottingConfirmation($event, element)"
                                            [disabled]="!isManageConfirmationPointsAllowed"
                                        >
                                            <mat-icon class="confirmation-edit-hint" matTooltip="Edit"> mode_edit </mat-icon>
                                        </button>
                                        <button
                                            mat-icon-button
                                            class="w-25"
                                            (click)="deleteConfirmationPoint($event, element)"
                                            [disabled]="!isManageConfirmationPointsAllowed"
                                        >
                                            <mat-icon class="confirmation-delete-hint"  matTooltip="Delete">delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="plottingConfirmationsColumns; sticky: true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: plottingConfirmationsColumns; let i = dataIndex;" (click)="confirmationToggleRow(row)"></mat-row>
                                <mat-row *matRowDef="let row; columns: plottingConfirmationsExtendedColumns; let i = dataIndex;" [class.hidden]="!row.expand"></mat-row>
                                <mat-row *matRowDef="let row; columns: plottingConfirmationsExtendedColumns; let i = dataIndex;" [class.hidden]="!row.expand"></mat-row>
                                <mat-row *matRowDef="let row; columns: plottingConfirmationsExtendedColumns; let i = dataIndex;" [class.hidden]="!row.expand"></mat-row>
                                <mat-row *matRowDef="let row; columns: plottingConfirmationsExtendedColumns; let i = dataIndex;" [class.hidden]="!row.expand"></mat-row>
                            </mat-table>
                        </div>
                        <div class="confirmations-footer sticky">

                            <mat-paginator
                                #confirmationPaginator
                                [length]="confirmationDataLength"
                                [pageIndex]="0"
                                [pageSize]="25"
                                (page)="confirmationOnPaginateChange($event)"
                                [pageSizeOptions]="[25, 50, 100]"
                            >
                            </mat-paginator>
                            <div class="app-flex-filler">
                                <span class="title">{{ 'COMMON.AVERAGES' | translate }}</span>
                                <div class="col">
                                    <div>
                                        {{ 'COMMON.DEPTH_DIFF' | translate }}:
                                        <span>{{ confirmationData && confirmationData.avgdd !== undefined
                                            ? (confirmationData.avgdd | number: '1.' + confirmationData.dp + '-' + confirmationData.dp)
                                            : '-'}}</span>
                                    </div>
                                    <div>
                                        {{ 'COMMON.CALCULATED_GAIN' | translate }}:
                                        <span>{{ confirmationData && confirmationData.avgcg !== undefined && isAvgcgNumeric
                                                ? (confirmationData.avgcg | number: '1.' + confirmationData.vp + '-' + confirmationData.vp)
                                                : '-'}}</span>
                                    </div>
                                    <div>
                                        {{ 'COMMON.MEASURED_SILT' | translate }}:
                                        <span>{{ confirmationData && confirmationData.avgs !== undefined
                                                ? (confirmationData.avgs | number: '1.' + confirmationData.vp + '-' + confirmationData.vp)
                                                : '-'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="auto-margin"></div>
                        </div>
                    </mat-card-content>

                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <span class="files-hint">
                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VIEW_LOCATION_FILES' | translate }}
                    </span>
                </ng-template>
                <ads-prism-vault-location
                    *ngIf="locationDetails.locationName"
                    [locationName]="locationDetails.locationName"
                    (locationDetailsUpdated)="getcurrentLocationDetails($event)"
                ></ads-prism-vault-location>
            </mat-tab>
            <!--Notes -->
            <mat-tab *ngIf="false">
                <ng-template mat-tab-label>
                    <span class="notes-hint">
                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VIEW_LOCATION_NOTES' | translate }}
                    </span>
                </ng-template>
                <div class="dateRangeHeader">
                    <div class="dateRangeText auto-margin">
                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NOTES' | translate }}
                    </div>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin custom-flat-button">
                        <button class="add-note-hint" mat-button (click)="addNote()">
                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.ADD_NOTES' | translate }}
                        </button>
                    </div>
                    <div class="auto-margin">
                        <mat-icon
                            class="backIcon filter-icon filter-hint"
                            matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                            [ngClass]="{ active: displayFilters }"
                            (click)="showHideFilter()"
                            >filter_list</mat-icon
                        >
                    </div>
                </div>
                <mat-card-content>
                    <div *ngIf="displayFilters">
                        <div class="filterSection category-filter">
                            <div class="eachSection">
                                <mat-form-field class="full-width">
                                    <mat-select
                                        placeholder="{{
                                            'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NOTES_CATEGORY' | translate
                                        }}"
                                    >
                                        <mat-option class="description-text">value</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="eachSection">
                                <button class="customDateRangeSelector full-width" mat-button>
                                    <span>{{ 'LOCATION_DASHBOARD.DATE_RANGE_SPAN' | translate }} </span>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="locationpagetable">
                        <mat-table #calculatedEntitiestable [dataSource]="viewLocationNotesDataSource">
                            <ng-container matColumnDef="category">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NOTES_CATEGORY' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let CalculatedEntitiesModel"
                                    >{{ CalculatedEntitiesModel.category }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <mat-header-cell *matHeaderCellDef>
                                    {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.DESCRIPTION' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let CalculatedEntitiesModel">
                                    {{ CalculatedEntitiesModel.description }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="startdate">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'COMMON.START_DATE' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let CalculatedEntitiesModel">
                                    {{ CalculatedEntitiesModel.startdate }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="enddate">
                                <mat-header-cell *matHeaderCellDef
                                    >{{ 'COMMON.END_DATE' | translate }}
                                </mat-header-cell>
                                <mat-cell *matCellDef="let CalculatedEntitiesModel"
                                    >{{ CalculatedEntitiesModel.enddate }}
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let element" class="text-right">
                                    <button mat-icon-button>
                                        <mat-icon matTooltip="Edit" (click)="editCalculatedEntity(element)">
                                            mode_edit
                                        </mat-icon>
                                    </button>
                                    <button mat-icon-button>
                                        <mat-icon matTooltip="Delete" (click)="deleteCalculatedEntity(element)"
                                            >delete
                                        </mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="viewLocationNotesColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: viewLocationNotesColumns"></mat-row>
                        </mat-table>
                    </div>
                </mat-card-content>
            </mat-tab>
            <mat-tab *ngIf="!isRainGauge && isAdvancedReportsAllowed">
                <ng-template mat-tab-label>
                    <span class="flow-balance-hint">
                        {{ 'FLOW_BALANCE.FLOW_BALANCE' | translate }}
                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.VIEW_LOCATION_REPORT' | translate }}
                    </span>
                </ng-template>
                <div class="associated-flow-balance">
                    <app-loader [isLoading]="isFlowBalanceData"></app-loader>
                    <div class="dateRangeHeader" *ngIf="isFlowBalanceVisible && !showEditFlowBalance">
                        <div class="dateRangeText auto-margin">
                            {{ 'FLOW_BALANCE.FLOW_BALANCE' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin custom-flat-button">
                            <button
                                class="edit generate-button-hint"
                                mat-button
                                matTooltip="{{ 'FLOW_BALANCE.GENERATE_REPORT' | translate }}"
                                (click)="redirectToFlowbalanceReport()"
                                [disabled]="!isFlowBalanceDataAvailable"
                            >
                                {{ 'FLOW_BALANCE.GENERATE_REPORT' | translate }}
                            </button>
                        </div>
                        <div class="auto-margin custom-flat-button">
                            <button
                                class="edit flow-balance-edit-hint"
                                mat-button
                                matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                                (click)="openFlowBalance()"
                            >
                                <mat-icon>edit</mat-icon>{{ 'COMMON.EDIT_TOOLTIP' | translate }}
                            </button>
                        </div>
                    </div>
                    <mat-card-content *ngIf="!showEditFlowBalance">
                        <div id="locationpagetable" *ngIf="isFlowBalanceDataAvailable && isFlowBalanceVisible">
                            <mat-table [dataSource]="flowBalanceDataSource">
                                <ng-container matColumnDef="name">
                                    <mat-header-cell *matHeaderCellDef>
                                        {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let entity">{{ entity.flowBalanceLocation }} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="flow">
                                    <mat-header-cell *matHeaderCellDef
                                        >{{ 'COMMON.FLOW' | translate }}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let entity">
                                        <span *ngIf="entity.flowType === 1">+</span>
                                        <span *ngIf="entity.flowType === 2">-</span>
                                    </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="flowBalanceColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: flowBalanceColumns"></mat-row>
                            </mat-table>
                        </div>
                        <div class="no-data-found-section" *ngIf="!isFlowBalanceDataAvailable && isFlowBalanceVisible">
                            <div class="no-data-content">
                                <div class="no-data-found-img"></div>
                                <div class="no-data-found-text custom-header">
                                    <span class="header-text">{{
                                        'FLOW_BALANCE.FLOW_BALANCE_NO_DATA' | translate
                                    }}</span>
                                    <span class="sub-header-text">{{
                                        'FLOW_BALANCE.FLOW_BALANCE_CHOOSE_EDIT' | translate
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-content *ngIf="!isFlowBalanceVisible">
                        <div class="page-marketing-screen">
                            <img src="assets/images/flow.png" class="pad-b-15" alt="flow" />
                            <h3 class="width-30-p flow-balance-report-content auto-margin">
                                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FLOW_BALANCE_SETUP' | translate }}
                            </h3>
                            <p class="width-30-p content-color auto-margin">
                                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FLOW_BALANCE_GAUGE' | translate }}
                            </p>
                            <button mat-button class="contact-button" (click)="contactUs()">
                                {{ 'COMMON.CONTACT_US' | translate }}
                            </button>
                        </div>
                    </mat-card-content>
                    <div *ngIf="showEditFlowBalance">
                        <app-add-edit-flow-balance
                            [flowBalanceData]="flowBalanceData"
                            (renderSection)="renderCreateSection($event, true)"
                        ></app-add-edit-flow-balance>
                    </div>
                    <div class="customFooter" *ngIf="isFlowBalanceDataAvailable && !showEditFlowBalance">
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin pad-r-15">+ {{ 'FLOW_BALANCE.UPSTREAM' | translate }}</div>
                        <div class="auto-margin pad-r-15">- {{ 'FLOW_BALANCE.OVERFLOW' | translate }}</div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab *ngIf="showLocationDiagnostics">
                <ng-template mat-tab-label>
                    <span class="advanced-hint">
                        {{ 'COMMON.ADVANCED_TEXT' | translate }}
                    </span>
                </ng-template>
                <app-location-diagnostics
                    [customerId]="customerId"
                    [locationId]="locationId"
                    [deviceTypes]="deviceTypes"
                    [monitorSeriesType]="locationDetails.series"
                ></app-location-diagnostics>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
