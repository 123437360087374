<div class="location-notes-dialog">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div
        class="popupHeader title"
        mat-dialog-title
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        >
        <span> {{ 'LOCATION_DASHBOARD.NOTES.ENTER_A_NOTE_FOR_A_LOCATION' | translate }}</span>
    </div>
    <div mat-dialog-content>
        <div class="left">
            <div class="header"><span>LOCATION</span></div>
            <div class="search">
                <input
                    matInput
                    placeholder="{{ 'LOCATION_DASHBOARD.NOTES.ENTER_A_LOCATION' | translate }}"
                    [(ngModel)]="searchText"
                    (ngModelChange)="filterLocations()"
                />
                <button [disabled]="searchText === undefined || searchText === null || searchText === ''" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="table-container">
                <table>
                    <tr
                        *ngFor="let item of filteredLocations"
                        [ngClass]="{'selected': item.locationId === selectedLocationId}"
                        (click)="selectRow(item)"
                        [id]="TR_ID_PREFIX+item.locationId"
                    >
                        <td>{{item.name}}</td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="right">
            <div class="header-container">
                <div class="header">
                    <span>{{ 'LOCATION_DASHBOARD.NOTES.NOTES_FOR' | translate | uppercase }} {{ selectedLocationName }}</span>
                </div>
                <div>
                    <button class="revert-btn" mat-button (click)="addNoteOpen()">{{ 'LOCATION_DASHBOARD.NOTES.ADD_NOTE' | translate }}</button>
                </div>
            </div>
            <div *ngIf="isAddNote">
                <div class="text-container">
                    <textarea
                        matInput
                        [(ngModel)]="locationText"
                    ></textarea>
                    <div class="bottom-info">
                        <div class="bottom-actions">
                            <button mat-button class="revert-btn" [disabled]="locationText === ''" (click)="addNoteSave()" matTooltip="{{ 'COMMON.SAVE_BUTTON' | translate }}"><mat-icon>save</mat-icon></button>
                            <button mat-button (click)="addNoteClose()" matTooltip="{{ 'COMMON.CANCEL' | translate }}"><mat-icon>close</mat-icon></button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!isLoading && !isAddNote && (!notes || !notes.length)" class="no-notes"><span>{{ 'LOCATION_DASHBOARD.NOTES.NO_NOTES_FOR_LOCATION' | translate }}</span></div>
            <div *ngFor="let note of notes; let i = index;">
                <div class="flex">
                    <div *ngIf="note.isPinned">
                        <span class="material-symbols-outlined fill-icon">bookmark</span>
                    </div>
                    <div class="text-container">
                        <textarea
                            *ngIf="editIndex === i"
                            matInput
                            [(ngModel)]="note.text"
                            ></textarea>
                        <span *ngIf="editIndex !== i" (click)="editNoteOpen(i)" class="text-note">
                            {{note.text}}
                        </span>
                        <div class="bottom-info">
                            <div class="bottom-text">
                                <div>
                                    {{ 'COMMON.CREATED_DATE_BY_USER' | translate | replace: '%1': (note.createdDate | date: customerDateFormat) | replace: '%2': note.createdBy }}
                                </div>
                                <div *ngIf="note.modifiedBy">
                                    {{ 'COMMON.MODIFIED_DATE_BY_USER' | translate | replace: '%1': (note.modifiedDate | date: customerDateFormat) | replace: '%2': note.modifiedBy }}
                                </div>
                            </div>
                            <div class="bottom-actions">
                                <button mat-button (click)="editNoteSave(note)" [disabled]="note.text === ''" *ngIf="editIndex === i" matTooltip="{{ 'COMMON.SAVE_BUTTON' | translate }}"><mat-icon>save</mat-icon></button>
                                <button mat-button (click)="editNoteCancel()" *ngIf="editIndex === i" matTooltip="{{ 'COMMON.CANCEL' | translate }}"><mat-icon>close</mat-icon></button>
                                <button mat-button (click)="notePin(note, true)" *ngIf="editIndex !== i && !note.isPinned" matTooltip="{{ 'COMMON.PIN' | translate }}">
                                    <span class="material-symbols-outlined">keep</span>
                                </button>
                                <button mat-button (click)="notePin(note, false)" *ngIf="editIndex !== i && note.isPinned" matTooltip="{{ 'COMMON.UNPIN' | translate }}">
                                    <span class="material-symbols-outlined">keep_off</span>
                                </button>
                                <button mat-button (click)="noteDelete(note.id)" [disabled]="editIndex === i" matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"><mat-icon>delete</mat-icon></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div
        mat-dialog-actions
        class="actions"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
    >
        <button class="revert-btn" mat-button (click)="download()">{{ 'COMMON.DOWNLOAD' | translate }}</button>
        <button mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>

    </div>
</div>
