import { Injectable } from '@angular/core';

import { AreaSeriesPositive, Axis, ChartXY, Color, ColorHEX, DashedLine, IndividualPointFill, LUT, LineSeries, PalettedFill, PointLineSeries, PointShape, SeriesXY, SolidFill, SolidLine, StipplePatterns, UIDraggingModes, UIElementBuilders, UIOrigins, ZoomBandChartSupportedSeries, emptyFill, emptyLine } from '@arction/lcjs';
import { LightningChartObject } from '../lightning-chart-object/lightning-chart-object';

import { BROWN_COLOR, DATA_QUALITY_COLORS, DEPTH_ENTITY, EDIT_COLOR, RAIN_DISPLAY_GROUP, RAIN_HEXCODE_FLAGGED, RAIN_HEXCODE_UNFLAGGED } from 'app/shared/constant';
import { HydrographNEWService } from 'app/shared/services/hydrographNEW.service';
import { CHART_COLOR_SELECTIONBOX_FILL, CHART_COLOR_SELECTIONBOX_STROKE, ChartPointSize, LC_RAIN_OVERVIEW_ALPHA, LC_RAIN_THICKNESS, LC_POINTSERIES_THICKNESS, LC_SERIES_THICKNESS } from './lightning-chart-ui-constants';
import { ChartDashStyle, GraphAnnotationSerieConfiguration, ChartPointShape, ChartSerieType } from '../hydrograph-data/hydrograph-data-model';
import { LCValues, LightningChartDataPoint, LightningChartSerie } from './lightning-chart-data-model';
import { MAX_RAIN_IN, MAX_RAIN_MM } from 'app/shared/models/units';


const TWO_HOURS = 2 * 60 * 60 * 1000;
const FIFE_MINUTES = 5 * 60 * 1000;



/**
 *
 * Create Lightning Charts series based on arguments.
 * Used by LightningChartBuilder to build series for a chart.
 *
 * Angular Singleton
 * IMPORTANT: IT SHOULD NOT CONTAIN ANY ATTRIBUTES, everything should be "static"
 *
 */
@Injectable({
    providedIn: 'root'
  })
export class LightningChartSeriesFactory {

    constructor(
        private hydroService: HydrographNEWService,
    ) {
    }

    private applySeriesOptions(serie: SeriesXY) {
        serie.setMouseInteractions(false);
        serie.setEffect(false);
    }

    private serieColors(): {
        edit: Color;
        flagged: Color;
        selected: Color;
        ignored: Color;
    } {
        return {
            edit: ColorHEX(EDIT_COLOR),
            flagged: ColorHEX(this.hydroService.getMarkerTypes().flagged.fillColor),
            selected: ColorHEX(this.hydroService.getMarkerTypes().selected.fillColor),
            ignored: ColorHEX(BROWN_COLOR),
        };
    }

    private dataQualityColors(): Color[] {
        const colors = [];
        for(const c of DATA_QUALITY_COLORS) {
            colors.push(ColorHEX(c));
        }
        return colors;
    }

    private getYAxis(chartIndex: number, yAxisIndex: number, chart: ChartXY, lcChart: LightningChartObject) {
        return yAxisIndex === 0 ? chart?.getDefaultAxisY() : lcChart.yAxes[chartIndex] ? lcChart.yAxes[chartIndex] : chart?.getDefaultAxisY();
    }

    public prepareRainData(serieData: LightningChartDataPoint[]): LightningChartDataPoint[] {
        // #37628 Rain should be presented in Overview chart more as bars, so we need to add missing points
        let prevX = undefined;
        const processedSerieData = [];
        if(serieData.length > 0) {

            for(let i = 0; i < serieData.length; i++) {
                const v = serieData[i];

                if(v.y === undefined || v.y === null) continue;

                if(prevX === undefined || prevX + TWO_HOURS < v.x) {
                    if(prevX !== undefined) {
                        const newPrevPoint = {
                            x: prevX + FIFE_MINUTES / 2,
                            y: 0
                        }
                        processedSerieData.push(newPrevPoint);
                    }

                    const newNextPoint = {
                        x: v.x - FIFE_MINUTES / 2,
                        y: 0
                    }
                    processedSerieData.push(newNextPoint);
                }

                processedSerieData.push(v);
                prevX = v.x;
            }

            processedSerieData.push({
                x: prevX + FIFE_MINUTES / 2,
                y: 0
            });
        }

        return processedSerieData;
    }

    public setupRainInZoomBandChart(lcChart: LightningChartObject, chart: ChartXY, yAxis: Axis, serieDef:LightningChartSerie ) {
        const serieData = serieDef.data;
        if(serieDef && serieDef.showInZoomBand) {
            lcChart.rainOverviewSeries?.dispose();

            const processedSerieData = this.prepareRainData(serieData);

            lcChart.rainOverviewSeries = chart.addAreaSeries({
                type: AreaSeriesPositive,
                yAxis: yAxis
            });

            lcChart.rainOverviewSeries.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX(serieDef.color)})}));

            lcChart.rainOverviewSeries.setVisible(false);

            this.applyZoomBandColor(lcChart, lcChart.rainOverviewSeries, serieDef);
            lcChart.rainOverviewSeries.add([...processedSerieData]);
        }
    }

    private applyZoomBandColor(lcChart: LightningChartObject, originalSeries: ZoomBandChartSupportedSeries, serieDef: LightningChartSerie) {
        // #37255 Maintain color on ZoomBandChart
        if(serieDef.showInZoomBand) {
            const extraSerie = lcChart.zoomBandChart.add(originalSeries);

            extraSerie.setEffect(false);
            extraSerie.setVisible(true);

            if(extraSerie instanceof LineSeries || extraSerie instanceof PointLineSeries) {
                extraSerie.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX(serieDef.color)})}).setThickness(LC_SERIES_THICKNESS));
            }
            if(extraSerie instanceof AreaSeriesPositive) {
                extraSerie.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX(serieDef.color)})}).setThickness(LC_SERIES_THICKNESS));
                extraSerie.setFillStyle(new SolidFill({color: ColorHEX(`${serieDef.color}${LC_RAIN_OVERVIEW_ALPHA}`)}));
            }

            if(extraSerie instanceof PointLineSeries) {
                extraSerie.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX(serieDef.color)})}).setThickness(LC_SERIES_THICKNESS));
                extraSerie.setPointFillStyle(new IndividualPointFill({color: ColorHEX(serieDef.color)}));
                extraSerie.setIndividualPointValueEnabled(true);
            }

        }
    }

    private applyAxisExtremes(lcChart: LightningChartObject, yAxis: Axis, chartIndex: number, yAxisIndex: number, customRanges?: {min?: number, max?: number}) {
        const extremes = lcChart.getExtremesForYAxis(chartIndex, yAxisIndex);

        yAxis.setInterval({
            start: customRanges && ![null, undefined].includes(customRanges.min) ? customRanges.min : extremes.min,
            end: customRanges && customRanges.max ? customRanges.max : extremes.max
        });
    }

    public addPointLineSerie(lcChart: LightningChartObject, serieDef: LightningChartSerie, customRanges?: {min?: number, max?: number}): SeriesXY {
        let serieData = serieDef.data;
        if(serieData && serieDef.yAxis !== undefined) {
            const {chartIndex, yAxisIndex} = lcChart.seriePlacement(serieDef);
            const chart = lcChart.charts[chartIndex];
            const yAxis = this.getYAxis(chartIndex, yAxisIndex, chart, lcChart);


            const lineSeries = chart.addPointLineSeries({
                yAxis: yAxis
            });

            lineSeries.setPointSize(ChartPointSize.DataPoint);

            this.applyAxisExtremes(lcChart, yAxis, chartIndex, yAxisIndex, customRanges);

            lineSeries.setName(serieDef.entityName);

            lineSeries.setStrokeStyle(new SolidLine({fillStyle: new SolidFill({color: ColorHEX(serieDef.color)})}).setThickness(LC_POINTSERIES_THICKNESS));

            lineSeries.setPointFillStyle(new IndividualPointFill({color: ColorHEX(serieDef.color)}));
            lineSeries.setIndividualPointValueEnabled(true);
            if(lcChart.annotations.isDataQuality) {
                serieData = serieData.map(p => {
                    const segmentColor = this.hydroService.getDataQualityIndex(p.value);
                    const color = ColorHEX(DATA_QUALITY_COLORS[segmentColor]);
                    return {...p, color: color}
                });
            } else {
                serieData = serieData.map(p => {
                    const color =
                        p.value === LCValues.Data ? ColorHEX(serieDef.color) :
                        p.value === LCValues.Edited ? ColorHEX(EDIT_COLOR) :
                        p.value === LCValues.Flagged ? ColorHEX(this.hydroService.getMarkerTypes().flagged.fillColor) :
                        p.value === LCValues.Selected ? ColorHEX(this.hydroService.getMarkerTypes().selected.fillColor) :
                        ColorHEX(serieDef.color);

                    return {...p, color: color}
                });
            }

            this.applyZoomBandColor(lcChart, lineSeries, serieDef);
            lineSeries.add([...serieData]);


            this.applySeriesOptions(lineSeries);

            return lineSeries;
        }

        return null;
    }



    public addAnnotationSerie(lcChart: LightningChartObject, serieDef: GraphAnnotationSerieConfiguration, customRanges?: {min?: number, max?: number}): SeriesXY {
        const serieData = serieDef.data;
        if(serieData && serieDef.yAxis !== undefined) {
            const {chartIndex, yAxisIndex} = lcChart.seriePlacement(serieDef);
            const chart = lcChart.charts[chartIndex];
            const yAxis = this.getYAxis(chartIndex, yAxisIndex, chart, lcChart);
            const name = serieDef.name;

            const lineSeries = chart.addLineSeries({
                yAxis: yAxis,
                individualLookupValuesEnabled: true
            });

            this.applyAxisExtremes(lcChart, yAxis, chartIndex, yAxisIndex, customRanges);

            lineSeries.setName(name);

            switch(serieDef.dashStyle) {
                case ChartDashStyle.Dash:
                    lineSeries.setStrokeStyle((stroke) =>
                        new DashedLine({
                            fillStyle: new SolidFill({color: ColorHEX(serieDef.color)}),
                            thickness: stroke.getThickness(),
                            pattern: StipplePatterns.Dashed,
                            patternScale: 4,
                        }));
                    break;
                case ChartDashStyle.Solid:
                    lineSeries.setStrokeStyle((stroke) =>
                        new SolidLine({
                            fillStyle: new SolidFill({color: ColorHEX(serieDef.color)}),
                            thickness: stroke.getThickness(),
                        }));
                    break;
                default:
                    break;
            }

            const firstPoint = serieData[0] || {x: 0, y: null};

            const textBox = chart.addUIElement(UIElementBuilders.TextBox, { x: chart.getDefaultAxisX(), y: yAxis })
                .setText(name)
                .setPosition({x: lcChart.xExtremes.maxx, y: firstPoint.y})
                // This does Works opposite - RightBottom is for true LeftTop
                .setOrigin(UIOrigins.RightBottom)
                .setVisible(true)
                .setTextFont((font) => font.setSize(12))
                .setTextFillStyle(new SolidFill({color: ColorHEX(serieDef.color)}))
                .setBackground((background) => background
                    .setFillStyle(emptyFill)
                    .setStrokeStyle(emptyLine)
                )
                .setDraggingMode(UIDraggingModes.notDraggable);

            lcChart.uiSeriesLabels.push(textBox);

            lineSeries.add([...serieData]);
            lineSeries.getStrokeStyle().setFillStyle(new SolidFill({color: ColorHEX(serieDef.color)}));
            lineSeries.getStrokeStyle().setThickness(LC_SERIES_THICKNESS);

            this.applySeriesOptions(lineSeries);

            return lineSeries;
        }

        return null;
    }

    public addLineSerie(lcChart: LightningChartObject, serieDef: LightningChartSerie, customRanges?: {min?: number, max?: number}): SeriesXY {
        const serieData = serieDef.data;

        if(serieData && serieDef.yAxis !== undefined) {
            const {chartIndex, yAxisIndex} = lcChart.seriePlacement(serieDef);
            const chart = lcChart.charts[chartIndex];
            const yAxis = this.getYAxis(chartIndex, yAxisIndex, chart, lcChart);
            const name = serieDef.entityName;

            const lineSeries = chart.addPointLineAreaSeries({
                dataStorage: Float64Array,
                dataPattern: 'ProgressiveX',
                yAxis: yAxis,
                allowDataGrouping: false,
                colors: true
            });

            lineSeries.setPointFillStyle(emptyFill);
            lineSeries.setStrokeStyle(stroke => stroke.setFillStyle(new IndividualPointFill()));
            lineSeries.setPointSize(10);
            lineSeries.setAreaFillStyle(emptyFill);
            lineSeries.setVisible(lcChart?.seriesDefition[serieDef.entityId]?.visible);
            this.applyAxisExtremes(lcChart, yAxis, chartIndex, yAxisIndex, customRanges);

            lineSeries.setName(name);

            this.applyZoomBandColor(lcChart, lineSeries, serieDef);

            const dataQualityColors = this.dataQualityColors();
            const serieColor =  ColorHEX(serieDef.color);
            const serieColors = this.serieColors();

            const xValues = [];
            const yValues = [];
            const colors = [];
            serieData.forEach((p) => {
                xValues.push(p.x);
                yValues.push(p.y);
                if(lcChart.annotations?.isDataQuality) {
                    colors.push(dataQualityColors[p.value]);
                } else {
                    colors.push(
                        p.value === LCValues.Selected ? serieColors.selected
                        : p.value === LCValues.Flagged ? serieColors.flagged
                        : p.value === LCValues.Edited ? serieColors.edit
                        : p.value === LCValues.Ignored ? serieColors.ignored
                        : serieColor
                    );
                }
            });

            lineSeries.appendSamples({
                xValues: xValues,
                yValues: yValues,
                colors: colors
            });

            this.applySeriesOptions(lineSeries);
            return lineSeries;
        }

        return null;
    }

    public addRectangleSerie(lcChart: LightningChartObject, serieDef: LightningChartSerie, customRanges?: {min?: number, max?: number, autoOpt?: boolean}): SeriesXY {
        const serieData = serieDef.data;
        if(serieData) {
            const {chartIndex, yAxisIndex} = lcChart.seriePlacement(serieDef);
            const chart = lcChart.charts[chartIndex];
            const yAxis = this.getYAxis(chartIndex, yAxisIndex, chart, lcChart);

            const barSeries = chart.addSegmentSeries({
                yAxis: yAxis
            });

            const extremes = lcChart.getExtremesForYAxis(chartIndex, yAxisIndex, true);

            const isRain = serieDef.displayGroupId === RAIN_DISPLAY_GROUP;

            let start = customRanges && ![null, undefined].includes(customRanges.min) ? customRanges.min : isRain ? extremes.min : 0;
            let end = customRanges && customRanges.max ? customRanges.max : isRain ? (lcChart.isMetrics ? MAX_RAIN_MM : MAX_RAIN_IN) : (extremes.max);
            if(customRanges?.autoOpt) {
                start = extremes.min;
                end = extremes.max;
            }

            if(lcChart.annotations?.isRainOnTop) {
                yAxis.setInterval({start: end, end: start});
            } else {
                yAxis.setInterval({start: start, end: end});
            }

            barSeries.setName(serieDef.entityName);
            for(const entry of serieData) {
                if(entry.y > 0) {
                    const rectDimensions = {
                        startX: entry.x,
                        startY: 0,
                        endX: entry.x,
                        endY: entry.y
                    }
                    const bar = barSeries.add(rectDimensions);

                    const color = entry.value === LCValues.Flagged ? RAIN_HEXCODE_FLAGGED : serieDef.color;
                    bar.setStrokeStyle(
                        new SolidLine({
                            thickness: LC_RAIN_THICKNESS,
                            fillStyle: new SolidFill({ color: ColorHEX(color) }),
                        })
                    )

                }
            }

            this.applySeriesOptions(barSeries);

            this.setupRainInZoomBandChart(lcChart, chart, yAxis, serieDef);

            return barSeries;
        }

        return null;
    }

    public addScatterSerie(lcChart: LightningChartObject, serieDef: LightningChartSerie, customRanges?: {min?: number, max?: number}): SeriesXY {
        const serieData = serieDef.data;
        if(serieData && serieDef.yAxis !== undefined) {
            const {chartIndex, yAxisIndex} = lcChart.seriePlacement(serieDef);
            const chart = lcChart.charts[chartIndex];
            const yAxis = this.getYAxis(chartIndex, yAxisIndex, chart, lcChart);

            const pointSize = this.pointSizeForDef(serieDef.seriesType) as number;
            const pointShape = this.pointShapeForDef(serieDef.shape);

            const scatterSeries = chart.addPointSeries({
                yAxis: yAxis,
                pointShape: pointShape
                });

            scatterSeries
                .setIndividualPointValueEnabled(true)
                .setPointFillStyle(new SolidFill({color: ColorHEX(serieDef.color)}))
                .setPointSize(pointSize);


            const steps = [
                { value: LCValues.Edited, color: ColorHEX(EDIT_COLOR) },
                { value: LCValues.Data, color: ColorHEX(serieDef.color) },
                { value: LCValues.Flagged, color: ColorHEX(this.hydroService.getMarkerTypes().flagged.fillColor)},
                { value: LCValues.Selected, color: ColorHEX(this.hydroService.getMarkerTypes().selected.fillColor) },
            ];

            scatterSeries.setPointFillStyle(new PalettedFill({
                    lut: new LUT({
                        steps: steps,
                        interpolate: false,
                    }),
                })
            );

            this.applyAxisExtremes(lcChart, yAxis, chartIndex, yAxisIndex, customRanges);

            scatterSeries.setName(serieDef.entityName);

            this.applyZoomBandColor(lcChart, scatterSeries, serieDef);
            scatterSeries.add([...serieData]);

            this.applySeriesOptions(scatterSeries);

            return scatterSeries;
        }

        return null;
    }


    public createSelectionSeries(lcChart: LightningChartObject, chart: ChartXY, index: number) {
        if(!lcChart.edit) return;

        lcChart.selectionSeries[index] = chart.addRectangleSeries();
        lcChart.edit.selection[index] = lcChart.selectionSeries[index]
            .add({ x1: 0, y1: 0, x2: 0, y2: 0 })
            .setFillStyle(
                new SolidFill({ color: ColorHEX(CHART_COLOR_SELECTIONBOX_FILL) }),
            )
            .setStrokeStyle(
                new SolidLine({
                    thickness: 2,
                    fillStyle: new SolidFill({ color: ColorHEX(CHART_COLOR_SELECTIONBOX_STROKE) }),
                }),
            )
            .setMouseInteractions(false)
            .setVisible(false);
    }


    public pointShapeForDef(shapeDef: ChartPointShape): PointShape {
        switch(shapeDef) {
            case ChartPointShape.Triangle: return PointShape.Triangle;
            case ChartPointShape.Square: return PointShape.Square;
            case ChartPointShape.Circle:
            default:
                return PointShape.Circle;
        }
    }

    public pointSizeForDef(serieType: ChartSerieType): ChartPointSize {
        switch(serieType) {
            case ChartSerieType.ScatterConfirmation:
                return ChartPointSize.Confirmation;
            case ChartSerieType.ScatterOiriginalEdits:
                return ChartPointSize.OriginalEdit;
            case ChartSerieType.Line:
            case ChartSerieType.Column:
            default:
                return ChartPointSize.DataPoint;
        }
    }
}
