<div class="customer-editor contentLayout" [hidden]="showNewLocationEditor">
    <mat-card>
        <mat-card-header>
            <mat-icon *ngIf="showNewCustomerEditor" class="backIcon auto-margin" (click)="exitCustomerEditor($event)"
                >arrow_back</mat-icon
            >
            <div class="auto-margin">{{ headerName }} : {{ customerNameHeader || customerName }}</div>
            <div class="app-flex-filler"></div>
        </mat-card-header>
        <div class="new-customer-editor">
            <app-loader [isLoading]="customersLoadingState"></app-loader>
            <div id="customerDetailsId">
                <mat-tab-group [selectedIndex]="tabIndex" (selectedTabChange)="onSelectedTabChange($event, tabs)" #tabs>
                    <mat-tab label="{{ 'CUSTOMER_EDITOR.DETAILS_LABEL' | translate }}">
                        <mat-card-content>
                            <form #addCustomerForm="ngForm" name="addCustomerForm" novalidate class="topPadding">
                                <div class="row">
                                    <div
                                        class="custom-error"
                                        [ngClass]="{ 'pad-b-15': customerNameInput?.errors?.pattern }"
                                    >
                                        <mat-form-field class="full-width edit-cus-name-hint">
                                            <input
                                                matInput
                                                name="customerNameInput"
                                                id="customerNameInput"
                                                placeholder="{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}"
                                                [(ngModel)]="customerName"
                                                (ngModelChange)="validateDuplicateCustomerName()"
                                                #customerNameInput="ngModel"
                                                required
                                                minlength="6"
                                                maxlength="64"
                                                [pattern]="customerNamePattern"
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span *ngIf="isValidCustomerName !== null && isValidCustomerName === false"
                                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    customerNameInput?.errors?.minlength || customerNameLengthIsInvalid
                                                "
                                            >
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_NAME_LENGTH_VALIDATION' | translate }}
                                            </span>
                                            <span *ngIf="customerNameInput?.errors?.maxlength">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_VALIDATION_MSG' | translate }}
                                            </span>
                                            <span *ngIf="customerNameInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_PATTERN_VALIDATION' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="showUpdateCustomerEditor" class="custom-error">
                                        <mat-form-field class="full-width edit-cus-short-name-hint">
                                            <input
                                                matInput
                                                name="shortNameInput"
                                                id="shortNameInput"
                                                placeholder="{{ 'CUSTOMER_EDITOR.CUSTOMER_SHORT_NAME' | translate }}"
                                                [(ngModel)]="shortName"
                                                (ngModelChange)="validateShortDuplicateCustomerName()"
                                                #shortNameInput="ngModel"
                                                required
                                                minlength="3"
                                                maxlength="32"
                                                [pattern]="alphaNumericWithoutSpacePattern"
                                                disabled
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span
                                                *ngIf="
                                                    isValidCustomerShortName !== null &&
                                                    isValidCustomerShortName === false
                                                "
                                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    shortNameInput?.errors?.minlength ||
                                                    customerShortNameLengthIsInvalid
                                                "
                                            >
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_VALIDATION' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.maxlength">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_NAME_LONG_MSG' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_PATTERN_ERR' | translate }}
                                            </span>
                                        </div>
                                    </div>

                                    <div *ngIf="!showUpdateCustomerEditor" class="custom-error">
                                        <mat-form-field class="full-width edit-cus-short-name-hint">
                                            <input
                                                matInput
                                                name="shortNameInput"
                                                id="shortNameInput"
                                                placeholder="{{ 'CUSTOMER_EDITOR.CUSTOMER_SHORT_NAME' | translate }}"
                                                [(ngModel)]="shortName"
                                                (ngModelChange)="validateShortDuplicateCustomerName()"
                                                #shortNameInput="ngModel"
                                                required
                                                minlength="3"
                                                maxlength="32"
                                                [pattern]="alphaNumericWithoutSpacePattern"
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span
                                                *ngIf="
                                                    isValidCustomerShortName !== null &&
                                                    isValidCustomerShortName === false
                                                "
                                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                                            >
                                            <span
                                                *ngIf="
                                                    shortNameInput?.errors?.minlength ||
                                                    customerShortNameLengthIsInvalid
                                                "
                                            >
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_VALIDATION' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.maxlength">
                                                {{ 'CUSTOMER_EDITOR.CUSTOMER_NAME_LONG_MSG' | translate }}
                                            </span>
                                            <span *ngIf="shortNameInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.SHORT_NAME_PATTERN_ERR' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="showUpdateCustomerEditor" class="">
                                        <mat-form-field class="full-width edit-cus-ttl-hint">
                                            <input
                                                matInput
                                                name="dataTTL"
                                                id="dataTTL"
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.TIME_TO_LIVE_PLACEHOLDER' | translate
                                                }}"
                                                [(ngModel)]="dataTTL"
                                                #shortNameInput="ngModel"
                                                disabled
                                            />
                                        </mat-form-field>
                                    </div>
                                    <div *ngIf="!showUpdateCustomerEditor" class="custom-error">
                                        <mat-form-field class="full-width edit-cus-ttl-hint">
                                            <input
                                                matTooltip="{{ 'CUSTOMER_EDITOR.DATA_TTL_TOOLTIP' | translate }}"
                                                matTooltipPosition="above"
                                                matInput
                                                name="dataTTL"
                                                id="dataTTL"
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.TIME_TO_LIVE_PLACEHOLDER' | translate
                                                }}"
                                                [(ngModel)]="dataTTL"
                                                #datTTLInput="ngModel"
                                                required
                                                [pattern]="numeric3_36Pattern"
                                            />
                                        </mat-form-field>
                                        <span class="custom-error-message" *ngIf="datTTLInput?.errors?.pattern">
                                            {{ 'CUSTOMER_EDITOR.TTL_VALIDATION' | translate }}
                                        </span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="customersDropdown">
                                        <mat-form-field class="full-width edit-cus-units-hint">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.UNITS_TEXT' | translate }}"
                                                [(ngModel)]="units"
                                                (selectionChange)="setUnit()"
                                                name="displayUnits"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let units of unitsofMeasure"
                                                    [value]="units.value"
                                                    >{{ units.text }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="">
                                        <mat-form-field class="full-width edit-cus-date-format-hint">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.DATE_FORMAT' | translate }}"
                                                [(ngModel)]="dateFormat"
                                                name="displayDateFormat"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let dateFormat of dateFormatType"
                                                    [value]="dateFormat.value"
                                                    >{{ dateFormat.text }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="">
                                        <mat-form-field class="full-width edit-cus-time-format-hint">
                                            <mat-select
                                                placeholder="{{ 'CUSTOMER_EDITOR.TIME_FORMAT' | translate }}"
                                                [(ngModel)]="timeFormat"
                                                name="displayTimeFormat"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let timeFormat of timeFormatType"
                                                    [value]="timeFormat.value"
                                                    >{{ timeFormat.text }}</mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="">
                                        <mat-form-field class="full-width edit-cus-timezone-hint">
                                            <mat-select
                                                placeholder="{{ 'COMMON.TIMEZONE_PLACEHOLDER' | translate }}"
                                                [(ngModel)]="timeZone"
                                                name="displayTimeZone"
                                                class="full-width"
                                                required
                                            >
                                                <mat-option
                                                    *ngFor="let timeZone of timeZoneType"
                                                    [value]="timeZone.value"
                                                    >{{ timeZone.text }} ({{ timeZone.utcValue }})
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="">
                                        <mat-form-field class="full-width edit-cus-work-order-hint">
                                            <input
                                                matInput
                                                name="workOrderLinkInput"
                                                #workOrderLinkInput="ngModel"
                                                id="workOrderLinkInput"
                                                placeholder="{{ 'COMMON.CUSTOMER_LINK_TEXT' | translate }}"
                                                [(ngModel)]="workOrderLink"
                                                [pattern]="validLinkPattern"
                                                maxlength="512"
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span *ngIf="workOrderLinkInput?.errors?.pattern">
                                                {{ 'CUSTOMER_EDITOR.URL_PATTERN_VALIDATION' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                    <mat-form-field class="full-width edit-cus-idle-time-hint">
                                        <input
                                            matInput
                                            name="idleTime"
                                            #idleTimeOutInput="ngModel"
                                            id="idleTimeOutInput"
                                            placeholder="{{ 'COMMON.IDLE_TIMEOUT' | translate }}"
                                            [(ngModel)]="idleTimeOut"
                                            type="number"
                                            matTooltip="{{ 'CUSTOMER_EDITOR.IDLE_TIMEOUT_TOOLTIP' | translate }}"
                                        />
                                    </mat-form-field>
                                </div>

                                <div class="pad-b-20">
                                    <mat-checkbox class="edit-cus-daylight-hint" name="daylightSavingFlag" [(ngModel)]="daylightSavingFlag">
                                        {{ 'COMMON.ENABLE_DAYLIGHT_TEXT' | translate }}
                                    </mat-checkbox>
                                </div>

                                <div class="row">
                                    <mat-form-field class="full-width edit-cus-con-num-hint">
                                        <input
                                            matInput
                                            name="contractNumber"
                                            placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.CONTRACT_NUMBER' | translate }}"
                                            [(ngModel)]="contractNumber"
                                            required
                                            type="text"
                                            maxlength="16"
                                        />
                                    </mat-form-field>
                                    <mat-form-field class="full-width edit-cus-subs-hint">
                                        <mat-select
                                            placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.SUBSCRIPTION_LEVEL' | translate }}"
                                            [(ngModel)]="subscriptionLevel"
                                            name="subscriptionLevel"
                                            class="full-width"
                                            (selectionChange)="onSubscriptionLevelChange($event)"
                                            required
                                        >
                                            <mat-option
                                                *ngFor="let alternative of subscriptionLevelAlternatives"
                                                [value]="alternative.value"
                                                [disabled] ="alternative.value === SubscriptionLevel.PrismLimited"
                                            >
                                                {{ alternative.text }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- mat-datetimepicker has a bug where it can't be connected to an field with [ngModel] inside a form -->
                                    <div class="custom-error">
                                        <mat-form-field class="full-width edit-cus-orig-start-hint">
                                            <input
                                                matInput
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.DETAILS.ORIGINAL_CONTRACT_START' | translate
                                                }}"
                                                [value]="originalContractStart | date: customerDateFormat"
                                                (change)="setOriginalContractStart($event)"
                                                tabindex="-1"
                                                class="datepickerStyles"
                                                appDatetimepickerPosition
                                                [matDatetimepickerComp]="originalContractStartPicker"
                                            />
                                            <input
                                                hidden
                                                [matDatetimepicker]="originalContractStartPicker"
                                                (dateInput)="setOriginalContractStart($event)"
                                            />
                                            <mat-datetimepicker-toggle matSuffix [for]="originalContractStartPicker">
                                            </mat-datetimepicker-toggle>
                                            <mat-datetimepicker
                                                #originalContractStartPicker
                                                timeInterval="1"
                                                type="date"
                                            >
                                            </mat-datetimepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="custom-error">
                                        <mat-form-field class="full-width edit-cus-curr-start-hint">
                                            <input
                                                matInput
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.DETAILS.CURRENT_CONTRACT_START' | translate
                                                }}"
                                                [value]="currentContractStart | date: customerDateFormat"
                                                (change)="setCurrentContractStart($event)"
                                                tabindex="-1"
                                                class="datepickerStyles"
                                                [max]="currentContractEnd"
                                                appDatetimepickerPosition
                                                [matDatetimepickerComp]="currentContractStartPicker"
                                            />
                                            <input
                                                hidden
                                                [matDatetimepicker]="currentContractStartPicker"
                                                [max]="currentContractEnd"
                                                (dateInput)="setCurrentContractStart($event)"
                                            />
                                            <mat-datetimepicker-toggle matSuffix [for]="currentContractStartPicker">
                                            </mat-datetimepicker-toggle>
                                            <mat-datetimepicker
                                                #currentContractStartPicker
                                                timeInterval="1"
                                                type="date"
                                                [max]="currentContractEnd"
                                            >
                                            </mat-datetimepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="custom-error">
                                        <mat-form-field class="full-width edit-cus-curr-end-hint">
                                            <input
                                                matInput
                                                placeholder="{{
                                                    'CUSTOMER_EDITOR.DETAILS.CURRENT_CONTRACT_END' | translate
                                                }}"
                                                [value]="currentContractEnd | date: customerDateFormat"
                                                (change)="setCurrentContractEnd($event)"
                                                tabindex="-1"
                                                class="datepickerStyles"
                                                [min]="currentContractStart"
                                                appDatetimepickerPosition
                                                [matDatetimepickerComp]="currentContractEndPicker"
                                            />
                                            <input
                                                hidden
                                                [matDatetimepicker]="currentContractEndPicker"
                                                [min]="currentContractStart"
                                                (dateInput)="setCurrentContractEnd($event)"
                                            />
                                            <mat-datetimepicker-toggle matSuffix [for]="currentContractEndPicker">
                                            </mat-datetimepicker-toggle>
                                            <mat-datetimepicker
                                                #currentContractEndPicker
                                                timeInterval="1"
                                                type="date"
                                                [min]="currentContractStart"
                                            >
                                            </mat-datetimepicker>
                                        </mat-form-field>
                                    </div>
                                    <app-auto-complete
                                        [items]="usersSelectable"
                                        [preselectedItem]="dataAnalyst"
                                        placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.DATA_ANALYST' | translate }}"
                                        [isShowAll]="false"
                                        (selectedItems)="dataAnalystSelected($event)"
                                        [panelWidth]="'300px'"
                                        [showTooltipMinLength]="33"
                                        [showAllOptionsOnFocus]="true"
                                        [sortStartWith]="true"
                                        [markSelectedOption]="true"
                                    >
                                    </app-auto-complete>
                                </div>
                                <div class="row">
                                    <app-auto-complete
                                        [items]="usersSelectable"
                                        [preselectedItem]="regionalManager"
                                        placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.REGIONAL_MANAGER' | translate }}"
                                        [isShowAll]="false"
                                        (selectedItems)="regionalManagerSelected($event)"
                                        [panelWidth]="'300px'"
                                        [showTooltipMinLength]="33"
                                        [populateSelectedValueonFocusOut]="true"
                                        [showAllOptionsOnFocus]="true"
                                        [sortStartWith]="true"
                                        [markSelectedOption]="true"
                                    >
                                    </app-auto-complete>
                                    <app-auto-complete
                                        [items]="usersSelectable"
                                        [preselectedItem]="salesPerson"
                                        placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.SALES_PERSON' | translate }}"
                                        [isShowAll]="false"
                                        (selectedItems)="salesPersonSelected($event)"
                                        [panelWidth]="'300px'"
                                        [showTooltipMinLength]="33"
                                        [populateSelectedValueonFocusOut]="true"
                                        [showAllOptionsOnFocus]="true"
                                        [sortStartWith]="true"
                                        [markSelectedOption]="true"
                                    >
                                    </app-auto-complete>
                                    <app-auto-complete
                                        [items]="usersSelectable"
                                        [preselectedItem]="projectManager"
                                        placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.PROJECT_MANAGER' | translate }}"
                                        [isShowAll]="false"
                                        (selectedItems)="projectManagerSelected($event)"
                                        [panelWidth]="'300px'"
                                        [showTooltipMinLength]="33"
                                        [populateSelectedValueonFocusOut]="true"
                                        [showAllOptionsOnFocus]="true"
                                        [sortStartWith]="true"
                                        [markSelectedOption]="true"
                                    >
                                    </app-auto-complete>
                                </div>
                                <div class="row">
                                    <mat-form-field class="full-width edit-cus-inactive-on-hint">
                                        <input
                                            matInput
                                            name="markedInactiveOn"
                                            placeholder="{{ 'CUSTOMER_EDITOR.DETAILS.MARKED_INACTIVE_ON' | translate }}"
                                            [disabled]="true"
                                            [value]="markedInactiveOn | date: customerDateFormat"
                                        />
                                    </mat-form-field>
                                    <div class="custom-error">
                                        <mat-form-field class="full-width edit-cus-rough-hint">
                                            <input
                                                matInput
                                                name="roughnessInput"
                                                id="roughnessInput"
                                                #roughnessInput="ngModel"
                                                placeholder="{{ 'COMMON.ROUGHNESS_COEFFICIENT' | translate }}"
                                                [(ngModel)]="roughness"
                                                [pattern]="numericWith5DecimalPlaces"
                                                type="number"
                                                required
                                            />
                                        </mat-form-field>
                                        <div class="custom-error-message">
                                            <span *ngIf="roughnessInput?.errors?.pattern">
                                                {{ 'COMMON.NUMERIC_PRECISION_ERROR_5_DECIMALS' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </mat-card-content>
                        <mat-card-actions>
                            <div class="auto-margin">
                                <button
                                    mat-fab
                                    color="accent"
                                    id="addNewLocation"
                                    class="add-new-location-top-value edit-cus-details-add-hint"
                                    matTooltip="{{ 'COMMON.ADD_LOCATION_TEXT' | translate }}"
                                    matTooltipPosition="above"
                                    [matMenuTriggerFor]="menu"
                                    #addNewLocationTrigger="matMenuTrigger"
                                >
                                    <mat-icon *ngIf="!addNewLocationTrigger.menuOpen">add_location</mat-icon>
                                    <mat-icon *ngIf="addNewLocationTrigger.menuOpen">close</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu" yPosition="above" xPosition="after" [overlapTrigger]="false">
                                    <button mat-menu-item (click)="addCompositeLocation()">
                                        <mat-icon>add_circle</mat-icon>
                                        <span>{{ 'HOME.MAP.LEGEND.ADD_MAP_COMPOSITE_LOCATION' | translate }}</span>
                                    </button>
                                    <button
                                        [disabled]="!isMonitorCanAddEdit"
                                        mat-menu-item
                                        (click)="addTritonLocation()"
                                    >
                                        <mat-icon>add_location</mat-icon>
                                        <span>{{ 'HOME.MAP.LEGEND.ADD_NEW_LOCATION' | translate }}</span>
                                    </button>
                                </mat-menu>
                            </div>

                            <div class="app-flex-filler"></div>
                            <div class="auto-margin textTypeButton">
                                <button
                                    mat-button
                                    (click)="addCustomer()"
                                    class="edit-cus-save-hint"
                                    [disabled]="
                                        !autoCompleteModified
                                        && !fieldsModified
                                        && (
                                            addCustomerForm.pristine ||
                                            !addCustomerForm.valid ||
                                            !isValidCustomerName ||
                                            !isValidCustomerShortName
                                        )
                                    "
                                >
                                    {{ 'COMMON.SAVE_BUTTON' | translate }}
                                </button>
                            </div>
                        </mat-card-actions>
                    </mat-tab>
                    <mat-tab
                        label="{{ 'CUSTOMER_EDITOR.API_ALLOCATION_LABEL' | translate }}"
                        [disabled]="!isApiKeyEnabled"
                    >
                        <div>
                            <app-api-key-dashboard></app-api-key-dashboard>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'CUSTOMER_EDITOR.ASSIGN_FEATURES_LABEL' | translate }}" [disabled]="!userRole">
                        <div>
                            <app-customer-detail-feature
                                [customerName]="customerName"
                                [subscriptionLevel]="subscriptionLevel"
                                [additionalFeaturesList]="additionalFeaturesList"
                                (featureselected)="featureselected($event)"
                                (featureSave)="onSaveAdditionalFeatures($event)"
                            >
                            </app-customer-detail-feature>
                        </div>
                    </mat-tab>
                    <mat-tab
                        label="{{ 'CUSTOMER_EDITOR.ASSIGN_USERS_LABEL' | translate }}"
                        [disabled]="!isAssignUserVisible"
                    >
                        <div *ngIf="isAssignUserVisible">
                            <app-customer-detail-user-editor (usersSelected)="usersSelected($event)"></app-customer-detail-user-editor>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'CUSTOMER_EDITOR.ASSIGN_LOCATIONS_LABEL' | translate }}">
                        <div>
                            <app-customer-detail-locations-editor
                                [customerid]="customerID"
                            ></app-customer-detail-locations-editor>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="hasSliicerEnabled" label="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE_LABEL' | translate }}">
                        <div>
                            <app-customer-rain-profile
                                [customerUnit]="units"
                                [customerDetails]="customer"
                                (rainfallProfileChanged) = "rainfallProfileChanged($event)"
                            ></app-customer-rain-profile>
                        </div>
                    </mat-tab>
                    <mat-tab *ngIf="hasSliicerEnabled" label="{{ 'CUSTOMER_EDITOR.GIS_LABEL' | translate }}">
                        <div>
                            <ads-prism-gis-layers></ads-prism-gis-layers>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'CUSTOMER_EDITOR.FTP_TAB' | translate }}">
                        <div>
                            <app-ads-prism-ftp-sweep></app-ads-prism-ftp-sweep>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </mat-card>
</div>
