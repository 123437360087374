import { Season } from 'app/pages/sliicer/sliicer-case-study-details/study-settings/seasons-settings/seasons-settings.utils';
import {
    DryDayDefinition,
    StormDefinition,
    DepthDurationFrequency,
    WasteWaterBaseInfiltration,
} from './customer-rainfall-profile';
import { DesignStorm } from './design-storm';
import { QvsIConfigurations } from './results/storm-events';

export interface Settings {
    dayGroups?: WeekGroup[];
    dryDayDefinition?: DryDayDefinition;
    stormEventDefinition?: StormDefinition;
    ddfData?: DepthDurationFrequency;
    wastewaterBaseInfiltration?: WasteWaterBaseInfiltration;
    defaultPrecompType?: PrecompensationType;
    seasonType?: SeasonType;
    regimes?: Regime[];
    designStorms?: DesignStorm[];
    seasons?: Season[];
    seasonDefinitions?: SeasonDefinitionDto[];
    altPrecomStart?: string;
    qvsIConfigurations?: QvsIConfigurations[];
}

export interface WeekGroup {
    name: string;
    days: number[];
}

export interface FilterSeasons {
    seasons: string[];
    years: number[];
    regime: string[];
}

export enum PrecompensationType {
    None = 'None',
    Constant = 'Constant',
    Linear = 'Linear',
    Log = 'Log',
    Power = 'Power',
}

export const PRECOMP_TYPES = [
    { value: PrecompensationType.None },
    { value: PrecompensationType.Constant },
    // hide per bug #32905
    // { value: PrecompensationType.Linear },
    { value: PrecompensationType.Log },
    { value: PrecompensationType.Power },
];

export enum SeasonType {
    None = 'None',
    Year = 'Year',
    Quarter = 'Quarter',
    Month = 'Month',
    Meteorological = 'Meteorological',
    Custom = 'Custom',
}

export enum Meteorological {
    Spring = 'Spring',
    Summer = 'Summer',
    Fall = 'Fall',
    Winter = 'Winter',
}

export interface DayOfYearDto {
    month: number;
    day: number;
}
export interface SeasonDefinitionDto {
    name: string;
    start: DayOfYearDto;
}

export interface Regime {
    name: string;
    periodStart: string;
    periodEnd: string;
    minPeriodStart: string;
}

export interface Preferences {
    activeDesignStorms?: string[];
}