import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { FlexmonsterPivot } from 'ngx-flexmonster';
import { Toolbar } from 'flexmonster';
import { environment } from 'app/environments/environment';
import { BasinQvsiProperties, createPivotMapping, csvToJSON, getBasinQvsiPivotOptions, getBasinQvsiPivotSlice, getKeyByProp } from './basin-qvsi-pivot-table.constants'
import { Subscription } from 'rxjs';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DatePipe } from '@angular/common';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { TranslateService } from '@ngx-translate/core';
import { BASIN_QVI_EXPORT_NAME, EXPORT_CSV_ID, EXPORT_EXCEL_ID, EXPORT_HTML_ID, EXPORT_IMG_ID, EXPORT_PDF_ID, EXPORT_VAULT_ID, TOOLBAR_EXPORT_TAB_ID, TOOLBAR_FULLSCREEN_TAB_ID, VAULT_ICON } from '../flexmonster.constant';

const blankText = '(blank)';

@Component({
	selector: 'app-basin-qvsi-pivot-table',
	templateUrl: './basin-qvsi-pivot-table.component.html',
	styleUrls: ['./basin-qvsi-pivot-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BasinQvsiPivotTableComponent implements OnInit, OnDestroy {
	public license = environment.flexmonsterLicense;
	public report: Object;
	@ViewChild('pivot') private pivotTable: FlexmonsterPivot;

	public isLoading = true;

	private caseStudyId: string;
	private customerId: number;

    public noData = false;
	private data;

	private studyDateForExportNaming: string;

	private subscriptions: Subscription[] = [];
	constructor(
		private sliicerService: SliicerService,
		private dateutilService: DateutilService,
		private datePipe: DatePipe,
		private snackBarNotificationService: SnackBarNotificationService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		this.subscriptions.push(this.sliicerService.studyDetailsData$.subscribe((study: SliicerCaseStudy) => {
			this.caseStudyId = study.id;
			this.customerId = study.customerId;

			const format = this.dateutilService.getFormat().replace(/\//g, '');
			const start = new Date(study.config.startDate);
			const end = new Date(study.config.endDate);

			this.studyDateForExportNaming = `${this.datePipe.transform(start, format)}-${this.datePipe.transform(end, format)}`

			this.getAllQviBasinResults();
		}));
	}

	public customizeToolbar(toolbar: Toolbar) {
		toolbar.showShareReportTab = true;

		const tabs = toolbar.getTabs();

		const exportTab = tabs.find(v => v.id === TOOLBAR_EXPORT_TAB_ID);
		this.formatExportsTab(exportTab);
		const fullScreenTab = tabs.find(v => v.id === TOOLBAR_FULLSCREEN_TAB_ID);

		toolbar.getTabs = function () {
			return [
				exportTab,
				fullScreenTab
			];
		}
	}

	private removeBlanks() {
		this.pivotTable.flexmonster.customizeCell((cell, data) => {
		  if (data.label === blankText) {
			cell.text = '-';
		  }
		});
	}

	private getAllQviBasinResults() {
		this.isLoading = true;

		this.sliicerService.getAllQviBasinResults(this.customerId, this.caseStudyId).subscribe(csv => {
			if (!csv) {
				this.isLoading = false;
				return;
			}

			const json: {}[] = csvToJSON(csv);
			if (!json || !json.length) {
				this.isLoading = false;
				return;
			}

			const data = json.filter(v => Object.keys(v).length > 0).map(item => {
				return Object.keys(item).reduce((acc, k) => {
					const key = k.replace(/"/g, '').trim();

					acc[key] = item[k];

					return acc;
				}, {});
			});


			this.data = data;
            if(!data || !data.length) {

                this.noData = true;
            } else {
                this.noData = false;
			    this.generateTable(this.data);
            }

			this.isLoading = false;
		},
			() => { this.isLoading = false; }
		)
	}
	private generateTable(data) {
		const mapping = createPivotMapping(data[0]);

		this.report = {
			options: getBasinQvsiPivotOptions(),
			dataSource: { data, mapping },
			slice: getBasinQvsiPivotSlice(data[0])
		};
		this.isLoading = false;
		this.pivotTable.flexmonster.setReport(this.report);
		this.removeBlanks();
	}

	private formatExportsTab(tab: Flexmonster.ToolbarTab) {
		const exportTypes = [EXPORT_HTML_ID, EXPORT_CSV_ID, EXPORT_EXCEL_ID];

		tab.menu = tab.menu.filter(menuItem => {
			if (!exportTypes.includes(menuItem.id)) {
				return false;
			}

			this.setExportHandler(menuItem);

			return true;
		});

		tab.menu.unshift({
			title: 'To Vault(Raw)',
			id: EXPORT_VAULT_ID,
			icon: VAULT_ICON,
			handler: () => this.exportVaultHandler()
		});
	}

	private setExportHandler(menuItem: Flexmonster.ToolbarTab) {
		switch (menuItem.id) {
			case EXPORT_HTML_ID: {
				menuItem.handler = this.exportToHTMLhandler();
				break;
			}
			case EXPORT_EXCEL_ID: {
				menuItem.handler = this.exportToExcelHandler()
				break;
			}
			case EXPORT_CSV_ID: {
				menuItem.handler = this.exportToCsvHandler();
				break;
			}
		}
	}

	private exportToExcelHandler() {
		return () => this.pivotTable.flexmonster.exportTo('excel', {
			filename: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
			excelSheetName: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});

	}

	private exportToCsvHandler() {
		return () => this.pivotTable.flexmonster.exportTo('csv', {
			filename: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToHTMLhandler() {
		return () => this.pivotTable.flexmonster.exportTo('html', {
			filename: `${BASIN_QVI_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportVaultHandler() {
		const successMessage = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_BASIN_SAVE_MESSAGE');
		const errorMessage = this.translate.instant('VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG');
		const dissmissText = this.translate.instant('COMMON.DISMISS_TEXT');

		this.isLoading = true;
		this.sliicerService
			.vaultExportBasicQvsiStatistics(this.customerId, this.caseStudyId)
			.subscribe(
				(res) => {
					this.isLoading = false;
					this.snackBarNotificationService.raiseNotification(
						successMessage,
						dissmissText,
						{
							duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
						},
						true,
					);
				},
				(err) => {
					this.snackBarNotificationService.raiseNotification(errorMessage, dissmissText, {
						panelClass: 'custom-error-snack-bar',
					}, false);
					this.isLoading = false;
				},
			);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(v => v.unsubscribe());
	}
}
