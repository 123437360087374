import * as E from 'fp-ts/es6/Either';
import { Either, isRight, left, right, chain } from 'fp-ts/es6/Either';
import { flow } from 'fp-ts/es6/function';

export type FormField<R, V> = {
    val: Either<string, V>;
    raw: R;
};

// export type DateFormField = {
//   val: Either<string, Date>
//   date: Date
// }

export function updateFormField<R, V>(raw: R, decoderFn: (s: R) => Either<string, V>): FormField<R, V> {
    return { raw: raw, val: decoderFn(raw) };
}

export interface SafeNumberBrand {
    readonly SafeNumber: unique symbol;
}
export interface PositiveNumberBrand {
    readonly PositiveNumber: unique symbol;
}
export interface NonNegNumberBrand {
    readonly NonNegNumber: unique symbol;
}
export interface IntBrand {
    readonly Int: unique symbol;
}
export interface NonEmptyStringBrand {
    readonly NonEmptyString: unique symbol;
}
export interface NotUniqueStringBrand {
    readonly NotUniqueString: unique symbol;
}
export interface SafeDateBrand {
    readonly SafeDate: unique symbol;
}

export type SafeNumber = number & SafeNumberBrand;
export type PositiveNumber = number & SafeNumberBrand & PositiveNumberBrand;
export type NonNegNumber = number & SafeNumberBrand & NonNegNumberBrand;
export type Int = number & IntBrand;
export type NonNegInt = number & IntBrand & NonNegNumberBrand;
export type PositiveInt = number & IntBrand & PositiveNumberBrand;
export type NonEmptyString = string & NonEmptyStringBrand;
export type NotUniqueString = string & NotUniqueStringBrand;
export type SafeDate = Date & SafeDateBrand;

function isInt<T extends number>(n: T): n is T & IntBrand {
    return Number.isSafeInteger(n);
}
function validateInt<T extends number>(n: T): Either<string, Int & T> {
    return isInt(n) ? right(n) : left('COMMON.VALIDATION.NOT_WHOLE_NUMBER');
}

function isSafeNumber<T extends number>(n: T): n is T & SafeNumberBrand {
    return !Number.isNaN(n) && Number.isFinite(n);
}
function validateSafeNumber<T extends number>(n: T): Either<string, SafeNumber & T> {
    return isSafeNumber(n) ? right(n) : left('COMMON.VALIDATION.INVALID_NUMBER');
}

function isPositiveNumber<T extends SafeNumber>(n: T): n is T & PositiveNumberBrand {
    return n > 0;
}
function validatePositiveNumber<T extends number>(n: T): Either<string, T & PositiveNumber> {
    const safeNum = validateSafeNumber(n);
    if (isRight(safeNum)) {
        return isPositiveNumber(safeNum.value) ? right(safeNum.value) : left('COMMON.VALIDATION.NOT_POSITIVE');
    } else {
        return left<string, T & PositiveNumber>(safeNum.value);
    }
}
function isNonNegNumber<T extends SafeNumber>(n: T): n is T & NonNegNumberBrand {
    return n >= 0;
}
function validateNonNegNumber<T extends number>(n: T): Either<string, T & NonNegNumber> {
    const safeNum = validateSafeNumber(n);
    if (isRight(safeNum)) {
        return isNonNegNumber(safeNum.value) ? right(safeNum.value) : left('COMMON.VALIDATION.NEGATIVE');
    } else {
        return left<string, T & NonNegNumber>(safeNum.value);
    }
}
function isNonEmptyString<T extends string>(s: T): s is T & NonEmptyStringBrand {
    return s.length > 0;
}
function validateNonEmptyString<T extends string>(s: T): Either<string, T & NonEmptyString> {
    return isNonEmptyString(s) ? right(s as T & NonEmptyString) : left('COMMON.VALIDATION.REQUIRED');
}
function isSafeDate<T extends Date>(d: T): d is T & SafeDateBrand {
    return d && !isNaN(d.valueOf());
}
function validateSafeDate<T extends Date>(d: T): Either<string, T & SafeDate> {
    return isSafeDate(d) ? right(d) : left('COMMON.VALIDATION.INVALID_DATE');
}
function validateUniqueString<T extends string>(s: T, uniqueSet: Set<string>): Either<string, T & NotUniqueString> {
    return uniqueSet.has(s.toLowerCase().trim()) ? left('COMMON.VALIDATION.UNIQUE_VALUE') : right(s as T & NotUniqueString);
}
function validateDecimals(n: number, maxDecimals: number): boolean {
    const decimalPart = n.toString().split('.')[1];
    return !decimalPart || decimalPart.length <= maxDecimals;
}
export function validInt(n: number): Either<string, Int> {
    return validateInt(n);
}

export function validPositiveInt(n: number): Either<string, PositiveInt> {
    return E.chain((x: PositiveNumber) => validateInt(x))(validatePositiveNumber(n));
}

export function validNonNegInt(n: number): Either<string, NonNegInt> {
    return E.chain((x: NonNegNumber) => validateInt(x))(validateNonNegNumber(n));
}

export function validPositiveNumber(n: number): Either<string, PositiveNumber> {
    return validatePositiveNumber(n);
}

export function validNonEmptyString(s: string): Either<string, NonEmptyString> {
    return validateNonEmptyString(s);
}

export function validUniqueString(s: string, uniqueSet: Set<string>): Either<string, string> {
    return validateUniqueString(s, uniqueSet);
}

export function validNonEmptyAndUniqueString(s: string, uniqueSet: Set<string>): Either<string, NonEmptyString> {
    return flow(
        validNonEmptyString,
        chain((validatedString: NonEmptyString) => 
            validUniqueString(validatedString, uniqueSet) as Either<string, NonEmptyString>
        )
    )(s);
}

export function validPositiveNumberWithDecimals(n: number, maxDecimals: number): Either<string, PositiveNumber> {
    const safeNum = validateSafeNumber(n);

    if (isRight(safeNum)) {
        if (!isPositiveNumber(safeNum.value)) {
            return left('COMMON.VALIDATION.NOT_POSITIVE');
        }

        if (!validateDecimals(safeNum.value, maxDecimals)) {
            return left(`COMMON.VALIDATION.${maxDecimals}_DECIMAL`);
        }

        return right(safeNum.value as PositiveNumber);
    } else {
        return left<string, PositiveNumber>(safeNum.value);
    }
}
export function decodeDate(str: string): Either<string, SafeDate> {
    return validateSafeDate(new Date(str));
}
export function decodeNumber(str: string): Either<string, SafeNumber> {
    return validateSafeNumber(+str);
}

export function safeDate(d: Date): Either<string, SafeDate> {
    return validateSafeDate(d);
}

export const decodeInt = flow(decodeNumber, E.chain(validInt));
export const decodeNonNegInt = flow(decodeNumber, E.chain(validNonNegInt));
export const decodePositiveInt = flow(decodeNumber, E.chain(validPositiveInt));
export const decodePositiveNumber = flow(decodeNumber, E.chain(validatePositiveNumber));
export const decodePositiveNumberWithDecimals = (maxDecimals: number) => flow(
    decodeNumber,
    E.chain((n) => validPositiveNumberWithDecimals(n, maxDecimals))
);