import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    OnChanges,
    OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { fromNullable } from 'fp-ts/es6/Option';
import * as moment from 'moment';
import {
    SLI_OCOLOR_PRECOMP,
    SLI_COLOR_WEEKGROUP_HIGHLIGHT,
    SLI_OCOLOR_STORM_PERIOD,
    SLI_COLOR_FLOW,
    SLI_COLOR_NET_FLOW,
    SLI_COLOR_RAINFALL,
    SLI_COLOR_UPSTREAMS,
    SLI_OLCOLOR_STORM_PERIOD,
} from 'app/shared/constant';
import { StormEvent, DryDayData, OutlierType, OutlierDay, BasinStormSettings, StormSettings, Overrides } from 'app/shared/models/sliicer';
import { MultiSeriesData } from 'app/shared/models/sliicer-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerUtils } from 'app/shared/utils/sliicer-utils';
import { DEFAULT_PRECOMPENSATION_LENGTH_MINUTES, SliicerService } from 'app/shared/services/sliicer.service';
import {
    ChartMouseDragFinish,
    ChartMouseDragSettings,
} from '../../directives/chart-mouse-drag/chart-mouse-drag.models';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StormSettingsDialogComponent } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-settings-dialog/storm-settings-dialog.component';
import { StormSettingsDialogData } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-settings-dialog/storm-settings-dialog.utils';
import { Season } from 'app/pages/sliicer/sliicer-case-study-details/study-settings/seasons-settings/seasons-settings.utils';
import { StormHelperService } from '../../services/stormHelper/storm-helper.service';
import { StormEventAdjustment } from 'app/shared/models/sliicer/overrides';
import { UNITS } from 'app/shared/models/units';

enum GraphDragAction {
    remove = 'Remove',
    add = 'Add'
}

const multicolorSeries = require('highcharts-multicolor-series/js/multicolor_series');
multicolorSeries(Highcharts);
const oneFullMonthInDays = 31;
const oneDayInMS = 86400000;
const RAIN = 'Rainfall';
const WEEK = 'Week';
const GRAPH_ID = 'mainHydrograph';

// highest z-order: flow lines (green here), rainfall (blue column chart), dry days, storms.
const plotBandOrder = {
    flowLine: 4,
    dryDay: 3,
    rainfall: 2,
    storm: 1,
};

@Component({
    selector: 'ads-prism-flow-monitor-hydrograph',
    templateUrl: './flow-monitor-hydrograph.component.html',
    styleUrls: ['./flow-monitor-hydrograph.component.scss'],
})
export class AdsFlowMonitorHydroGraphComponent implements OnInit, OnChanges {
    @Input() public isDryDaysEditActive: boolean;
    @Input() public isStormAddActive: boolean;
    @Input() public basinDryDays: DryDayData;
    @Input() public stormEvents: StormEvent[] = [];
    @Input() public selectedStormId = 0;
    @Input() public basinOutlierDays: OutlierDay[];
    @Input() public basinName: string;
    @Input() public basinStormSettings: BasinStormSettings[];
    @Input() public stormSettings: StormSettings[];
    @Input() public addedStormEvents: StormEventAdjustment[];
    @Input() public isLoading: boolean;
    @Input() public seasons: Season[];
    @Input() public regimes: [];

    @ViewChild('chart') public chartElement: Highcharts.ChartObject;

    @Output('plotBandAdded') public plotBandAdded = new EventEmitter();
    @Output('plotBandRemoved') public plotBandRemoved = new EventEmitter();
    @Output('plotBandSelected') public plotBandSelected = new EventEmitter();
    @Output() public stormCreated = new EventEmitter<{ overrides: Overrides, storm: StormSettingsDialogData }>();

    // These are accessed directly from flow-monitor.component.
    // TODO: WPS - either make inputs or make them private.
    public chartXAxis: any;
    public chartYAxis: any;
    public chartRangeSelectorOption: any;
    public chartToolTipOption: any;
    public chartSeriesData: any;
    public chartPlotOptions: any;
    public showStormsValue = true;
    public showDryDaysValue = true;

    private plotBands: Array<any> = [];
    private plotBandIds: Array<number> = [];
    private stormIdMap: any = {};
    private isUTCEnabled = false;

    public showGraph = false;
    public chartOptions: Highcharts.Options;

    public mouseDragSettings: ChartMouseDragSettings = {
        plotId: 'multipleDryDays',
        zIndex: plotBandOrder.dryDay,
        color: SLI_OCOLOR_PRECOMP,
    };

    public graphElementId = GRAPH_ID;
    private yAxisLeftLabel: string;
    private yAxisRightLabel: string;
    private startDate: string;
    private endDate: string;
    private shownDateRange: number[];
    private selectedDateRange: number[];

    private debugTooltipLbl;
    readonly DEBUG_TOOLTIP_POSITION = { x: 380, y: 12 };

    constructor(
        private changeDetector: ChangeDetectorRef,
        private dateutilService: DateutilService,
        private translateService: TranslateService,
        private sliicerService: SliicerService,
        private statusCodeService: StatusCodeService,
        private stormHelper: StormHelperService,
        private matDialog: MatDialog
    ) {
        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme(this.chartElement);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chartElement);
            }
        });
    }

    public ngOnInit() {
        this.applyTranslations();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (this.showStormsValue) {
            this.addStorms();
        }
        if (changes.basinDryDays || changes.basinOutlierDays) {
            if (this.showDryDaysValue) {
                this.addDryDays();
            }
        }
        if (this.showGraph) {
            this.updateChartZoomType();
        }
        if (changes.selectedStormId && !changes.selectedStormId.firstChange) {
            this.hideStorms();
            if (this.showStormsValue) {
                this.addStorms();
            }
        }
    }

    /**
     * Handle dragging after the mouse click+drag is done
     * @param dragData
     */
    public mouseDragFinish(dragData: ChartMouseDragFinish): void {
        this.removeMultipleDryDaysPlotBand();

        // #33894 Ensure start and end dates are within study dates range
        const chartStartDate = new Date(this.startDate).getTime();
        if (dragData.dragStart < chartStartDate) {
            dragData.dragStart = chartStartDate;
        }
        const chartEndDate = new Date(this.endDate).getTime();
        if (dragData.dragEnd > chartEndDate) {
            dragData.dragEnd = chartEndDate;
        }

        if (dragData.dragStart > dragData.dragEnd) {
            const temporaryStart = dragData.dragStart;
            dragData.dragStart = dragData.dragEnd;
            dragData.dragEnd = temporaryStart;
        }

        let dragAction: GraphDragAction;

        if (this.plotBands && this.plotBands.length && this.plotBands.find(v => v.from <= dragData.dragStart && v.to >= dragData.dragStart)) {
            dragAction = GraphDragAction.remove;
        } else {
            dragAction = GraphDragAction.add;
        }

        if (moment(dragData.dragStart).add(1, 'days').valueOf() < dragData.dragEnd) {
            if (dragAction === GraphDragAction.remove) {
                this.removeMultupleDryDays(dragData.dragStart, dragData.dragEnd);
            } else {
                this.addMultipleDryDays(dragData.dragStart, dragData.dragEnd);
            }
        }
    }

    public setIsLoading(value: boolean): void {
        this.isLoading = value;
    }

    public setShowGraph(showGraph: boolean) {
        this.showGraph = showGraph;
        this.changeDetector.detectChanges();
        if (this.showGraph) {
            this.renderChart();
            if (this.showStormsValue) {
                this.addStorms();
            }
            if (this.showDryDaysValue) {
                this.addDryDays();
            }
        } else {
            // TODO: WPS - clear chart
        }
    }

    public setTooltip(enabled: boolean) {
        this.chartElement.update({
            tooltip: {
                enabled: enabled,
            },
        });
    }

    public setSeriesData(
        basinName: string,
        startDate: string,
        endDate: string,
        seriesData: MultiSeriesData,
        rainOnTop: boolean,
    ) {
        this.applyTranslations();
        const metric = this.dateutilService.isCustomerUnitsMetric.getValue();
        const unitOfMeasure = metric ? 'mm' : 'in';
        this.chartSeriesData = this.buildSeriesData(basinName, seriesData, unitOfMeasure);

        this.startDate = startDate;
        this.endDate = endDate;

        this.shownDateRange = [new Date(this.startDate).getTime(), new Date(this.endDate).getTime()]
        this.selectedDateRange = [new Date(this.startDate).getTime(), new Date(this.endDate).getTime()]

        const xAxisCategories = this.prepareXAxisCategories(
            AdsFlowMonitorHydroGraphComponent.toTimestamp(new Date(startDate)),
            AdsFlowMonitorHydroGraphComponent.toTimestamp(new Date(endDate)),
        );

        const self = this;

        this.chartXAxis = [
            {
                categories: xAxisCategories,
                ordinal: false,
                labels: {
                    style: {
                        fontSize: '10px',
                    },
                    rotation: -70,
                    formatter: function () {
                        const { min, max } = this.axis.getExtremes();

                        const includeYearsInDateFormat = new Date(min).getFullYear() !== new Date(max).getFullYear();
                        const xAxisDateFormat = self.sliicerService.getGraphDateFormat(includeYearsInDateFormat);
                        const timeFormat = self.sliicerService.getGraphTimeFormat();

                        const oneDay = 1000 * 60 * 60 * 24;
                        const range = max - min;

                        // for zoomed graph we need to show hours format, for weekly/monthly date span we show day and the month, for year long we display the year also
                        if (range < (oneDay * 4)) {
                            return Highcharts.dateFormat(timeFormat, this.value);
                        } else if (range <= (oneDay * 31)) {
                            return Highcharts.dateFormat(self.sliicerService.getGraphDateFormat(false), this.value);
                        } else {
                            return Highcharts.dateFormat(xAxisDateFormat, this.value);
                        }
                    }
                },
                endOnTick: true, // make sure there is an empty date on the graph at the end of the line
                startOnTick: false, // make sure there are not empty days on the graph on the start of the line
                crosshair: true,
                type: 'datetime',
                dateTimeLabelFormats: this.sliicerService.dateFormatFullStudy(),
                events: {
                    setExtremes: function (event) {
                        const newRanges = [event.min, event.max];
                        if (event.trigger === 'navigator') {
                            setTimeout(() => self.useNavigator(newRanges), 0);
                        }
                        if (event.trigger === 'zoom') {
                            self.shownDateRange = newRanges;
                        }
                        if (event.trigger !== 'rangeSelectorButton') {
                            self.onXAxisZoomChange(event, self);
                        } else {
                            event.preventDefault();
                        }
                    },
                },
                showFirstLabel: true,
                showLastLabel: true,
            },
        ];

        const labelDecimal = metric ? '{value:.1f}' : '{value:.2f}';
        const unitOfMeasureYaxis = this.dateutilService.customerUnit.getValue();
        this.chartYAxis = [
            {
                title: {
                    text: `${this.yAxisLeftLabel} (${unitOfMeasureYaxis})`,
                },
                opposite: false,
                labels: {
                    format: labelDecimal,
                },
                showLastLabel: true,
                min: 0,
            },
            {
                title: {
                    text: `${this.yAxisRightLabel} (${unitOfMeasure})`,
                },
                opposite: true,
                reversed: rainOnTop,
                labels: {
                    format: labelDecimal,
                },
                showLastLabel: true,
                max: SliicerUtils.rainAxisMax(unitOfMeasure),
            },
        ];

        this.chartRangeSelectorOption = {
            inputEnabled: false,
            allButtonsEnabled: true,
            buttons: [
                {
                    type: 'day',
                    count: 1,
                    text: '1d',
                    tooltip: '1 Day',
                },
                {
                    type: 'week',
                    count: 1,
                    text: '1w',
                    tooltip: '1 Week',
                },
                {
                    type: 'month',
                    count: 1,
                    text: '1m',
                    tooltip: '1 Month',
                },
                {
                    type: 'all',
                    text: 'All',
                },
            ],
        };

        this.chartPlotOptions = {
            line: {
                lineWidth: 1,
            },
            series: {
                dataGrouping: {
                    enabled: false,
                },
                states: {
                    hover: {
                        enabled: false,
                    },
                },
                pointWidth: 3,
                events: {
                    // #34116 Remember what is visible and what is not
                    legendItemClick: function () {
                        self.sliicerService.flowMonitorHydrographSetSelected(this.name, this.visible)
                    }
                },
            },
        };

        const xAxisDateFormat = this.dateutilService.getGraphDateFormat();
        const tooltipTimeFormat = this.dateutilService.getTimeFormat().replace(':ss', '');
        this.chartToolTipOption = {
            shared: true,
            useHTML: true,
            formatter: function () {
                return this.points.map((point, i: number) => {
                    const units = point.series.userOptions.plotUnit.toLowerCase();
                    const decimals = self.getDecimalsForUnit(units);

                    const yPoint = Number(point.y).toFixed(decimals);
                    // tslint:disable-next-line: max-line-length
                    const dateX = new Date(point.x).getTime();
                    const date =
                        Highcharts.dateFormat(xAxisDateFormat, dateX)
                        + ', '
                        + moment(dateX).format(tooltipTimeFormat);

                    const prefix = `${date}<br/>`;

                    return `${i === 0 ? prefix : ''}<span style ='color:${point.series.color}'>\u25CF</span> ${point.series.name} : <b>
                        ${yPoint} </b> ${point.series.userOptions.plotUnit} <br />`;
                }, '<b>' + this.x + '</b>');
            },
            positioner(labelWidth: number, labelHeight: number, point: { plotX: number; plotY: number }) {
                const horizontalOffset = 75;
                const verticalOffset = 47;

                return {
                    x: horizontalOffset,
                    y: verticalOffset,
                };
            },
            split: false, // for some reason without this option (although it's false by default) the positioner doesn't work
            shape: 'rect', // make sure the line (present for the default type 'callout') is not shown
        };
    }

    private getDecimalsForUnit(unit: string) {
        if (unit === UNITS.CFS || unit === UNITS.MGD) {
            return 3;
        }

        if (unit === UNITS.mm || unit === UNITS.LitSec) {
            return 1;
        }

        return 2;
    }

    public renderChart() {
        this.chartOptions = this.getChartOptions();

        // chart x-axis
        if (this.chartXAxis) {
            // if there are categories defined and the study is less not more then 1 month long,
            // add shorter tick intervals to show the first one
            // as well as add an extra day at the end of the graph to show the very end of the last day
            if (this.chartXAxis[0] && this.chartXAxis[0].categories && this.chartXAxis[0].categories.length) {
                const categories = this.chartXAxis[0].categories;
                const minCategory = categories[0];
                const maxCategory = categories[categories.length - 1];
                const isLeastThanOneMonthDiff =
                    moment(maxCategory).diff(moment(minCategory), 'days') <= oneFullMonthInDays;

                if (isLeastThanOneMonthDiff) {
                    const nextDayAfterMax = moment(maxCategory).add(1, 'day').startOf('day').valueOf();

                    this.chartXAxis[0].max = nextDayAfterMax;
                    this.chartXAxis[0].tickInterval = oneDayInMS;
                }
            }

            this.chartOptions.xAxis = this.chartXAxis;
        }

        // chart y-axis
        if (this.chartYAxis) {
            this.chartOptions.yAxis = this.chartYAxis;
        }

        // chart plot options
        if (this.chartPlotOptions) {
            this.chartOptions.plotOptions = this.chartPlotOptions;
        }

        this.chartOptions.navigator = {
            enabled: true,
            series: {
                label: {
                    enabled: false
                }
            },
            xAxis: { width: '100%' },
        }

        // make sure that when the user clicks on the series the chart click event handler is fired
        this.chartOptions.plotOptions.series.events.click = this.seriesClickCallback.bind(this);

        const self = this;
        if (this.chartElement) {
            if (this.chartElement.nativeElement) {
                this.chartElement.nativeElement.onmousemove = function (event) {
                    const chart = Highcharts.charts.find(v => v && v.renderTo && v.renderTo.id === self.graphElementId);
                    const mouseOverX = event.chartX;

                    const chartValueX = chart.xAxis[0].toValue(mouseOverX);


                    const dateFormatted = Highcharts.dateFormat(this.tooltipDateFormat, new Date(chartValueX).getTime());

                    self.debugTooltipLbl.attr({
                        text: `Mouse Position: <br />${dateFormatted}`,
                    });
                }

                this.chartElement.nativeElement.onmouseleave = () => {
                    self.debugTooltipLbl.attr({
                        text: ''
                    });
                }
            }

            Highcharts.setOptions({
                global: {
                    useUTC: this.isUTCEnabled,
                },
            });
            this.chartElement = new Highcharts.StockChart(this.chartOptions);
            this.checkJumpScrollButtonsEnabled();
        }
    }

    public showStorms(value: boolean) {
        this.showStormsValue = value;
        if (this.showStormsValue) {
            this.addStorms();
        } else {
            this.hideStorms();
        }
    }

    public showDryDays(value: boolean) {
        this.showDryDaysValue = value;
        if (this.showDryDaysValue) {
            this.addDryDays();
        } else {
            this.hideDryDays();
        }
        this.checkJumpScrollButtonsEnabled();
    }

    /**
     * Event triggered when user clicks a region that does not have a plot band.
     */
    public chartClickCallback(event: Highcharts.ChartClickEvent) {
        const xValue = event.xAxis[0].value;
        if (this.isDryDaysEditActive && !event.target.classList.contains('highcharts-plot-band') && event.xAxis) {
            // Without a timeout here xAxis is undefined
            setTimeout(() => {
                const startDate = moment(xValue).startOf('date').valueOf();

                this.addPlotBand(startDate, plotBandOrder.dryDay);
            }, 0);
        } else if (this.isStormAddActive) {
            this.addStorm(xValue);
        } else {
            const momentDay = moment(xValue);
            const day = momentDay.toString();
            const formattedDay = momentDay.format('YYYY-MM-DD');
            const selected = this.highlightPlotLineDay({ day: day, remove: false });
            this.plotBandSelected.emit({ day: formattedDay, selected: selected });
        }
    }

    /**
     * Event triggered when user clicks on a data series point.
     *
     * Handles a click on the series data where the x value is taken from the point x value.
     * If the plot band doesn't exist - adds it. If not - removes it.
     */
    public seriesClickCallback(event: Highcharts.ChartClickEvent) {
        const isLineSeriesClick = event.target instanceof SVGPathElement && event.point && event.point.x;

        if (this.isDryDaysEditActive && isLineSeriesClick) {
            // Without a timeout here xAxis is undefined
            setTimeout(() => {
                const xValue = event.point.x;
                const startDate = moment(xValue).startOf('date').valueOf();
                const index = this.plotBands.findIndex((p) => p.id === startDate);

                if (index >= 0) {
                    this.removePlotBand(startDate);
                } else {
                    this.addPlotBand(startDate, plotBandOrder.dryDay);
                }
            }, 0);
        }
    }

    /**
     * This method will replace the existing plot band with a new one for a selected date on the Dry Day Dirunal Graph
     *
     * If it already had been highlighted, it is removed and the original plot band is added back (if it exists).
     *
     * If the remove flag is set to true, removes the values in all cases.
     *
     * @param {number} dateStamp: date as timestamp number recived as argument
     * @param {boolean} remove: Should the highlight be removed for sure even if it is not highlighted at the moment
     */
    public highlightPlotLineDay({ day, remove = false }: { day: string; remove?: boolean }): boolean {
        const timestamp = moment(day);
        const startTime = moment(timestamp).startOf('date').valueOf();

        if (this.plotBandIds && this.plotBandIds.indexOf(startTime) === -1 && !remove) {
            this.plotBandIds.push(startTime);

            if (this.chartElement && this.chartElement.xAxis) {
                this.chartElement.xAxis[0].addPlotBand({
                    from: startTime,
                    to: moment(startTime).add(1, 'day').valueOf(),
                    id: startTime,
                    color: SLI_COLOR_WEEKGROUP_HIGHLIGHT,
                    zIndex: plotBandOrder.flowLine,
                });
            }

            return true;
        } else {
            if (this.chartElement && this.chartElement.xAxis) {
                this.chartElement.xAxis[0].removePlotBand(startTime);
            }

            if (this.plotBandIds) {
                this.plotBandIds.splice(this.plotBandIds.indexOf(startTime), 1);
            }

            const index = this.plotBands.findIndex((b) => b.id === startTime);
            if (index >= 0) {
                this.chartElement.xAxis[0].addPlotBand({ ...this.plotBands[index], zIndex: plotBandOrder.flowLine });
            }

            return false;
        }
    }

    public setDryDayPlotBands(dryDayData: DryDayData, outliers: OutlierDay[]): void {
        if (!this.chartElement || !this.chartElement.xAxis) {
            return;
        }
        const newPlotBands = [];
        if (dryDayData && dryDayData.dayOfWeekGroupDays) {
            dryDayData.dayOfWeekGroupDays.forEach((item, index) => {
                const bandColor = SliicerUtils.weekGroupOverlayColor(item.dayOfWeekGroupName, index);
                item.days.forEach((day) => {
                    const oIndex = outliers.findIndex((o) => o.date === day && o.type === OutlierType.NONE);
                    if (oIndex < 0) {
                        const data = this.getPlotBandForString(bandColor, day);
                        newPlotBands.push(data);
                    }
                });
            });
        }

        if (this.chartElement && this.plotBands.length > 0) {
            this.plotBands.forEach((p) => {
                this.chartElement.xAxis[0].removePlotBand(p.id);
            });
        }
        this.plotBands = newPlotBands;
        this.plotBands.forEach((b) => {
            // TODO: WPS - Can we get rid of plotBands? We need a method
            //      like this.chartElement.xAxis[0].hasPlotBand(id) in
            //      order to do it.
            this.chartElement.xAxis[0].addPlotBand(Object.assign({ zIndex: plotBandOrder.dryDay }, b));
        });
    }

    public onRemoveStorm(removedStormTime: string) {
        const ts = new Date(removedStormTime).getTime();
        const plotBand = this.chartElement.xAxis[0].plotLinesAndBands.find(v => v.options.from === ts);

        if (plotBand) {
            plotBand.destroy();
        }
    }

    /**
     * This method will remove the plot band from hydrograph chart
     * @param {number} plotband: recive plotband as number
     */
    public removePlotBand(plotband: number) {
        const index = this.plotBands.findIndex((p) => p.id === plotband);
        if (index >= 0) {
            this.chartElement.xAxis[0].removePlotBand(plotband);
            this.plotBands.splice(index, 1);
            this.plotBandRemoved.emit(moment(plotband).format('YYYY-MM-DD'));
        }
    }

    /**
     * Remove dry days between and including dragStart and dragEnd
     * @param dragStart
     * @param dragEnd
     */
    private removeMultupleDryDays(dragStart: number, dragEnd: number): void {
        while (dragStart <= dragEnd) {
            const band = this.plotBands.find(v => v.from <= dragStart && v.to >= dragStart);
            if (band) {
                this.removePlotBand(band.id);
            }
            dragStart = moment(dragStart).add(1, 'days').valueOf();
        }
    }

    /**
     * Add dry days between and including dragStart and dragEnd
     * @param dragStart
     * @param dragEnd
     */
    private addMultipleDryDays(dragStart: number, dragEnd: number): void {
        while (dragStart <= dragEnd) {
            const startDate = moment(dragStart).startOf('date').valueOf();
            this.addPlotBand(startDate, plotBandOrder.dryDay);
            dragStart = moment(dragStart).add(1, 'days').valueOf();
        }
    }

    /**
     * Remove the plot band with the mouseDragSettings plot id.
     */
    private removeMultipleDryDaysPlotBand(): void {
        this.chartElement.xAxis[0].removePlotBand(this.mouseDragSettings['plotId']);
    }

    /**
     * Toggle zoom availability based on edit
     */
    private updateChartZoomType(): void {
        // #38649 For Stock chart we're using here zoomType is not enough to update zoom, has to call zooming as well
        // #38649 For Stock chart we have to disable panning, it got auto enabled if we won't
        const updateOptions = {
            chart: {
                panning: {enabled: false},
                zoomType: this.isDryDaysEditActive ? undefined : 'x',
                zooming: {
                    type: this.isDryDaysEditActive ? undefined : 'x'
                }
            }
        }
        this.chartElement.update(updateOptions, true);
    }

    private applyTranslations(): void {
        const translateKeys: Array<string> = [
            'COMMON.FLOW_RATE',
            'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH',
        ];
        this.translateService.get(translateKeys).subscribe((values) => {
            this.yAxisLeftLabel = values['COMMON.FLOW_RATE'];
            this.yAxisRightLabel = values['SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH'];
        });
    }

    /**
     * Triggered from the graph when zooming. Emit the new start and end dates to parent component
     * @param event
     * @param self
     */
    private onXAxisZoomChange(event, self): void {
        if (event && event.min && event.max) {
            const startDate = new Date(event.min);
            const endDate = new Date(event.max);
            event.target.options.categories = self.prepareXAxisCategories(startDate, endDate);
        }
    }

    /**
     * Create axis categories based on study duration
     * @param startDate
     * @param endDate
     */
    private prepareXAxisCategories(startDate: Date, endDate: Date) {
        const totalDays = this.dateutilService.differenceInDays(startDate, endDate);
        let xAxisCategories = [];
        if (totalDays > 61) {
            // month categories - do not need any special categories
        } else if (totalDays > 31 && totalDays <= 61) {
            // week categories - if date range more than month and max two month only
            const weekCount = this.dateutilService.weekCount(startDate);
            xAxisCategories = [];
            for (let i = 1; i <= weekCount; i++) {
                xAxisCategories.push(`${WEEK} ${i}`);
            }
        } else {
            // day categories - if date range equal or less than a month
            xAxisCategories = this.dateutilService.getDateRange(startDate, endDate);
        }
        return xAxisCategories;
    }

    private buildSeriesData(basinName: string, graphData: MultiSeriesData, unitOfMeasure: string): Array<any> {
        // #34116 Restore series visibility settings
        const lastSelected = this.sliicerService.flowMonitorHydrographNotSelectedItems;

        const seriesData = [];
        graphData.series.forEach((s, index) => {
            const round: number = s.seriesName === RAIN ? (unitOfMeasure === 'mm' ? 1 : 2) : 3;
            const points = s.data.map((m) => {
                return [Date.parse(m.x.toString()), Number(Number(m.y).toFixed(round))];
            });

            // TODO: WPS - This is a wholly unacceptable way to do this. However, needed
            //      for demo. Proper way is to have an ID on the graphData that indicates
            //      what color (or array of colors) we need to be using.
            let color = SLI_COLOR_FLOW;
            if (s.seriesName === RAIN) {
                color = SLI_COLOR_RAINFALL;
            } else if (s.seriesName === basinName + ' Flow') {
                color = SLI_COLOR_FLOW;
            } else if (s.seriesName.indexOf('Net Flow') >= 0) {
                color = SLI_COLOR_NET_FLOW;
            } else {
                // these are upstreams
                color = SLI_COLOR_UPSTREAMS[index % SLI_COLOR_UPSTREAMS.length];
            }

            seriesData.push({
                name: s.seriesName,
                type: s.seriesName === RAIN ? 'column' : 'line',
                yAxis: s.seriesName === RAIN ? 1 : 0,
                dashStyle: s.seriesName === RAIN ? 'shortdot' : 'solid',
                data: points || [],
                color: color,
                visible: !lastSelected[s.seriesName],
                plotUnit: s.seriesName === RAIN ? unitOfMeasure : this.dateutilService.customerUnit.getValue(),
            });
        });
        return seriesData;
    }

    private getChartOptions(): Highcharts.Options {
        const self = this;
        return {
            chart: {
                type: 'line',
                panning: {enabled: false},
                zoomType: this.isDryDaysEditActive ? undefined : 'x',
                ignoreHiddenSeries: false,
                renderTo: this.chartElement.nativeElement,
                events: {
                    load: function () {
                        const chart = this;
                        self.debugTooltipLbl = chart.renderer
                            .text('', self.DEBUG_TOOLTIP_POSITION.x, self.DEBUG_TOOLTIP_POSITION.y)
                            .attr({
                                zIndex: 5,
                            })
                            .css({
                                color: chart.options && chart.options.ads && chart.options.ads.debugtooltipcolor ? chart.options.ads.debugtooltipcolor : '#888'
                            })
                            .add();
                    },
                    selection: this.chartSelectionCallback.bind(this),
                    click: this.chartClickCallback.bind(this),
                },
                reflow: true,
            } as Highcharts.Options.ChartOptions,
            scrollbar: {
                enabled: false,
            },
            title: {
                text: '',
            },
            rangeSelector: {
                enabled: true,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1d',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(0), 0);
                            },
                        },
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1w',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(1), 0);
                            },
                        },

                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(2), 0);
                            },
                        },
                    },
                    {
                        type: 'all',
                        text: 'Off',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(3), 0);
                            },
                        },
                    },
                ],
                selected: 3, // Default to 'all' or 'Off' button selected
            },
            tooltip: this.chartToolTipOption,
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: true,
                buttons: {
                    forward: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'bottom',
                        x: -10,
                        y: -55,
                        className: 'jump-scroll-btn',
                        text: '>',
                        onclick: function () {
                            self.jumpScroll(true);
                        },
                    },
                    back: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'bottom',
                        x: 20,
                        y: -55,
                        className: 'jump-scroll-btn',
                        text: '<',
                        onclick: function () {
                            self.jumpScroll(false);
                        },
                    },
                }
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'right',
                verticalAlign: 'bottom',
            },
            series: this.chartSeriesData || [],
        } as Highcharts.Options;
    }

    private addDryDays() {
        if (!this.chartElement || !this.chartElement.xAxis) {
            return;
        }
        this.setDryDayPlotBands(this.basinDryDays, this.basinOutlierDays);
    }

    private plotBandForStorm(
        stormPlotBandId: string,
        stormStartTime: string,
        altPrecompStart: string,
        stormPeriodLength: number,
        recovery1PeriodLength: number,
        recovery2PeriodLength: number,
        highlighted: boolean,
    ): {} {
        const pb = {
            id: stormPlotBandId,
            from: moment(stormStartTime).valueOf(),
            to: moment(stormStartTime)
                .add(stormPeriodLength + recovery1PeriodLength + recovery2PeriodLength, 'minutes')
                .valueOf(),
            color: SLI_OCOLOR_STORM_PERIOD,
            zIndex: plotBandOrder.storm,
        };
        if (highlighted) {
            pb['borderColor'] = SLI_OLCOLOR_STORM_PERIOD;
            pb['borderWidth'] = 2.5;
        }
        return pb;
    }

    private jumpScroll(direction: boolean) {
        const shownTimeSpan = this.shownDateRange[1] - this.shownDateRange[0];
        let newRange: number[];

        if (direction) {
            // Jump forward
            newRange = [this.shownDateRange[1], this.shownDateRange[1] + shownTimeSpan];
            if (newRange[0] > new Date(this.selectedDateRange[1]).getTime()) {
                return;
            }
        } else {
            // Jump backward
            newRange = [this.shownDateRange[0] - shownTimeSpan, this.shownDateRange[0]];
            if (newRange[1] < new Date(this.selectedDateRange[0]).getTime()) {
                return;
            }
        }
        this.useNavigator(newRange);
    }

    private checkJumpScrollButtonsEnabled() {
        const shownTimeSpan = this.shownDateRange[1] - this.shownDateRange[0];
        const newForwardRange = [this.shownDateRange[1] + 1, this.shownDateRange[1] + 1 + shownTimeSpan];
        const newBackRange = [this.shownDateRange[0] - 1 - shownTimeSpan, this.shownDateRange[0] - 1];

        const defaultClass = 'jump-scroll-btn';
        const disabledClass = ' disabled';

        const chartOptions = {
            xAxis: {
                min: this.shownDateRange[0],
                max: this.shownDateRange[1]
            },
            exporting: {
                buttons: {
                    forward: {
                        className: defaultClass
                    },
                    back: {
                        className: defaultClass
                    }
                }

            }
        };

        // Add disabled class if needed
        if (newForwardRange[0] > this.selectedDateRange[1]) {
            chartOptions.exporting.buttons.forward.className += disabledClass;
        }
        if (newBackRange[1] < this.selectedDateRange[0]) {
            chartOptions.exporting.buttons.back.className += disabledClass;
        }
        setTimeout(() => {
            // Need to ensure DOM is loaded before sending graph to it
            this.chartElement.update(chartOptions);
        }, 0);
    }

    private useNavigator(newRanges: number[]) {
        this.shownDateRange = newRanges;
        this.checkJumpScrollButtonsEnabled();
        this.chartElement.xAxis[0].setExtremes(newRanges[0], newRanges[1]);
    }

    private clickZoomButton(buttonId: number) {
        // Default zoom button action takes time from end of chart
        const min = this.selectedDateRange[0];
        let max = this.selectedDateRange[1];
        const oneDay = 60 * 60 * 24 * 1000;

        const self = this;

        switch (buttonId) {
            case 0: {
                // 1 day
                max = min + oneDay;
                this.chartOptions.rangeSelector.selected = 0;
                break;
            }
            case 1: {
                // 1 week
                max = min + oneDay * 7;
                this.chartOptions.rangeSelector.selected = 1;
                break;
            }
            case 2: {
                // 1 month
                max = min + oneDay * 30;
                this.chartOptions.rangeSelector.selected = 2;
                break;
            }
            case 3:
            default: {
                this.chartOptions.rangeSelector.selected = 3;
                break;
            }
        }
        this.shownDateRange = [min, max];
        this.useNavigator([min, max]);
        this.checkJumpScrollButtonsEnabled();
    }

    private getPrecompStartForStorm(stormId: number, stormStartTime: string): string {
        const overrideIndex = this.basinStormSettings
            ? this.basinStormSettings.findIndex((b) => b.stormId === stormId && b.basinName === this.basinName)
            : -1;
        const altPrecompStart =
            overrideIndex < 0
                ? undefined
                : this.basinStormSettings[overrideIndex].altPrecompStart;
        return altPrecompStart;
    }

    /** Show storm event plot bands. */
    public addStorms() {
        if (!this.chartElement || !this.chartElement.xAxis) {
            return;
        }
        this.stormIdMap = {};
        if (this.stormEvents) {
            this.stormEvents.forEach((storm: StormEvent) => {
                const { stormId, stormStartTime, stormPeriodLength, recovery1PeriodLength, recovery2PeriodLength } =
                    storm;
                const altPrecompStart = this.getPrecompStartForStorm(stormId, stormStartTime);
                const stormPlotBandId = this.getStormPlotbandId(stormStartTime, stormPeriodLength);

                this.chartElement.xAxis[0].removePlotBand(stormPlotBandId);
                if (!this.stormIdMap[stormPlotBandId]) {
                    const pb = this.plotBandForStorm(
                        stormPlotBandId,
                        stormStartTime,
                        altPrecompStart,
                        stormPeriodLength,
                        recovery1PeriodLength,
                        recovery2PeriodLength,
                        stormId === this.selectedStormId,
                    );
                    this.chartElement.xAxis[0].addPlotBand(pb);
                    this.stormIdMap[stormPlotBandId] = stormPlotBandId;
                }
            });
        }
    }

    /** Hide storm event plot bands. */
    public hideStorms() {
        if (!this.chartElement) return;

        const stormIds = Object.keys(this.stormIdMap);

        stormIds.forEach((stormId) => {
            this.chartElement.xAxis[0].removePlotBand(stormId);
        });

        this.stormIdMap = {};
    }

    private hideDryDays() {
        if (this.chartElement && this.plotBands.length > 0) {
            this.plotBands.forEach((p) => {
                this.chartElement.xAxis[0].removePlotBand(p.id);
            });
        }
    }

    /** Creates a unique ID for a storm in order to avoid plot bands with duplicate IDs. */
    private getStormPlotbandId(stormStartTime: string, stormPeriodLength: number) {
        return `storm-id-${stormStartTime}-${stormPeriodLength}`;
    }

    /**
     * This method will add selected dry day on chart by dragging on chart
     * @param {number} minDate: as start date in number format
     * @param {number} maxDate: as end date in number format
     */
    private selectDryDayOnChart(minDate: number, maxDate: number) {
        let currentTimestamp = minDate;
        while (currentTimestamp <= maxDate) {
            const index = this.plotBands.findIndex((b) => b.id === currentTimestamp);
            if (index >= 0) {
                this.removePlotBand(currentTimestamp);
            } else {
                this.addPlotBand(currentTimestamp, plotBandOrder.dryDay);
            }
            currentTimestamp = moment(currentTimestamp).add(1, 'day').valueOf();
        }
    }

    private addStorm(dateTime: number) {
        const study = this.sliicerService.caseStudyDetails.getValue();
        const precompType = study.settings.defaultPrecompType;
        const studyStepLength = study.config.stepLengthMinutes;
        const isHourlyStep = studyStepLength === 60;

        let stormStartTime: Date;

        if (isHourlyStep) {
            stormStartTime = this.dateutilService.roundToNearestHour(new Date(dateTime));
        } else {
            stormStartTime = this.dateutilService.roundToNearest30(new Date(dateTime));
        }

        stormStartTime = this.validateNewStormStartTime(stormStartTime.getTime(), isHourlyStep);

        const stormAdjustment = {
            deattachPrecomp: true,
            precompPeriodLength: 1440,
            recovery1PeriodLength: 1440,
            recovery2PeriodLength: 1440,
            stormPeriodLength: 1440,
            stormStartTime: stormStartTime.toISOString(),
        }

        this.matDialog
            .open<StormSettingsDialogComponent, StormSettingsDialogData, StormSettingsDialogData>(
                StormSettingsDialogComponent,
                {
                    data: {
                        adjustment: stormAdjustment,
                        precompType: fromNullable(precompType),
                        season: '',
                        regime: '',
                        seasons: this.seasons,
                        regimes: this.regimes,
                        isCreateMode: true,
                        existingStormsStartDates: this.stormEvents.map(v => new Date(v.stormStartTime).getTime())
                    },
                },
            ).afterClosed().subscribe((res) => {
                if (!res) return;

                const overrides: Overrides = {
                    addedStormEvents: [...this.addedStormEvents, { stormStartTime: res.adjustment.stormStartTime }]
                };

                this.stormHelper.applyStormEdit(overrides, {} as any, res, this.basinName);
                this.stormCreated.emit({ overrides, storm: res });
            });
    }

    // will check if user has chosen existing storm start time, if so, will get new date
    // for picking new date we get earlier datetime by study step length (30mins, 60mins), if no earlier available, we take later dates
    private validateNewStormStartTime(startTime: number, isHourlyStep: boolean, toLeft = true): Date {
        const allStormsStartTimes = this.stormEvents.map(v => new Date(v.stormStartTime).getTime());

        // no duplicate date time, return current value
        if (!allStormsStartTimes.includes(startTime)) {
            return new Date(startTime);
        }

        const step = isHourlyStep ? 60 * 1000 * 60 : 30 * 1000 * 60;
        const studyStart = this.chartSeriesData[0].data[0][0];

        // to Left true means we are picking earlier dates
        if (toLeft) {
            // egde case, if picked date time goes outside of study date, here we need to start picking later dates
            if (startTime - step < studyStart) {
                return this.validateNewStormStartTime(startTime + step, isHourlyStep, false);
            } else {
                // normal case, newly picked date is within study date range, new call will try new date time
                return this.validateNewStormStartTime(startTime - step, isHourlyStep, true);
            }
        }

        // if toLeft is false, then we are picking later dates
        if (toLeft === false) {
            return this.validateNewStormStartTime(startTime + step, isHourlyStep, false);
        }
    }

    /**
     * This method will add a plot band to the hydrograph chart
     * @param plotBand
     * @param zIndex
     */
    private addPlotBand(plotBand: number, zIndex: number) {
        const timestamp = moment(plotBand);
        const endTime = moment(timestamp).add(1, 'days').valueOf();
        // TODO: WPS - do we want to colorize this differently?
        //      We do _not_ want to use `plotBandColorFromDayOfWeek`
        const color = SLI_OCOLOR_PRECOMP;
        const index = this.plotBands.findIndex((p) => p.id === plotBand);
        if (index >= 0) {
            this.removePlotBand(this.plotBands[index].id);
        }
        const band = this.getPlotBand(color, plotBand, endTime);
        this.plotBands.push(band);
        this.chartElement.xAxis[0].addPlotBand(Object.assign({ zIndex }, band));
        this.plotBandAdded.emit(timestamp.format('YYYY-MM-DD'));
    }

    private getPlotBand(color: string, startTime: number, endTime: number): any {
        return {
            color: color,
            from: startTime,
            to: endTime,
            id: startTime,
            events: {
                click: this.plotBandClickCallback.bind(this),
            },
        };
    }

    private getPlotBandForString(color: string, startTimeString: string): any {
        const timestamp = moment(startTimeString);
        const startTime = moment(timestamp).startOf('date').valueOf();
        const endTime = moment(timestamp).add(1, 'days').valueOf();
        return this.getPlotBand(color, startTime, endTime);
    }

    /**
     * Event triggered when user clicks a plot band
     * TODO: WPS - Will need better logic when we have more overlays (selected
     *       days are lines right now but need to be plot bands, selected storm
     *       will also need to be shown).
     * @param event
     */
    private plotBandClickCallback(event: Highcharts.ChartClickEvent) {
        if (this.isDryDaysEditActive && event.target instanceof SVGPathElement) {
            // Without a timeout here xAxis is undefined
            setTimeout(() => {
                const xValue = event.xAxis[0].value;
                const index = this.getPlotBandIndex(xValue);
                if (index >= 0) {
                    this.removePlotBand(this.plotBands[index].id);
                }
            }, 0);
        }
    }

    private getPlotBandIndex(dateValue) {
        return this.plotBands.findIndex((p) => p.id === moment(dateValue).startOf('date').valueOf());
    }

    /**
     * Event triggered when user selects a region of the chart
     * @param event
     */
    private chartSelectionCallback(event: Highcharts.ChartSelectionEvent) {
        if (this.isDryDaysEditActive) {
            setTimeout(() => {
                if (event.xAxis && event.xAxis[0]) {
                    const xMinValue = moment(event.xAxis[0].min).startOf('date').valueOf();
                    const xMaxValue = moment(event.xAxis[0].max).endOf('date').valueOf();
                    this.selectDryDayOnChart(xMinValue, xMaxValue);
                }
            }, 0);
            event.preventDefault();
        }
    }

    // This method will convert date into timestamp
    private static toTimestamp(date: Date, addDay = 0, addMonth = 0, addYear = 0, removeTime = false) {
        return new Date(
            !!addYear ? date.getUTCFullYear() + addYear : date.getUTCFullYear(),
            !!addMonth ? date.getUTCMonth() + addMonth : date.getUTCMonth(),
            !!addDay ? date.getUTCDate() + addDay : date.getUTCDate(),
            !!removeTime ? 0 : date.getUTCHours(),
            !!removeTime ? 0 : date.getUTCMinutes(),
            !!removeTime ? 0 : date.getUTCSeconds(),
            !!removeTime ? 0 : date.getUTCMilliseconds(),
        );
    }

}
