import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    Input,
    OnChanges,
    EventEmitter,
    Output,
    ViewChild,
    ElementRef,
    SimpleChanges,
    ChangeDetectionStrategy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
const HIGHCHARTS = require('highcharts');
const HIGHCHARTS3D = require('highcharts/highcharts-3d');
HIGHCHARTS3D(HIGHCHARTS);
import { FilterDataSource } from '../../shared/components/paging/filter-data-source';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StringUtils } from '../../shared/utils/string-utils';
import { StatusCodeService } from '../../shared/services/status-code.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import * as moment from 'moment';
import { BatteryStatusList, BatteryStatusReportArgs } from 'app/shared/models/battery-status';
import { FilterSettings, WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { BatteryStatusService } from 'app/shared/services/battery-status.service';
import { LocationService } from 'app/shared/services/location.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { MonitorSeries } from 'app/shared/components/location-card/location-card.constants';

const LOW = 'LOW';
const CRITICAL = 'CRITICAL';
const GOOD = 'GOOD';
const UNKNOWN = 'UNKNOWN';

@Component({
    selector: 'app-battery-status-widget',
    templateUrl: './battery-status-widget.component.html',
    styleUrls: ['./battery-status-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryStatusWidgetComponent implements OnInit, OnChanges, OnDestroy {
    public navigationSubscription: any;
    @Input() public showBatteryStatusSearch: boolean;
    @Input() public availablePageHint: boolean;
    @Output() public showMapLocationForBattery: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public customerLocationsLoaded: boolean;
    @Input() public includeInactiveLocations: boolean;
    @Input() public dateFormat: string;
    @Input() public toggleState: boolean;
    @Input() public reloadReport: Subject<boolean>
    @Output() private emitWidgetData = new EventEmitter();

    private subscriptions = new Array<Subscription>();
    public batteryStatusCriticalData = new Array<number>();
    public options: Object;
    public sparkLineCharts = new Array<Object>();
    public sparkLineOptions: Object;
    public chart: Object;
    public batteryStatusData: BatteryStatusList;
    public batteryCsvStatusData: BatteryStatusList;
    public statuses = new Array<string>();
    public chartData = new Array<Array<Object>>();
    public sparkLineData = new Array<Object>();
    public setFirstPage: boolean;
    public filterSettings: FilterSettings;
    public status: string;
    public location: number;
    private previousGraphStatus = '';
    public isdataAvailableUponFilter = true;

    public batteryWidgetDisplayedColumns = [];
    public batteryWidgetFilterColumns = [];
    public batteryWidgetDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator) public batteryWidgetPaginator: MatPaginator;
    @ViewChild(MatSort) public batteryWidgetSort: MatSort;
    @ViewChild('filter') public batteryWidgetFilter: ElementRef;
    public batteryWidgetDataChange: BehaviorSubject<Object[]> = new BehaviorSubject<Object[]>([]);
    public locationHeader: string;
    public monitorHeader: string;
    public statusHeader: string;
    public timeHeader: string;
    public voltageHeader: string;
    public get batteryWidgetData(): Object[] {
        return this.batteryWidgetDataChange.value;
    }
    public totalPaginationLength: number;
    @Output()
    public batteryWidgetLoadingState: boolean;

    // Variable which validates API state of the first initially loaded battery widget locations on map

    public batteryWidgetAPIState = true;

    public criticalBatteryStatus: string;
    public lowBatteryStatus: string;
    public goodBatteryStatus: string;
    public unknownBatteryStatus: string;
    public isExpandedWidget: boolean;
    public translateKeys: Array<string> = [
        'COMMON.CRITICAL_BATTERY_STATUS',
        'COMMON.GOOD_BATTERY_STATUS',
        'COMMON.LOW_BATTERY_STATUS',
        'COMMON.UNKNOWN_BATTERY_STATUS',
        'HOME.BATTERY_WIDGET.SERIES_COLUMN_TITLE',
        'HOME.BATTERY_WIDGET.VOLTAGE_COLUMN_TITLE',
        'HOME.BATTERY_WIDGET.TIME_STAMP_TITLE',
        'HOME.BATTERY_WIDGET.STATUS_COLUMN_TITLE',
        'HOME.BATTERY_WIDGET.LOCATION_TITLE',
    ];
    constructor(
        private batteryStatusService: BatteryStatusService,
        private cdr: ChangeDetectorRef,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private statusCodeService: StatusCodeService,
        private router: Router,
        private dateutilService: DateutilService,
        public translate: TranslateService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.criticalBatteryStatus = translateValues['COMMON.CRITICAL_BATTERY_STATUS'];
            this.goodBatteryStatus = translateValues['COMMON.GOOD_BATTERY_STATUS'];
            this.lowBatteryStatus = translateValues['COMMON.LOW_BATTERY_STATUS'];
            this.unknownBatteryStatus = translateValues['COMMON.UNKNOWN_BATTERY_STATUS'];
            this.monitorHeader = translateValues['HOME.BATTERY_WIDGET.SERIES_COLUMN_TITLE'];
            this.voltageHeader = translateValues['HOME.BATTERY_WIDGET.VOLTAGE_COLUMN_TITLE'];
            this.timeHeader = translateValues['HOME.BATTERY_WIDGET.TIME_STAMP_TITLE'];
            this.statusHeader = translateValues['HOME.BATTERY_WIDGET.STATUS_COLUMN_TITLE'];
            this.locationHeader = translateValues['HOME.BATTERY_WIDGET.LOCATION_TITLE'];
        });
    }

    public ngOnInit() {
        this.previousGraphStatus = null;

        this.filterSettings = <FilterSettings>{
            skipInitialCall: true,
            displayLocations: true,
            singleLocationSelect: true,
            displayBatteryStatusItems: true,
            preselectedBatteryStatus: null,
        };

        if(this.reloadReport) {
            this.subscriptions.push(
                this.reloadReport.subscribe((ignoreReload) => {
                    const args = <BatteryStatusReportArgs>{
                        CustomerID: this.customerID,
                        LocationGroupID: this.locationGroupID,
                        IncludeInactiveLocations: this.includeInactiveLocations,
                    };

                    args.PageSize = 25;
                    args.StartPage = 1;
                    this.generateBatteryWidgetTable(args);
                })
            );
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.toggleState && changes.toggleState.currentValue === true) {
            this.batteryWidgetDisplayedColumns = this.batteryWidgetFilterColumns = [
                'locationName',
                'seriesName',
                'voltage',
                'day',
            ];
        }
        if (changes.toggleState && changes.toggleState.currentValue === false) {
            this.isExpandedWidget = true;
            this.batteryWidgetDisplayedColumns = this.batteryWidgetFilterColumns = [
                'locationName',
                'seriesName',
                'voltage',
                'day',
                'status',
            ];
        }

        if ((changes.customerLocationsLoaded && changes.customerLocationsLoaded.currentValue) || changes.dateFormat) {
            const args = <BatteryStatusReportArgs>{
                CustomerID: this.customerID,
                LocationGroupID: this.locationGroupID,
                IncludeInactiveLocations: this.includeInactiveLocations,
            };

            this.previousGraphStatus = null;
            this.filterSettings = <FilterSettings>{
                skipInitialCall: true,
                displayLocations: true,
                singleLocationSelect: true,
                displayBatteryStatusItems: true,
                preselectedBatteryStatus: null,
            };

            this.batteryWidgetAPIState = true;
            args.PageSize = 25;
            args.StartPage = 1;
            this.generateBatteryWidgetTable(args);
        }
        if (this.batteryStatusData && this.isExpandedWidget && this.batteryStatusData.batteryStatusList.length > 0) {
            this.formatBatteryStatusTable();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public notifyWidget(data: WidgetFilterData) {
        const params = <BatteryStatusReportArgs>{
            CustomerID: this.customerID,
            LocationGroupID: this.locationGroupID,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        if (data.batteryStatus) {
            this.status = data.batteryStatus;
            params.Status = this.status;
        } else {
            this.status = '';
        }

        if (!data.batteryStatus) {
            this.previousGraphStatus = null;
        }

        if (data.locationIDs && data.locationIDs.length > 0) {
            this.location = data.locationIDs[0];
            params.LocationID = data.locationIDs;
        } else {
            // Reset Location to its default state.
            this.location = undefined;
        }
        params.PageSize = this.batteryWidgetPaginator.pageSize;
        params.StartPage = 1;
        this.batteryWidgetPaginator.pageIndex = 0;


        this.generateBatteryWidgetTable(params);
    }

    public generateBatteryWidgetGraph(goodBatteryTotal, criticalBatteryTotal, lowBatteryTotal, unknownBatteryTotal) {
        this.chartData = [
            [this.goodBatteryStatus, goodBatteryTotal],
            [this.criticalBatteryStatus, criticalBatteryTotal],
            [this.lowBatteryStatus, lowBatteryTotal],
            [this.unknownBatteryStatus, unknownBatteryTotal],
        ];

        // manually detect the changes to reflect the UI bindings
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme();
                this.drawChart();
            } else {
                StringUtils.setHighchartWhiteTheme();
                this.drawChart();
            }
        });
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public drawChart() {
        this.options = new Highcharts.Chart(<any>{
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
            chart: {
                width: 400,
                height: 240,
                renderTo: 'battery-chart',
                align: 'center',
            },
            title: { text: '' },
            colors: ['#5cb258', '#F44336', '#f99300', '#bfbab2'],
            tooltip: {
                enabled: false,
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical',
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 0,
                itemStyle: {
                    fontSize: '10px',
                    cursor: 'default',
                },
                itemMarginTop: 10,
                labelFormatter: function () {
                    return this.name + '  ' + this.percentage.toFixed(1) + ' %';
                },
            },
            plotOptions: {
                pie: {
                    size: 200,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    point: {
                        events: {
                            legendItemClick: function () {
                                return false;
                            },
                        },
                    },
                },
            },
            series: [
                {
                    type: 'pie',
                    innerSize: '50%',
                    data: this.chartData,
                    events: {
                        click: function (event) {
                            this.batteryWidgetLoadingState = true;
                            const params = <BatteryStatusReportArgs>{
                                CustomerID: this.customerID,
                                LocationGroupID: this.locationGroupID,
                                IncludeInactiveLocations: this.includeInactiveLocations,
                                Status: event.point.name,
                                PageSize: this.batteryWidgetPaginator.pageSize,
                                StartPage: 1,
                            };
                            this.batteryWidgetPaginator.pageIndex = 0;
                            this.status = event.point.name;
                            this.generateBatteryWidgetTable(params, false);
                        }.bind(this),
                    },
                },
            ],
        });
    }

    public getMarkerLocationDetails(locationId: number) {
        this.locationService.locationId = locationId;
    }

    public generateBatteryWidgetTable(params: BatteryStatusReportArgs, isRefreshPieCharts = true) {
        this.batteryWidgetLoadingState = true;
        const criticalAndLowBatterys: number[] = [];

        this.subscriptions.push(
            this.batteryStatusService.getBatteryStatus(params).subscribe(
                (res) => {
                    if (res) {
                        this.batteryStatusData = res;
                        this.totalPaginationLength = res.count;

                        this.batteryStatusData.batteryStatusList = res.batteryStatusList;

                        this.navigationSubscription = this.router.events.subscribe((e: any) => {
                            // If it is a NavigationEnd event re-initalise the component
                            if (e instanceof NavigationEnd) {
                                this.isdataAvailableUponFilter = true;
                                this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
                                    if (response) {
                                        StringUtils.setHighChartTheme();
                                    } else {
                                        StringUtils.setHighchartWhiteTheme();
                                    }
                                });
                            }
                        });
                        this.subscriptions.push(this.navigationSubscription);

                        if (isRefreshPieCharts) {
                            this.isdataAvailableUponFilter = true;
                            const goodBattery = res.batteryGraphStatusList.find((item) => item.status === GOOD);
                            const criticalBattery = res.batteryGraphStatusList.find((item) => item.status === CRITICAL);
                            const lowBattery = res.batteryGraphStatusList.find((item) => item.status === LOW);
                            const unknownBattery = res.batteryGraphStatusList.find((item) => item.status === UNKNOWN);
                            this.generateBatteryWidgetGraph(
                                goodBattery ? goodBattery.count : 0,
                                criticalBattery ? criticalBattery.count : 0,
                                lowBattery ? lowBattery.count : 0,
                                unknownBattery ? unknownBattery.count : 0,
                            );
                        }
                    } else {
                        this.isdataAvailableUponFilter = false;
                    }

                    /**
                     * Below Logic will emit the battery widget locations to parent component(i.e.landing page)
                     * to display the locations on map. This code will also avoid the multiple API call to fetch the battery status data.
                     */
                    if (this.batteryStatusData) {
                        this.batteryStatusService.batteryStatusData = this.batteryStatusData.batteryStatusList;

                        for (const item of this.batteryStatusData.batteryStatusList) {
                            const itemStatus = item.status.toLowerCase();
                            if (itemStatus === 'critical' || itemStatus === 'low') {
                                criticalAndLowBatterys.push(item.locationID);
                            }
                        }
                    }

                    if (this.batteryWidgetAPIState) {
                        this.showMapLocationForBattery.emit(criticalAndLowBatterys);
                    }
                    this.batteryWidgetAPIState = false;

                    this.formatBatteryStatusTable();

                    this.batteryWidgetLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.batteryStatusData = null;
                    this.totalPaginationLength = 0;
                    this.batteryWidgetDataSource = new FilterDataSource(
                        new BehaviorSubject([]),
                        [],
                        this.batteryWidgetPaginator,
                        this.batteryWidgetSort,
                        this.batteryWidgetFilterColumns,
                        true,
                        this.dateFormat,
                    );
                    this.batteryWidgetLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }

    private createParams(page?): BatteryStatusReportArgs {
        const params = <BatteryStatusReportArgs>{
            CustomerID: this.customerID,
            LocationGroupID: this.locationGroupID,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        params.Sort = '';

        if(page) {
            params.StartPage = page ? page.pageIndex + 1 : 1;
            params.PageSize = page.pageSize;
        }

        params.SearchValue = '';
        if (this.status) {
            params.Status = this.status;
        }
        if (this.location) {
            params.LocationID = [this.location];
        }

        return params;
    }

    public changePage(page) {
        const params = this.createParams(page);
        this.generateBatteryWidgetTable(params, false);
    }

    public downloadCSV() {
        this.batteryWidgetLoadingState = true;
        const params = this.createParams();
        this.batteryStatusService.getBatteryStatus(params).subscribe((res) => {

            const csvData = [];
            const dateFormat = `${String(this.dateutilService.getFormat()).toUpperCase()}`;
            const startDate = this.dateutilService.getPreviousDate(new Date(), 1);
            const csvHeaders = [
                this.locationHeader,
                this.monitorHeader,
                this.voltageHeader,
                this.timeHeader,
                this.statusHeader,
            ];
            if (res && res.batteryStatusList && res.batteryStatusList.length > 0) {
                res.batteryStatusList.map((arrayItem) => {
                    if (arrayItem) {
                        csvData.push([
                            arrayItem.locationName,
                            arrayItem.seriesName,
                            arrayItem.voltage,
                            arrayItem.day,
                            arrayItem.status,
                        ]);
                    }
                });
            }
            const options = {
                showLabels: true,
                headers: csvHeaders,
                title: `Battery ${moment(startDate).format(dateFormat)}`,
                showTitle: true,
            };

            this.batteryWidgetLoadingState = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);

            const result = new AngularCsv(csvData, 'Battery Status Report', options);
        },
        (ignoreError) => {
            this.batteryWidgetLoadingState = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

    }

    public formatBatteryStatusTable() {
        this.batteryWidgetDataSource = null;
        this.batteryWidgetDataChange = new BehaviorSubject<Object[]>([]);
        if (this.batteryStatusData) {
            for (const batteryStatusData of this.batteryStatusData.batteryStatusList) {
                const batteryWidgetCopiedData = this.batteryWidgetData.slice();
                const seriesName = MonitorSeries.find(a => a.value === batteryStatusData.seriesName);
                const data = {
                    locationName: batteryStatusData.locationName,
                    locationID: batteryStatusData.locationID,
                    voltage: batteryStatusData.voltage,
                    status: batteryStatusData.status,
                    day: batteryStatusData.day.split(' ')[0], // Just need to go with date only.
                    seriesName: seriesName ? seriesName.text : batteryStatusData.seriesName,
                };
                batteryWidgetCopiedData.push(data);
                this.batteryWidgetDataChange.next(batteryWidgetCopiedData);
            }
            this.dateFormat = this.dateutilService.getFormat();

            this.batteryWidgetDataSource = new FilterDataSource(
                this.batteryWidgetDataChange,
                this.batteryWidgetData,
                this.batteryWidgetPaginator,
                this.batteryWidgetSort,
                this.batteryWidgetFilterColumns,
                true,
                this.dateFormat,
            );
            this.emitWidgetData.emit(this.batteryWidgetDataChange.getValue());
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public navigate() {
        window.open('https://store.adsenv.com/', '_blank');
    }

    /**
     * //checks if the name length is more than specific length
     */
    public validateLocationNameLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }
}
