import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormControl } from '@angular/forms';
import {
    Component,
    OnInit,
    OnDestroy,
    AfterContentInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ViewChild,
    Inject,
    ChangeDetectorRef,
    SimpleChanges,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { DataEditingAuditReportArgs } from 'app/pages/report/data-editing-audit-report/data-editing-audit-report.model';
import { Observable } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { RAIN_ENTITY, VELOCITY_ENTITY, DEPTH_ENTITY, QUANTITY_ENTITY, UnitOfMeasureType, DataEditType, RAW_VELOCITY_ENTITY, ELEVATION_ENTITY, Q_A1_ENTITY, Q_A2_ENTITY, Q_A1, Q_A2 } from 'app/shared/constant';
import * as moment from 'moment';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DataEditLog, DataEditReport, DataRevertRequest, RevertDataEdit } from 'app/shared/models/data-edit';
import { SelectItem } from 'app/shared/models/selected-Item';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CustomerService } from 'app/shared/services/customer.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { TranslateService } from '@ngx-translate/core';

export interface RollbackEditorDialogData {
    customerId: number;
    locationId: number;
    startDate: Date;
    endDate: Date;
    depthOnY: boolean;
    editingCurve: number;

}

interface RollbackRow {
    user: string;
    timestamp: string;
    timestampValue: string;
    reason: string;
    [RAIN_ENTITY]: DataEditLog;
    [VELOCITY_ENTITY]: DataEditLog;
    [DEPTH_ENTITY]: DataEditLog;
    [ELEVATION_ENTITY]: DataEditLog;
    [QUANTITY_ENTITY]: DataEditLog;
    [RAW_VELOCITY_ENTITY]: DataEditLog;
}

@Component({
    templateUrl: './rollback-editor-dialog.component.html',
    styleUrls: ['./rollback-editor-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class RollbackEditorComponent implements OnInit, OnDestroy, AfterContentInit {
    public showConfirmation: boolean;
    public displayedColumnsFirstRow: string[] = ['empty', 'depth', 'velocity', 'quantity', 'rain', 'elevation', 'rawVelocity', 'qa1', 'qa2', 'empty2'];
    public displayedColumnsSecondRow: string[] = [
        'select',
        'timestamp',
        'originalValueDepth',
        'assignedValueDepth',
        'originalValueVelocity',
        'assignedValueVelocity',
        'originalValueQuantity',
        'assignedValueQuantity',
        'originalValueRain',
        'assignedValueRain',
        'originalValueElevation',
        'assignedValueElevation',
        'originalRawVelocity',
        'assignedRawVelocity',

        'originalQA1',
        'assignedQA1',
        'originalQA2',
        'assignedQA2',

        'performedBy',
        'comments',
        'revert',
    ];
    public dataSource: MatTableDataSource<RollbackRow>;
    private allData: RollbackRow[];
    public selection = new SelectionModel<string>(true, []);
    public selectedEntities = new FormControl();
    public endDate: Date;
    public startDate: Date;
    public today = new Date();
    public isLoading = false;
    public length: number;
    public isAllSelected = false;
    public entities: Array<SelectItem> = [
        { id: RAIN_ENTITY, name: 'RAIN', text: 'RAIN', isChecked: true },
        { id: VELOCITY_ENTITY, name: 'VELOCITY', text: 'VELOCITY', isChecked: true },
        { id: DEPTH_ENTITY, name: 'DEPTH', text: 'DEPTH', isChecked: true },
        { id: ELEVATION_ENTITY, name: 'ELEVATION', text: 'ELEVATION', isChecked: true },
        { id: QUANTITY_ENTITY, name: 'QUANTITY', text: 'QFINAL', isChecked: true },
        { id: RAW_VELOCITY_ENTITY, name: 'RAWVEL', text: 'RAWVEL', isChecked: true },
        { id: Q_A1_ENTITY, name: Q_A1, text: Q_A1, isChecked: true },
        { id: Q_A2_ENTITY, name: Q_A2, text: Q_A2, isChecked: true },
    ];

    public rainEntityId = RAIN_ENTITY;
    public velocityEntityId = VELOCITY_ENTITY;
    public depthEntityId = DEPTH_ENTITY;
    public quantityEntityId = QUANTITY_ENTITY;
    public elevationEntityId = ELEVATION_ENTITY;
    public rawVelocityEntityId = RAW_VELOCITY_ENTITY;
    public entitiesIds = {
        qa1: Q_A1_ENTITY,
        qa2: Q_A2_ENTITY
    }
    public customerDateFormat: string;

    public isMetric: boolean;
    public availablePageHint: boolean;

    private datesHaveChanged = false;
    private hasReverted = false;

    @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
    // @ViewChild('entitiesGroupMultiselectBeta') public entitiesGroupMultiselect: MultiSelectGroupBetaComponent;

    private subscriptions = new Array<Subscription>();
    private locationId: number;
    private customerId: number;

    constructor(
        private userEditService: DataEditService,
        @Inject(MAT_DIALOG_DATA) public data: RollbackEditorDialogData,
        public dialogRef: MatDialogRef<RollbackEditorComponent>,
        private matDialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private uiUtilService: UiUtilsService,
        private dateutilService: DateutilService,
        private customerService: CustomerService,
        public domOpsService: DomOperationUtilsService,
        private translate: TranslateService
    ) {
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.locationId = data.locationId;
        this.customerId = data.customerId;
        this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            this.customerDateFormat = newDateFormat;
        });
    }

    public ngOnInit() {
        const hintSubs = this.domOpsService.showpageHint.subscribe((result: boolean) => {
            this.availablePageHint = result;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });
        this.subscriptions.push(hintSubs);
        this.domOpsService.rollbackFilterOpened = true;

        this.isMetric = this.customerService.customer.unitsType === UnitOfMeasureType.METRIC;
        this.selectedEntities.valueChanges.subscribe(() => {
            this.deselectAll();
            this.selection.clear();
            this.paginator.firstPage();
            this.fetchDataAndUpdateTable();
        });

        this.selectedEntities.setValue(this.entities);
        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    public ngAfterContentInit() {
        this.dataSource = new MatTableDataSource();
        this.fetchDataAndUpdateTable();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public onDateChanged(changes: SimpleChanges) {
        if (
            changes.startDate.previousValue !== changes.startDate.currentValue ||
            changes.endDate.previousValue !== changes.endDate.currentValue
        ) {
            this.datesHaveChanged = true;
        }
    }

    public onCloseDateMenu() {
        if (this.datesHaveChanged) {
            this.deselectAll();
            this.paginator.firstPage();
            this.fetchDataAndUpdateTable();
            this.datesHaveChanged = false;
        }
    }

    public masterToggle() {
        this.isAllSelected ? this.selection.clear() : this.selectAllInPage();

        this.isAllSelected = !this.isAllSelected;
    }

    public cancel() {
        this.dialogRef.close({ isReverted: this.hasReverted, points: [] });
    }

    // fetch and revert all rows if isAllSelected, otherwise revert rows in selection
    public revertSelected() {
        if (this.isAllSelected) {
            this.isLoading = true;
            this.fetchData(this.length).subscribe((report) => {
                this.isLoading = false;
                this.revert(report.logs.map((row) => this.mapDataEditLog(row)), report.logs);
            });
        } else {
            this.revert(
                this.selection.selected.reduce((acc, str) => [...acc, ...this.parseRevertDataEditString(str)], []),
                this.selection.selected.map(v => JSON.parse(v))
            );
        }
    }

    public revertRow(row: RollbackRow) {
        const logs = [];
        if (row[DEPTH_ENTITY]) {
            logs.push({ ...row, ...row[DEPTH_ENTITY] });
        }

        if (row[VELOCITY_ENTITY]) {
            logs.push({ ...row, ...row[VELOCITY_ENTITY] });
        }

        if(row[RAW_VELOCITY_ENTITY]) {
            logs.push({ ...row, ...row[RAW_VELOCITY_ENTITY] });
        }

        if (row[ELEVATION_ENTITY]) {
            logs.push({ ...row, ...row[ELEVATION_ENTITY] });
        }

        this.revert(this.mapRollbackRow(row), logs);
    }

    private deselectAll() {
        this.selection.clear();
        this.isAllSelected = false;
    }

    private revert(rows: RevertDataEdit[], logs: DataEditLog[] | RollbackRow[]): Subscription {
        const dateFormat = `${String(
            this.dateutilService.getFormat(),
        ).toUpperCase()} ${this.dateutilService.getTimeFormat()}`;
        const message = `Are you sure you want to revert ${rows.length} values?`;
        const dangerText = this.translate.instant('COMMON.ROLLBACK_EDITOR_QH.REVERT_DANGER_TEXT');
        return this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: 'Revert confirmation',
                    message,
                    okText: 'Yes',
                    cancelText: 'No',
                    okDisabled: rows.length === 0,
                    dangerText
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.hasReverted = true;
                    this.isLoading = true;
                    this.uiUtilService.safeChangeDetection(this.cdr);

                    const updatePoints = rows.map(({ eid, timestampValue }) => {
                        // #32416 Cannot use JS Date here, we need real UTC time (including timezone time changes)
                        const ts = moment.utc(timestampValue).toISOString();
                        return {
                            eid,
                            timestamp: ts
                        };
                    });
                    const isRawvelSelected = this.selectedEntities.value?.some((e) => e.id === RAW_VELOCITY_ENTITY);
                    const dataRevertRequest: DataRevertRequest = {
                        dataEditType: DataEditType.RollbackEditor,
                        start: this.startDate,
                        end: this.endDate,
                        depthOnY: this.data.depthOnY,
                        editingCurve: this.data.editingCurve,
                        updatePoints: updatePoints,
                        isRawVelEntitySelected: isRawvelSelected,
                    }

                    const revertSubscription = this.userEditService
                        .dataEditRevert(
                            this.customerId,
                            this.locationId,
                            dataRevertRequest
                        )
                        .subscribe(
                            (res) => {
                                this.isLoading = false;
                                this.selection.clear();
                                this.isAllSelected = false;
                                // we need to emit reverted points to revert changes on scattergraph
                                this.dialogRef.close({ isReverted: this.hasReverted, points: logs, response: res});
                            },
                            () => {
                                this.isLoading = false;
                                this.uiUtilService.safeChangeDetection(this.cdr);
                            },
                        );

                    this.subscriptions.push(revertSubscription);
                } else {
                    this.isLoading = false;
                    this.uiUtilService.safeChangeDetection(this.cdr);
                }
            });
    }

    public fetchDataAndUpdateTable() {
        if (!this.selectedEntities.value) {
            return;
        }
        this.isLoading = true;
        const reportSubscription = this.fetchData().subscribe((report) => {
            const defaultRow = {
                [RAIN_ENTITY]: null,
                [VELOCITY_ENTITY]: null,
                [DEPTH_ENTITY]: null,
                [ELEVATION_ENTITY]: null,
                [QUANTITY_ENTITY]: null,
                [RAW_VELOCITY_ENTITY]: null,
                [Q_A1_ENTITY]: null,
                [Q_A2_ENTITY]: null,
            };

            this.allData = Object.values(
                report.logs.reduce((acc, val) => {
                    if (!acc[val.timestamp]) {
                        acc[val.timestamp] = {
                            ...defaultRow,
                            timestamp: val.timestamp,
                            timestampValue: val.timestampValue,
                            user: val.user,
                            reason: val.reason,
                        };
                    }
                    return {
                        ...acc,
                        [val.timestamp]: {
                            ...acc[val.timestamp],
                            [val.entityID]: val,
                        },
                    };
                }, {} as { [key: string]: RollbackRow }),
            ).sort((a, b) => new Date(b.timestampValue).getTime() - new Date(a.timestampValue).getTime());

            this.dataSource.data = this.allData;
            this.length = report.count;

            if (this.isAllSelected) {
                this.selectAllInPage();
            }
            this.isLoading = false;
            this.uiUtilService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.push(reportSubscription);

        return reportSubscription;
    }

    // We use this to identify rows rather than a reference to the row object as that will break on pagination
    public stringifyDataEditLog(row: RollbackRow): string {
        return JSON.stringify(row);
    }

    public isRawvelSelected() {
        return this.selection.selected.some(v => JSON.parse(v)[RAW_VELOCITY_ENTITY]);
    }

    private parseRevertDataEditString(input: string): RevertDataEdit[] {
        const parsed = JSON.parse(input);
        if (!parsed || typeof parsed !== 'object') {
            throw new Error('Unexpected type');
        }

        const result = this.mapRollbackRow(parsed);

        return result;
    }

    public setCurrentlySelectedgroupEntities(filterGroupEntities: SelectItem[]) {
        this.selectedEntities.setValue(filterGroupEntities.filter(({ isChecked }) => isChecked));
        this.dataSource.data = this.allData.filter(
            (x) =>
                (x[DEPTH_ENTITY] && this.selectedEntities.value.some((e) => e.id === DEPTH_ENTITY)) ||
                (x[VELOCITY_ENTITY] && this.selectedEntities.value.some((e) => e.id === VELOCITY_ENTITY)) ||
                (x[RAW_VELOCITY_ENTITY] && this.selectedEntities.value.some((e) => e.id === RAW_VELOCITY_ENTITY)) ||
                (x[QUANTITY_ENTITY] && this.selectedEntities.value.some((e) => e.id === QUANTITY_ENTITY)) ||
                (x[RAIN_ENTITY] && this.selectedEntities.value.some((e) => e.id === RAIN_ENTITY)) ||
                (x[ELEVATION_ENTITY] && this.selectedEntities.value.some((e) => e.id === ELEVATION_ENTITY)),
        );
    }

    // need to slice table items on hint, to hide scrollbar, bug #25378
    public sliceItemsOnHint(dataSource: MatTableDataSource<RollbackRow>) {
        return dataSource.data.slice(0, 10);
    }

    private selectAllInPage() {
        this.dataSource.data.forEach((row) => this.selection.select(this.stringifyDataEditLog(row)));
    }

    private fetchData(pageSize?: number): Observable<DataEditReport> {
        const args: DataEditingAuditReportArgs = {
            locationIds: [this.locationId],
            startTime: this.dateutilService.getLocalDateFromUTCDate(this.startDate),
            endTime: this.dateutilService.getLocalDateFromUTCDate(this.endDate),
            includeValues: true,
            paging: {
                pageSize: pageSize || this.paginator.pageSize,
                startPage: this.paginator.pageIndex + 1,
            },
            entityIds: this.selectedEntities.value.map((entity: SelectItem) => entity.id),
        };

        return this.userEditService.dataEditingAuditReport(this.customerId, args);
    }

    private mapDataEditLog({ timestamp, entityID, timestampValue }: DataEditLog): RevertDataEdit {
        return {
            timestamp,
            eid: entityID,
            timestampValue
        };
    }

    private mapRollbackRow(row: RollbackRow): RevertDataEdit[] {
        const result: RevertDataEdit[] = [];

        [DEPTH_ENTITY, VELOCITY_ENTITY, QUANTITY_ENTITY, RAIN_ENTITY, RAW_VELOCITY_ENTITY, ELEVATION_ENTITY, Q_A1_ENTITY, Q_A2_ENTITY].forEach((entityId) => {
            if (row[entityId]) {
                result.push(this.mapDataEditLog(row[entityId]));
            }
        });

        return result;
    }
}
