import { ChartXY, CoordinateXY, CursorPoint, SegmentFigure, SegmentSeries, UICheckBox, UIElement } from "@arction/lcjs";
import { LightningChartObject } from "./lightning-chart-object";
import moment from "moment";
import _ from "lodash";
import { LightningChartBuilder } from "../lightning-chart-builder/lightning-chart-builder";
import { HG_TOOLTIP_ARROW_STYLE, HG_TOOLTIP_INFO, HG_TOOLTIP_PADDING, HTML_BR, HTML_CIRCLE, HTML_DISPLAY_BLOCK, HTML_DISPLAY_NONE, LC_TOOLTIP_MIN_WIDTH, LC_YAXIS_THICKNESS_LEFT, LC_YAXIS_THICKNESS_RIGHT, NEW_CHART_TOP_RIGHT_SCREEN_POINT } from '../lightning-chart-builder/lightning-chart-ui-constants';
import { ChartPointShape, ChartSerieType, GraphEventDataConfiguration } from "../hydrograph-data/hydrograph-data-model";

interface TooltipSerieDef {
    color: string;
    hasTooltip?: boolean;
    unitOfMeasure?: string;
    precision?: number;
    isAnnot?: boolean;
}

const CHART_DIV_Y_OFFSET = 0;

export class LightningChartTooltip {
    lcChart: LightningChartObject;

    itTooltipStatic = false;
    showCursorAt: (chart: ChartXY, clientCoords: { clientX: number, clientY: number }, nearestDataPoints: CursorPoint[][]) => void;
    tooltipBox: HTMLDivElement;
    arrows: HTMLDivElement[][] = [];
    htmlContent: HTMLDivElement;

    info: HTMLDivElement;

    public translations: {
        description: '',
        started: '',
        duration: ''
    }

    public tooltipDateFormat: () => string;

    constructor(lcChart: LightningChartObject) {
        this.lcChart = lcChart;
    }

    public infoShowHint() {
        this.infoShow(this.lcChart.charts[0], {clientX: 0, clientY: 0});
        this.infoBoxShow(true);
    }

    public infoShow(chart: ChartXY, clientCoords: { clientX: number, clientY: number }) {
        if(chart === null) return;
        if(!this.lcChart.showEditingMenu || !this.lcChart.showEditingMenu()) return;

        const chartIndex = this.lcChart.charts.findIndex(c => c === chart);
        const yAdditionalAxis = this.lcChart.yAxes[chartIndex];

        const yAxes = yAdditionalAxis ? [chart.getDefaultAxisY(), yAdditionalAxis] : [chart.getDefaultAxisY()];

        let infoHTML = '';
        let seriesIndex = 0;
        let chartXposition = null;
        for(const axis of yAxes) {
            const serieDef = this.lcChart.seriesDef && this.lcChart.seriesDef[chartIndex] ? this.lcChart.seriesDef[chartIndex][seriesIndex] : null;
            if(!serieDef || serieDef.entityId === null || serieDef.entityId === undefined) {
                seriesIndex++;
                continue;
            }

            const chartSerie = this.lcChart.chartSeries[chartIndex][seriesIndex];
            const serieDefinition = this.lcChart.seriesDefition[serieDef.entityId];

            const mouseLocationAxis = chart.translateCoordinate(clientCoords, { x: chartSerie.axisX, y: chartSerie.axisY });
            chartXposition = mouseLocationAxis.x;
            const precision = serieDefinition.precision;
            infoHTML += `${axis.getTitle()}: ${Number(mouseLocationAxis.y).toFixed(precision)}${HTML_BR}`;
            seriesIndex++;
        }

        const dateOffset = new Date().getTimezoneOffset() * 60000;
        const momentDate = moment(chartXposition + dateOffset);
        infoHTML = `<div class="unselectable">${momentDate.format(this.tooltipDateFormat())}${HTML_BR} ${infoHTML}</div>`

        this.info.innerHTML = infoHTML;
        this.infoBoxShow(true);

        this.info.style.left = `${HG_TOOLTIP_INFO.left}px`;
        this.info.style.top = `${HG_TOOLTIP_INFO.topModifier}px`;
    }

    public tooltipShow(chart: ChartXY, clientCoords: { clientX: number, clientY: number }, nearestDataPoints: CursorPoint[][], syncWithWindow = true, notifySg = true) {
        this.infoShow(chart, clientCoords);

        if(!this.lcChart || !this.lcChart.chartSeries || !this.lcChart.chartSeries.length || !this.lcChart.chartSeries[0]?.length) return;

        const boundingClientRect = this.lcChart.dashboard.engine.container.getBoundingClientRect();

        let outerMousePosition: CoordinateXY;
        let outerNearestX: CursorPoint;
        this.htmlContent.innerHTML = '';

        let rowElement = ''
        let containsAny = false;

        for(let c = 0; c < this.lcChart.charts.length; c++) {
            if(!this.lcChart.chartSeries[c] || this.lcChart.chartSeries[c].length === 0) continue;

            for (let i = 0; i < this.lcChart.chartSeries[c].length; i++) {
                const series = this.lcChart.chartSeries[c][i];

                const mouseLocationAxis = series.chart.translateCoordinate(clientCoords, { x: series.axisX, y: series.axisY });

                const nearestPointX = nearestDataPoints.flat().reduce((prev, curr, i) => {
                    if (!prev) return curr;
                    if (!curr) return prev;
                    return Math.abs(mouseLocationAxis.x - curr.location.x) <= Math.abs(mouseLocationAxis.x - prev.location.x) ? curr : prev;
                });

                if (!nearestPointX) {
                    continue;
                }

                if (!outerNearestX) {
                    outerNearestX = nearestPointX;
                }

                if (mouseLocationAxis && mouseLocationAxis) {
                    outerMousePosition = mouseLocationAxis;
                }

                if (!this.htmlContent.innerHTML) {
                    const momentDate = moment.utc(nearestPointX.location.x);
                    this.htmlContent.innerHTML = `<span class="unselectable">${momentDate.format(this.tooltipDateFormat())}</span>`
                }

                let tooltipDef: TooltipSerieDef;
                const serieDef = this.lcChart.seriesDef[c][i];

                // #37309 Display tooltip for both series and annotation series
                if(serieDef) {
                    const serieDefinition = this.lcChart.seriesDefition[serieDef.entityId];
                    tooltipDef = {
                        color: serieDef.color,
                        ...serieDefinition
                    }
                } else {
                    // Since annotation series definitions are held separatable, but in chart series are held together
                    const annotationSerieDef = this.lcChart.annotationSeries[i - this.lcChart.seriesDef[c].length];

                    if(!annotationSerieDef) {
                        continue;
                    }

                    tooltipDef = {...annotationSerieDef, isAnnot: true};
                }

                if(!tooltipDef.hasTooltip) continue;

                let nearestDataPoint = nearestDataPoints[c][i];

                // #38453 if no nearest data point found by Lightning charts, try to find one manually
                if (!nearestDataPoint) {
                    const toFindPoint = nearestDataPoints.flat().find(v => !!v && v.location && v.location.x);

                    if (toFindPoint) {
                        nearestDataPoint = this.findNearestPoint(c, i, toFindPoint.location.x);
                    }
                }

                let nearestY = nearestDataPoint?.location?.y;
                // FigureSeriesCursorPoint is not exported in LC
                if(nearestDataPoint?.hasOwnProperty('figure')) {
                    const figure = ((nearestDataPoint as any).figure) as SegmentFigure;
                    nearestY = figure.getDimensions().endY;
                }
                const chartSerie = this.lcChart.chartSeries[c][i];

                if(!chartSerie.getVisible()) continue;

                if(!nearestDataPoint || !nearestDataPoint.location || isNaN(nearestY)) {
                    this.arrowShow(c, i, false);
                    continue;
                }

                const currentChart = this.lcChart.charts[c];
                const nearestPointClient = currentChart.translateCoordinate({ x: nearestDataPoint.location.x, y: nearestY }, { x: chartSerie.axisX, y: chartSerie.axisY }, currentChart.coordsClient);

                const relativePosition = {
                    clientX: nearestPointClient.clientX - boundingClientRect.left,
                    clientY: nearestPointClient.clientY - boundingClientRect.top - CHART_DIV_Y_OFFSET
                }

                const pointsDoesNotMatch = nearestPointX.location.x !== nearestDataPoint.location.x;
                // #37293 Do not display tooltip for values within chart margin
                // #38203 Do display tooltip for values within the chart margin
                const pointsInViewport = relativePosition.clientX > LC_YAXIS_THICKNESS_LEFT && relativePosition.clientX < boundingClientRect.right -  boundingClientRect.left - LC_YAXIS_THICKNESS_RIGHT;

                if (tooltipDef.isAnnot) {
                    this.arrowShow(c, i, false);
                } else if (pointsDoesNotMatch) {
                    this.arrowShow(c, i, false);
                    continue;
                }

                containsAny = true;
                const color = tooltipDef.color;
                const units = tooltipDef.unitOfMeasure;
                const precision = tooltipDef.precision;

                // do not create pointers for annotation series
                if (!tooltipDef.isAnnot) {
                    this.createArrow(c, i);
                    this.arrows[c][i].style.left = `${Math.round(relativePosition.clientX)}px`;
                    this.arrows[c][i].style.top = `${Math.round(relativePosition.clientY)}px`;
                    this.arrows[c][i].style.backgroundColor = `${color}`;
                    this.arrowShow(c, i, true);
                }

                rowElement +=
                    `<div class='values unselectable'><span class="unselectable" style='color: ${color}' >${HTML_CIRCLE}</span><span class="unselectable"> ${this.lcChart.chartSeries[c][i].getName()}:</span>
                    <b>${(Math.round(Number(nearestY) * 1000) / 1000).toFixed(precision)}</b> ${units}</div>`
            }
        }

            this.htmlContent.innerHTML += rowElement;
            this.tooltipBoxShow(containsAny);

            if (outerMousePosition) {
                this.positionTooltip(clientCoords, outerMousePosition.x);
            }


            if(syncWithWindow) {
                this.lcChart.separateWindowService.dataHoverSubject.next({ id: 1, dateTime: outerNearestX?.location?.x });
            }

            if (notifySg && outerNearestX && outerNearestX.location) {
                this.lcChart.viewDataService.correlateAdvancedGraphs(
                    this.lcChart.dashboard.engine.container,
                    outerNearestX.location.x,
                );
            }
    }

    private findNearestPoint(chartIndex: number, seriesIndex: number, timestamp: number) {
        const serieDef = this.lcChart.seriesDef[chartIndex][seriesIndex];

        if (!serieDef || !serieDef.data || !serieDef.data.length) {
            return null;
        }

        const point = serieDef.data.find(v => v.x === timestamp);

        if (!point) {
            return null;
        }

        return { location: { x: point.x, y: point.y } } as CursorPoint;
    }

    public displayEventTooltip(element: UICheckBox & UIElement, entry: GraphEventDataConfiguration) {

        const dateOffset = new Date().getTimezoneOffset() * 60000;
        const momentDate = moment(entry.start + dateOffset);
        this.htmlContent.innerHTML =
            `<span>${this.translations.description}: ${entry.description}</span><br />`
            + `<span>${this.translations.started}: ${momentDate.format(this.tooltipDateFormat())}</span><br />`
            + `<span>${this.translations.duration}: ${entry.duration}</span><br />`;

        this.tooltipBoxShow(true);

        const currentChart = this.lcChart.charts[0];
        const chartSerie = this.lcChart.chartSeries[0][0];
        const nearestPointClient = currentChart.translateCoordinate({ x: element.getPosition().x, y: element.getPosition().y }, { x: chartSerie.axisX, y: chartSerie.axisY }, currentChart.coordsClient);
        this.positionTooltip(nearestPointClient, nearestPointClient.clientX);
    }

    private positionTooltip(position: {clientX: number, clientY: number}, mouseX: number) {
        const boundingClientRect = this.lcChart.dashboard.engine.container.getBoundingClientRect();

        let tooltipX = 0;
        let tooltipY = -40;
        if(this.itTooltipStatic) {
            tooltipX = boundingClientRect.right - boundingClientRect.left - this.tooltipBox.clientWidth + HG_TOOLTIP_PADDING.x;
        } else {
            tooltipX = position.clientX - boundingClientRect.left;
            tooltipY = position.clientY - boundingClientRect.top - CHART_DIV_Y_OFFSET;

            const tooltipWidth = this.tooltipBox.clientWidth > LC_TOOLTIP_MIN_WIDTH ? this.tooltipBox.clientWidth : LC_TOOLTIP_MIN_WIDTH;

            if(position.clientX > boundingClientRect.right  - tooltipWidth) tooltipX -= tooltipWidth + 20; else tooltipX += 20;
        }

        this.tooltipBox.style.left = `${Math.round(tooltipX)}px`;
        this.tooltipBox.style.top = `${Math.round(tooltipY)}px`;
    }

    public tooltipSynchronize(dateTime, notifySg = true) {
        let closest: CoordinateXY = null;
        let nearestPointClient: { clientX: number, clientY: number } = null;

        // DEPTH or VELOCITY is always on first chart, and first serie, so c=0 s=0
        const c = 0;
        const s = 0;

        if(!this.lcChart?.seriesDef || !this.lcChart.seriesDef[c] || !this.lcChart.seriesDef[c][s]) return;

        const serie = this.lcChart.seriesDef[c][s];

        // Binary search here. It's called every frame, we need it to be super fast
        const pointIndex = _.sortedIndexBy(serie.data, {
            x: dateTime,
            y: null
        }, 'x');

        closest = serie.data[pointIndex];

        if(closest) {
            const currentChart = this.lcChart.charts[c];
            nearestPointClient = currentChart.translateCoordinate(closest, currentChart.coordsAxis, currentChart.coordsClient);
        }

        if(nearestPointClient === null) {
            return;
        }

        const nearestDataPoints = [];
        for(let c = 0; c < this.lcChart.chartSeries.length; c++) {

            nearestDataPoints[c] = this.lcChart.chartSeries[c].map((el, i) => {
                if(el instanceof SegmentSeries) {
                    const currentChart = this.lcChart.charts[c];
                    // #37982 SegmentSeries are found by middle Y point (they are rectangles). We have upper Y value here, have to translate it to middle (so m=t/2)
                    return el.solveNearestFromScreen(currentChart.translateCoordinate({x: closest.x, y: closest.y / 2}, currentChart.coordsAxis, currentChart.coordsClient));
                } else {
                    return el.solveNearestFromScreen(nearestPointClient)
                }
            });
        }

        this.tooltipShow(null, nearestPointClient, nearestDataPoints, false, notifySg);
    }

    public tooltipHide() {
        this.tooltipBoxShow(false);
        this.infoBoxShow(false);

        for(let c = 0; c < this.lcChart.charts.length; c++) {
            if(!this.lcChart.chartSeries[c]) continue;
            for (let i = 0; i < this.lcChart.chartSeries[c].length; i++) {
                this.arrowShow(c, i, false);
            }
        }
    }

    public tooltipBoxShow(isVisible: boolean) {
        this.tooltipBox.style.display = isVisible ? HTML_DISPLAY_BLOCK : HTML_DISPLAY_NONE;
    }
    public infoBoxShow(isVisible: boolean) {
        this.info.style.display = isVisible ? HTML_DISPLAY_BLOCK : HTML_DISPLAY_NONE;
    }


    public arrowShow(chartIndex: number, serieIndex: number, isVisible: boolean) {
        if(!this.arrows[chartIndex] || !this.arrows[chartIndex][serieIndex]) return;
        this.arrows[chartIndex][serieIndex].style.display = isVisible ? HTML_DISPLAY_BLOCK : HTML_DISPLAY_NONE;
    }

    public createArrow(chartIndex: number, serieIndex: number) {
        if(this.arrows[chartIndex] && this.arrows[chartIndex][serieIndex]) return;

        const arrow = document.createElement('div');

        LightningChartBuilder.applyStyles(arrow, HG_TOOLTIP_ARROW_STYLE);

        this.lcChart.dashboard.engine.container.append(arrow)

        if(!this.arrows[chartIndex]) this.arrows[chartIndex] = [];

        this.arrows[chartIndex][serieIndex] = arrow;
    }

    public dispose() {
        for(let c = 0; c<this.lcChart.charts.length; c++) {
            if(this.arrows[c]) {
                for(const arrow of this.arrows[c]) {
                    if(arrow) {
                        arrow.remove();
                        this.arrows[c] = undefined;
                    }
                }
            }
        }
        if(this.tooltipBox) this.tooltipBox.remove();
        if(this.info) this.info.remove();
    }

}
