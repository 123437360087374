<div class="hydrograph-editing-menu edit-graph-filter-section">
    <!-- Select entity dropdown -->
    <mat-form-field class="de-hg-entity-hint">
        <mat-select
            [(ngModel)]="currentParams.entity"
            (ngModelChange)="onEntitySelected($event)"
            placeholder=" {{ 'CALCULATED_ENTITY_DIALOG.SELECT_ENTITY' | translate }}"
        >
            <mat-option *ngFor="let entity of entityList" [value]="entity">
                {{ entity.entityName }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Edit option buttons -->
    <div class="edit-buttons" [ngClass]="{ 'edit-btns-on-hint': selectedHintPageName === 'location-dashBoard-edit-hint'}">
        <div *ngFor="let button of buttonList">
            <button
                *ngIf="button.enabled || selectedHintPageName === 'location-dashBoard-edit-hint'"
                mat-button
                type="button"
                [disabled]="button.inactive !== undefined ? !button.inactive() : false"
                (click)="onButtonClick(button)"
                matTooltip="{{ button.tooltip | translate }}"
                [ngClass]="{
                    active: button.action === currentParams.action,
                    drag: button.action === ACTION_TYPES.DragDrop,
                    inactive: button.inactive !== undefined ? !button.inactive() : false
                }"
                [class]="button.class ? button.class : ''"
            >
                <mat-icon
                    *ngIf="button.icon"
                    class="mat-18"
                    [ngClass]="{ drag: button.action === ACTION_TYPES.DragDrop }"
                >
                    {{ button.icon }}</mat-icon
                >
                <img style="height: 18px" *ngIf="button.iconUrl" [src]="button.iconUrl" />
            </button>
        </div>
    </div>

    <div class="undo-redo-container">
        <span matTooltip="{{ 'LOCATION_DASHBOARD.DATA_EDITING.UNDO_TOOLTIP' | translate }}">
            <button mat-button type="button"
                class="undo-redo-button" [disabled]="disableUndo || (dataEditService.editRequestLoading | async)"
                (click)="dataEditService.undoChanges(customerId)" [ngClass]="{ 'active': !disableUndo }">
                <mat-icon>undo</mat-icon>
            </button>
        </span>

        <span matTooltip="{{ 'LOCATION_DASHBOARD.DATA_EDITING.REDO_TOOLTIP' | translate }}">
            <button mat-button type="button"
                class="undo-redo-button" [disabled]="disableRedo || (dataEditService.editRequestLoading | async)"
                (click)="dataEditService.redoChanges(customerId)" [ngClass]="{ 'active': !disableRedo }">
                <mat-icon>redo</mat-icon>
            </button>
        </span>
    </div>
</div>

<!-- Additional options for copy paste and edit multiple points action -->
<div
    *ngIf="currentParams.action === ACTION_TYPES.CopyPaste || currentParams.action === ACTION_TYPES.MultiPointEdit"
    class="hydrograph-editing-more-data"
>
    <div *ngIf="!arePointsSelected" class="waiting-on-data">
        <!-- No background line about choosing date range / data -->
        {{ currentParams.action === ACTION_TYPES.CopyPaste ? COPY_PASTE.CHOOSE_DATA : EDIT_MULTIPLE.CHOOSE_TIMESPAN }}
    </div>

    <div *ngIf="arePointsSelected" class="input-more">
        <div *ngIf="selectedDateRange && currentParams.action === ACTION_TYPES.CopyPaste" class="single-row">
            <!-- Date time range line with gray background -->
            <div class="custom-error">
                <div class="timerow single-row">
                    <div class="txt">{{ COPY_PASTE.SOURCE_TEXT }}</div>
                    <div class="custom-error">
                        <mat-form-field>
                            <input
                                matInput
                                placeholder=""
                                [value]="displaySelectedDateRangeDates[0] | date: dateFormat"
                                [max]="maxEndDate"
                                (change)="onManualSourceDateSelected(0, $event)"
                                appDatetimepickerPosition
                                [matDatetimepickerComp]="fromStartDateCopyPaste"
                            />
                            <input
                                hidden
                                matInput
                                placeholder=""
                                [(ngModel)]="displaySelectedDateRangeDates[0]"
                                [max]="maxEndDate"
                                [matDatetimepicker]="fromStartDateCopyPaste"
                                (dateChange)="onSourceDateSelected(0, $event)"
                            />
                            <mat-datetimepicker-toggle
                                matSuffix
                                [for]="fromStartDateCopyPaste"
                            ></mat-datetimepicker-toggle>
                            <mat-datetimepicker
                                #fromStartDateCopyPaste
                                timeInterval="1"
                                type="datetime"
                            ></mat-datetimepicker>
                        </mat-form-field>
                        <div class="custom-error-message">
                            <div *ngIf="dateInvalid[0]">{{ 'HOME.WIDGET_SCHEDULE.INVALID_TIME' | translate }}</div>
                        </div>
                    </div>
                    <div class="txt">-</div>
                    <div class="custom-error">
                        <mat-form-field>
                            <input
                                matInput
                                placeholder=""
                                [value]="displaySelectedDateRangeDates[1] | date: dateFormat"
                                [max]="maxEndDate"
                                (change)="onManualSourceDateSelected(1, $event)"
                                appDatetimepickerPosition
                                [matDatetimepickerComp]="fromEndDateCopyPaste"
                            />
                            <input
                                hidden
                                placeholder=""
                                [(ngModel)]="displaySelectedDateRangeDates[1]"
                                [max]="maxEndDate"
                                [matDatetimepicker]="fromEndDateCopyPaste"
                                (dateChange)="onSourceDateSelected(1, $event)"
                            />
                            <mat-datetimepicker-toggle
                                matSuffix
                                [for]="fromEndDateCopyPaste"
                            ></mat-datetimepicker-toggle>
                            <mat-datetimepicker
                                #fromEndDateCopyPaste
                                timeInterval="1"
                                type="datetime"
                            ></mat-datetimepicker>
                        </mat-form-field>
                        <div class="custom-error-message" style="margin-left: 15px">
                            <div *ngIf="dateInvalid[1]">{{ 'HOME.WIDGET_SCHEDULE.INVALID_TIME' | translate }}</div>
                        </div>
                    </div>
                </div>
                <div class="custom-error-message">
                    <div *ngIf="dateRangeInvalid">Date range is invalid</div>
                </div>
            </div>
        </div>
        <div *ngIf="currentParams.action === ACTION_TYPES.MultiPointEdit && selectedValueRange" class="single-row">
            {{ EDIT_MULTIPLE.SELECTED_RANGE_TEXT }}
            <span>
                {{ selectedValueRange[0] }}
                &nbsp;-&nbsp;
                {{ selectedValueRange[1] }}
            </span>
        </div>
        <div [hidden]="currentParams.action !== ACTION_TYPES.CopyPaste" [ngClass]="{'single-row': currentParams.action === ACTION_TYPES.CopyPaste} ">
            <!-- Gray background box with copy/paste specific information-->
            <mat-form-field>
                <mat-select
                    name="copyPasteDestinationEntity"
                    [ngModel]="copyPasteDestination.entity.name"
                    (ngModelChange)="onPasteEntitySelect($event)"
                    placeholder="{{ COPY_PASTE.DESTINATION_ENTITY }}"
                    [disabled]="pasteEntitiesList.length < 2"
                >
                    <mat-option *ngFor="let entity of pasteEntitiesList" [value]="entity.name">
                        {{ entity.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="ml custom-error">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="{{ COPY_PASTE.DESTINATION_DATE_TIME_START }}"
                        [ngModel]="destinationDateDisplay"
                        [max]="maxEndDate"
                        (ngModelChange)="onManualDestinationDateModelChange($event)"
                        (focusout)="onManualDestinationDateSelected($event)"
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="startDateCopyPaste"
                    />
                    <input
                        matInput
                        hidden
                        [(ngModel)]="destinationDateDisplay"
                        [max]="maxEndDate"
                        [matDatetimepicker]="startDateCopyPaste"
                        (dateChange)="onDestinationDateSelected($event)"
                    />
                    <mat-datetimepicker-toggle matSuffix [for]="startDateCopyPaste"></mat-datetimepicker-toggle>
                    <mat-datetimepicker #startDateCopyPaste timeInterval="1" type="datetime"></mat-datetimepicker>
                </mat-form-field>
                <div class="custom-error-message">
                    <div *ngIf="destinationDateInvalid">{{ 'HOME.WIDGET_SCHEDULE.INVALID_TIME' | translate }}</div>
                </div>
            </div>
            <div class="ml">
                <mat-form-field>
                    <input
                        matInput
                        placeholder="{{ COPY_PASTE.DESTINATION_DATE_TIME_END }}"
                        [disabled]="true"
                        [value]="getDestinationDateTimeEnd()"
                    />
                </mat-form-field>
            </div>
            <div class="ml">
                <mat-form-field>
                    <mat-select
                        name="copyPasteDestinationAction"
                        [(ngModel)]="copyPasteDestination.action"
                        (ngModelChange)="onCopyPasteActionSelected($event)"
                        placeholder="{{ COMMON.ACTION_TEXT }}"
                    >
                        <mat-option *ngFor="let action of copyPasteManipulationList" [value]="action">
                            {{ action ? COMMON[action] : '' }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="ml">
                <mat-form-field>
                    <input
                        matInput
                        #copyPasteActionValueInput="ngModel"
                        name="copyPasteDestinationValue"
                        type="number"
                        [disabled]="!copyPasteDestination.action"
                        [pattern]="currentParams.entity.precision === 1 ? numericDecimal1PlacePattern : currentParams.entity.precision === 2 ? numericDecimal2PlacePattern : numeric3PlaceDecimalRegEx"
                        placeholder="{{ COMMON.VALUE_TEXT }}"
                        [(ngModel)]="copyPasteDestination.value"
                        required
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="copyPasteActionValueInput?.errors?.pattern">
                    <span *ngIf="currentParams.entity.precision === 1">{{ COMMON.NUMERIC_PRECISION_ERROR_METRIC }}</span>
                    <span *ngIf="currentParams.entity.precision === 2">{{ COMMON.NUMERIC_PRECISION_ERROR }}</span>
                    <span *ngIf="currentParams.entity.precision === 3">{{ COMMON.THREE_DECIMAL_PLACES_PRECISION_ERROR }}</span>
                </div>
            </div>
        </div>
        <div [hidden]="currentParams.action !== ACTION_TYPES.MultiPointEdit" [ngClass]="{'single-row': currentParams.action === ACTION_TYPES.MultiPointEdit} ">
            <!-- Gray background box with multi point edit specific information-->
            <mat-form-field>
                <mat-select
                    name="multiplePointsRulesAction"
                    [(ngModel)]="multiplePointsRules.action"
                    (ngModelChange)="onActionSelected($event)"
                    placeholder="{{ COMMON.ACTION_TEXT }}"
                >
                    <mat-option *ngFor="let action of manipulationList" [value]="action">
                        {{ COMMON[action] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <mat-form-field>
                    <input
                        matInput
                        name="multiplePointsRulesValue"
                        #actionValueInput="ngModel"
                        type="number"
                        [disabled]="!multiplePointsRules.action"
                        [pattern]="currentParams.entity.precision === 1 ? numericDecimal1PlacePattern : currentParams.entity.precision === 2 ? numericDecimal2PlacePattern : numeric3PlaceDecimalRegEx"
                        placeholder="{{ COMMON.VALUE_TEXT }}"
                        [(ngModel)]="multiplePointsRules.value"
                        required
                    />
                </mat-form-field>
                <div class="mat-error" *ngIf="actionValueInput?.errors?.pattern">
                    <span *ngIf="currentParams.entity.precision === 1">{{ COMMON.NUMERIC_PRECISION_ERROR_METRIC }}</span>
                    <span *ngIf="currentParams.entity.precision === 2">{{ COMMON.NUMERIC_PRECISION_ERROR }}</span>
                    <span *ngIf="currentParams.entity.precision === 3">{{ COMMON.THREE_DECIMAL_PLACES_PRECISION_ERROR }}</span>
                </div>
            </div>
        </div>
        <div class="button-row">
            <!-- Bottom row of buttons with gray background -->
            <button
                mat-button
                (click)="cancelEdits()"
            >
                {{ COMMON.CANCEL_BUTTON }}
            </button>
            <button
                mat-button
                [disabled]="
                    (currentParams.action === ACTION_TYPES.MultiPointEdit &&
                        (actionValueInput?.errors?.pattern || !multiplePointsRules.action || multiplePointsRules.value === null || multiplePointsRules.value === undefined)) ||
                    (currentParams.action === ACTION_TYPES.CopyPaste &&
                        (copyPasteActionValueInput?.errors?.pattern || !destinationDateDisplay || destinationDateInvalid || (copyPasteDestination.action && !copyPasteDestination.value)))
                "
                (click)="applyEdits()"
            >
                {{ COMMON.APPLY_BTN }}
            </button>
        </div>
    </div>
</div>

<!-- Additional options for interpolate points action -->
<div *ngIf="currentParams.action === ACTION_TYPES.Interpolate" class="hydrograph-editing-more-data">
    <div *ngIf="!arePointsSelected" class="input-more">
        <p>Please select the point(s) or area of data you want to interpolate.</p>
    </div>

    <div *ngIf="arePointsSelected" class="input-more">
        <p>Interpolate</p>
        <p class="interpolate-gaps-info" *ngIf="isInterpolateSelectedPoints === false">{{ 'HYDROGRAPH_NEW.INTERPOLATE.DATA_GAPS_MESSAGE' | translate }}</p>

        <mat-radio-group color="primary" [(ngModel)]="isInterpolateSelectedPoints">
            <mat-radio-button color="primary" [value]="true">Selected Points</mat-radio-button>
            <mat-radio-button color="primary" [value]="false">Data Gaps</mat-radio-button>
        </mat-radio-group>

        <div class="button-row">
            <!-- Bottom row of buttons with gray background -->
            <button mat-button (click)="onCancelInterpolation()">{{ COMMON.CANCEL_BUTTON }}</button>
            <button mat-button [disabled]="((selectedMoreThenHour && !isInterpolateSelectedPoints) || interpolateHasBeenApplied)" (click)="interpolateAccept.emit(isInterpolateSelectedPoints)">
                {{ COMMON.APPLY_BTN }}
            </button>
        </div>
    </div>
</div>

<!-- Additional options for flag/unflag points action -->
<div
    *ngIf="currentParams.action === ACTION_TYPES.Flag || currentParams.action === ACTION_TYPES.Unflag"
    class="hydrograph-editing-more-data"
>
    <div *ngIf="!arePointsSelected" class="waiting-on-data">
        <!-- No background line about choosing date range / data -->
        {{ EDIT_MULTIPLE.CHOOSE_TIMESPAN_FLAG }}
    </div>
    <div class="input-more">
        <div *ngIf="arePointsSelected" class="button-row">
            <!-- Bottom row of buttons with gray background -->
            <button mat-button (click)="applyEdits()">{{ COMMON.APPLY_BTN }}</button>
        </div>
    </div>
</div>
