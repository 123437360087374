import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationTreeComponent } from 'app/shared/components/notification-tree/notification-tree.component';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { NotificationDashboardService } from 'app/shared/services/notification.service';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import { Subscription } from 'rxjs';
import uniqBy from 'lodash/uniqBy';
import flatMap from 'lodash/flatMap';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { LocationWetDryOverflowComponent } from 'app/shared/components/location-wet-dry-overflow/location-wet-dry-overflow.component';
import { MapService } from 'app/shared/services/map.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationService } from 'app/shared/services/location.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import {
    NotificationAlarmOptions,
    NotificationDetailsLite,
    NotificationLevel,
    NotificationLocationOptions,
    NotificationResponseValidation,
    NotificationRouteAlarmType,
    NotificationUser,
    NotificationValidation,
    NotificationValidationError,
} from 'app/shared/models/notifications';
import {
    AppQueryParams,
    CustomerFeatures,
    customerQueryParam,
    genericIdQueryParam,
    GetLocationsRainGaugeResponse,
} from 'app/shared/models/customer';
import { DefinedInstallationType, SlimLocationDetails } from 'app/shared/models/location-details';
import { GetPermissionsResponseFeature } from 'app/shared/models/users-permission';
import { AlarmTypes } from 'app/shared/models/alarms';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { GISService } from 'app/shared/services/gis-service';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { LocationStatus } from 'app/shared/models/locations';
interface UIAlarmNotificationValidation extends NotificationValidation {
    fieldWarnings: { field: string; message: string }[];
}

@Component({
    selector: 'standard-alarm',
    templateUrl: './standard-alarm.component.html',
    styleUrls: ['./../alarms.component.scss', './../notification-dashboard.component.scss'],
})
export class StandardAlarmComponent implements OnInit, OnDestroy {
    @ViewChild('nameField') public nameField: ElementRef;
    @ViewChild('enabledToggle') public enabledToggle: MatSlideToggle;
    @ViewChildren('enabledLevelToggle') public enabledLevelToggle: QueryList<MatSlideToggle>;

    // FOR ALL TABS
    private subscriptions = [new Subscription()];
    public PAGE_TEXT: any;
    public notificationObject: NotificationDetailsLite = {
        name: null,
        desc: null,
        enabled: true,
        levels: [],
        lids: [],
        aids: [],
    };
    public backupNotificationObject: NotificationDetailsLite;
    public selectedIndex = 4; // Start on confirmation page for subscribe only users
    private customerId: number;
    public notificationId: string;
    public loadingItems = { page: true, locations: true, validations: true, users: true };
    public subscribeOnly = true; // Can't edit unless admin or customer admin
    private userID: string;

    // FOR LOCATIONS TAB
    public locationsOptions: NotificationLocationOptions[] = [];
    public dispLocationsOptions: NotificationLocationOptions[] = [];
    public allLocationsChecked = false;
    public locationGroupOptions: string[];
    public selectedLocationGroup: string;
    public locationSearchString: string;

    // FOR ALARM TAB
    public alarmOptions: NotificationAlarmOptions[];
    /** Null means was not loaded yet */
    public isWetDryAvailable: boolean | null = null;

    // For notification tree tab
    public notificationTreeColumns: string[] = ['name', 'delay', 'userCount', 'enable', 'options'];
    public userOptions: NotificationUser[];

    // FOR VALIDATION TAB
    public notificationValidated = false;
    public showErrors = true;
    public showWarnings = true;
    public validationInfo: UIAlarmNotificationValidation = {
        warnings: [],
        fieldWarnings: [],
        errors: [],
        fieldErrors: [],
    };
    public AlarmTypes = AlarmTypes;
    public NotificationValidationError = NotificationValidationError;

    // FOR CONFIRMATION TAB
    public confirmationShowUsers: boolean[] = [];
    public subscribeUser: { alarm: boolean; rtn: boolean }[] = [];

    constructor(
        private translate: TranslateService,
        private locationGroupService: LocationGroupService,
        private locationService: LocationService,
        private customerService: CustomerService,
        private notificationService: NotificationDashboardService,
        private usersService: UsersService,
        private mapService: MapService,
        private matDialog: MatDialog,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private gisService: GISService,
        public domOperationUtilsService: DomOperationUtilsService,
        private locationCardService: LocationCardService
    ) {
        this.translate.get('NOTIFICATION_DASHBOARD').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
        this.userID = this.usersService.userID.getValue().toUpperCase();
        this.subscribeOnly = !this.usersService.userRoles
            .getValue()
            .some((x) => x === USER_ROLES.ADMIN || x === USER_ROLES.CUSTOMER_ADMIN || x === USER_ROLES.CUSTOMER_USER_MANAGER);

        // Set current customer
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                const customerId = Number(params.get(customerQueryParam));
                const notificationId = params.get(genericIdQueryParam);

                if (this.notificationId !== notificationId || this.customerId !== customerId) {
                    // Only need to update if customer or id has changed
                    this.customerId = customerId;
                    this.notificationId = notificationId;

                    this.getNotificationForEditing();
                }
            }),
        );
    }
    public ngOnInit() {
        // On page load
        if (this.subscribeOnly) {
            this.selectedIndex = 4;
        } else if (this.notificationId) {
            // Admin and edit notification
            this.selectedIndex = 0;
        } else {
            // Admin and new notification
            this.selectedIndex = 0;
        }

        this.customerService.currentCustomerFeatures.subscribe((res: Array<GetPermissionsResponseFeature>) => {
            this.isWetDryAvailable = res.some((x) => x.id === CustomerFeatures.WetDryOverflow);
        });

        setTimeout(() => this.nameField.nativeElement.focus(), 50);
    }

    // Page load functions
    private getNotificationForEditing() {
        this.loadingItems.page = true;
        const notificationSubscription = this.notificationService
            .getSingleNotificationDetails(this.customerId, this.notificationId)
            .subscribe(
                (res: NotificationDetailsLite) => {
                    if (res) {
                        this.notificationObject = { ...res };
                        res.levels.forEach((lvl, ind) => {
                            const userInLevel = lvl.users.find((x) => x.id === this.userID);
                            if (userInLevel) {
                                this.subscribeUser[ind] = { alarm: userInLevel.alarm, rtn: userInLevel.rtn };
                            } else {
                                this.subscribeUser[ind] = { alarm: false, rtn: false };
                            }

                            lvl.users = lvl.users.filter(x => x.rtn || x.admin || x.alarm);
                        });

                    } else {
                        // Notification not found aka create new one
                        this.notificationId = undefined;
                        this.notificationObject = {
                            name: '',
                            desc: null,
                            enabled: true,
                            levels: [],
                            lids: [],
                            aids: [],
                        };
                    }

                    // Once we have a notification (or blank template if no notification)
                    // we can populate all of the options that the page will need for each tab
                    this.backupNotificationObject = { ...this.notificationObject };
                    this.loadingItems.page = false;
                    this.populatePageOptions();
                },
                (error) => {
                    this.loadingItems.page = false;
                },
            );
        this.subscriptions.push(notificationSubscription);
    }
    private populatePageOptions() {
        this.loadLocations();
        this.loadAlarmTypes();
        this.loadUsersForTree();
    }

    private locationsFilter(
        locations: SlimLocationDetails[],
        locationGroups: any,
        selectedLocs: number[],
    ): NotificationLocationOptions[] {

        const out = [];
        const locationsInGroups = [];

        const locAssoc = {};
        for(const location of locations) {
            locAssoc[location.locationId] = location;
        }

        if (locationGroups !== undefined) {
            // Add locations that are in location groups.
            locationGroups.forEach(locGrp => {
                locGrp.locations.forEach(loc => {
                    // MP2 check. Slim locations do not return this. Change it here if it will be added in future
                    // #40894 if inactive it does not exist in assoc array
                    if (loc.installationType === DefinedInstallationType.Composite
                        || !locAssoc[loc.locationId] || locAssoc[loc.locationId].status === LocationStatus.Inactive) {

                        return;
                    }

                    locationsInGroups.push(loc.locationID);
                    out.push({
                        id: loc.locationID,
                        name: loc.name,
                        checked: selectedLocs.includes(loc.locationID),
                        group: locGrp.name,
                    });
                });
            });
        }

        // Add locations that are not in a location group.
        locations.forEach(loc => {
            if (loc.installationType === DefinedInstallationType.Composite
                || locationsInGroups.includes(loc.locationId)
                // #40894 if inactive it does not exist in assoc array
                || !locAssoc[loc.locationId] || locAssoc[loc.locationId].status === LocationStatus.Inactive
            ) {
                return;
            }

            out.push({
                id: loc.locationId,
                name: loc.locationName,
                checked: selectedLocs.includes(loc.locationId),
                group: undefined,
            });
        });
        return out.sort((l1, l2) => (l1.name && l2.name ? l1.name.localeCompare(l2.name) : l1.name ? -1 : 1));
    }

    public loadLocations() {
        // Clear out locations before getting new ones
        this.loadingItems.locations = true;
        this.locationGroupOptions = [];
        this.locationsOptions = [];
        this.dispLocationsOptions = [];

        // Need location groups in order to know which group each location belongs in
        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.customerId).subscribe(
            (locGrpRes) => {
                const locationGroups = locGrpRes.locationGroups;

                // Once you have location groups, get the actual locations
                const locationsSubscription = this.locationService.getSlimLocations(this.customerId, false).subscribe(
                    (locRes) => {
                        if (locRes) {
                            const selectedLocs = this.notificationObject.lids;
                            this.locationsOptions = this.locationsFilter(locRes, locationGroups, selectedLocs);

                            this.locationGroupOptions = uniqBy(this.locationsOptions, 'group')
                                .map((x) => x.group)
                                .filter(Boolean)
                                .sort();
                            this.locationGroupOptions.unshift('All');
                            this.selectedLocationGroup = this.locationGroupOptions[0];
                            this.locationSetSearchAndGroup(); // Populates screen and handles filtering / check all functionality
                        }
                        this.loadingItems.locations = false;
                    },
                    (error) => {
                        this.loadingItems.locations = false;
                    },
                );
                this.subscriptions.push(locationsSubscription);
            },
            (error) => {
                this.loadingItems.locations = false;
            },
        );
        this.subscriptions.push(locationGroupSubscription);
    }
    public loadAlarmTypes() {
        this.alarmOptions = [];
        const selectedOpts = this.notificationObject.aids;
        const opts = this.notificationService.getAllAlarmTypes();
        this.alarmOptions = opts
            .filter(
                (x) =>
                    x.type === NotificationRouteAlarmType.Standard &&
                    (!x.featureCondition ||
                        (this.isWetDryAvailable && x.featureCondition === CustomerFeatures.WetDryOverflow)),
            )
            .map((x) => {
                return {
                    id: x.id,
                    name: x.name,
                    checked: selectedOpts.includes(x.id),
                };
            });
    }
    public loadUsersForTree() {
        this.loadingItems.users = true;
        this.userOptions = [];
        if (!this.subscribeOnly) {
            const usersSubscription = this.usersService
                .getCustomerUsersV2(this.customerId, false, null, null, null, false)
                .subscribe({
                    next: (res) => {
                        if (res && res.payload) {
                            this.userOptions = res.payload
                                .sort((u1, u2) => {
                                    const lnc = u1.lastName.localeCompare(u2.lastName);
                                    if (lnc !== 0) return lnc;

                                    const fnc = u1.firstName.localeCompare(u2.firstName);
                                    if (fnc !== 0) return lnc;

                                    return u1.userID > u2.userID ? 1 : u1.userID < u2.userID ? -1 : 0;
                                })
                                .map((usr) => {
                                    return {
                                        id: usr.userID.toUpperCase(),
                                        dispName:
                                            usr.firstName || usr.lastName
                                                ? usr.firstName.concat(' ', usr.lastName)
                                                : usr.userName,
                                        admin: usr.role === USER_ROLES.ADMIN,
                                        alarm: false,
                                        rtn: false,
                                    };
                                });
                        }
                        this.loadingItems.users = false;
                    },
                    error: (error) => this.loadingItems.users = false,
                });
            this.subscriptions.push(usersSubscription);
        } else {
            this.loadingItems.users = false;
        }
    }
    public toggleNotificationEnabled(event: MatSlideToggleChange) {
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_NOTIFICATION_CONFIRM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.notificationObject.enabled = false;
                    } else {
                        this.notificationObject.enabled = true;
                        this.enabledToggle.checked = true; // To visually force it back to the previous state
                    }
                });
        } else {
            this.notificationObject.enabled = true;
        }
    }
    public backToAllNotifications() {
        // Make sure you capture any changes in active tab
        if (this.selectedIndex === 0) {
            this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        } else if (this.selectedIndex === 1) {
            this.notificationObject.aids = this.alarmOptions.filter((x) => x.checked).map((x) => x.id);
        }

        if (isEqual(this.backupNotificationObject, this.notificationObject)) {
            // No changes have been made, just go back
            this.actuallyGoBack();
        } else {
            // Confirm that you want to discard changes
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.BACK_HEADER,
                        message: this.PAGE_TEXT.BACK_TEXT,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.actuallyGoBack();
                    }
                });
        }
    }
    private actuallyGoBack() {
        const route = '/pages/menuDashboard/notifications';
        const appQueryParams: AppQueryParams = { c: this.customerId };

        this.router.navigate([route], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    // ALL TAB FUNCTIONS
    public tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.selectedIndex = tabChangeEvent.index;
        this.domOperationUtilsService.selectedStandarAlarmTab.next(this.selectedIndex);
        this.handleTabChange();
    }
    public handleTabChange() {
        // Update notification object or validate as necessary
        this.notificationValidated = false;
        this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        this.notificationObject.aids = this.alarmOptions.filter((x) => x.checked).map((x) => x.id);

        if (this.selectedIndex === 3) {
            this.validateNotification();
        }
    }
    public allowNext(currentTabIndex: number) {
        let condition = true;
        switch (currentTabIndex) {
            // Validation tab
            case 3:
            default:
                condition =
                    condition &&
                    this.notificationValidated &&
                    this.validationInfo.errors.length === 0 &&
                    this.validationInfo.fieldErrors.length === 0;
            // Levels Tab
            case 2:
                condition = condition && this.notificationObject.levels.length > 0;
            // Alarm tab
            case 1:
                condition = condition && this.alarmOptions.some((x) => x.checked);
            // Location tab
            case 0:
                condition =
                    condition &&
                    this.locationsOptions.some((x) => x.checked) &&
                    this.notificationObject.name !== null &&
                    this.notificationObject.name !== '';
        }
        return condition;
    }
    public disableTab(tabIndex: number) {
        switch (tabIndex) {
            case 0: {
                // Location tab
                // Disable if subscribe only
                return this.subscribeOnly;
            }
            case 1: // Alarm tab
            case 2: // Levels tab
            case 3: {
                // Validation tab
                // Disable if subscribe only OR new add and in earlier tab
                return this.subscribeOnly || (!this.notificationId && this.selectedIndex < tabIndex);
            }
            case 4:
            default: {
                return this.selectedIndex !== 4;
            }
        }
    }
    public uiChangeTab(changeTab: boolean, direction?: boolean) {
        if (changeTab) {
            direction ? (this.selectedIndex += 1) : (this.selectedIndex -= 1);
        }
    }

    public checkName() {
        if (this.notificationObject.name !== null) {
            this.notificationObject.name = this.notificationObject.name.trim();
        }
        if (this.selectedIndex === 3) {
            this.validateNotification();
        }
    }
    // END ALL TAB FUNCTIONS

    // LOCATIONS TAB FUNCTIONS
    public checkAllLocations() {
        // Update displayed locations only
        this.allLocationsChecked = !this.allLocationsChecked;
        this.dispLocationsOptions.forEach((loc) => (loc.checked = this.allLocationsChecked));
        // Have to "touch" locationOptions so pipe at summary will refresh
        this.locationsOptions = this.locationsOptions.map((loc) => loc);
    }
    public updateLocation(location: NotificationLocationOptions) {
        this.locationsOptions = this.locationsOptions.map((loc) => {
            if (loc.id === location.id) {
                loc.checked = !loc.checked;
            }
            return loc;
        });
        this.locationSetSearchAndGroup(); // Update displayed location and whether 'check all' should be checked
    }
    public locationSetSearchAndGroup() {
        // Set displayed locations by group first
        if (this.selectedLocationGroup === this.locationGroupOptions[0]) {
            // 'All' option
            this.dispLocationsOptions = uniqBy(this.locationsOptions, 'id');;
        } else {
            this.dispLocationsOptions = this.locationsOptions.filter((loc) => loc.group === this.selectedLocationGroup);
        }

        // Next filter by search string
        if (this.locationSearchString && this.locationSearchString !== '') {
            this.dispLocationsOptions = this.dispLocationsOptions.filter((loc) =>
                loc.name && loc.name.toLowerCase().includes(this.locationSearchString.toLowerCase()),
            );
        }

        // Finally update whether all displayed locations should be checked
        this.allLocationsChecked =
            this.dispLocationsOptions.length > 0 && this.dispLocationsOptions.every((x) => x.checked);
    }
    // END LOCATIONS TAB FUNCTIONS

    // ALARM TAB FUNCTIONS
    public updateAlarm(alarm: NotificationAlarmOptions) {
        this.alarmOptions = this.alarmOptions.map((alm) => {
            if (alm.name === alarm.name) {
                alm.checked = !alm.checked;
            }
            return alm;
        });
    }
    // END ALARM TAB FUNCTIONS

    // NOTIFICATION TREE TAB FUNCTIONS
    public addNotificationLevel() {
        const names = this.notificationObject.levels.map((lvl) => lvl.name);
        this.openNotificationDialog(names);
    }
    public editNotificationLevel(element: NotificationLevel) {
        const names = this.notificationObject.levels.map((lvl) => lvl.name).filter((name) => name !== element.name);
        this.openNotificationDialog(names, element);
    }
    public deleteNotificationLevel(element: NotificationLevel) {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.BACK_HEADER,
                    message:
                        this.PAGE_TEXT.DELETE_FIRST +
                        element.name +
                        this.PAGE_TEXT.DELETE_LEVEL +
                        this.PAGE_TEXT.DELETE_SECOND,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.notificationObject = {
                        ...this.notificationObject,
                        levels: this.notificationObject.levels.filter((x) => x.name !== element.name),
                    };
                }
            });
    }
    public toggleNotificationLevelEnabled(event: MatSlideToggleChange, element: NotificationLevel) {
        const affectedInd = this.notificationObject.levels.findIndex((x) => x.name === element.name);
        const affectedElement = this.notificationObject.levels[affectedInd];
        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_LEVEL_CONRIM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        affectedElement.enabled = false;
                    } else {
                        affectedElement.enabled = true;
                        const affectedToggle = this.enabledLevelToggle.filter((e, i) => i === affectedInd)[0];
                        affectedToggle.checked = true; // To visually force it back to the previous state
                    }
                });
        } else {
            affectedElement.enabled = true;
        }
    }
    public openNotificationDialog(names: string[], element?: NotificationLevel) {
        this.matDialog
            .open(NotificationTreeComponent, {
                disableClose: true,
                data: {
                    users: this.userOptions,
                    selectedUsers: element ? element.users : [],
                    name: element ? element.name : undefined,
                    names: names,
                    delay: element ? element.delay : 0,
                    enabled: element ? element.enabled : true,
                    showRtn: true,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.save && result.data) {
                    const resLevel: NotificationLevel = {
                        name: result.data.name,
                        delay: result.data.delay,
                        enabled: result.data.enabled,
                        users: result.data.selectedUsers,
                    };

                    if (element) {
                        // Update existing level
                        this.notificationObject = {
                            ...this.notificationObject,
                            levels: this.notificationObject.levels.map((lvl) => {
                                return lvl.name === element.name ? resLevel : lvl;
                            }),
                        };
                    } else {
                        // Create a new level
                        this.notificationObject = {
                            ...this.notificationObject,
                            levels: this.notificationObject.levels.concat(resLevel),
                        };
                    }

                    this.notificationObject.levels = sortBy(this.notificationObject.levels, ['delay']);
                }
            });
    }
    // END NOTIFICATION TREE TAB FUNCTIONS

    // VALIDATION TAB FUNCTIONS
    public validateNotification() {
        this.notificationValidated = false;
        this.loadingItems.validations = true;

        // Check that all of the required items are present don't validate if not
        if (!this.checkAllRequiredInputs()) {
            return;
        }

        // Once everything that is required is present, validate the alarm
        const validationSubscription = this.notificationService
            .validateNotification(this.customerId, this.notificationObject)
            .subscribe(
                (res: NotificationResponseValidation) => {
                    this.validationInfo = { warnings: [], fieldWarnings: [], errors: [], fieldErrors: [] };

                    const noUserWillBeNotfied = this.notificationObject.levels.every(
                        (x) => !x.enabled || x.users.length === 0,
                    );
                    if (noUserWillBeNotfied) {
                        this.validationInfo.fieldWarnings.push({
                            field: 'notifications',
                            message:
                                'No users was selected to receive notifications. Levels are disabled or do not contain users.',
                        });
                    }

                    if (res) {
                        if (res.fieldErrors) {
                            for (const error of res.fieldErrors) {
                                this.validationInfo.fieldErrors.push({
                                    field: error.fieldName,
                                    message: error.fieldMessage,
                                });
                            }
                        }

                        if (res.payload) {
                            const locKeys = Object.keys(res.payload);
                            const payload = res.payload;
                            locKeys.forEach((loc) => {
                                const warningLocItem = {
                                    location: payload[loc][0].locationName,
                                    lid: payload[loc][0].locationID,
                                    items: [],
                                };
                                const errorLocItem = {
                                    location: payload[loc][0].locationName,
                                    lid: payload[loc][0].locationID,
                                    items: [],
                                };

                                payload[loc].forEach((itm) => {
                                    switch (itm.error) {
                                        case NotificationValidationError.AlarmInvalid: {
                                            warningLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.PAGE_TEXT.ERROR_TEXT.ALARM_INVALID.concat(
                                                    this.notificationService.getSpecificAlarmType(itm.alarmType).name,
                                                ).concat(this.PAGE_TEXT.ERROR_TEXT.ALARM),
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.AlarmNotConfigured: {
                                            const text =
                                                itm.alarmType === AlarmTypes.WET_OVERFLOW_ALARM
                                                    ? this.notificationService.getWDONotConfigured()
                                                    : this.notificationService
                                                          .getSpecificAlarmType(itm.alarmType)
                                                          .name.concat(this.PAGE_TEXT.ERROR_TEXT.ALARM_NOT_CONFIGURED);

                                            errorLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: text,
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.DuplicateNotification: {
                                            errorLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.notificationService
                                                    .getSpecificAlarmType(itm.alarmType)
                                                    .name.concat(this.PAGE_TEXT.ERROR_TEXT.DUPLICATE_NOTIFICATION),
                                            });
                                            break;
                                        }
                                        case NotificationValidationError.ConfigurationInvalid: {
                                            warningLocItem.items.push({
                                                error: itm.error,
                                                alarmType: itm.alarmType,
                                                text: this.notificationService
                                                    .getSpecificAlarmType(itm.alarmType)
                                                    .name.concat(this.PAGE_TEXT.ERROR_TEXT.CONFIGURATION_INVALID),
                                            });
                                            break;
                                        }
                                    }
                                });

                                if (warningLocItem.items.length > 0) {
                                    this.validationInfo.warnings.push(warningLocItem);
                                }
                                if (errorLocItem.items.length > 0) {
                                    this.validationInfo.errors.push(errorLocItem);
                                }
                            });
                        }
                    }

                    // Any locations with errors need full location data for location cards
                    this.notificationValidated = true;
                    this.loadingItems.validations = false;
                },
                (error) => {
                    this.loadingItems.validations = false;
                },
            );
        this.subscriptions.push(validationSubscription);
    }
    public openLocationCard(lid: number) {
        const locCardSub = this.locationService.getLocationDetailsV2(this.customerId, lid).subscribe(
            (locRes) => {
                const locationDetails = {
                    series: locRes[0].series,
                    serialNumber: locRes[0].serialNumber,
                    isActive: locRes[0].isActive,
                    modem: locRes[0].modem,
                    description: locRes[0].description,
                    qFinalEntityId: locRes[0].qFinalEntityID,
                    manholeAddress: locRes[0].manholeAddress,
                    coordinate: { latitude: locRes[0].latitude, longitude: locRes[0].longitude, elevation: 1 },
                    locationID: locRes[0].locationID,
                    locationName: locRes[0].locationName,
                    assignedRainGaugeLocationId: locRes[0].assignedRainGaugeLocationId,
                    installationHeight: locRes[0].height,
                    installationWidth: locRes[0].width,
                    installationType: locRes[0].installationType,
                    installationId: locRes[0].installationID,
                    depthUnit: locRes[0].depthUnit,
                    flowUnit: locRes[0].flowUnit,
                    locationType: locRes[0].components ? 3 : 1,
                    components: locRes[0].components,
                    installationShape: locRes[0].installationShape,
                    ipaddress: locRes[0].connectionString,
                    range: locRes[0].range,
                    capacity: locRes[0].capacity,
                    width: locRes[0].width,
                    installationShapeTypeID: locRes[0].installationShapeTypeID,
                    manholedepth: locRes[0].manholeDepth,
                    length: locRes[0].length,
                    breadth: locRes[0].breadth,
                    coefficient: locRes[0].coefficient,
                    assignedRainGaugeLocationName: locRes[0].assignedRainGaugeLocationName,
                    entries: locRes[0].entries,
                    lastCollectedDate: '',
                };
                const dialogOptions: MatDialogConfig = {
                    disableClose: true,
                    data: {
                        rainGaugeLocations: [],
                        customerId: this.customerId,
                        editMode: true,
                        locationDetails,
                        isFromCustomerEditor: false,
                        isFromNotificationDash: true,
                    },
                };

                if (this.gisService.locationCardPosition) {
                    dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
                }

                if (this.mapService.addEditLocationDialog) {
                    this.mapService.addEditLocationDialog.close();
                }

                this.mapService.addEditLocationDialog = this.matDialog.open(
                    TritonLocationDialogComponent,
                    dialogOptions,
                );
                // this.subscriptions.push(this.mapService.addEditLocationDialog.afterClosed().subscribe(res => {
                //     if (res.success) {
                //         const comp = this.mapService.addEditLocationDialog.componentInstance;
                //         const name = comp.tritonLocationName;
                //         const alarms = {
                //             lowlevelenable: comp.lowlevelenable,
                //             fullpipeenable: comp.fullpipeenable,
                //             highlevelenable: comp.highlevelenable,
                //             highhighenable: comp.highhighenable,
                //             batteryLowenable: comp.batteryLowenable,
                //             overflowEnable: comp.overflowEnable,
                //             tiltEnable: comp.tiltEnable
                //         };
                //         let notActivated = false;
                //         const locationErrors = this.validationInfo.errors.find(x => x.location === name);
                //         if(locationErrors) {
                //             for(const error of locationErrors.items) {
                //                 switch(error.error) {
                //                     case AlarmTypes.LOW_BATTERY_ALARM: notActivated = notActivated || !alarms.batteryLowenable; break;
                //                     case AlarmTypes.FULL_PIPE_ALARM: notActivated = notActivated || !alarms.fullpipeenable; break;
                //                     case AlarmTypes.HIGH_LEVEL_ALARM: notActivated = notActivated || !alarms.highlevelenable; break;
                //                     case AlarmTypes.HIGH_HIGH_ALARM: notActivated = notActivated || !alarms.highhighenable; break;
                //                     case AlarmTypes.LOW_LEVEL_ALARM: notActivated = notActivated || !alarms.lowlevelenable; break;
                //                     case AlarmTypes.OVERFLOW_ALARM: notActivated = notActivated || !alarms.overflowEnable; break;
                //                     case AlarmTypes.TILT_ALARM: notActivated = notActivated || !alarms.tiltEnable; break;
                //                 }
                //             }
                //         }

                //         if(notActivated) {
                //             this.matDialog.open(ConfirmDialogComponent, {
                //                 disableClose: true,
                //                 data: {
                //                     title: this.PAGE_TEXT.WARNING,
                //                     message: this.PAGE_TEXT.WARNING_NOT_ACTIVE
                //                 }
                //             });
                //         }
                //         setTimeout(() => {

                //             this.validateNotification();
                //         }, 1000);
                //     }
                // }));

                // TODO: uncomment
                // this.subscriptions.push(this.mapService.addEditLocationDialog.afterClosed().subscribe(res => {
                //     if (res.success) {
                //         const comp = this.mapService.addEditLocationDialog.componentInstance;
                //         const activated = comp.isMonitorActivationRequired;

                //         if(! activated) {
                //             this.matDialog.open(ConfirmDialogComponent, {
                //                 disableClose: true,
                //                 data: {
                //                     title: this.PAGE_TEXT.WARNING,
                //                     message: this.PAGE_TEXT.WARNING_NOT_ACTIVE
                //                 }
                //             });
                //         }
                //         setTimeout(() => {
                //             this.validateNotification();
                //         }, 1000);
                //     }
                // }));
            },
            (error) => {},
        );
        this.subscriptions.push(locCardSub);
    }
    public openWetDryConfiguration(lid: number, location: string) {
        const parameters = {
            CID: this.customerId,
            IncludeInactiveLocations: false,
            IType: 'RainGauge',
            PageSize: 1000,
            StartPage: 1,
        };
        // get all rain gauge ids for that location
        const locationRainGaugeSubscription = this.customerService
            .getAllRainGauges(parameters)
            .subscribe((response: GetLocationsRainGaugeResponse[]) => {
                if (response) {
                    const rainGaugesList = response;
                    const availableRainGauges = [];
                    if (rainGaugesList.length > 0) {
                        rainGaugesList.forEach((locationInfo, index) => {
                            if (locationInfo.series === 'RainAlert II' || locationInfo.series === 'RainAlert III') {
                                availableRainGauges.push(locationInfo);
                            }
                        });
                    }
                    this.matDialog
                        .open(LocationWetDryOverflowComponent, {
                            disableClose: true,
                            data: {
                                customerId: this.customerId,
                                overidecustomer: false,
                                rainGaugeList: availableRainGauges,
                                locationName: location,
                                locationId: lid,
                            },
                        })
                        .afterClosed()
                        .subscribe((res) => {
                            if (res.success) {
                                setTimeout(() => this.validateNotification(), 1000);
                            }
                        });
                }
            });
        this.subscriptions.push(locationRainGaugeSubscription);
    }
    public removeLocationFromList(locId: number) {
        this.locationsOptions = this.locationsOptions.map((x) => {
            if (x.id === locId) {
                x.checked = false;
            }
            return x;
        });
        this.notificationObject.lids = this.locationsOptions.filter((x) => x.checked).map((x) => x.id);
        this.validationInfo.errors = this.validationInfo.errors.filter((x) => x.lid !== locId);

        if (this.notificationObject.lids.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LOCATIONS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            this.selectedIndex = 0;
        }
    }

    // CONFIRMATION TAB FUNCTIONS
    public convertIDtoUserNames(users: NotificationUser[]) {
        const usersByName = users.map((usr) => {
            const correctUser = this.userOptions.find((x) => x.id === usr.id);
            if (correctUser) {
                return correctUser.dispName;
            }
            return 'Unknown user';
        });
        return usersByName.sort();
    }
    public checkAllRequiredInputs(): boolean {
        if (this.notificationObject.name === null || this.notificationObject.name.trim() === '') {
            this.snackBar.open(this.PAGE_TEXT.MISSING_NAME, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => this.nameField.nativeElement.focus(), 0);
            return false;
        }

        this.notificationObject = {
            ...this.notificationObject,
            name: this.notificationObject.name.trim(),
            desc: this.notificationObject.desc ? this.notificationObject.desc.trim() : '',
        };
        const obj = this.notificationObject;
        if (obj.lids.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LOCATIONS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 0), 500);
            return false;
        } else if (obj.aids.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_ALARMS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 1), 500);
            return false;
        } else if (obj.levels.length === 0) {
            this.snackBar.open(this.PAGE_TEXT.NO_LEVELS_REMAINING, this.PAGE_TEXT.DISMISS, { duration: 10000 });
            setTimeout(() => (this.selectedIndex = 2), 500);
            return false;
        }

        return true;
    }
    public promptToUpdateSubscription(ind: number, alarm: boolean) {
        // If you are currently subscribed and want to unsubscribe, prompt before doing so
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.DISABLE_HEADER,
                    message: this.PAGE_TEXT.DISABLE_USER_CONFIRM,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    // Only actually update if they confirm
                    this.updateSubscription(ind, alarm);
                }
            });
    }
    public updateSubscription(ind: number, alarm: boolean) {
        const alarmUser = this.subscribeUser[ind];
        if (alarm) {
            alarmUser.alarm = !alarmUser.alarm;
        } else {
            alarmUser.rtn = !alarmUser.rtn;
        }

        const levelUsers = this.notificationObject.levels[ind].users;
        let myUser = levelUsers.find((x) => x.id === this.userID);
        if (myUser) {
            myUser.alarm = alarmUser.alarm;
            myUser.rtn = alarmUser.rtn;
        } else {
            levelUsers.push({ id: this.userID, alarm: alarmUser.alarm, rtn: alarmUser.rtn, admin: false });
        }
        const subscribed = alarm ? alarmUser.alarm : alarmUser.rtn;
        const updateSubscription = this.notificationService
            .updateNotification(this.customerId, this.notificationObject)
            .subscribe(
                () => {
                    let responseText = subscribed
                        ? this.PAGE_TEXT.SUBSCRIBE_TEXT.SUBSCRIBE_SUCCESS
                        : this.PAGE_TEXT.SUBSCRIBE_TEXT.UNSUBSCRIBE_SUCCESS;
                    responseText = responseText.concat(this.notificationObject.levels[ind].name);
                    this.snackBar.open(responseText, this.PAGE_TEXT.DISMISS, { duration: 10000 });
                },
                (error) => {
                    let responseText = subscribed
                        ? this.PAGE_TEXT.SUBSCRIBE_TEXT.SUBSCRIBE_ERROR
                        : this.PAGE_TEXT.SUBSCRIBE_TEXT.UNSUBSCRIBE_ERROR;
                    responseText = responseText.concat(this.notificationObject.levels[ind].name, error);
                    this.snackBar.open(responseText, this.PAGE_TEXT.DISMISS);

                    // Reset icons back to how they were
                    if (alarm) {
                        alarmUser.alarm = !alarmUser.alarm;
                    } else {
                        alarmUser.rtn = !alarmUser.rtn;
                    }
                    myUser = { ...myUser, alarm: alarmUser.alarm, rtn: alarmUser.rtn };
                },
            );
        this.subscriptions.push(updateSubscription);

        if(myUser && (!myUser.alarm && !myUser.rtn)) {
            this.notificationObject.levels[ind].users = this.notificationObject.levels[ind].users.filter(x => x.rtn || x.alarm);
        }
    }
    public confirm() {
        let cnt = 1;
        this.notificationObject.levels.forEach((x) => (x.level = cnt++));

        const saveSubscription = this.notificationService
            .updateNotification(this.customerId, this.notificationObject)
            .subscribe(
                (res: any) => {
                    if (res && res.isError === false) {
                        this.actuallyGoBack();
                    } else {
                        this.snackBar.open(this.PAGE_TEXT.CONFIRM_ERROR, this.PAGE_TEXT.DISMISS, { duration: 10000 });
                    }
                },
                (error) => {
                    this.snackBar.open(this.PAGE_TEXT.CONFIRM_ERROR, this.PAGE_TEXT.DISMISS);
                },
            );
        this.subscriptions.push(saveSubscription);
    }
    // END CONFIRMATION TAB FUNCTIONS

    ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.forEach((v) => v.unsubscribe());
    }
}
