<div class="non-printable contentLayout | sanitize: 'style'">
    <mat-drawer-container class="flex-container layout-row no-wrap ipadWidgetMapView map sidebardrawer">
        <mat-drawer
            mode="side"
            [(opened)]="sidebaropen"
            #drawer
            opened
            (mouseleave)="sidebarPinView()"
            [disableClose]="true"
            class="sidedrawer"
            [ngClass]="{ 'sidedrawer-expanded': !toggleState, 'disabledDiv': uiService.isDisabled(), 'width-no-scrolls': availablePageHint }"
        >
            <mat-toolbar>
                <span class="toolbar-spacer"></span>
                <button
                    mat-button
                    (click)="sidebarPin()"
                    class="pinsidebar pin-hint"
                    matTooltip="{{ 'GIS_TOOLTIPS.PUSHPIN' | translate }}"
                >
                    <mat-icon *ngIf="toggleSidePin">push_pin</mat-icon>
                    <mat-icon
                        *ngIf="!toggleSidePin"
                        class="pinrotate"
                    >push_pin</mat-icon>
                </button>
            </mat-toolbar>
            <!-- *ngIf condition here to fix mat-tab-group glitch causing tabs are shifted and not visible, bug #21292. -->
            <mat-tab-group
                class="location-dashboard-tab"
                (animationDone)="domOperationUtilsService.homeDashboardTabChangeComplete.next(null)"
                *ngIf="activeLayersTab !== undefined && activeLayersTab !== null"
                (selectedTabChange)="clickOnSidebarTab($event)"
                [(selectedIndex)]="activeLayersTab"
                mat-stretch-tabs
            >
                <mat-tab>
                    <ng-template
                        mat-tab-label
                        style="background-color: #fff !important"
                    >
                        <div class="tab-label-container widgets-hint">
                            <mat-icon class="tab-icon">widgets</mat-icon> <span>Widget</span>
                        </div>
                    </ng-template>

                    <div
                        id="leaderBoardContainer"
                        class="leaderBoardContainer widgets-list"
                    >
                        <mat-card
                            class="carddisplay alarm-hint"
                            *ngIf="currentWidget === widgetOptions.ALL || currentWidget === widgetOptions.ALARM"
                        >
                            <div
                                class="alaramLeaderBoard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.ALARM,
                                    'hint-background': availablePageHint
                                }"
                                (click)="loadWidgetDetail(widgetOptions.ALARM)"
                            >
                                <app-loader [isLoading]="alarmLoadingState"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon>alarm</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-content">
                                            <div class="item-value">{{ activeAlarmCount }}</div>
                                            <div class="item-text text-wrap">
                                                {{ 'HOME.ALARMS_TILE.ITEM_TEXT' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.ALARMS_TILE.TITLE' | translate }}</span>
                                </div>
                            </div>
                        </mat-card>
                        <mat-card
                            class="carddisplay battery-hint"
                            *ngIf="currentWidget === widgetOptions.ALL || currentWidget === widgetOptions.BATTERY"
                        >
                            <div
                                matRipple
                                class="batteryLeaderBoard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.BATTERY,
                                    'hint-background': availablePageHint
                                }"
                                (click)="loadWidgetDetail(widgetOptions.BATTERY)"
                            >
                                <app-loader [isLoading]="batteryLoadingState"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon>battery_full</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-col">
                                            <div class="item-content">
                                                <div class="item-value">
                                                    {{ batteryTotalCount?.criticalBatteryTotal }}
                                                </div>
                                                <div class="item-text text-wrap">
                                                    {{ 'HOME.BATTERY_TILE.ITEM_TEXT_Critical' | translate }}
                                                </div>
                                            </div>
                                            <div class="item-content">
                                                <div class="item-value">{{ batteryTotalCount?.lowBatteryTotal }}</div>
                                                <div class="item-text text-wrap">
                                                    {{ 'HOME.BATTERY_TILE.ITEM_TEXT_LOW' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.BATTERY_TILE.TITLE' | translate }}</span>
                                </div>
                            </div>
                        </mat-card>
                        <mat-card
                            class="carddisplay data-comm-hint"
                            *ngIf="
                                currentWidget === widgetOptions.ALL ||
                                currentWidget === widgetOptions.DATA_COMMUNICATION
                            "
                        >
                            <div
                                matRipple
                                class="collectionLeaderBoard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.DATA_COMMUNICATION,
                                    'hint-background': availablePageHint
                                }"
                                (click)="loadWidgetDetail(widgetOptions.DATA_COMMUNICATION)"
                            >
                                <app-loader [isLoading]="collectionTileLoadingState"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon>filter_none</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-content">
                                            <div class="item-value">{{ failedCollection }}</div>
                                            <div class="item-text text-wrap tile-width">
                                                {{
                                                'HOME.COLLECTION_TILE.NOT_COLLECTED_IN_LAST_24_HR.DATA_COMMUNICATION_WIDGET_TILE'
                                                | translate
                                                }}
                                            </div>
                                        </div>
                                        <div
                                            class="item-row"
                                            *ngIf="false"
                                        >
                                            <div class="item-content">
                                                <div class="item-value">{{ failedCollection }}</div>
                                                <div class="item-text text-wrap">
                                                    {{ 'HOME.COLLECTION_TILE.ITEM_TEXT_FAILED' | translate }}
                                                </div>
                                            </div>
                                            <div class="item-content">
                                                <div class="item-text text-wrap">
                                                    {{ 'HOME.COLLECTION_TILE.ITEM_TEXT_NEXT_SCHEDULE' | translate }}
                                                </div>
                                                <div>
                                                    <b class="strong">{{ upcomingScheduleCollection }}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.COLLECTION_TILE.TITLE' | translate }}</span>
                                </div>
                            </div>
                        </mat-card>
                        <mat-card
                            class="carddisplay blockage-predict-hint"
                            *ngIf="
                                (currentWidget === widgetOptions.ALL || currentWidget === widgetOptions.BLOCKAGE_PREDICT) && subscriptionLevel !== SubscriptionLevel.Prism
                            "
                        >
                            <div
                                class="blockagePDLeaderBoard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.BLOCKAGE_PREDICT,
                                    'hint-background': availablePageHint
                                }"
                                (click)="loadWidgetDetail(widgetOptions.BLOCKAGE_PREDICT)"
                            >
                                <app-loader [isLoading]="bpLoadingState"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon class="border-outer">clear</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-content">
                                            <div
                                                class="item-value"
                                                *ngIf="widgetsPermissionBp"
                                            >
                                                {{ blockagePredictionCount }}
                                            </div>
                                            <div class="item-text text-wrap">
                                                {{ 'HOME.BLOCKAGE_PREDICTION_TILE.ITEM_TEXT' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.BLOCKAGE_PREDICTION_TILE.TITLE' | translate }}</span>
                                </div>
                                <div
                                    *ngIf="betaFlagBlockagePermission"
                                    class="betaLabel"
                                >
                                    {{ 'COMMON.BETA_LABEL' | translate }}
                                </div>
                            </div>
                        </mat-card>
                        <mat-card
                            class="carddisplay auto-review-hint"
                            *ngIf="
                                isAutoReviewEnabled && 
                                (currentWidget === widgetOptions.ALL || currentWidget === widgetOptions.AUTO_REVIEW)
                            "
                        >
                            <div
                                class="autoReviewLeaderBoard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.AUTO_REVIEW,
                                    'hint-background': availablePageHint
                                }"
                                (click)="loadWidgetDetail(widgetOptions.AUTO_REVIEW)"
                            >
                                <app-loader [isLoading]="autoReviewLoadingState"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon>filter_center_focus</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-col">
                                            <div class="item-content">
                                                <div
                                                    class="item-value"
                                                    *ngIf="widgetsPermissionAutoReview"
                                                >
                                                    {{ autoReviewCount?.requireReview }}
                                                </div>
                                                <div class="item-text text-wrap">
                                                    {{ 'HOME.AUTO_REVIEW_TILE.ITEM_TEXT_ANAMOLIES' | translate }}
                                                </div>
                                            </div>
                                            <div class="item-content">
                                                <div
                                                    class="item-value"
                                                    *ngIf="widgetsPermissionAutoReview"
                                                >
                                                    {{ autoReviewCount?.suggestedReview }}
                                                </div>
                                                <div class="item-text text-wrap">
                                                    {{ 'HOME.AUTO_REVIEW_TILE.ITEM_TEXT_OTHER' | translate }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.AUTO_REVIEW_TILE.TITLE' | translate }}</span>
                                    <span></span>
                                </div>
                                <div
                                    *ngIf="betaFlagAutoReviewPermission"
                                    class="betaLabel"
                                >
                                    {{ 'COMMON.BETA_LABEL' | translate }}
                                </div>
                            </div>
                        </mat-card>

                        <mat-card
                            class="carddisplay event-widget-hint"
                            *ngIf="(usersService.isEventEditorOnCustomerAllowed | async).value && (currentWidget === widgetOptions.ALL || currentWidget === widgetOptions.EVENT)"
                        >
                            <div
                                class="event-leaderboard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.EVENT
                                }"
                                (click)="loadWidgetDetail(widgetOptions.EVENT)"
                            >
                                <app-loader [isLoading]="bpLoadingState"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon class="border-outer">event</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-content">
                                            <div class="item-value">
                                                {{ last30daysEventsCount ? last30daysEventsCount : 0 }}
                                            </div>
                                            <div class="item-text text-wrap">
                                                {{ 'HOME.EVENT_TILE.ITEM_TEXT' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.EVENT_TILE.TITLE' | translate }}</span>
                                </div>
                            </div>
                        </mat-card>
                        <mat-card
                            class="carddisplay event-widget-hint"
                            *ngIf="(usersService.isCumulativeRainAllowed | async).value && (currentWidget === widgetOptions.ALL || currentWidget === widgetOptions.CUMULATIVE_RAIN)"
                        >
                            <div
                                class="cumulative-rain-leaderboard leaderBoard-item"
                                [ngClass]="{
                                    activeLeaderBoard: currentWidget === widgetOptions.CUMULATIVE_RAIN
                                }"
                                (click)="loadWidgetDetail(widgetOptions.CUMULATIVE_RAIN)"
                            >
                                <app-loader [isLoading]="bpLoadingState || bpLoadingLocations"></app-loader>
                                <div class="lB-item-top">
                                    <div class="lB-item-top-lft">
                                        <mat-icon class="border-outer">cloudy</mat-icon>
                                    </div>
                                    <div class="lB-item-top-rgt">
                                        <div class="item-content">
                                            <div class="item-value">
                                                {{ cumulativeRainInfo ? cumulativeRainInfo.value : '' }}
                                            </div>
                                            <div class="item-text text-wrap">
                                                {{ cumulativeRainInfo ? cumulativeRainInfo.description : ''}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="lB-item-but">
                                    <span>{{ 'HOME.CUMULATIVE_RAIN_TILE.TITLE' | translate }}</span>
                                </div>
                            </div>
                        </mat-card>
                        <!--  ALARMS WIDGET -->
                        <mat-card
                            id=""
                            class="widgetSection alarmLeaderBoardContent"
                            *ngIf="currentWidget === widgetOptions.ALARM"
                            [ngClass]="{ 'full-width-leaderboard': !showMap, 'auto-width-leaderboard': showMap }"
                        >
                            <div class="activeWidgeHeader">
                                <span class="alarm-title-hint alarm-sm-title-hint">{{ 'HOME.ALARMS_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span>
                                    <mat-icon
                                        *ngIf="!toggleState"
                                        class="alarm-filter-hint"
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        [ngClass]="{ active: showAlarmSearch }"
                                        (click)="showSearchAlarms()"
                                    >
                                        filter_list</mat-icon>
                                    <mat-icon
                                        *ngIf="showMap"
                                        class="alarm-sm-expand-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(false)"
                                    >fullscreen</mat-icon>
                                    <mat-icon
                                        *ngIf="!showMap"
                                        class="alarm-collapse-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(true); showAlarmSearch ? showSearchAlarms() : null"
                                    >fullscreen_exit</mat-icon>
                                    <mat-icon
                                        class="alarm-close-hint alarm-sm-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget(); showAlarmSearch ? showSearchAlarms() : null"
                                    >close
                                    </mat-icon>
                                </span>
                            </div>
                            <app-loader [isLoading]="alarmLoadingState"></app-loader>
                            <app-alarm-widget
                                class="handleOverflow"
                                [showAlarmSearch]="showAlarmSearch"
                                (showMap)="showLocationMap($event)"
                                [toggleState]="toggleState"
                                (showMapLocationForAlarm)="onLocationsChange($event)"
                                (alarmStatusChange)="alarmStatusChange($event)"
                                (displayedAlarms)="zoomToLocationIDs($event)"
                                [customerID]="customerID"
                                [locationGroupID]="locationGroupID"
                                [customerLocationsLoaded]="customerLocationsLoaded"
                                [updateAlarmTable]="updateAlarmTable"
                                [includeInactiveLocations]="includeInactiveLocations"
                                [dateFormat]="dateFormat"
                                [timeFormat]="timeFormat"
                            >
                            </app-alarm-widget>
                        </mat-card>

                        <!--  BATTERY STATUS WIDGET -->
                        <mat-card
                            class="widgetSection batteryLeaderBoardContent"
                            *ngIf="currentWidget === widgetOptions.BATTERY"
                            [ngClass]="{ 'full-width-leaderboard': !showMap, 'auto-width-leaderboard': showMap }"
                        >
                            <div class="activeWidgeHeader">
                                <span class="battery-title-hint battery-sm-title-hint">{{ 'HOME.BATTERY_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span [ngClass]="{ 'widget-toolbard-hint': availablePageHint }">
                                    <mat-icon
                                        class="battery-filter-hint battery-sm-filter-hint"
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        [ngClass]="{ active: showBatteryStatusSearch }"
                                        (click)="showSearchBatteryStatus()"
                                    >filter_list</mat-icon>
                                    <mat-icon
                                        *ngIf="showMap"
                                        class="battery-sm-expand-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(false)"
                                    >fullscreen</mat-icon>
                                    <mat-icon
                                        *ngIf="!showMap"
                                        class="battery-fullscreen-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(true)"
                                    >fullscreen_exit</mat-icon>
                                    <mat-icon
                                        class="battery-close-hint battery-sm-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                </span>
                            </div>
                            <app-loader [isLoading]="batteryLoadingState"></app-loader>
                            <app-battery-status-widget
                                class="handleOverflow"
                                [showBatteryStatusSearch]="showBatteryStatusSearch"
                                [toggleState]="toggleState"
                                (showMapLocationForBattery)="onLocationsChange($event)"
                                [customerID]="customerID"
                                [locationGroupID]="locationGroupID"
                                [dateFormat]="dateFormat"
                                [customerLocationsLoaded]="customerLocationsLoaded"
                                [reloadReport]="reloadBatteryLocations$"
                                [includeInactiveLocations]="includeInactiveLocations"
                                [availablePageHint]="availablePageHint"
                                (emitWidgetData)="setWidgetData($event)"
                            >
                            </app-battery-status-widget>
                        </mat-card>

                        <!--  BLOCKAGE PREDICTION WIDGET -->
                        <mat-card
                            class="widgetSection blockagePredictionLeaderBoardContent auto-width-leaderboard"
                            *ngIf="currentWidget === widgetOptions.BLOCKAGE_PREDICT"
                        >
                            <div class="activeWidgeHeader showBetaLabel">
                                <span>{{ 'HOME.BLOCKAGE_PREDICTION_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span>
                                    <mat-icon
                                        *ngIf="widgetsPermissionBp"
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        class="blockage-filter-hint"
                                        [ngClass]="{ active: showBlockagePredictionSearch }"
                                        (click)="showSearchBlockagePrediction()"
                                    >filter_list</mat-icon>
                                    <mat-icon
                                        class="blockage-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                    <div
                                        *ngIf="betaFlagBlockagePermission"
                                        class="betaLabel"
                                    >
                                        {{ 'COMMON.BETA_LABEL' | translate }}
                                    </div>
                                </span>
                            </div>
                            <div *ngIf="widgetsPermissionBp">
                                <app-loader [isLoading]="bpLoadingState"></app-loader>
                                <app-blockage-prediction-widget
                                    class="handleOverflow"
                                    [showBlockagePredictionSearch]="showBlockagePredictionSearch"
                                    (showMapLocationForBlockagePrediction)="onLocationsChange($event)"
                                    [customerID]="customerID"
                                    [locationGroupID]="locationGroupID"
                                    [dateFormat]="dateFormat"
                                    [timeFormat]="timeFormat"
                                    [customerLocationsLoaded]="customerLocationsLoaded"
                                    [includeInactiveLocations]="includeInactiveLocations"
                                    (emitWidgetData)="setWidgetData($event)"
                                >
                                </app-blockage-prediction-widget>
                            </div>
                            <div *ngIf="!widgetsPermissionBp">
                                <mat-card-content class="betaContent">
                                    <div class="top"></div>
                                    <p>
                                        {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.ACCESS_INFORMATION' | translate }}
                                    </p>
                                    <strong>{{
                                        'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_HEADING' | translate
                                        }}</strong>
                                    {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_HEADING1' | translate
                                    }}<sup>{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SUP_TM' | translate }}</sup>
                                    {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_HEADING2' | translate }}
                                    <ul>
                                        <li>
                                            {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT1' | translate }}
                                        </li>
                                        <li>
                                            {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT2' | translate }}
                                        </li>
                                        <li>
                                            {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT3' | translate }}
                                        </li>
                                    </ul>
                                    <p>
                                        <a
                                            href="http://www.adsenv.com"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {{
                                            'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT4' | translate
                                            }}</a>
                                        {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT5' | translate }}
                                        <a
                                            href="http://www.adsenv.com/sales"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {{
                                            'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT6' | translate
                                            }}</a>
                                        {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.PREDICTION_ENABLE_POINT7' | translate }}
                                    </p>
                                </mat-card-content>
                            </div>
                        </mat-card>

                        <!--  AUTO REVIEW WIDGET  -->
                        <mat-card
                            class="widgetSection dataReviewLeaderBoardContent"
                            *ngIf="currentWidget === widgetOptions.AUTO_REVIEW && isAutoReviewEnabled"
                            [ngClass]="{ 'full-width-leaderboard': !showMap, 'auto-width-leaderboard': showMap }"
                        >
                            <div class="activeWidgeHeader showBetaLabel">
                                <span>{{ 'HOME.AUTO_REVIEW_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span
                                    *ngIf="widgetsPermissionAutoReview"
                                    [ngClass]="{ 'widget-toolbard-hint': availablePageHint }"
                                >
                                    <!-- <mat-icon class="material-icons"
                                         matTooltip="{{'COMMON.REFRESH_TOOLTIP' | translate}}"
                                        (click)="initiateAnomaliesRefresh()">refresh</mat-icon> -->
                                    <mat-icon
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        [ngClass]="{ active: showAutoReviwSearch }"
                                        class="ar-filter-hint"
                                        (click)="showSearchAutoReviw()"
                                    >
                                        filter_list</mat-icon>
                                    <mat-icon
                                        *ngIf="showMap"
                                        class="ar-full-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(false)"
                                    >fullscreen</mat-icon>
                                    <mat-icon
                                        *ngIf="!showMap"
                                        class="ar-full-close-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(true)"
                                    >fullscreen_exit</mat-icon>
                                    <mat-icon
                                        class="ar-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                    <div
                                        *ngIf="betaFlagAutoReviewPermission"
                                        class="betaLabel"
                                    >
                                        {{ 'COMMON.BETA_LABEL' | translate }}
                                    </div>
                                </span>
                                <span *ngIf="!widgetsPermissionAutoReview">
                                    <mat-icon
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        class="ar-close-hint"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                    <div
                                        *ngIf="betaFlagAutoReviewPermission"
                                        class="betaLabel"
                                    >
                                        {{ 'COMMON.BETA_LABEL' | translate }}
                                    </div>
                                </span>
                            </div>
                            <div
                                *ngIf="widgetsPermissionAutoReview"
                                id="ar-widget-container"
                                class="handleOverflow"
                                [ngClass]="{'hide-scroll': availablePageHint}"
                            >
                                <app-loader [isLoading]="autoReviewLoadingState"></app-loader>
                                <app-auto-review-widget
                                    [availablePageHint]="availablePageHint"
                                    [showAutoReviwSearch]="showAutoReviwSearch"
                                    [toggleState]="toggleState"
                                    (showMap)="showLocationMap($event)"
                                    (displayedData)="zoomToLocationIDs($event)"
                                    (showMapLocationForAutoReview)="onLocationsChange($event)"
                                    [customerID]="customerID"
                                    [locationGroupID]="locationGroupID"
                                    [dateFormat]="dateFormat"
                                    [timeFormat]="timeFormat"
                                    [customerLocationsLoaded]="customerLocationsLoaded"
                                    [includeInactiveLocations]="includeInactiveLocations"
                                    (emitWidgetData)="setWidgetData($event)"
                                >
                                </app-auto-review-widget>
                            </div>
                            <div
                                *ngIf="!widgetsPermissionAutoReview"
                                class="handleOverflow"
                            >
                                <mat-card-content class="betaContent">
                                    <div class="top"></div>
                                    <h3>{{ 'COMMON.THANK_YOU_MESSAGE_HEADING' | translate }}</h3>
                                    <p>
                                        {{ 'HOME.AUTO_REVIEW_WIDGET.ACCESS_INFORMATION' | translate }}
                                    </p>
                                    {{ 'HOME.AUTO_REVIEW_WIDGET.REVIEW_ENABLE_HEADING' | translate }}
                                    <ul>
                                        <li>
                                            {{ 'HOME.AUTO_REVIEW_WIDGET.REVIEW_ENABLE_POINT1' | translate }}
                                        </li>
                                        <li>
                                            {{ 'HOME.AUTO_REVIEW_WIDGET.REVIEW_ENABLE_POINT2' | translate }}
                                        </li>
                                        <li>
                                            {{ 'HOME.AUTO_REVIEW_WIDGET.REVIEW_ENABLE_POINT3' | translate }}
                                        </li>
                                    </ul>
                                    <p>
                                        <a
                                            href="http://www.adsenv.com"
                                            target="_blank"
                                            rel="noopener"
                                        >Learn more</a>
                                        about Auto Review, or
                                        <a
                                            href="http://www.adsenv.com/sales"
                                            target="_blank"
                                            rel="noopener"
                                        >Contact your ADS sales representative</a>
                                        to activate Auto Review in FlowView PRISM.
                                    </p>
                                </mat-card-content>
                            </div>
                        </mat-card>

                        <!--  COLLECTION WIDGET -->
                        <mat-card
                            class="widgetSection collectionLeaderBoardContent"
                            *ngIf="currentWidget === widgetOptions.DATA_COMMUNICATION"
                            [ngClass]="{ 'full-width-leaderboard': !showMap, 'auto-width-leaderboard': showMap }"
                        >
                            <div class="activeWidgeHeader">
                                <span class="data-comm-title-hint">{{ 'HOME.COLLECTION_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span [ngClass]="{ 'widget-toolbard-hint': availablePageHint }">
                                    <mat-icon
                                        class="data-comm-filter-hint"
                                        *ngIf="!toggleState"
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        [ngClass]="{ active: showCollectionSearch }"
                                        (click)="showSearchCollection()"
                                    >
                                        filter_list</mat-icon>
                                    <mat-icon
                                        class="data-comm-full-hint"
                                        *ngIf="showMap"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(false)"
                                    >fullscreen</mat-icon>
                                    <mat-icon
                                        class="data-comm-full-hint"
                                        *ngIf="!showMap"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(true)"
                                    >fullscreen_exit</mat-icon>
                                    <mat-icon
                                        class="data-comm-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                </span>
                            </div>
                            <app-loader [isLoading]="collectionLoadingState"></app-loader>
                            <app-collection-widget
                                class="handleOverflow"
                                [showCollectionSearch]="showCollectionSearch"
                                [toggleState]="toggleState"
                                (showMap)="showLocationMap($event)"
                                (showMapLocationForCollectionWidget)="onLocationsChange($event)"
                                [customerID]="customerID"
                                [locationGroupID]="locationGroupID"
                                [dateFormat]="dateFormat"
                                [customerLocationsLoaded]="customerLocationsLoaded"
                                (displayedData)="zoomToLocationIDs($event)"
                                [availablePageHint]="availablePageHint"
                                [includeInactiveLocations]="includeInactiveLocations"
                                (emitWidgetData)="setWidgetData($event)"
                            >
                            </app-collection-widget>
                        </mat-card>

                        <!--  EVENT WIDGET -->
                        <mat-card
                            class="widgetSection collectionLeaderBoardContent"
                            *ngIf="currentWidget === widgetOptions.EVENT"
                            [ngClass]="{ 'full-width-leaderboard': !showMap, 'auto-width-leaderboard': showMap }"
                        >
                            <div class="activeWidgeHeader">
                                <span class="data-comm-title-hint">{{ 'HOME.EVENT_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span [ngClass]="{ 'widget-toolbard-hint': availablePageHint }">
                                    <mat-icon
                                        *ngIf="usersService.isEventEditorOnUserAllowed | async"
                                        class="add-event-hint"
                                        matTooltip="{{ 'HOME.EVENT_WIDGET.ADD_EVENT_TOOLTIP' | translate }}"
                                        (click)="addEvent()"
                                    >add_circle</mat-icon>
                                    <mat-icon
                                        class="event-filters-hint"
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        [ngClass]="{ active: showEventSearch }"
                                        (click)="showEventFilters()"
                                    >filter_list</mat-icon>
                                    <mat-icon
                                        *ngIf="showMap"
                                        class="event-full-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(false)"
                                    >fullscreen</mat-icon>
                                    <mat-icon
                                        *ngIf="!showMap"
                                        class="event-full-close-hint"
                                        matTooltip="{{ 'COMMON.FULLSCREEN_EXIT_TOOLTIP' | translate }}"
                                        (click)="toggleWidgetSize(true)"
                                    >fullscreen_exit</mat-icon>
                                    <mat-icon
                                        class="event-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                </span>
                            </div>
                            <app-loader [isLoading]="collectionLoadingState"></app-loader>
                            <app-event-widget
                                class="handleOverflow full-height"
                                [locationGroupID]="locationGroupID"
                                id="eventWidget"
                                [customerID]="customerID"
                                [availablePageHint]="availablePageHint"
                                [toggleState]="toggleState"
                                [showEventSearch]="showEventSearch"
                                [userPermission]="usersService.isEventEditorOnUserAllowed | async"
                                [events]="events"
                                (GISevent)="onGISevent($event)"
                                [includeInactiveLocations]="includeInactiveLocations"
                                (updateEvents)="getEventsTotal($event)"
                            ></app-event-widget>
                        </mat-card>

                        <!-- CUMULATIVE_RAIN-->
                        <mat-card
                            class="widgetSection collectionLeaderBoardContent"
                            *ngIf="currentWidget === widgetOptions.CUMULATIVE_RAIN"
                            [ngClass]="{ 'full-width-leaderboard': !showMap, 'auto-width-leaderboard': showMap }"
                        >
                            <div class="activeWidgeHeader">
                                <span class="data-comm-title-hint">{{ 'HOME.CUMULATIVE_RAIN_TILE.TITLE' | translate }}</span>
                                <span class="app-flex-filler"></span>
                                <span [ngClass]="{ 'widget-toolbard-hint': availablePageHint }">
                                    <mat-icon
                                        class="event-filters-hint"
                                        matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                                        [ngClass]="{ active: showEventSearch }"
                                        (click)="showCumulativeRainFilters()"
                                    >filter_list</mat-icon>
                                    <mat-icon
                                        class="event-close-hint"
                                        matTooltip="{{ 'COMMON.CLOSE' | translate }}"
                                        (click)="closeWidget()"
                                    >close
                                    </mat-icon>
                                </span>
                            </div>
                            <app-loader [isLoading]="collectionLoadingState"></app-loader>
                            <ads-cumulative-rain-widget
                                class="handleOverflow full-height"
                                [locationGroupID]="locationGroupID"
                                [customerID]="customerID"
                                [includeInactiveLocations]="includeInactiveLocations"
                                [filters]="showCumulativeRainSearch"
                                (cumulativeRainReport)="onCumulativeRain($event)"
                                (cumulativeRainInfo)="onCumulativeRainInfo($event)"
                            ></ads-cumulative-rain-widget>
                        </mat-card>
                    </div>
                </mat-tab>
                <mat-tab class="location-dashboard-tab">
                    <ng-template mat-tab-label>
                        <div class="tab-label-container layers-hint">
                            <mat-icon class="tab-icon">layers</mat-icon> <span>Layers</span>
                        </div>
                    </ng-template>
                    <mat-toolbar>
                        <span class="toolbar-spacer"></span>
                        <button
                            mat-button
                            [matMenuTriggerFor]="basemapmenu"
                            class="basemap-hint"
                            matTooltip="{{ 'GIS_TOOLTIPS.BASE_MAP' | translate }}"
                        >
                            <mat-icon>apps</mat-icon> {{ 'HOME.MAP.BASEMAP' | translate }}
                        </button>
                        <mat-menu
                            #basemapmenu="matMenu"
                            class="baseMap"
                        >
                            <mat-button-toggle-group
                                #basemapgroup="matButtonToggleGroup"
                                [value]="this.activeBaseMapId"
                                (change)="changeShownBaseMap(basemapgroup.value)"
                            >
                                <mat-button-toggle
                                    *ngFor="let basemap of baseMapOptions"
                                    value="{{ basemap.baseMapId }}"
                                    class="basemapgrp"
                                    [ngClass]="{ activeMap: gisUserSetting.baseMapId === basemap.baseMapId }"
                                >
                                    <div><img
                                            class="basemapiconimg"
                                            src="{{ basemap.imageUrl }}"
                                        /></div>
                                    <div class="basemapname">{{ basemap.name }}</div>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </mat-menu>
                    </mat-toolbar>
                    <div class="leaderBoardContainer">
                        <app-location-layer-list
                            #layerlist
                            class="layerList"
                            [locations]="locations"
                            [mapLayerList]="mapLayerLists"
                            (labelsChanged)="monitorLocationUpdate(false)"
                            (layerSelection)="layersTabLayerAdjust($event, true)"
                            (opacityChange)="layersTabLayerAdjust($event, false)"
                            [availablePageHint]="availablePageHint"
                            (zoomtolayer)="zoomToSelectedLayer($event)"
                            (refreshMap)="updateMap()"
                        >
                        </app-location-layer-list>
                    </div>
                    <div></div>
                </mat-tab>
                <mat-tab class="location-dashboard-tab">
                    <ng-template mat-tab-label>
                        <div class="tab-label-container tools-hint">
                            <mat-icon class="tab-icon">settings</mat-icon> <span>Tools</span>
                        </div>
                    </ng-template>
                    <mat-toolbar
                        color="primary"
                        class="gis-tools"
                        [ngClass]="{ 'hint-background': availablePageHint, 'tool-toolbar-on-hint': availablePageHint }"
                    >
                        <div>
                            <button
                                mat-icon-button
                                #selectMenuTrigger
                                class="selection-menu"
                                [disabled]="!isAnyLayerSelected"
                                [matMenuTriggerFor]="selectionMenu"
                                matTooltip="{{ 'GIS_TOOLTIPS.SELECT_FEATURES' | translate }}"
                            >
                                <mat-icon [ngClass]="{'disabled': !isAnyLayerSelected}">highlight_alt</mat-icon>
                            </button>
                            <mat-menu #selectionMenu="matMenu">
                                <button
                                    mat-menu-item
                                    (click)="startsSelection(geometryOptions.POINT)"
                                    class="select-feat-hint"
                                >
                                    <span>Select Feature(s)</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="startsSelection(geometryOptions.POLYGON)"
                                    class="select-poly-hint"
                                >
                                    <span>Select Features by Polygon</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="startsSelection(geometryOptions.CIRCLE)"
                                    class="select-radius-hint"
                                >
                                    <span>Select Features by Radius</span>
                                </button>
                            </mat-menu>
                            <button
                                mat-icon-button
                                class="clear-tool-selection-hint"
                                matTooltip="{{ 'GIS_TOOLTIPS.CLEAR_FEATURES' | translate }}"
                                (click)="clearSelection()"
                            >
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                            <span class="example-spacer"></span>
                            <button
                                mat-icon-button
                                #measureMenuTrigger
                                [matMenuTriggerFor]="measurementMenu"
                                matTooltip="{{ 'GIS_TOOLTIPS.MEASURE' | translate }}"
                                class="measure-menu"
                            >
                                <mat-icon>design_services</mat-icon>
                            </button>
                            <mat-menu #measurementMenu="matMenu">
                                <button
                                    mat-menu-item
                                    (click)="startMeasure(geometryOptions.POLYGON)"
                                >
                                    <mat-icon> format_shapes</mat-icon>
                                    <span>Measure Area</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="startMeasure(geometryOptions.LINE_STRING)"
                                >
                                    <mat-icon>straighten</mat-icon>
                                    <span>Measure Length</span>
                                </button>
                                <button
                                    mat-menu-item
                                    (click)="clearMeasure()"
                                >
                                    <mat-icon>delete_outline</mat-icon>
                                    <span>Clear</span>
                                </button>
                            </mat-menu>
                        </div>
                        <a
                            id="image-download"
                            download="PRISMMap.png"
                        ></a>
                        <button
                            mat-icon-button
                            matTooltip="{{ 'GIS_TOOLTIPS.DOWNLOAD_MAP' | translate }}"
                            (click)="downloadMap()"
                        >
                            <mat-icon>download</mat-icon>
                        </button>
                    </mat-toolbar>
                    <div
                        class="tools-content grabber row leaderBoardContainer"
                    >
                        <div
                            class="top cell features-list"
                        >
                            <app-location-feature-property-list
                                #selectionlist
                                [locations]="locations"
                                [availablePageHint]="availablePageHint"
                                [activeNodeItem]="activeMonitor"
                                (featureLocationZoom)="zoomToSelectedFeature($event)"
                                (featureselected)="featureselected($event)"
                                (refreshMap)="updateMap()"
                                (featureLocationEdit)="featureLocationEdit($event)"
                            >
                            </app-location-feature-property-list>
                        </div>
                        <div
                            class="bottom cell basic-information"
                            [directions]="['top']"
                            [rFlex]="true"
                            *ngIf="propertyItemList.length > 0"
                        >
                            <div class="scrollwrap">
                                <table class="prop-items-hint">
                                    <tr *ngFor="let pitem of propertyItemList">
                                        <td class="label">{{ pitem.name }}</td>
                                        <td class="value">{{ pitem.value }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-drawer>
        <mat-drawer
            [(opened)]="sidebaropen2 && toggleState"
            #drawer2
            opened
            (mouseleave)="sidebarPinView2()"
            mode="side"
            class="sidebardrawer secondPanel"
            position="end"
            [ngClass]="{'disabledDiv': uiService.isDisabled()}"
        >
            <mat-toolbar>
                <button
                    mat-button
                    (click)="sidebarPin2()"
                    class="pinsidebar"
                    matTooltip="{{ 'GIS_TOOLTIPS.PUSHPIN' | translate }}"
                >
                    <mat-icon *ngIf="toggleSidePin2">push_pin</mat-icon>
                    <mat-icon
                        *ngIf="!toggleSidePin2"
                        class="pinrotate"
                    >push_pin</mat-icon>
                </button>
                <div
                    class="btn"
                    (click)="LocationDashboard()"
                    matTooltip="{{ 'GIS_TOOLTIPS.LINK_TO_DASHBOARD' | translate }}"
                >
                    {{ activeMonitor }}
                </div>
                <span class="toolbar-spacer"></span>
            </mat-toolbar>
            <app-location-gis-quick-look
                [ngClass]="{ hide: !activeMonitor }"
                #gisQuickLook
                [locationGroupID]="locationGroupID"
                [isOpened]="sidebaropen2"
                [customerID]="customerID"
                [locationEntityData]="locationEntityData"
                (preventUnpinnedClose)="setPreventStatus($event)"
            ></app-location-gis-quick-look>
        </mat-drawer>
        <mat-drawer-content
            class="flex-container layout-row no-wrap alarm-sm-map-hint ipadWidgetMapView map mapdrawer"
            [ngClass]="{'disabledDiv': uiService.isMapDisabled()}"
        >
            <button
                *ngIf="!sidebaropen"
                (click)="openSideBar(true)"
                class="sidebarhandlebutton"
            >
                <mat-icon class="sidebarbtnicon">keyboard_arrow_right</mat-icon>
            </button>
            <div class="flex-container layout-row no-wrap ipadWidgetMapView map map-overview-hint">
                <div
                    id="olmap"
                    class="olmap"
                ></div>
                <div class="map-attribution">
                    <span>{{ attributionText }}</span>
                    <span>
                        {{ 'HOME.MAP.ATTRIBUTIONS.POWERED_BY' | translate }}
                        <a href="https://www.esri.com" target="_blank" rel="noopener noreferrer" class="esri-attribution">
                            {{ 'HOME.MAP.ATTRIBUTIONS.ESRI_ATTRIBUTION' | translate }}
                        </a>
                    </span>
                </div>
                <div class="mapLegend" *ngIf="displayMapRains.customLegend">
                    <table>
                        <tr *ngFor="let item of stormYearsLegend.StormYearsColors; let i = index">
                            <td class="colorbox"><div [ngStyle]="{'background-color': item.color}"></div></td>
                            <td class="units">{{('HOME.YEAR_STORM_LEGEND.' + item.translationKey) | translate }} {{'HOME.YEAR_STORM_LEGEND.YEAR_STORM' | translate }}</td>
                        </tr>
                    </table>
                </div>
                <div
                    #popup
                    class="popup"
                    [hidden]="true"
                ></div>
                <canvas
                    style="display: none"
                    id="imageCanvas"
                >
                    <canvas
                        id="canvasID"
                        #canvasId
                    ></canvas>
                </canvas>
                <a
                    id="image-download"
                    href=""
                    download="map.png"
                ></a>
                <button
                    mat-button
                    class="mapbuttons zoominbtn"
                    [ngClass]="{ 'zoom-in-on-hint': availablePageHint }"
                    (click)="zoomIn()"
                >
                    +
                </button>
                <button
                    mat-button
                    class="mapbuttons zoomoutbtn"
                    [ngClass]="{ 'zoom-out-on-hint': availablePageHint }"
                    (click)="zoomOut()"
                >
                    -
                </button>
                <button
                    mat-button
                    class="mapbuttons zoommaxbtn map-btn-hint"
                    [ngClass]="{ 'zoom-max-on-hint': availablePageHint }"
                    (click)="zoomToInital()"
                    matTooltip="{{ 'GIS_TOOLTIPS.HOME_ICON' | translate }}"
                >
                    <mat-icon class="mapicons">home</mat-icon>
                </button>
                <button
                    mat-button
                    class="mapbuttons map-gps-btn"
                    (click)="getGPSLocation()"
                    matTooltip="{{ 'GIS_TOOLTIPS.CURRENT_LOCATION' | translate }}"
                    [ngClass]="{ 'zoom-gps-on-hint': availablePageHint }"
                >
                    <mat-icon class="mapicons">gps_not_fixed</mat-icon>
                </button>
                <div
                    id="scaleline"
                    class="scaleline map-scale-hint"
                ></div>
                <form
                    id="mapLocationAutoInput"
                    class="search-loc-hint"
                >
                    <mat-form-field floatLabel="never">
                        <mat-icon
                            class="locationSearch"
                            matPrefix
                        >search</mat-icon>
                        <input
                            type="text"
                            placeholder="{{ 'COMMON.SEARCH_FEATURES_PLACEHOLDER' | translate }}"
                            matInput
                            [formControl]="searchControl"
                            [matAutocomplete]="matLocationSearch"
                        />
                        <mat-autocomplete
                            #matLocationSearch="matAutocomplete"
                            (optionSelected)="getLocationSearchobj($event.option.value)"
                            [displayWith]="locationSearchDisplay"
                        >
                            <mat-option
                                *ngFor="let option of searchBarLocation | async"
                                [value]="option"
                                [ngClass]="{ 'unchecked-search-item' : option.unchecked }"
                                [disabled]="option.unchecked"
                            >
                                <ng-container>
                                    <mat-icon *ngIf="option.type === 'feature'">category</mat-icon>
                                    <mat-icon *ngIf="option.type === 'location'">place</mat-icon>
                                    {{ option.name }}
                                </ng-container>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
                <!--</mat-card>-->
            </div>
            <button
                *ngIf="!sidebaropen2"
                (click)="openSideBar2(true)"
                class="sidebarhandlebutton right"
                matTooltip="{{ 'GIS_TOOLTIPS.GIS_TOOLTIPS' | translate }}"
            >
                <mat-icon class="sidebarbtnicon">keyboard_arrow_left</mat-icon>
            </button>
        </mat-drawer-content>
    </mat-drawer-container>
    <!--  MAP -->
</div>
<img id="print-map-dashboard" />
