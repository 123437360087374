import {
    Component,
    ViewChild,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { CustomerFeatureComponent } from 'app/pages/admin/customer-feature/customer-feature.component';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SharedService } from 'app/shared/services/shared.service';
import { CustomerService } from 'app/shared/services/customer.service';
import {
    Active,
    All,
    AppQueryParams,
    customerFilter,
    customerQueryParam,
    activeInactiveLocationQueryParam,
    GetCustomerFeatureResponse,
    GetCustomerSummary,
    InActive,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
    userSearchParam,
    SubscriptionTier,
    SubscriptionsInfoTier,
    SubscriptionsInfoFeatures,
} from 'app/shared/models/customer';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { USER_ROLES } from '../users.service';
const PAGESIZE = 25;
const MINPAGESIZE = 0;
@Component({
    selector: 'app-admin-customer-editor',
    templateUrl: './admin-customer.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./admin-customer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCustomerComponent implements OnInit, OnDestroy {
    public searchString = new FormControl();
    public customers = new Array<GetCustomerSummary>();
    public customerSummary = new Array<GetCustomerSummary>();
    public filteredData = new Array<GetCustomerSummary>();
    private subscriptions = new Array<Subscription>();
    public customerDataChange: BehaviorSubject<GetCustomerSummary[]> = new BehaviorSubject<GetCustomerSummary[]>([]);
    public initialData = new BehaviorSubject<GetCustomerSummary[]>([]);
    public customerDataSource: FilterDataSource | null;
    public totalPaginationLength: number;
    @ViewChild(MatPaginator) public userPaginator: MatPaginator;
    @ViewChild(MatSort) public userSort: MatSort;
    @ViewChild(MatSort) public customerSort: MatSort;
    public customerFilterColumns = [
        'name',
        'activeLocations',
        'inactiveLocations',
        'subscriptionLevel',
        'addOns',
        'active',
        'editCustomer',
    ];
    public customerEdited: number;
    public customerEditedName: string;
    public showNewCustomerEditor: boolean;
    public isMatTableVisible = true;
    public userDataSource: FilterDataSource | null;
    public loggedInUserRole: string;
    public isCustomer = true;
    public addNewCustomerVisible: boolean;
    /**
     * Indicates the loading state of the component.
     */
    public isLoading: boolean;
    public allCustomerFeatures = new Array<GetCustomerFeatureResponse>();
    public featureList = new Array<GetCustomerFeatureResponse>();
    public showCustomerSearch: boolean;
    public activeInactiveList: any[];
    public activeInactiveCustomers = new Array<GetCustomerSummary>();
    public selectedCustomerID: number;
    public customerID: number;
    public currentCustomerID: number;
    public includeActiveInactiveLocations: number;
    public showUpdateCustomerEditor: boolean;
    public activateStatus: boolean;
    public snackbarSuccessMsg: string;
    public snackbarErrMsg: string;
    public dismissBtnText: string;
    public isCustomerStatusChanged: boolean;
    public startPage: number;
    public pageSize: number;
    public searchValue: string;
    public isCustomerEdited: boolean;
    public customerName: string;
    public isActiveInactiveFilter: boolean;
    public eventValue: string;
    public rowCount: number;
    public inActivationHeader: string;
    public inActivationMessage: string;
    public cancel: string;
    public confrim: string;
    public pageIndex: number;
    public dontShowActiveInactiveTab: boolean;
    public availablePageHint = false;
    public isActive: string;
    public subscriptionLevelTiers: SubscriptionsInfoTier[] = [];
    public subscriptionLevelFeatures: string[] = [];
    public availableAddOns: SubscriptionsInfoFeatures[] = [];

    public filters = {
        subscriptionLevels: new Array<number>(),
        assignedAddOns: new Array<number>()
    }

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private sharedService: SharedService,
        private customerService: CustomerService,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {
        /*  Search by user name & roles  */
        this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.searchValue = res;
                this.isCustomer = true;
                this.getCustomerSummary();
                // this.filterCustomers(res);
            });
    }

    public ngOnInit() {
        // Checks if page hint is available
        this.subscriptions.push(
            this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
                this.availablePageHint = result;
                this.uiUtilsService.safeChangeDetection(this.cdr);

                if (this.availablePageHint) {
                    document.body.style.overflow = 'hidden';
                    this.domOperationUtilsService.scrollToTop('html');
                } else {
                    document.body.style.overflow = 'auto';
                }
            }),
        );
        this.subscriptions.push(
            this.customerService.getSubscriptionInfo().subscribe((res) => {
                this.subscriptionLevelTiers = [];
                this.subscriptionLevelFeatures = [];
                for(const tier of res.subscriptions) {
                    this.subscriptionLevelTiers[tier.level] = tier;
                    let description = '';
                    for(const feature of tier.features) {
                        if(description !== '') description += ', ';
                        description += `${feature.displayname}`;
                    }
                    this.subscriptionLevelFeatures[tier.level] = description;

                }
                this.availableAddOns = res.availableAddOns;
            })
        )
        this.dontShowActiveInactiveTab = true;
        this.statusCodeService.userInfo.subscribe((res) => {
            if (res.userRole) this.loggedInUserRole = res.userRole[0];
            this.dontShowActiveInactiveTab = this.loggedInUserRole !== 'Admin' && this.loggedInUserRole !== 'CustomerAdmin';
            this.addNewCustomerVisible = this.loggedInUserRole === USER_ROLES.ADMIN ;
        });

        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtnText = res;
        });
        this.translate.get('ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_SUCCESS_MESSAGE').subscribe((res: string) => {
            this.snackbarSuccessMsg = res;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.translate.get('ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_ERROR_MESSAGE').subscribe((res: string) => {
            this.snackbarErrMsg = res;
        });
        this.translate.get('ADMIN.CONFIRM_INACTIVATION_TEXT').subscribe((res: string) => {
            this.inActivationHeader = res;
        });
        this.translate.get('ADMIN.INACTIVATION_MESSAGE').subscribe((res: string) => {
            this.inActivationMessage = res;
        });
        this.translate.get('ADMIN.CONFIRM').subscribe((res: string) => {
            this.confrim = res;
        });
        this.translate.get('ADMIN.CANCEL').subscribe((res: string) => {
            this.cancel = res;
        });
        this.isLoading = true;
        this.statusCodeService.customerIdInEditScreen.subscribe((customerId: number) => {
            this.customerEdited = customerId;
        });
        this.statusCodeService.customerIdInEditName.subscribe((customerName: string) => {
            this.customerEditedName = customerName;
        });
        // subscribe to route change
        if (this.customerEditedName !== '' && this.customerEditedName !== undefined) {
            this.statusCodeService.customerIdInEditName.next('');
            this.activatedRoute.queryParamMap.subscribe(
                (params: ParamMap) => {
                    this.searchValue = params.get(userSearchParam);
                    this.startPage = Number(params.get(userSearchPageIndexParam));
                    this.pageSize = Number(params.get(userSearchPageSizeParam));
                    this.pageIndex = Number(params.get(userSearchPageIndexParam));
                    this.isCustomerEdited = true;
                },
                (error) => {
                    // error block
                },
            );
        }
        //  get all the users
        if (this.searchValue) {
            this.searchString.setValue(this.searchValue);
        }
        this.getCustomerSummary();
        // get all features for user/customer
        const customeFeatureSubscription = this.sharedService
            .getFeatures()
            .subscribe((response: GetCustomerFeatureResponse[]) => {
                if (response) {
                    this.allCustomerFeatures = response;
                    this.featureList = response.filter((x) => x.canassigncustomer);
                }
            });
        this.subscriptions.push(customeFeatureSubscription);

        this.activeInactiveList = [
            { value: '1', text: 'All' },
            { value: '2', text: 'Active' },
            { value: '3', text: 'Inactive' },
        ];
        // get current query params for customer
        this.currentCustomerID = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));
        this.includeActiveInactiveLocations = Number(this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam));
    }
    /**
     * Method activate/inactivate customers based on toggle
     * @param customerId and activateStatus
     */
    public activateCustomer(customer: GetCustomerSummary, customerId: number) {
        this.customerName = customer.name;
        if (customer.active) {
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.inActivationHeader,
                        message: this.inActivationMessage,
                        okText: this.confrim,
                        cancelText: this.cancel,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.isLoading = true;
                        this.changeState(customerId, false);
                    } else {
                        this.generateUserTable(this.customerSummary);
                    }
                });
        } else {
            this.isLoading = true;
            this.changeState(customerId, true);
        }
    }
    public changeState(customerId: number, status: boolean) {
        this.customerService.activateStatus(customerId, status).subscribe(
            (response) => {
                this.isCustomerStatusChanged = true;
                this.isCustomer = true;
                this.getCustomerSummary();
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                // snak bar message when error occured
                this.snackBar.open(this.snackbarErrMsg + ' ' + this.customerName, this.dismissBtnText, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Method sets customer search filter to show /hide
     * @param value - show/hide customer search filters
     */
    public showCustomersFilters(value?: boolean) {
        if (value == null) {
            this.showCustomerSearch = !this.showCustomerSearch;
            if (this.showCustomerSearch === false) {
                this.filterCustomers();
                this.filters.assignedAddOns = [];
            }
            return;
        }
        this.showCustomerSearch = value;
    }
    /**
     * Method filters customer based on selected feature
     * @param value - $event which is an array of features
     */
    public getCustomerByAddOns(event) {
        this.isCustomer = true;
        this.filters.assignedAddOns = [];
        const isAddOnSelected = event.length > 0;
        if (isAddOnSelected) {
            this.filters.assignedAddOns.push(...event);
        }
        this.getCustomerSummary();
    }
    public onSubscriptionFilterChange(event: number[]) {
        this.filters.subscriptionLevels = event;
        this.isCustomer = true;
        this.getCustomerSummary();
    }
    /**
     * Method generates a table of customers based on active/inactive flag
     */
    public onSelection(event) {
        this.isCustomer = true;
        if (event === Active) {
            this.isActive = customerFilter.Active;
        } else if (event === InActive) {
            this.isActive = customerFilter.Inactive;
        } else if (event === All) {
            this.isActive = customerFilter.All;
        }
        this.getCustomerSummary();
    }

    public filterCustomers() {
        this.filteredData = this.customerSummary;

        this.filteredData =
            this.eventValue === 'Active' || this.eventValue === 'Inactive'
                ? this.filteredData.filter((customer) => customer.active === (this.eventValue === 'Active'))
                : this.customerSummary;
        this.isMatTableVisible = this.filteredData.length > 0;
        this.totalPaginationLength = this.filteredData.length;
        this.generateUserTable(this.filteredData);
    }
    public onPageChange(event): void {
        this.isCustomer = true;
        this.getCustomerSummary(event);
    }
    public getCustomerSummary(event?) {
        if (this.filters.assignedAddOns && this.filters.assignedAddOns.length > 0) {
            this.filters.assignedAddOns = this.filters.assignedAddOns.filter(
                (item, index) => this.filters.assignedAddOns.indexOf(item) === index,
            );
        }
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        if (event) {
            this.startPage = event.pageIndex + 1;
            this.pageSize = event.pageSize;
        } else if (!this.isCustomerEdited) {
            this.pageSize = 25;
            this.startPage = 0;
        }
        if (this.isCustomerEdited) {
            this.startPage = this.pageIndex + 1;
            this.pageSize = this.pageSize === MINPAGESIZE ? (this.pageSize = PAGESIZE) : this.pageSize;
            this.userPaginator.pageIndex = this.startPage - 1;
            this.userPaginator.pageSize = this.pageSize;
        }

        // get all customer summary
        const customersummarySubscription = this.customerService
            .getCustomerSummaryV2(
                this.startPage,
                this.pageSize,
                this.searchValue,
                this.isActive,
                this.filters.subscriptionLevels,
                this.filters.assignedAddOns,
                false,
            )
            .subscribe(
                (sub) => {
                    if (this.isCustomer) {
                        const customerSummaryData = this.customerService.customerSummary;
                        if (customerSummaryData && customerSummaryData.length > 0) {
                            if (this.isCustomerStatusChanged) {
                                // snak bar message
                                const simpleSnackBarRef = this.snackBar.open(
                                    this.customerName + ' ' + this.snackbarSuccessMsg,
                                    this.dismissBtnText,
                                );
                                setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                            }
                            this.rowCount = this.customerService.summaryRowCount;
                            this.isCustomerEdited = false;
                            this.isCustomerStatusChanged = false;
                            this.customers = this.customerSummary = this.filteredData = customerSummaryData;
                            this.initialData.next(this.customers);
                            this.generateUserTable(this.customerSummary);
                            this.isLoading = false;
                            this.customerService.summaryRowCount = undefined;
                            this.customerService.customerSummary = undefined;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        }
                        this.isLoading = false;
                        this.isMatTableVisible = customerSummaryData.length > 0;
                        this.isCustomer = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.customerService.summaryRowCount = undefined;
                    this.customerService.customerSummary = undefined;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );
        this.subscriptions.push(customersummarySubscription);
    }
    /**
     * Method scroll to top
     */
    public scrollTop() {
        this.domOperationUtilsService.scrollToTop('#customerGroupEditorTable');
    }

    /**
     * Method to add new customer
     * @param value - customerId which is an number
     */
    public updateCustomer(customer: GetCustomerSummary, customerId?: number) {
        // set customer id for editing
        this.statusCodeService.customerStatus.next(customer.active);
        this.statusCodeService.customerIdInEditName.next(customer.name);
        this.selectedCustomerID = customerId;
        this.customerID = customerId;
        this.openCreateCustomerEditor(true);
        this.statusCodeService.addNewCustomer.next(false);
        this.showUpdateCustomerEditor = true;
        this.scrollTop();
        this.statusCodeService.editedCustomerData.next(customer);
        this.router.navigate(['/pages/customerEditor/details'], {
            queryParams: <AppQueryParams>{
                c: this.currentCustomerID,
                cid: customerId,
                lt: this.includeActiveInactiveLocations,
                s: !!this.searchString.value ? this.searchString.value : undefined,
                pi: !this.userPaginator.pageIndex ? 0 : Number(this.userPaginator.pageIndex),
                ps: this.userPaginator.pageSize || undefined,
            },
            relativeTo: this.activatedRoute,
        });
    }
    /**
     * Method to open customer editor
     */
    public openCreateCustomerEditor(isFromUpdate = false) {
        if (!isFromUpdate) {
            this.statusCodeService.addNewCustomer.next(true);
        }
        this.scrollTop();
        this.showNewCustomerEditor = true;
        this.showUpdateCustomerEditor = false;
        this.includeActiveInactiveLocations = Number(this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam));
        this.router.navigate(['/pages/customerEditor'], {
            queryParams: <AppQueryParams>{
                c: this.currentCustomerID,
                lt: this.includeActiveInactiveLocations
            },
            relativeTo: this.activatedRoute,
        });
    }
    /**
     * Method generates a table of customers
     */
    private generateUserTable(customers) {
        this.isLoading = true;
        this.customerDataSource = null;
        this.filteredData = [];
        this.customerDataChange = new BehaviorSubject<GetCustomerSummary[]>([]);
        const customerCopiedData = this.filteredData;
        customers.map((customer: GetCustomerSummary) => {
            if (this.customerEdited !== null && this.customerEdited === customer.id) {
                customer.highlightRow = true;
                this.statusCodeService.customerIdInEditScreen.next(-1);
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.isCustomer = false;
            }
            customerCopiedData.push(customer);
            this.customerDataChange.next(customerCopiedData);
        });
        this.totalPaginationLength = this.rowCount;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.customerDataSource = new FilterDataSource(
            this.customerDataChange,
            customerCopiedData,
            this.userPaginator,
            this.userSort,
            this.customerFilterColumns,
            true,
        );
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Destroy the subscription data
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Method brings up a pop up window with customer Features
     * @param customer the customer data object.
     */
    public editCustomerFeatures(customer: GetCustomerSummary) {
        // Open the dialog component with the features list
        this.matDialog
            .open(CustomerFeatureComponent, {
                disableClose: true,
                data: {
                    customer: customer,
                    featureList: this.allCustomerFeatures,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.success) {
                    this.isLoading = true;
                    this.getCustomerSummary();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
    }
}
